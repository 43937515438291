import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, Modal, ModalBody } from "reactstrap";

import "./style.scss";
import { SVG } from "../../AbstractElements";
import useIsMobile from "../../utils/helper/responsive";
import CourseSummaryModal, {
  CourseSummaryModalData,
} from "../CourseSummaryModal";
import MUIIcons from "../MUIIcon/MUIIcons";

interface CourseData {
  id: number;
  icon: string;
  count: number;
  label: string;
  infoData: CourseSummaryModalData[];
}

interface CourseSummaryProps {
  data: CourseData[];
}

const CourseSummary: React.FC<CourseSummaryProps> = ({ data }) => {
  const isMobile = useIsMobile();
  const [openModal, setOpenModal] = useState<{ [key: number]: boolean }>({});

  const toggleModal = (id: number) => {
    setOpenModal(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Card
      className={`course-summary-card ${isMobile && "course-summary-card-mobile"}`}
    >
      <CardBody
        className={`course-summary-body ${isMobile && "course-summary-body-mobile"}`}
      >
        {data.map((item, index) => (
          <div
            key={item.id}
            className={`course-summary-item ${isMobile && "course-summary-item-mobile"}`}
          >
            <SVG iconId={item.icon} className="icon-circle" />
            <div className="d-flex flex-row gap-2">
              <h3 className="custom-count">{item.count}</h3>
              <MUIIcons
                className="mt-1 pointer"
                iconName="InfoOutlined"
                size={18}
                onClick={() => toggleModal(item.id)}
              />
            </div>
            <Modal
              isOpen={openModal[item.id]}
              toggle={() => toggleModal(item.id)}
            >
              <ModalBody>
                <CourseSummaryModal cardData={item.infoData} />
              </ModalBody>
            </Modal>
            <span className="custom-label-text">{item.label}</span>
            {index < data.length - 1 && !isMobile && (
              <div
                className={`vertical-divider ${isMobile && "vertical-divider-mobile"}`}
              ></div>
            )}
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

CourseSummary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      infoData: PropTypes.arrayOf(
        PropTypes.exact({
          courseName: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};
export default CourseSummary;
