import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import { ALL_USERS_STATUS, TAG_QUESTION_BANK } from "../../../Api/constants";
import { QuestionBankListItem } from "../../../Api/entities/QuestionBankEntity";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import QuestionBankCard from "../../../container/GenericCard";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getTagsList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  deleteModuleOrQb,
  getAssessmentQuestionBankList,
  setQuestionBankPayloadToInitials,
} from "../../../ReduxToolkit/Reducers/QuestionBankSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../Service";
import {
  AddNew,
  Confirm,
  DeleteQB,
  DeleteQuestionBankHeader,
  Draft,
  Module,
  NoDataText,
  NotYetAdded,
  Question,
  Select,
  SelfAssessment,
  Tags,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  questionBankTabs,
  selefAssesmentOptions,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import useIsMobile from "../../../utils/helper/responsive";

const QuestionBankList = () => {
  const dispatch = useAppDispatch();
  const { questionBankList } = useAppSelector(state => state.questionBank);
  const [activeTab, setActiveTab] = useState<string>(Draft);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const { usersList } = useAppSelector(state => state.user);
  const { tagList } = useAppSelector(state => state.master);
  const [userMap, setUserMap] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQb, setSelectedQb] = useState(null);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [tagsListData, setTagsListData] = useState([]);
  const [tagsListDataIdsMap, setTagsListDataIdsMap] = useState({});
  const isMobile = useIsMobile();

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
  };

  const handleSearch = (query: string) => {
    const filteredCurriculum = questionBankList?.question_banks?.filter(bank =>
      bank.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCurriculum || []);
    setNoDataFound(!filteredCurriculum || filteredCurriculum?.length === 0);
  };

  useEffect(() => {
    dispatch(getTagsList({ tagType: TAG_QUESTION_BANK }));
  }, [dispatch]);

  useEffect(() => {
    const tags = [];
    const tagsMap = {};
    if (tagList) {
      tagList?.tags?.forEach((item, ind) => {
        tagsMap[ind] = item?.ids;
        tags.push({
          name: ind,
          value: item?.tag_name,
          label: item?.tag_name,
        });
      });
    }
    setTagsListData(tags);
    setTagsListDataIdsMap(tagsMap);
  }, [tagList]);

  useEffect(() => {
    fetchQuestionBank();
  }, [activeTab]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const fetchQuestionBank = async () => {
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(
      getAssessmentQuestionBankList({
        status: activeTab,
        tag: null,
        self_assessment: null,
      })
    ).then(response => {
      if (response?.payload?.question_bank?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const onHeaderDropdownClick = e => {
    e.preventDefault();
    toggleModal();
  };

  const renderQuestionBankCard = (bank: QuestionBankListItem, index) => {
    return (
      <Col onClick={() => setSelectedQb(index)} key={index} sm={12} lg={3}>
        <Link
          to={"/master-data/add-question-bank"}
          state={{ questionBankId: bank?.id }}
        >
          <QuestionBankCard
            id={index}
            header={bank?.name}
            footerDate={formatCustomDate(bank?.created_at)}
            onHeaderDropdownClick={onHeaderDropdownClick}
            isHideMoreOption
            userCardWithoutDetail={{
              imagePath: dynamicImage("dashboard-2/user/2.png"),
              name:
                userMap &&
                bank?.created_by &&
                userMap[bank?.created_by]?.user_name,
            }}
            moduleDetails={[
              {
                label: Module,
                value: bank?.no_of_modules?.toString(),
                key: `${bank.id}_${Module}`,
              },
              {
                label: Question,
                value: bank?.no_of_question?.toString(),
                key: `${bank.id}_${Question}`,
              },
            ]}
          />
        </Link>
      </Col>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const tagIds = [];
    if (values["tags"]) {
      values["tags"]?.forEach(item => {
        if (tagsListDataIdsMap[item]) tagIds.push(...tagsListDataIdsMap[item]);
      });
    }

    let selfAssessmentValue: string | null = null;

    if (values["self_assessment"]) {
      selfAssessmentValue =
        values["self_assessment"].toString() === "no" ? "0" : "1";
    }

    const params: { status: string; tag: string; self_assessment: string } = {
      status: activeTab,
      tag: tagIds?.toString() || null,
      self_assessment: selfAssessmentValue,
    };

    await dispatch(getAssessmentQuestionBankList(params));
  };

  const handleClear = async () => {
    await dispatch(
      getAssessmentQuestionBankList({
        status: activeTab,
        tag: null,
        self_assessment: null,
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const renderContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    if (questionBankList?.question_banks?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }

    if (searchResults?.length > 0) {
      return searchResults.map((curriculum, index) =>
        renderQuestionBankCard(curriculum, index)
      );
    } else {
      return questionBankList?.question_banks?.map((curriculum, index) =>
        renderQuestionBankCard(curriculum, index)
      );
    }
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteQuestionBankHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={() => {
          dispatch(deleteModuleOrQb(selectedQb));
          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteQB}
      </CommonModal>
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 align-items-center">
              <JustifyTabs
                tabs={questionBankTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Tags,
                      key: "tags",
                      tooltipText: `${Select} ${Tags}`,
                      options: tagsListData,
                      isMultiSelect: true,
                    },
                    {
                      label: SelfAssessment,
                      key: "self_assessment",
                      tooltipText: `${Select} ${SelfAssessment}?`,
                      options: selefAssesmentOptions,
                      isMultiSelect: false,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              {hasPermissionToComponent("CREATE_QUESTION_BANK") && (
                <Link
                  to={"/master-data/add-question-bank"}
                  onClick={() => dispatch(setQuestionBankPayloadToInitials())}
                  state={{ questionBankId: null }}
                >
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderContent()}</Row>
    </div>
  );
};

export default QuestionBankList;
