import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, Col } from "reactstrap";

import { H4 } from "../../AbstractElements";
import SearchBar from "../../CommonElements/SearchBar";
import UserCardDetails from "../../CommonElements/UserCardDetails";
import { ListEmployee } from "../../utils/Constant";

import "./style.scss";

interface UserCard {
  imagePath: string;
  name: string;
  detail: string;
  id: number;
}

interface EmployeeListProps {
  userCardWithDetail: UserCard[];
  showCard?: boolean;
  deleteParticipant?: boolean;
  onParticipantDelete?: any;
}

const EmployeeList: React.FC<EmployeeListProps> = ({
  userCardWithDetail,
  showCard = true,
  deleteParticipant = false,
  onParticipantDelete = () => {
    return;
  },
}) => {
  const [selectedEmp, setSelectedEmp] = useState<number>(0);

  const handleCardClick = (id: number) => {
    setSelectedEmp(id);
  };

  const handleSearch = (value: number | string) => {
    return value;
  };

  const content = (
    <>
      {userCardWithDetail.map((user: any) => (
        <div
          key={user.id}
          onClick={() => handleCardClick(user.id)}
          className={`pointer employee-list__card ${
            selectedEmp === user.id ? "employee-list__selected" : ""
          }`}
        >
          <UserCardDetails
            id={user.id}
            imagePath={user.imagePath}
            name={user.name}
            otherDetails={true}
            detail={user.detail}
            showDelete={deleteParticipant}
            onDelete={onParticipantDelete}
            empId={user?.empId}
            phoneNumber={user?.phoneNumber}
            showOtherDetails
          />
        </div>
      ))}
    </>
  );

  return (
    <Col className="employee-list">
      {showCard ? (
        <Card>
          <CardBody>
            <H4>{ListEmployee}</H4>
            <SearchBar
              showSearch={true}
              className="mt-4 mb-4"
              onSearch={handleSearch}
            />
            {content}
          </CardBody>
        </Card>
      ) : (
        content
      )}
    </Col>
  );
};

EmployeeList.propTypes = {
  userCardWithDetail: PropTypes.array.isRequired,
  showCard: PropTypes.bool,
};

export default EmployeeList;
