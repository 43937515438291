import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H3, H4, H5, Image } from "../../../../AbstractElements";
import Divider from "../../../../CommonElements/Divider";
import IconHeader from "../../../../CommonElements/IconHeader";
import VerticalDivider from "../../../../CommonElements/VericalDivider";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getCompletedSessionDetail } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { dynamicImage } from "../../../../Service";
import {
  Dates,
  Duration,
  KeyHighlights,
  Mentor,
  MyFeedback,
  NextSession,
} from "../../../../utils/Constant";
import {
  convertMinutesToHours,
  FeedbackQuestions,
  formatCustomDate,
  HighlightQuestions,
} from "../../../../utils/helper/helper";

const SessionDetailPage = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { sessionId } = state || {};
  const [formValues, setFormValues] = useState({
    topic: "",
    key_thoughts: "",
    start_date: "",
    duration: null,
    feedback: null,
    whats_not_working: "",
    whats_working_well: "",
    resources_required: "",
    next_session_date: "",
    mentor_id: null,
  });
  const { getCompletedSessionDetails } = useAppSelector(state => state.learner);

  useEffect(() => {
    if (sessionId) dispatch(getCompletedSessionDetail({ sessionId }));
  }, [dispatch]);

  useEffect(() => {
    setFormValues(prevState => ({
      ...prevState,
      topic: getCompletedSessionDetails?.topic,
      key_thoughts: getCompletedSessionDetails?.key_thoughts,
      start_date: getCompletedSessionDetails?.start_date,
      duration: getCompletedSessionDetails?.duration,
      feedback: getCompletedSessionDetails?.feedback,
      whats_not_working: getCompletedSessionDetails?.whats_not_working,
      whats_working_well: getCompletedSessionDetails?.whats_working_well,
      resources_required: getCompletedSessionDetails?.resources_required,
      next_session_date: getCompletedSessionDetails?.next_session_date,
      mentor_id: getCompletedSessionDetails?.mentor_id,
    }));
  }, [getCompletedSessionDetails]);

  return (
    <div className="page-body">
      <Card>
        <CardBody className="p-4">
          <H3 className="fw-bold mb-2">{formValues.topic}</H3>
          <p className="text-gray">{formValues.key_thoughts}</p>

          <Divider />
          <Row className="mt-3 d-flex align-items-start">
            <Col lg="2" xs="6" className="mb-4">
              <IconHeader
                assign={Dates}
                name={formatCustomDate(formValues.start_date)}
              />
            </Col>
            <Col lg="1" xs="1" className="text-center">
              <VerticalDivider />
            </Col>
            <Col lg="1" xs="6" className="mb-4">
              <IconHeader
                assign={Duration}
                name={convertMinutesToHours(formValues.duration)}
              />
            </Col>
            <Col lg="1" xs="1" className="text-center">
              <VerticalDivider />
            </Col>
            <Col lg="1" xs="6" className="mb-4 d-flex align-items-center">
              <IconHeader
                assign={Mentor}
                name={
                  <Image
                    key={formValues.mentor_id}
                    className="img-30 rounded-circle p-0"
                    src={dynamicImage("dashboard-4/user.png")}
                    alt="Mentor"
                  />
                }
              />
            </Col>
            <Col lg="1" xs="1" className="text-center">
              <VerticalDivider />
            </Col>
            {formValues.next_session_date && (
              <Col lg="2" xs="6" className="mb-4">
                <IconHeader
                  assign={NextSession}
                  name={formatCustomDate(formValues.next_session_date)}
                />
              </Col>
            )}
          </Row>

          <div className="custom-session-details-keyHighlights">
            <H4 className="fw-bold mt-2 p-2">{KeyHighlights}</H4>
            <Divider />

            <div className="d-flex flex-column gap-4 mt-3">
              <Col md={12}>
                <H5 className="text-black fw-bold mb-1">
                  {HighlightQuestions[0].question}
                </H5>
                <p>{formValues.whats_working_well}</p>
              </Col>
              <Col md={12}>
                <H5 className="text-black fw-bold mb-1">
                  {HighlightQuestions[1].question}
                </H5>
                <p>{formValues.whats_not_working}</p>
              </Col>
              <Col md={12}>
                <H5 className="text-black fw-bold mb-1">
                  {HighlightQuestions[2].question}
                </H5>
                <p>{formValues.resources_required}</p>
              </Col>
            </div>
          </div>
        </CardBody>
      </Card>

      {formValues.feedback && (
        <Card>
          <CardBody>
            <div className="p-2">
              <H4 className="fw-bold mt-2">{MyFeedback}</H4>
              <Divider />

              <div className="d-flex flex-column gap-4 p-1 mt-2">
                <Col md={12}>
                  <H5 className="text-black fw-bold mb-1">
                    {FeedbackQuestions[0].question}
                  </H5>
                  <p>{formValues.feedback?.key_changes_and_dev_goals}</p>
                </Col>
                <Col md={12}>
                  <H5 className="text-black fw-bold mb-1">
                    {FeedbackQuestions[1].question}
                  </H5>
                  <p>{formValues.feedback?.Key_actions}</p>
                </Col>
                <Col md={12}>
                  <H5 className="text-black fw-bold mb-1">
                    {FeedbackQuestions[2].question}
                  </H5>
                  <p>{formValues.feedback?.key_thoughts}</p>
                </Col>
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default SessionDetailPage;
