import PropTypes from "prop-types";
import React from "react";
import { CardText } from "reactstrap";

import "./style.scss";
import { selectionTypeMap } from "../../utils/helper/helper";
import Badges from "../Badge";

interface ModuleQuestionProps {
  id?: number;
  children: React.ReactNode;
  indicatorColor?: string;
  onQuestionClick?: (id: number, module_id: number, module: any) => void;
  module_id?: number;
  module?: any;
  status: string;
  showIndicator: boolean;
  type?: string;
}

const ModuleQuestion: React.FC<ModuleQuestionProps> = ({
  id,
  children,
  indicatorColor = "success",
  showIndicator = false,
  onQuestionClick = () => {
    return;
  },
  module_id,
  module,
  status,
  type = "",
}) => {
  return (
    <div
      className="module-question mb-3 pointer"
      onClick={() => onQuestionClick(id, module_id, module)}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          {showIndicator && (
            <div
              className={`indicator bg-${status === "upload_later" ? "danger" : indicatorColor} flex-shrink-0`}
            ></div>
          )}

          <CardText className="text-break">{children}</CardText>
        </div>
        {type && type !== "" && <Badges>{selectionTypeMap[type]}</Badges>}
      </div>
    </div>
  );
};

ModuleQuestion.propTypes = {
  children: PropTypes.node.isRequired,
  indicatorColor: PropTypes.string,
  onQuestionClick: PropTypes.func,
};

export default ModuleQuestion;
