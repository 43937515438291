import PropTypes from "prop-types";

import ProfileUserDetails from "./ProfileUserDetails";

import "./style.scss";

const ProfileDetail = ({ profileDetailData }) => {
  return (
    <div className="profile-container">
      <ProfileUserDetails data={profileDetailData} />
    </div>
  );
};

ProfileDetail.propTypes = {
  profileDetailData: PropTypes.exact({
    userProfileUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    userDetails: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number.isRequired,
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        detail: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ProfileDetail;
