import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H1, H2 } from "../../../AbstractElements";
import CourseSummary from "../../../CommonElements/CourseSummaryCard";
import MultiProgressBar from "../../../CommonElements/MultiProgressBar";
import TargetLevelCard from "../../../CommonElements/TargetLevelCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getMappedSgaList,
  getOverallStatistics,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import { getProficiencyList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import { OverallStatistics } from "../../../utils/Constant";
import {
  calculateValuePercentage,
  formatDate,
  ilpCourseSummaryData,
  multiProgressData,
} from "../../../utils/helper/helper";
import "./style.scss";

const ILPOverview = ({ userId, jobRoleId }) => {
  const dispatch = useAppDispatch();
  const [targetLevelInfoList, setTargetLevelInfoList] = useState([]);
  const { mappedSga } = useAppSelector(state => state.learningPlan);
  const { proficiencyList } = useAppSelector(state => state.master);
  const { overallStatistics } = useAppSelector(state => state.learningPlan);

  useEffect(() => {
    dispatch(getProficiencyList());
    dispatch(getOverallStatistics({ selfEnrollment: false, userId: userId }));
    dispatch(
      getMappedSgaList({
        type: "overview",
        userId: userId,
        jobRoleId: jobRoleId,
      })
    );
  }, []);

  useEffect(() => {
    const targetLevelInfo = mappedSga?.sga?.map(item => {
      const { total_assessment, passed_assessment, level_id, status, sga_id } =
        item;
      const percentageCompleted = calculateValuePercentage(
        passed_assessment,
        total_assessment
      );

      return {
        id: sga_id,
        expertiseLevel: getLevelNameById(level_id),
        totalCount: total_assessment,
        completedCount: passed_assessment,
        percentageCompleted: percentageCompleted,
        status: status,
        completedDate:
          status === "certified" && item.issued_at
            ? formatDate(item.issued_at)
            : "",
      };
    });

    setTargetLevelInfoList(targetLevelInfo);
  }, [mappedSga, proficiencyList]);

  const getLevelNameById = (id: number): string => {
    const level = proficiencyList?.proficiency?.find(level => level.id === id);
    return level ? level.level_name : "";
  };

  const transformData = () => {
    if (!userId) {
      return {
        targetLevel: "-",
        currentLevel: "-",
        percentageCompleted: "0",
      };
    }
    const currentLevelData = targetLevelInfoList?.find(
      item => item.status === "in_progress"
    );

    return {
      targetLevel: "-",
      currentLevel: currentLevelData?.expertiseLevel,
      percentageCompleted: currentLevelData?.percentageCompleted
        ? currentLevelData?.percentageCompleted
        : "0",
    };
  };

  return (
    <>
      <TargetLevelCard
        cardData={transformData()}
        infoData={userId ? targetLevelInfoList : []}
        userId={userId}
      />
      <Card className="p-2 mb-3">
        <CardBody>
          <Row className="justify-content-center gap-5 gap-lg-0">
            <Col className="mt-3" lg={4} xs={12}>
              <H2 className="text-black">{OverallStatistics}</H2>
              <H1 className="mt-1 custom-overall-percentage">
                {overallStatistics &&
                  `${calculateValuePercentage(
                    overallStatistics.completed_courses,
                    overallStatistics.total_courses
                  )}%`}
              </H1>
              <div className="mt-2">
                {overallStatistics?.total_courses > 0 && (
                  <MultiProgressBar
                    progressBars={multiProgressData(overallStatistics)}
                  />
                )}
              </div>
            </Col>
            <Col lg={8} xs={12}>
              {overallStatistics && (
                <CourseSummary data={ilpCourseSummaryData(overallStatistics)} />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

ILPOverview.propTypes = {
  userId: PropTypes.number,
  jobRoleId: PropTypes.number,
};
export default ILPOverview;
