import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import { DRAFT_STATUS, curriculumTabsStatusMap } from "../../../Api/constants";
import { CurriculumListItem } from "../../../Api/entities/CurriculumEntity";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import AssessmentCard from "../../../container/GenericCard";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCurriculumList } from "../../../ReduxToolkit/Reducers/CurriculumSlice";
import {
  getDomainList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddNew,
  Course,
  Domain,
  ILT,
  IsSpecialCurriculum,
  JobRole,
  Level,
  NoDataText,
  NotYetAdded,
  Role,
  Select,
  Special,
  VILT,
  WBT,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  curriculumTabs,
  mapListToOptions,
  convertMinutesToHours,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import "./style.scss";
import useIsMobile from "../../../utils/helper/responsive";

const CurriculumList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const [activeTab, setActiveTab] = useState<string>(statusParam || "draft");
  const { curriculumList } = useAppSelector(state => state.curriculum);
  const { domainList, roleList } = useAppSelector(state => state.master);

  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [domainListData, setDomainListData] = useState([]);
  const [roleListData, setRoleListData] = useState([]);
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getRolesList({}));
  }, []);

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    fetchCurriculum(curriculumTabsStatusMap[activeTab], selectedFilterValues);
  }, [activeTab]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    if (roleList) {
      const options = mapListToOptions(roleList.job_role, "name", "id");
      setRoleListData(options);
    }
  }, [roleList]);

  const fetchCurriculum = (tabStatus, filteredStatus) => {
    dispatch(
      getCurriculumList({
        status: tabStatus,
        curriculum_type: filteredStatus,
      })
    ).then(response => {
      if (response.payload.curriculum?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    navigate(`/master-data/curriculum?status=${tabId}`);
  };

  const handleSearch = (query: string) => {
    const filteredCurriculum = curriculumList?.curriculum?.filter(curriculum =>
      curriculum.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCurriculum || []);
    setNoDataFound(!filteredCurriculum || filteredCurriculum?.length === 0);
  };

  const getRoleNameById = id => {
    const role = roleList?.job_role?.find(role => role.id === id);
    return role ? role.name : "-";
  };

  const renderCurriculumCard = (curriculum: CurriculumListItem, index) => {
    const domainValue = curriculumList.domain[curriculum.id];
    const durationValue = curriculumList.duration[curriculum.id];
    const combinedIltViltDuration =
      durationValue?.total_ilt_duration + durationValue?.total_vilt_duration;

    const roleName = getRoleNameById(curriculum.role_id);
    return (
      <Col key={index} sm={12} lg={4}>
        <Link
          to={"/master-data/add-new-curriculum"}
          state={{ curriculumRequestId: curriculum.id }}
        >
          <AssessmentCard
            id={index}
            header={curriculum?.name}
            footerDate={formatCustomDate(curriculum?.created_at)}
            moduleDetails={[
              {
                label: Role,
                value: roleName,
                key: `${curriculum.id}-${Role}`,
              },
              {
                label: Level,
                value: curriculum.total_levels.toString(),
                key: `${curriculum.id}-${Level}`,
              },
              {
                label: Course,
                value: curriculum.total_courses.toString(),
                key: `${curriculum.id}-${Course}`,
              },
              {
                label: Domain,
                value: domainValue?.map(item => item.name)?.join(", ") || "-",
                key: `${curriculum.id}-${Domain}`,
              },
              {
                label: `${ILT}/${VILT}`,
                value: `${convertMinutesToHours(combinedIltViltDuration)} h`,
                key: `${curriculum.id}-${VILT}`,
              },
              {
                label: WBT,
                value: `${convertMinutesToHours(durationValue?.total_wbt_duration)} h`,
                key: `${curriculum.id}-${WBT}`,
              },
            ]}
            isHideMoreOption={true}
            footerBadge={curriculum.is_special_curriculum ? Special : ""}
            status={curriculum.status === DRAFT_STATUS ? "" : curriculum.status}
          />
        </Link>
      </Col>
    );
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    fetchCurriculum(curriculumTabsStatusMap[activeTab], {
      ...values,
      specialCurriculum: values?.specialCurriculum ? true : false,
    });
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    fetchCurriculum(curriculumTabsStatusMap[activeTab], {});
  };

  const renderCurriculumContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    if (curriculumList.curriculum?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }

    if (searchResults?.length > 0) {
      return searchResults.map((curriculum, index) =>
        renderCurriculumCard(curriculum, index)
      );
    }

    return curriculumList.curriculum?.map((curriculum, index) =>
      renderCurriculumCard(curriculum, index)
    );
  };

  return (
    <div className="page-body page-body-margin curriculum-list">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={curriculumTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Domain,
                      key: "domain",
                      tooltipText: `${Select} ${Domain}`,
                      options: domainListData,
                      isMultiSelect: true,
                    },
                    {
                      label: IsSpecialCurriculum,
                      key: "specialCurriculum",
                      tooltipText: `${Select} ${IsSpecialCurriculum}`,
                      isCheckbox: true,
                      isMultiSelect: false,
                    },
                    {
                      label: JobRole,
                      key: "role",
                      tooltipText: `${Select} ${JobRole}`,
                      options: roleListData,
                      isMultiSelect: false,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              {hasPermissionToComponent("CREATE_CURRICULUM") && (
                <Link to={"/master-data/add-new-curriculum"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderCurriculumContent()}</Row>
    </div>
  );
};

export default CurriculumList;
