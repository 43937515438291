import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Badge, Tooltip } from "reactstrap";

import BorderTabs from "../../../../CommonElements/BorderTabs";
import Divider from "../../../../CommonElements/Divider";
import CommonModal from "../../../../CommonElements/Modal";
import NoData from "../../../../container/NoData";
import UserProfileContainer from "../../../../container/UserProfileContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getAllMenteesUnderMentorList,
  unlinkMenteeFromMentor,
} from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getSbuList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUserDetailsList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Confirm,
  Menteess,
  NoDataText,
  UnLinkMentee,
  UnLinkMenteeConfirmMessage,
} from "../../../../utils/Constant";
import {
  formatCustomDate,
  MenteesListTabs,
  truncateText,
} from "../../../../utils/helper/helper";
import "./style.scss";

interface MenteesListProps {
  mentorDomainId: number;
  onActiveTabChange: (tabId: string) => void;
}

const MenteesList: React.FC<MenteesListProps> = ({
  mentorDomainId,
  onActiveTabChange,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState({
    id: "1",
    title: MenteesListTabs[0].title.toLowerCase(),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMentorMenteeId, setSelectedMentorMenteeId] =
    useState<number>(null);
  const [combinedMenteeData, setCombinedMenteeData] = useState([]);

  const { getAllMenteesUnderMentor } = useAppSelector(state => state.learner);
  const { userDetailsList } = useAppSelector(state => state.user);
  const { sbuList } = useAppSelector(state => state.master);

  const [sbuNames, setSbuNames] = useState<{ [key: number]: string }>({});
  const [tooltipOpen, setTooltipOpen] = useState<{
    [key: number]: boolean;
  }>({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getSbuList());
  }, [dispatch]);

  useEffect(() => {
    if (sbuList?.sbu) {
      const sbuMapping = sbuList.sbu.reduce(
        (map, sbuItem) => {
          map[sbuItem.id] = sbuItem.name;
          return map;
        },
        {} as { [key: number]: string }
      );
      setSbuNames(sbuMapping);
    }
  }, [sbuList]);

  useEffect(() => {
    dispatch(
      getAllMenteesUnderMentorList({ mentorDomainId, status: activeTab.title })
    );
  }, [dispatch, mentorDomainId, activeTab.title]);

  useEffect(() => {
    const menteeIds =
      getAllMenteesUnderMentor?.mentees?.map(item => item.mentee_id) || [];
    if (menteeIds.length > 0) {
      dispatch(getUserDetailsList({ id: menteeIds }));
    }
  }, [dispatch, getAllMenteesUnderMentor]);

  useEffect(() => {
    const data = (getAllMenteesUnderMentor?.mentees || []).map(mentee => {
      const userDetails = userDetailsList?.users?.find(
        user => user.id === mentee.mentee_id
      );
      return { ...mentee, ...userDetails };
    });
    setCombinedMenteeData(data);
  }, [dispatch, userDetailsList, getAllMenteesUnderMentor]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = combinedMenteeData?.filter(
        mentee =>
          mentee?.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          mentee?.employee_code
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          mentee?.phone?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(combinedMenteeData);
    }
  }, [searchQuery, combinedMenteeData]);

  const getIcons = (
    role,
    department,
    countOfSessions,
    latestSessiondate,
    menteeId
  ) => [
    {
      id: 1,
      iconName: "EngineeringOutlined",
      value: (
        <>
          <span
            id={`tooltip-role-${menteeId}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(role, 20)}
          </span>
          <Tooltip
            placement="top"
            target={`tooltip-role-${menteeId}`}
            isOpen={tooltipOpen[menteeId] || false}
            toggle={() => handleTooltip(menteeId)}
          >
            {role}
          </Tooltip>
        </>
      ),
      columnSize: "4",
    },
    { id: 2, iconName: "BadgeOutlined", value: department, columnSize: "3" },
    {
      id: 3,
      iconName: "CastForEducationOutlined",
      value: countOfSessions,
      columnSize: "2",
    },
    {
      id: 4,
      iconName: "CalendarTodayOutlined",
      value: latestSessiondate,
      columnSize: "3",
    },
  ];

  const handleTabClick = (tabId, title) => {
    const lowerTitle = title.toLowerCase();
    setActiveTab({ id: tabId, title: lowerTitle });
    setSearchQuery("");
    onActiveTabChange(tabId);
  };

  const onRowClick =
    (mentorMenteeId, menteeId, countOfSessions, latestSessiondate) => () => {
      navigate(`${process.env.PUBLIC_URL}/mentoring/mentee-details`, {
        state: { mentorMenteeId, menteeId, countOfSessions, latestSessiondate },
      });
    };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleUnLink = (mentorMenteeId: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedMentorMenteeId(mentorMenteeId);
    toggleModal();
  };

  const renderMenteeProfile = mentee => {
    return (
      <React.Fragment key={mentee?.mentor_mentee_id}>
        <UserProfileContainer
          userDetails={{
            name: mentee?.user_name,
            empId: mentee?.employee_code || "-",
            mobile: mentee?.phone || "-",
            id: mentee?.mentee_id,
          }}
          iconItems={getIcons(
            mentee?.job_role_name || "-",
            sbuNames[mentee?.sbu],
            mentee?.count_of_sessions,
            formatCustomDate(mentee?.latest_session_date),
            mentee?.mentee_id
          )}
          unlink={activeTab.id === "1"}
          onUnlinkClick={handleUnLink(mentee?.mentor_mentee_id)}
          onRowClick={onRowClick(
            mentee?.mentor_mentee_id,
            mentee?.mentee_id,
            mentee?.count_of_sessions,
            mentee?.latest_session_date
          )}
        />
        <Divider />
      </React.Fragment>
    );
  };

  const handleUnlinkConfirm = async () => {
    if (selectedMentorMenteeId) {
      await dispatch(
        unlinkMenteeFromMentor({
          unlinkMenteePayload: {
            mentor_mentee_id: selectedMentorMenteeId,
            action: "unlink",
          },
        })
      );

      toggleModal();
      dispatch(
        getAllMenteesUnderMentorList({
          mentorDomainId,
          status: activeTab.title,
        })
      );
    }
  };

  const handleTooltip = (id: number) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Card>
      <CardBody>
        <div className="mt-4">
          <BorderTabs
            tabs={MenteesListTabs}
            activeTab={activeTab.id}
            showCard={false}
            handleTabClick={tabId => {
              const selectedTab = MenteesListTabs.find(
                tab => tab.tabId === tabId
              );
              handleTabClick(tabId, selectedTab?.title || "");
            }}
            searchBar
            handleSearch={query => setSearchQuery(query)}
          />
          <Badge className="text-black mt-3 custom-Mentees-badge">
            {getAllMenteesUnderMentor?.count_of_mentees} {Menteess}
          </Badge>

          {filteredData.length > 0 ? (
            filteredData?.map(mentee => mentee && renderMenteeProfile(mentee))
          ) : (
            <NoData
              svg={"empty-folder-icon"}
              title={NoDataText}
              showCard={false}
            />
          )}
        </div>

        <CommonModal
          sizeTitle={UnLinkMentee}
          isOpen={isModalOpen}
          toggle={toggleModal}
          backdrop="static"
          size="lg"
          showFooter
          onPrimaryBtnClick={handleUnlinkConfirm}
          primaryBtnText={Confirm}
        >
          {UnLinkMenteeConfirmMessage}
        </CommonModal>
      </CardBody>
    </Card>
  );
};

export default MenteesList;
