import PropTypes from "prop-types";
import { Nav, NavItem, NavLink } from "reactstrap";

import Badges from "../Badge";

import "./style.scss";

const ScrollableJustifyTabs = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="justify-tabs-wrapper">
      <Nav pills className="nav-justified nav-warning flex-nowrap">
        {tabs?.map(tab => (
          <NavItem key={tab.id}>
            <NavLink
              className={activeTab === tab.id ? "active" : ""}
              onClick={() => onTabClick(tab.id)}
            >
              {tab.label}
              {tab.count && (
                <Badges className="notification-badge bg-primary text-white">
                  {tab.count}
                </Badges>
              )}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
  );
};

ScrollableJustifyTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number,
    })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default ScrollableJustifyTabs;
