import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Col, Input } from "reactstrap";

import "./style.scss";
import { H5, MUIIcons } from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import {
  Available,
  MainTrainers,
  NotAvailable,
  SelectTrainer,
  OtherTrainer,
  SecondaryTrainers,
  SelectClassroom,
  NoDataText,
} from "../../utils/Constant";

interface Trainer {
  id: string;
  name: string;
  available: boolean;
  always_available?: boolean;
}

interface TrainerDropdownProps {
  isTrainer?: boolean;
  primaryTrainers: Trainer[];
  secondaryTrainers: Trainer[];
  otherTrainers: Trainer[];
  isMultiSelect: boolean;
  onBlur?: (selectedTrainers: Trainer[], type: string) => void;
  knowTrainerAvailability?: (selectedTrainers: [string]) => void;
  setSelectedTrainers?;
  selectedTrainers?;
  disabled?;
  otherTrainerChecked?;
  setOtherTrainerChecked?;
}

const TrainerDropdown: React.FC<TrainerDropdownProps> = ({
  isTrainer = true,
  primaryTrainers,
  secondaryTrainers,
  otherTrainers,
  isMultiSelect,
  knowTrainerAvailability,
  onBlur,
  setSelectedTrainers,
  selectedTrainers,
  disabled = false,
  otherTrainerChecked,
  setOtherTrainerChecked,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedTrainerType, setSelectedTrainerType] = useState("");

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSelection = (trainer: Trainer) => {
    if (isMultiSelect) {
      setSelectedTrainers(prevSelected => {
        const isSelected = prevSelected.some(t => t.id === trainer.id);
        if (isSelected) {
          return prevSelected.filter(t => t.id !== trainer.id);
        } else {
          return [...prevSelected, trainer];
        }
      });
    } else {
      if (isTrainer) knowTrainerAvailability([trainer?.id]);
      setSelectedTrainers([trainer]);
    }

    if (primaryTrainers.some(t => t.id === trainer.id)) {
      setSelectedTrainerType("primary");
    } else if (secondaryTrainers.some(t => t.id === trainer.id)) {
      setSelectedTrainerType("primary");
    } else if (otherTrainers.some(t => t.id === trainer.id)) {
      setSelectedTrainerType("other");
    }

    if (otherTrainers.some(t => t.id === trainer.id) && isTrainer) {
      setOtherTrainerChecked(prevState =>
        prevState.includes(trainer.id) ? prevState : [...prevState, trainer.id]
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
        onBlur(selectedTrainers, selectedTrainerType);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen, selectedTrainers, onBlur]);

  const renderTrainerList = (
    title: string,
    trainers: Trainer[],
    isPrimaryOrSecondary: boolean,
    showDivider: boolean
  ) => (
    <>
      <div>{isTrainer && title}</div>
      {trainers?.length > 0 ? (
        <div className="trainer-list">
          {trainers.map(trainer => (
            <div className="trainer-item mt-2" key={trainer.id}>
              <Col lg={4}>
                <H5 className="fw-bold">{trainer.name}</H5>
              </Col>

              <Col lg={4} className="d-flex align-items-center">
                {(isPrimaryOrSecondary ||
                  otherTrainerChecked?.includes(trainer.id) ||
                  !isTrainer) && (
                  <span
                    className={`status ${trainer.available ? "available" : "not-available"}`}
                  >
                    {trainer.available ? (
                      <div className="d-flex align-items-center">
                        <div className="custom-available-indicator bg-success"></div>
                        {Available}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="custom-available-indicator bg-gray"></div>
                        {NotAvailable}
                      </div>
                    )}
                  </span>
                )}
              </Col>

              <Col lg={1} className="d-flex align-items-center">
                <Input
                  className="primary-checkbox"
                  type="checkbox"
                  checked={selectedTrainers.some(t => t.id === trainer.id)}
                  onChange={() => handleSelection(trainer)}
                />
              </Col>
            </div>
          ))}
          {showDivider && <Divider />}
        </div>
      ) : (
        <div className="text-center">{NoDataText}</div>
      )}
    </>
  );

  const selectedTrainersNames =
    selectedTrainers.length > 0
      ? selectedTrainers.map(t => t.name).join(", ")
      : "";

  return (
    <div ref={wrapperRef}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="trainer-dropdown h-50"
      >
        <DropdownToggle tag="div" onClick={toggleDropdown}>
          <Input
            disabled={disabled}
            type="text"
            value={selectedTrainersNames}
            placeholder={isTrainer ? SelectTrainer : SelectClassroom}
            readOnly
            className="trainer-input"
            onClick={toggleDropdown}
          />

          <MUIIcons
            iconName={
              dropdownOpen
                ? "KeyboardArrowUpOutlined"
                : "KeyboardArrowDownOutlined"
            }
            size={20}
            className="trainer-dropdown-icon"
          />
        </DropdownToggle>
        <DropdownMenu className="custom-trainer-dropdownMenu">
          {isTrainer &&
            renderTrainerList(MainTrainers, primaryTrainers, true, true)}
          {isTrainer &&
            renderTrainerList(SecondaryTrainers, secondaryTrainers, true, true)}
          {renderTrainerList(OtherTrainer, otherTrainers, false, false)}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default TrainerDropdown;
