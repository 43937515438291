import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Tooltip } from "reactstrap";

import { H4 } from "../../../../AbstractElements";
import Divider from "../../../../CommonElements/Divider";
import SearchBar from "../../../../CommonElements/SearchBar";
import NoData from "../../../../container/NoData";
import UserProfileContainer from "../../../../container/UserProfileContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMenteesUnderMentorList } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getDomainList,
  getSbuList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUserDetailsList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { Menteess, NoDataText } from "../../../../utils/Constant";
import {
  formatCustomDate,
  truncateText,
} from "../../../../utils/helper/helper";

const MentorMenteeList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getAllMenteesUnderMentor } = useAppSelector(state => state.learner);
  const { domainList, sbuList } = useAppSelector(state => state.master);
  const { userDetailsList } = useAppSelector(state => state.user);

  const [combinedMenteeData, setCombinedMenteeData] = useState([]);
  const [sbuNames, setSbuNames] = useState<{ [key: number]: string }>({});
  const [roletooltipOpen, setRoletooltipOpen] = useState<{
    [key: number]: boolean;
  }>({});
  const [domaintooltipOpen, setDomaintooltipOpen] = useState<{
    [key: number]: boolean;
  }>({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getSbuList());
    dispatch(getAllMenteesUnderMentorList({ status: null }));
  }, [dispatch]);

  useEffect(() => {
    const menteeIds =
      getAllMenteesUnderMentor?.mentees?.map(item => item.mentee_id) || [];
    if (menteeIds.length > 0) {
      dispatch(getUserDetailsList({ id: menteeIds }));
    }
  }, [dispatch, getAllMenteesUnderMentor]);

  useEffect(() => {
    const data = (getAllMenteesUnderMentor?.mentees || []).map(mentee => {
      const userDetails = userDetailsList?.users?.find(
        user => user.id === mentee.mentee_id
      );
      return { ...mentee, ...userDetails };
    });
    setCombinedMenteeData(data);
  }, [userDetailsList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = combinedMenteeData?.filter(
        mentor =>
          mentor?.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          mentor?.employee_code
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          mentor?.phone?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(combinedMenteeData);
    }
  }, [searchQuery, combinedMenteeData]);

  useEffect(() => {
    const sbuMapping = sbuList?.sbu?.reduce(
      (sbu, sbuItem) => {
        sbu[sbuItem.id] = sbuItem.name;
        return sbu;
      },
      {} as { [key: number]: string }
    );
    setSbuNames(sbuMapping || {});
  }, [sbuList]);

  const getIcons = (
    domain,
    role,
    department,
    countOfSessions,
    latestSessiondate,
    menteeId
  ) => [
    {
      id: 1,
      iconName: "DomainOutlined",
      value: (
        <>
          <span
            id={`tooltip-domain-${menteeId}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(domain, 20)}
          </span>
          <Tooltip
            placement="top"
            target={`tooltip-domain-${menteeId}`}
            isOpen={domaintooltipOpen[menteeId] || false}
            toggle={() => handleDomainTooltip(menteeId)}
          >
            {domain}
          </Tooltip>
        </>
      ),
      columnSize: "3",
    },
    {
      id: 2,
      iconName: "EngineeringOutlined",
      value: (
        <>
          <span
            id={`tooltip-role-${menteeId}`}
            className="text-truncate d-flex align-items-center"
          >
            {truncateText(role, 20)}
          </span>
          <Tooltip
            placement="top"
            target={`tooltip-role-${menteeId}`}
            isOpen={roletooltipOpen[menteeId] || false}
            toggle={() => handleRoleTooltip(menteeId)}
          >
            {role}
          </Tooltip>
        </>
      ),
      columnSize: "3",
    },
    { id: 3, iconName: "BadgeOutlined", value: department, columnSize: "2" },
    {
      id: 4,
      iconName: "CastForEducationOutlined",
      value: countOfSessions,
      columnSize: "1",
    },
    {
      id: 5,
      iconName: "CalendarTodayOutlined",
      value: latestSessiondate,
      columnSize: "3",
    },
  ];

  const onRowClick = (
    mentorMenteeId: number,
    menteeId: number,
    countOfSessions: number,
    latestSessiondate: string
  ) => {
    navigate(`${process.env.PUBLIC_URL}/mentoring/mentee-details`, {
      state: { mentorMenteeId, menteeId, countOfSessions, latestSessiondate },
    });
  };

  const getDomainName = (domainId: number) => {
    const domain = domainList?.domain?.find(item => item.id === domainId);
    return domain ? domain.domain_name : "";
  };

  const handleRoleTooltip = (id: number) => {
    setRoletooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDomainTooltip = (id: number) => {
    setDomaintooltipOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <H4 className="fw-bold mt-3">{Menteess}</H4>
          <SearchBar onSearch={query => setSearchQuery(query)} />
        </div>

        <Divider />
        {filteredData?.length > 0 ? (
          filteredData?.map(mentee => (
            <>
              <UserProfileContainer
                key={mentee.mentor_mentee_id}
                userDetails={{
                  name: mentee?.user_name,
                  empId: mentee?.employee_code || "-",
                  mobile: mentee?.phone || "-",
                  id: mentee?.mentee_id,
                }}
                iconItems={getIcons(
                  getDomainName(mentee?.domain_id) || "-",
                  mentee?.job_role_name || "-",
                  sbuNames[mentee?.sbu],
                  mentee?.count_of_sessions,
                  formatCustomDate(mentee?.latest_session_date),
                  mentee?.mentee_id
                )}
                onRowClick={() =>
                  onRowClick(
                    mentee?.mentor_mentee_id,
                    mentee?.mentee_id,
                    mentee?.count_of_sessions,
                    mentee?.latest_session_date
                  )
                }
                iconItemsColSize="9"
              />
              <Divider />
            </>
          ))
        ) : (
          <NoData
            svg={"empty-folder-icon"}
            title={NoDataText}
            showCard={false}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default MentorMenteeList;
