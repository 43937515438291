import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { H5, MUIIcons } from "../../../../AbstractElements";
import "./style.scss";
import ProfileName from "../ProfileName";

const ProfileUserDetails = ({ data }) => {
  const userDetails = data?.userDetails ?? [];
  const topRowItems = [
    ...userDetails.slice(0, 2),
    { id: "profile-name", type: "profile-name" },
    ...userDetails.slice(2, 4),
  ];
  const bottomRowItems = userDetails.slice(4);

  const renderProfileDetail = (items, colSize, isTopRow) =>
    items.map(item => {
      if (isTopRow && item.type === "profile-name") {
        return (
          <Col
            key={item.id}
            xs={6}
            lg={colSize}
            className="d-flex flex-column align-items-center user-profile-name"
          >
            <ProfileName
              name={data?.name}
              designation={data?.designation}
              empId={data?.employee_code}
            />
          </Col>
        );
      }

      return (
        <Col
          key={item.id}
          xs={6}
          lg={colSize}
          className="d-flex flex-column align-items-center mb-3"
        >
          <H5 className="text-gray d-flex gap-1">
            <MUIIcons iconName={item.icon} size={20} /> {item.title}
          </H5>
          <span className="text-black">{item.detail}</span>
        </Col>
      );
    });

  return (
    <Row className="user-profile-info pt-2 pb-2 position-relative">
      <Row className="w-100 justify-content-between">
        {renderProfileDetail(topRowItems, 2, true)}
      </Row>
      <Row className="w-100 justify-content-between profile-divider">
        <span className="divider-profile-custom-line"></span>
      </Row>

      <Row className="w-100 justify-content-between">
        {renderProfileDetail(bottomRowItems, 2, false)}
      </Row>
    </Row>
  );
};

ProfileUserDetails.propTypes = {
  data: PropTypes.exact({
    userProfileUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    employee_code: PropTypes.string.isRequired,
    userDetails: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number.isRequired,
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        detail: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default ProfileUserDetails;
