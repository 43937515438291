import { useState, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { H3 } from "../AbstractElements";
import {
  EXTERNAL_LEARNER,
  EXTERNAL_TRAINER,
  INTERNAL_LEARNER,
  INTERNAL_TRAINER,
  LEARNER,
  TRAINER,
} from "../Api/constants";
import { useAppContext } from "../AppContext";
import CommonModal from "../CommonElements/Modal";
import SwitchModalContent from "../CommonElements/SwitchModalContent";
import Login from "../Componant/Authentication/Login";
import ResetPassword from "../Componant/Authentication/ResetPassword";
import ResetPasswordForm from "../Componant/Authentication/ResetPasswordForm";
import CompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessments";
import AssessmentDetails from "../Pages/AssessmentDetails";
import LearningAssessment from "../Pages/LearningAssessment";
import AssessmentInstruction from "../Pages/LearningAssessment/AssessmentInstruction";
import { useAppDispatch } from "../ReduxToolkit/Hooks";
import { generateRefreshTokenViaSwitchRole } from "../ReduxToolkit/Reducers/AuthenticationSlice";
import { setSideBarMenuItems } from "../ReduxToolkit/Reducers/LayoutSlice";
import { syncScormData } from "../ReduxToolkit/Reducers/LearnerSlice";
import {
  ConfirmRoleSwitch,
  PleaseSelect,
  SwitchRole,
  LoginFailed,
  SomethingWentWrong,
  LMSRole,
} from "../utils/Constant";
import { roleMapping, showToast } from "../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
  OPENED_SCORM_DETAILS,
  removeAllLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "../utils/helper/localStorageutils";
import {
  REDIRECT_URL,
  saveToSessionStorage,
} from "../utils/helper/sessionStorageUtils";

import LayoutRoutes from "./LayoutRoutes";
import PrivateRoute from "./PrivateRoute";

const RouterData = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const login = getFromLocalStorage(LOGGED_IN_USER);
  const dispatch = useAppDispatch();
  const { showSwitchRoleModal, setShowSwitchRoleModal } = useAppContext();

  const [selectedType, setSelectedType] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [incomingRole, setIncomingRole] = useState("");
  const [incomingUrl, setIncomingUrl] = useState({});
  const [errors, setErrors] = useState({ selectedRole: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setSideBarMenuItems());
    window.addEventListener("syncScormData", handleCustomEvent);
    return () => {
      removeScormListener();
    };
  }, []);

  useEffect(() => {
    const url = location?.pathname;

    if (url.includes("/callback") && location?.search) {
      const searchParams = new URLSearchParams(location?.search);
      const tokenFromUrl = searchParams?.get("login_data");

      if (tokenFromUrl) {
        const decodedString = decodeURIComponent(tokenFromUrl);

        try {
          const jsonObject = JSON.parse(decodedString);

          const userData = {
            id: jsonObject?.id,
            name: jsonObject?.user_name,
            role: roleMapping[jsonObject?.current_role]?.key,
            currentRole: jsonObject?.current_role,
            email: jsonObject?.email,
            accessToken: jsonObject?.token?.access_token,
            refreshToken: jsonObject?.token?.refresh_token,
            mappedRoles: jsonObject?.mapped_lms_roles,
            business_group: jsonObject?.business_group,
            office_city: jsonObject?.office_city,
            designation: jsonObject?.designation,
          };

          saveToLocalStorage(LOGGED_IN_USER, userData);
          dispatch(setSideBarMenuItems());
          navigate(`${process.env.PUBLIC_URL}/dashboard`);
        } catch (error) {
          showToast(LoginFailed, "error");
          removeAllLocalStorage();
          navigate(`${process.env.PUBLIC_URL}/login`);
        }
      } else {
        showToast(LoginFailed, "error");
        removeAllLocalStorage();
        navigate(`${process.env.PUBLIC_URL}/login`);
      }
    } else if (url.includes("/error") && location?.search) {
      const searchParams = new URLSearchParams(location?.search);
      const message = searchParams?.get("message");
      showToast(message || SomethingWentWrong, "error");
      removeAllLocalStorage();
      navigate(`${process.env.PUBLIC_URL}/login`);
    }
  }, [location.pathname, location.search, navigate, dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let forParam = searchParams.get("for");
    const includedRoles = [TRAINER, LEARNER];
    if (includedRoles.includes(forParam)) {
      const roleMap = {
        learner: login?.mappedRoles?.includes(EXTERNAL_LEARNER)
          ? EXTERNAL_LEARNER
          : INTERNAL_LEARNER,
        trainer: login?.mappedRoles?.includes(EXTERNAL_TRAINER)
          ? EXTERNAL_TRAINER
          : INTERNAL_TRAINER,
      };
      forParam = roleMap[forParam];
    }

    if (forParam) {
      setLoading(true);
      searchParams.delete("for");
      if (login && login?.currentRole !== forParam) {
        setIncomingUrl(location);
        setSelectedType("switch");
        setIncomingRole(forParam);
        setShowSwitchRoleModal(true);
      } else {
        saveToSessionStorage(REDIRECT_URL, location);
        setLoading(false);
      }
    }
  }, [location.pathname, location.search, setShowSwitchRoleModal]);

  const removeScormListener = () => {
    window.removeEventListener("syncScormData", handleCustomEvent);
  };

  const handleCustomEvent = async (event: Event) => {
    const customEvent = event as CustomEvent;
    if (customEvent?.detail?.message === "LMSFinish") {
      const courseDetails = getFromLocalStorage(OPENED_SCORM_DETAILS);
      if (courseDetails) {
        await dispatch(syncScormData({ scormContent: courseDetails }));
        removeFromLocalStorage(OPENED_SCORM_DETAILS);
      }
    }
  };

  const closeModal = () => {
    setLoading(false);
    setShowSwitchRoleModal(false);
    if (selectedType === "switch") {
      navigate(`${process.env.PUBLIC_URL}/dashboard?switch=true`);
    }
    setSelectedRole("");
    setSelectedType("");
    setIncomingRole("");
  };

  const validateForm = () => {
    if (!selectedRole && selectedType !== "switch") {
      setErrors({ selectedRole: `${PleaseSelect} ${LMSRole}` });
      return false;
    }
    setErrors({ selectedRole: "" });
    return true;
  };

  const handleModalCallback = async () => {
    if (validateForm()) {
      let updatedRole = selectedRole;

      if (selectedType === "switch") {
        updatedRole = incomingRole;
      }

      try {
        const response = await dispatch(
          generateRefreshTokenViaSwitchRole({ role: updatedRole })
        );

        login.accessToken = response?.payload?.access_token;
        login.refreshToken = response?.payload?.refresh_token;
        login.currentRole = updatedRole;
        login.role = roleMapping[updatedRole].key;
        saveToLocalStorage(LOGGED_IN_USER, login);
        setSelectedRole("");
        closeModal();
        if (selectedType === "switch" && incomingUrl) {
          navigate(incomingUrl);
          dispatch(setSideBarMenuItems());
        } else {
          navigate(`${process.env.PUBLIC_URL}/dashboard?switch=true`);
          window.location.reload();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setErrors({ selectedRole: "" });
  }, [showSwitchRoleModal]);

  const AuthGuard = ({ children }: { children: JSX.Element }) => {
    if (login) {
      return <Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />;
    }
    return children;
  };

  return (
    <>
      <CommonModal
        sizeTitle={ConfirmRoleSwitch}
        isOpen={showSwitchRoleModal}
        toggle={closeModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleModalCallback}
        primaryBtnText={SwitchRole}
      >
        {selectedType === "switch" && (
          <H3 className="mb-3">{`${roleMapping?.[login?.currentRole]?.value} -> ${roleMapping?.[incomingRole]?.value}`}</H3>
        )}
        {showSwitchRoleModal && (
          <SwitchModalContent
            type={selectedType === "switch"}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            errors={errors}
          />
        )}
      </CommonModal>

      {!loading && (
        <Routes>
          {login && (
            <Route
              path={`${process.env.PUBLIC_URL || "/"}`}
              element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}
            />
          )}

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/*" element={<LayoutRoutes />} />
          </Route>

          <Route
            path={`${process.env.PUBLIC_URL}/login`}
            element={
              <AuthGuard>
                <Login />
              </AuthGuard>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reset-password`}
            element={
              <AuthGuard>
                <ResetPassword />
              </AuthGuard>
            }
          />
          <Route
            path={`${process.env.PUBLIC_URL}/reset-password-page`}
            element={
              <AuthGuard>
                <ResetPasswordForm />
              </AuthGuard>
            }
          />

          <Route
            path={`${process.env.PUBLIC_URL}/assessment-instruction`}
            element={<AssessmentInstruction />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/learning-assessment`}
            element={<LearningAssessment />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/self-assessment`}
            element={<CompetencyAssessment />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/assessment-details`}
            element={<AssessmentDetails />}
          />
        </Routes>
      )}
    </>
  );
};

export default RouterData;
