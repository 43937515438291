import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";

import Divider from "../../../../CommonElements/Divider";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import NoData from "../../../../container/NoData";
import TmReplacementCard from "../../../../container/TmReplacementCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getNomineesOfSchedule,
  reviewTrainerOrNominee,
} from "../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  AddComment,
  ApproveNomineeReason,
  Confirm,
  Description,
  EmptyCommentsAlert,
  Enter,
  NoDataText,
  RejectNomineeReason,
} from "../../../../utils/Constant";
import { formatDate, showToast } from "../../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../../utils/helper/permission";

const ReplacementList = ({
  scheduledId,
  userMap,
  sbuMap,
  getScheduledDetails,
}) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedModalType, setSelectedModalType] = useState("");
  const [comments, setComments] = useState("");
  const { nominees } = useAppSelector(state => state.course);

  useEffect(() => {
    dispatch(
      getNomineesOfSchedule({ schedule_id: scheduledId, type: "replacement" })
    );
  }, [dispatch, scheduledId]);

  let sizeTitle = "";
  if (selectedModalType === "approve") {
    sizeTitle = ApproveNomineeReason;
  } else if (selectedModalType === "reject") {
    sizeTitle = RejectNomineeReason;
  }

  const renderModalBody = type => {
    return (
      <>
        {type !== "remove" && (
          <FormGroup>
            <LabelTooltip
              label={AddComment}
              tooltipText={`${Enter} ${Description}`}
              important
            />
            <Input
              onChange={e => {
                if (
                  (e.target.value && e.target.value.length <= 255) ||
                  e.target.value === ""
                ) {
                  setComments(e.target.value);
                }
              }}
              rows={4}
              name="objective"
              type="textarea"
              placeholder={`${Enter} ${Description}`}
            />
          </FormGroup>
        )}
      </>
    );
  };

  const handlePrimaryBtnClick = async () => {
    if (selectedModalType === "approve" || selectedModalType === "reject") {
      if (!comments || comments.trim() === "") {
        showToast(EmptyCommentsAlert, "error");
        return;
      }
    }

    let payload;
    if (selectedModalType === "approve") {
      payload = {
        action: "approved",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "reject") {
      payload = {
        action: "rejected",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    }

    await dispatch(
      getNomineesOfSchedule({ schedule_id: scheduledId, type: "replacement" })
    );
    await getScheduledDetails(scheduledId);
    toggleModal();
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedModalType("");
      setComments("");
    }
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      <div className="mt-3">
        {nominees?.nominees?.length === 0 && (
          <div className="p-5">
            <NoData
              showCard={false}
              showIcon={false}
              svg="empty-folder-icon"
              title={NoDataText}
            />
          </div>
        )}
        {nominees?.nominees?.length > 0 &&
          nominees?.nominees?.map((item, index) => {
            if (userMap) {
              const details = {
                unique_id: item?.id?.toString(),
                id: userMap[item?.participant_id]?.id,
                name: userMap[item?.participant_id]?.user_name,
                user_name: userMap[item?.participant_id]?.user_name,
                empId: userMap[item?.participant_id]?.employee_code,
                userId: userMap[item?.participant_id]?.id,
                mobile: userMap[item?.participant_id]?.phone,
                sbu: sbuMap[userMap[item?.participant_id]?.sbu],
                rmName: userMap[item?.participant_id]?.mapped_rm[0]?.name,
                rmMobileNo:
                  userMap[item?.participant_id]?.mapped_rm[0]?.rm_phone,
                status: item?.nomination_status,
                participant_id: item?.id?.toString(),
                nominatedOn: item?.updated_at
                  ? formatDate(item?.updated_at)
                  : "",
              };

              const replace_details = {
                id: userMap[item?.replacement_user_id]?.id,
                name: userMap[item?.replacement_user_id]?.user_name,
                user_name: userMap[item?.replacement_user_id]?.user_name,
                empId: userMap[item?.replacement_user_id]?.employee_code,
                userId: userMap[item?.replacement_user_id]?.id,
                mobile: userMap[item?.replacement_user_id]?.phone,
                sbu: sbuMap[userMap[item?.replacement_user_id]?.sbu],
                rmName: userMap[item?.replacement_user_id]?.mapped_rm[0]?.name,
                rmMobileNo:
                  userMap[item?.replacement_user_id]?.mapped_rm[0]?.rm_phone,
                status: item?.nomination_status,
                participant_id: item?.replacement_user_id?.toString(),
                nominatedOn: item?.updated_at
                  ? formatDate(item?.updated_at)
                  : "",
              };

              return (
                <div key={item.id} className="mt-3">
                  <TmReplacementCard
                    showCard={false}
                    data={details}
                    replaceData={replace_details}
                    onApprove={id => {
                      setSelectedId(id);
                      setSelectedModalType("approve");
                      toggleModal();
                    }}
                    onReject={id => {
                      setSelectedId(id);
                      setSelectedModalType("reject");
                      toggleModal();
                    }}
                    showActions={hasPermissionToComponent("ADD_NOMINEES")}
                  />

                  {index < nominees?.nominees.length - 1 && <Divider />}
                </div>
              );
            }
          })}
      </div>
    </>
  );
};

ReplacementList.propTypes = {
  scheduledId: PropTypes.number.isRequired,
  sbuMap: PropTypes.object,
  userMap: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_name: PropTypes.string,
      employee_code: PropTypes.string,
      phone: PropTypes.string,
      sbu: PropTypes.string,
      mapped_rm: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          rm_phone: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  getScheduledDetails: PropTypes.any,
};

export default ReplacementList;
