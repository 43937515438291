import {
  FormValues,
  CurriculumDetailsPayload,
  ProficiencyState,
} from "../../Api/entities/CurriculumEntity";
import { formatDate } from "../../utils/helper/helper";
import {
  DRAFT,
  DRAFT_STATUS,
  PUBLISHED,
  SCHEDULED_FOR_PUBLISH,
} from "../constants";

export const parseCurriculumRequestData = (
  formValues: FormValues,
  proficiency: ProficiencyState,
  status: string,
  domain: any,
  isSpecialCurriculum: boolean
): CurriculumDetailsPayload => {
  const levels = Object.keys(proficiency).reduce((acc, level) => {
    const courses = proficiency[level].course
      .filter(course => course.isNew)
      .map(course => ({
        course_id: Number(course.name),
      }));

    if (courses.length > 0) {
      acc.push({
        level_id: proficiency[level].level_id,
        courses,
      });
    }

    return acc;
  }, []);

  return {
    name: formValues.curriculum_name,
    is_publish_now:
      status === DRAFT_STATUS ? false : !formValues.isPublishLater,
    publish_at: formValues.isPublishLater
      ? formatDate(formValues.futureDate)
      : null,
    status: status,
    role_id: formValues.role.length > 0 ? Number(formValues.role) : null,
    levels: levels.length > 0 ? levels : [],
    domain: [parseInt(domain, 10)],
    is_special_curriculum: isSpecialCurriculum,
  };
};

export const filterCurriculumsVersion = curriculums => {
  return curriculums.reduce(
    (result, curriculum) => {
      const { status } = curriculum;

      switch (status) {
        case PUBLISHED:
          result.published.push(curriculum);
          break;
        case SCHEDULED_FOR_PUBLISH:
          result.scheduledForPublish.push(curriculum);
          break;
        case DRAFT:
          result.draft.push(curriculum);
          break;
        default:
          result.unpublished.push(curriculum);
          break;
      }

      return result;
    },
    {
      published: [],
      draft: [],
      unpublished: [],
      scheduledForPublish: [],
    }
  );
};
