import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { EXTERNAL_LEARNER, INTERNAL_LEARNER } from "../../../Api/constants";
import Btn from "../../../CommonElements/Button";
import MapAssessmentModal from "../../../CommonElements/MapAssessmentModal";
import MUIIcons from "../../../CommonElements/MUIIcon/MUIIcons";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import GenericCard from "../../../container/GenericCard";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { setLearnerAssessmentDetailsToInitial } from "../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getMappedAssessment } from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Add,
  Completed,
  CompletedOn,
  CoolingPeriod,
  Days,
  End,
  FinalScore,
  Mins,
  Modules,
  NoDataText,
  Note,
  NotYetAdded,
  Pending,
  Questions,
  Resume,
  Retake,
  Start,
  StartNote,
  SubmittedOn,
  Time,
  Total,
} from "../../../utils/Constant";
import {
  assessmentStatus,
  assessmentTabStatus,
  assessmentTypesMap,
  calculateDayDifference,
  examStatus,
  formatCustomDate,
  formatDate,
} from "../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

import "./style.scss";

const ILPAssessment = ({ userId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mappedAssessment, loading } = useAppSelector(
    state => state.learningPlan
  );
  const [isMapAssessmentModalOpen, setIsMapAssessmentModalOpen] =
    useState(false);
  const [searchResults, setSearchResults] = useState<string>("");
  const [fyYear, setFyYear] = useState(new Date().getFullYear().toString());
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getAssessmentList();
  }, [dispatch, fyYear]);

  useEffect(() => {
    if (mappedAssessment?.data?.assessments) {
      if (searchResults) {
        const filtered = mappedAssessment.data.assessments.filter(assessment =>
          assessment.name?.toLowerCase().includes(searchResults)
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(mappedAssessment.data.assessments);
      }
    } else {
      setFilteredData([]);
    }
  }, [searchResults, mappedAssessment]);

  const getAssessmentList = () => {
    if (userId) {
      dispatch(
        getMappedAssessment({
          fyYear: fyYear,
          userId: userId,
        })
      );
    } else {
      dispatch(
        getMappedAssessment({
          fyYear: fyYear,
          userId: null,
        })
      );
    }
  };

  const mapAssessmentModalToggle = isMapped => {
    setIsMapAssessmentModalOpen(!isMapAssessmentModalOpen);
    if (isMapped === true) {
      getAssessmentList();
    }
  };

  const handleSearch = (query: string) => {
    setSearchResults(query.toLowerCase());
  };

  const calculateAssessmentCounts = (
    mappedAssessment: any,
    searchResults: string
  ) => {
    const assessments = mappedAssessment?.data?.assessments || [];

    const counts = assessments.reduce(
      (acc, assessment) => {
        if (
          !assessment?.name?.toLowerCase().includes(searchResults.toLowerCase())
        ) {
          return acc;
        }

        acc.total++;

        switch (assessment.status) {
          case "yts":
          case "in_progress":
            acc.pending++;
            break;
          case "failed":
          case "passed":
          case "under_review":
          case "certification":
          case "retake":
          case "draft":
          case "completed":
            acc.completed++;
            break;
          default:
            break;
        }

        return acc;
      },
      { pending: 0, total: 0, completed: 0 }
    );

    return counts;
  };

  const isUpcomingExam = dateString => {
    const inputDate = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return inputDate > today;
  };

  const getButtonTitle = status => {
    if (status === assessmentStatus.inProgress) {
      return Resume;
    } else if (status === assessmentStatus.yetToStart) {
      return Start;
    } else if (status === assessmentStatus.retake) {
      return Retake;
    }
  };

  const handleExam = assessment => {
    if (assessment?.assessment_type === assessmentTypesMap.competency) {
      dispatch(setLearnerAssessmentDetailsToInitial());
      return navigate("/self-assessment", {
        state: {
          participantId: assessment?.participant_id,
        },
      });
    }
    if (assessment?.status === assessmentStatus.yetToStart) {
      //Came via Start
      return navigate("/assessment-instruction", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment?.name,
          termsAndCondition: assessment?.term_and_condition,
        },
      });
    } else {
      //Came via Resume
      return navigate("/learning-assessment", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment.name,
        },
      });
    }
  };

  const getTabStatus = assessment => {
    const { start_date, status } = assessment;
    if (
      status === examStatus.failed ||
      status === examStatus.passed ||
      userId
    ) {
      return assessmentTabStatus.past;
    }
    if (isUpcomingExam(start_date)) {
      return assessmentTabStatus.upcoming;
    }
  };

  const handleClick = item => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);

    if ([EXTERNAL_LEARNER, INTERNAL_LEARNER].includes(loginData.currentRole)) {
      return navigate("/assessment-list/assessment-details", {
        state: {
          assessment: item,
          activeTab: getTabStatus(item),
          hideAction: userId,
        },
      });
    }

    return navigate(
      `/assessments/assessment-details?assessmentId=${item.assessment_id}`
    );
  };

  const renderAssessmentCard = (assessment, index) => {
    const {
      participant_id,
      name,
      start_date,
      end_date,
      estimated_time,
      cooling_period_date,
      module_count,
      overall_question_count,
      end_time,
      score,
      status,
      assessment_type,
    } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);

    const footerDate = (() => {
      if (status === examStatus.passed || status === examStatus.failed) {
        return `${CompletedOn} ${formatDate(end_time)}`;
      } else if (status === examStatus.under_review) {
        return `${SubmittedOn} ${formatDate(end_time)}`;
      }
      return null;
    })();

    const getButtonType = assessment => {
      const { status, cooling_period_date } = assessment;
      const coolingPeriod = calculateDayDifference(cooling_period_date);
      const isCoolingPeriod = coolingPeriod > 0;

      if (
        status === examStatus.under_review ||
        status === examStatus.failed ||
        status === examStatus.passed ||
        userId
      ) {
        return null;
      }

      if (isUpcomingExam(start_date)) {
        return {
          title: Start,
          onClick: () => {
            return;
          },
          disabled: true,
        };
      }

      if (status === assessmentStatus.yetToStart) {
        return {
          title: getButtonTitle(status),
          disabled: isCoolingPeriod,
          onClick: isCoolingPeriod
            ? () => {
                return;
              }
            : () => handleExam(assessment),
        };
      }

      return null;
    };

    return (
      <Col key={participant_id + index} sm={12} lg={4}>
        <button
          className="border-0 bg-transparent pointer p-0 w-100 text-start"
          onClick={() => handleClick(assessment)}
        >
          <GenericCard
            id={participant_id}
            header={name}
            moduleDetails={[
              {
                label: Start,
                value: start_date ? formatCustomDate(start_date) : "-",
                key: `${participant_id}_${Start}`,
              },
              {
                label: End,
                value: end_date ? formatCustomDate(end_date) : "-",
                key: `${participant_id}_${End}`,
              },
              {
                label: Time,
                value: estimated_time ? `${estimated_time} ${Mins}` : "-",
                key: `${participant_id}_${Time}`,
              },
              {
                label: Questions,
                value: overall_question_count ?? "-",
                key: `${participant_id}_${Questions}`,
              },
              {
                label: Modules,
                value: module_count ?? "-",
                key: `${participant_id}_${Modules}`,
              },
            ]}
            isHideMoreOption={true}
            footerBadgeRight={
              coolingPeriod &&
              (status !== examStatus.passed || status !== examStatus.failed)
                ? `${CoolingPeriod} ${coolingPeriod} ${Days}`
                : ""
            }
            footerBadge={
              assessment_type !== assessmentTypesMap.competency &&
              (status === examStatus.passed || status === examStatus.failed) &&
              `${FinalScore} ${score}%`
            }
            footerBadgeStatus={
              (status === examStatus.passed || status === examStatus.failed) &&
              status === examStatus.passed
                ? "draft"
                : "rejected"
            }
            btnType={getButtonType(assessment)}
            altText={isUpcomingExam(start_date) && `${Note}: ${StartNote}`}
            footerDate={footerDate}
          />
        </button>
        {/* </Link> */}
      </Col>
    );
  };

  const filteredCountData = calculateAssessmentCounts(
    mappedAssessment,
    searchResults
  );

  const handleYearChange = (year: string) => {
    setFyYear(year);
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (mappedAssessment?.data?.assessments?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          showCard={false}
        />
      );
    }

    if (filteredData?.length === 0) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    return filteredData?.map((assessment, index) =>
      renderAssessmentCard(assessment, index)
    );
  };

  return (
    <div>
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="7" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {Total} :
                <strong className="text-black">
                  {filteredCountData.total}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Pending}:
                <strong className="text-black">
                  {filteredCountData.pending}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Completed}:
                <strong className="text-black">
                  {filteredCountData.completed}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end "
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                <YearNavigator
                  pastYears={20}
                  futureYears={20}
                  onYearChange={handleYearChange}
                />
                {userId &&
                  hasPermissionToComponent(
                    "USER_MANAGEMENT.MAP_ASSESSMENT"
                  ) && (
                    <Btn
                      icon={
                        <MUIIcons
                          size={16}
                          iconName="AddCircleOutlineOutlined"
                        />
                      }
                      color="primary"
                      onClick={mapAssessmentModalToggle}
                    >
                      {Add}
                    </Btn>
                  )}
                {isMapAssessmentModalOpen && (
                  <MapAssessmentModal
                    isOpen={isMapAssessmentModalOpen}
                    toggle={mapAssessmentModalToggle}
                    userId={userId}
                  />
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row className="mt-5">{renderContent()}</Row>
    </div>
  );
};
ILPAssessment.propTypes = {
  userId: PropTypes.number,
};
export default ILPAssessment;
