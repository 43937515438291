export const Href = "#javascript";

export const DoNotAccount = "Don't have an account?";
export const CreateAccount = "Create Account";
export const SignInAccount = "Sign in to account";
export const NewPassword = "New Password";
export const NewPasswordConfirmation = "New Password Confirmation";
export const ResetPasswordText = "Reset Password";
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const SignInWith = "Or Sign In With";
export const Pinned = "Pinned";
export const Back = "Back";
export const SaveAsDraft = "Save as Draft";
export const Search = "Search";
export const CopyText = "Copy text";
export const Configuration = "Configuration";
export const Cancel = "Cancel";
export const Notification = "Notification";
export const CheckAll = "Check All";
export const FacebookHeading = "facebook";
export const TwitterHeading = "twitter";
export const LinkedInHeading = "LinkedIn";
export const SignIn = "Sign In";
export const LoginText = "Login";
export const Password = "Password";
export const UserName = "Username";
export const SelectIntendedAudience = "Select Intended Audience";
export const InviteSentOn = "hasn't confirmed yet. Invite sent On";
export const PleaseEnterPassword = "Please enter a password";
export const PasswordValidation =
  "Password must be at most 16 characters, include 1 uppercase letter, 1 number, and 1 special character";
export const PasswordNotMatch = "Passwords do not match";
export const PasswordResetSuccess = "Password reset successfully!";
export const SingInWithMicrosoft = "Sign in with GMMCO SSO";
export const LoginFailed = "Login failed";

//Redux reducer
export const FillAllFields = "Please fill all the fields";
export const PleaseAddComment = "Please add comment";
export const NoResponseFromServer = "No response from server";
export const PleaseProvideRequiredInformation =
  "Please provide all the required information";

//Pages
export const NewRequest = "New Request";
export const AddCourse = "Add Course";
export const Welcome = "Welcome";
export const Next = "Next";
export const Finish = "Finish";
export const Other = "Other";
export const Notes = "Notes";
export const Active = "Active";
export const Upcoming = "Upcoming";
export const Past = "Past";
export const Delete = "Delete";
export const BasicDetails = "Basic Details";
export const CustomerName = "Customer Name";
export const Enter = "Enter";
export const FilledBy = "Filled By";
export const Designation = "Designation";
export const Region = "Region";
export const Dates = "Date";
export const PointOfContact = "Your point of contact with GMMCO";
export const NatureOfBusiness = "Nature Of Business";
export const BusinessGroup = "Business Group";
export const KeyContact = "Key Contact";
export const PrimaryContact = "Primary Contact";
export const PhoneNumber = "Phone Number";
export const EmailId = "Email ID";
export const Extension = "Extension";
export const ExtensionRequest = "Extension Request";
export const SecondaryContact = "Secondary Contact";
export const SecondaryPhoneNumber = "Secondary Phone Number";
export const SecondaryEmailId = "Secondary Email ID";
export const SecondaryExtension = "Secondary Extension";
export const ContactDetails = "Contact Details";
export const ContentTopic = "Content Topic";
export const Summary = "Summary";
export const Accomplishment = "Accomplishment";
export const TargetAudience = "Target Audience";
export const CountOfTargetAudience = "Count of Target Audience";
export const SuggestedDuration = "Suggested Duration";
export const SuggestedDays = "Suggested Duration";
export const SuggestedHours = "Suggested Hours";
export const Objective = "Objective";
export const RequestedDetails = "Request details";
export const RejectedByTM = "Rejected by TM";
export const RejectedFor = "Rejected For";
export const Modify = "Modify";
export const UsersEnrolled = "Users Enrolled";
export const BasicInfo = "Basic Info";
export const ContentDetails = "Content Details";
export const RejectReason = "Reject Reason";
export const DeclineReason = "Decline extension request";
export const DeclineModification = "Decline modification request";
export const ApproveReason = "Approve extension request";
export const RevokeReason = "Revoke Reason";
export const Submit = "Submit";
export const AddComment = "Add comment";
export const Comment = "Comment";
export const CompetencyStatement = "Competency Statement";
export const ConfirmPublish = "Confirm Publish";
export const PleaseAddDayPlans = "Please Add all the Day plans";
export const AddBasicDetails = "Add Basic Details";
export const CourseName = "Course Name";
export const CourseType = "Course Type";
export const FrequencyType = "Frequency Type";
export const FrequencyCount = "Frequency Count";
export const RecurEvery = "Recur every";
export const Occurrence = "Occurrence (No. of times will be assigned)";
export const EndAfter = "End after";
export const Occurrences = "Occurrences";
export const ModuleList = "Module List";
export const ModificationAccessRequest = "Modification Access Request";
export const NoOfQuestions = "No Of Questions";
export const CourseOwner = "Course Owner";
export const AssignSME = "Assign SME";

export const ClickingManagerCTA = action => {
  return `By clicking on ${action}, Trainer & SME will get notified`;
};

export const FileTypeError = action => {
  return `Only the following file types are accepted: ${action}`;
};

export const DuplicateFileError = "File already exists";

export const Reject = "Reject";
export const Decline = "Decline";
export const RejectStatus = "reject";
export const Add = "Add";
export const CreateCourse = "Create course";
export const ModifyCourse = "Modify course";
export const AddPolicy = "Add Policy";
export const CreatePolicy = "Create a Policy";
export const ModifyPolicy = "Modify Policy";
export const CourseStructure = "Course Structure";
export const AddCourseStructure = "Add Course Structure";
export const Approve = "Approve";
export const CompetencyStatements = "Competency statement";
export const MaterialsHeader = "Materials";
export const LessonPlans = "Lesson Plan";
export const FacilitatorRequest =
  "Facilitators are to ensure that the tasks are completed:";
export const LearningOutcomes = "Learning Outcome";
export const AddLearningOutcomes = "Add Learning Outcome";
export const BondDetails = "Add Trainer & Bond details";
export const PrimaryTrainer = "Primary Trainer";
export const MainTrainer = "Main Trainer";
export const MainTrainers = "Main Trainers";
export const OtherTrainer = "Other Trainers";
export const SelectTrainer = "Select Trainer";
export const SelectClassroom = "Select Classroom";
export const SecondaryTrainer = "Secondary Trainer";
export const SecondaryTrainers = "Secondary Trainers";
export const AddCurriculum = "Do you want to add this course to a curriculum?";
export const Others = "Other References";
export const OtherRemedialAction = "Others";
export const OthersRequired = "Do you want to add Other References?";
export const OthersToolsRequiredText = "Do you want to add Other Tools?";
export const OthersTrainingAidsRequiredText =
  "Do you want to add Other Training Aids?";
export const OthersPrerequisiteRequired =
  "Do you want to add Other Prerequisites?";
export const OtherIntendedAudienceRequired =
  "Do you want to add Other Intended audience?";
export const OtherPreCourseWorkRequired =
  "Do you want to add Other Pre-course work and materials?";
export const OtherIntendedAudience = "Other Intended Audience";

export const OthersPrerequisites = "Other Prerequisites";
export const AddCurriculumButton = "Add Curriculum";
export const DeleteCurriculumButton = "Delete Curriculum";
export const Curriculum = "Curriculum";
export const ExpertiseLevel = "Expertise Level";
export const CourseFee = "Course Fee per person";
export const BondRequired = "Bond Required";
export const PublishLater = "Publish Later";
export const BondRupees = "Bond Rupees";
export const BondYears = "Bond Years";
export const PublishConfirmation =
  "Are you sure you want to publish this course?";
export const PublishCourseCatalog =
  "By clicking 'Publish now,' your course will be published immediately in the catalog.";
export const PublishLaterCourseCatalog =
  "By clicking 'Publish Later,' your course will be published in the catalog on the selected date.";
export const PublishNow = "Publish Now";
export const Publish = "Publish";
export const Update = "Update";
export const ViewDetails = "View Details";
export const ViewReport = "View Report";
export const Revoke = "Revoke";
export const StartNow = "Start Now";
export const RM = "RM: ";
export const RequestedOn = "Requested on ";
export const Send = "Send";
export const Name = "Name";
export const Description = "Description";
export const AddReason = "Add Reason";
export const AddReasonForDecline = "Add Reason for decline";
export const AddReasonForApprove = "Add Reason for approve";
export const Reason = "Reason";
export const AccessRequestedOn = "Access Requested On : ";
export const ApprovedOn = "Approved On : ";
export const YouDoNotHaveAccess = "You don't have access to this.";
export const AddProperReason = "Please add Reason for Modification request";
export const RecommendedDelivery = "Recommended Delivery";
export const Text = "Text";
export const New = "New";
export const Save = "Save";
export const SaveQuestion = "Save Question";
export const SaveRemedialAction = "Save Remedial Action";
export const Complete = "Complete";
export const Domain = "Domain";
export const Competency = "Competency";
export const ProficiencyLevel = "Proficiency Level";
export const SelectedLos = "Selected Learning outcomes : ";
export const SelectAll = "Select All";
export const Select = "Select";
export const Participant = "Participant";
export const Trainer = "Trainer";
export const Users = "Users";
export const ParticipantDetails = "Participant Details";
export const FacilitatorInformation = "Add More Facilitator information";
export const EnterFacilitatorInformation = "More Facilitator information";
export const AutoSavedOn = "Auto Saved on";
export const Prerequisite = "Prerequisite";
export const OtherPrerequisite = "OtherPrerequisites";
export const VersionNumber = "Version Number";
export const Condition = "Condition";
export const PersonalStatement =
  "As a minimum, personnel must meet the following requirements";
export const MediaDemonstration = "Media, Demonstration and practical given";
export const ClassroomEnvironment =
  "Classroom and laboratory/workshop environment";
export const EstimatedDelivery = "Estimated days";
export const Classroom = "Classroom";
export const Practical = "Practical";
export const Resources = "Resources";
export const Tools = "Tools";
export const TrainingAids = "Training Aids";
export const Reference = "References";
export const OtherReference = "Other References";
export const OtherTools = "Other Tools";
export const OtherTrainingAids = "Other Training Aids";
export const Review = "Review";
export const Assessment = "Assessment";
export const StartDate = "Start Date";
export const EndDate = "End Date";
export const NominationStartDate = "Nomination Start Date";
export const NominationEndDate = "Nomination End Date";
export const ReviewAssessment = "Review & Assessment";
export const AddNew = "Add New";
export const Done = "Done";
export const Apply = "Apply";
export const Filter = "Filter";
export const DayWiseTopic = "Topic";
export const DayWiseLearning = "Learning activities";
export const DayWiseDelivery = "Delivery";
export const DayWiseTime = "Estimated Duration (in minutes)";
export const IntendedAudience = "Intended Audience";
export const MinimumAttendance = "Minimum Attendance";
export const MaximumAttendance = "Maximum Attendance";
export const Literature = "Literature/Suggestion";
export const Qualification =
  "Any Qualification or Certification that this training leads";
export const AssessmentCriteria = "Assessment Criteria";
export const TheoryRatio = "Theory Ratio";
export const PracticalRatio = "Practical Ratio";
export const PracticalActivityDetails = "Practical Activity Details";
export const ToolsAids = "Tools & Aids Required";
export const RegistrationRequirement = "Registration Requirement";
export const PreCourseWork = "Pre-course work and materials";
export const OtherPreCourseWork = "Other Pre-course work and materials";
export const AddMaterial = "Add Material";
export const AddedMaterials = "Added Materials";
export const ClickToUpload = "Click To Upload";
export const Upload = "Upload";
export const LoadMore = "Load More";
export const Document = "Document";
export const PDF = "PDF";
export const DOCX = "DOCX";
export const TXT = "TXT";
export const MB = "MB";
export const Course = "Course";
export const ModifyExistingCourse =
  "Do you want to modify the existing course?";
export const ViewRMDetails = "Request Details";
export const CreateAssessments = "Create Assessment";
export const EditAssessment = "Edit Assessment";
export const AssessmentType = "Assessment Type";
export const MappedQuestionBank = "Mapped Question Bank";
export const MappedModule = "Mapped Module";
export const QuestionBank = "Question Bank";
export const ModuleDetails = "Module Details";
export const QuestionBankName = "Question Bank Name";
export const Tag = "Tag";
export const PleaseAddTag = "Please add Tags";
export const PleaseAddModule = "Please add minimum one Module";
export const PleaseAddQuestion = "Please add minimum one Question";
export const IsSelfAssessment = "Is this a Self Assessment?";
export const SelfAssessment = "Self Assessment";
export const ModuleName = "Module Name";
export const OverallCount = "Overall Question Count";
export const AssessmentName = "Assessment Name";
export const EstimatedTime = "Estimated Time";
export const AssessmentDurationInMinutes = "Assessment Duration (in minutes)";
export const Policy = "Policy";
export const Weightage = "Weightage";
export const Questions = "Questions";
export const RequestType = "Request Type";
export const TrainerBondDetails = "Trainer & Bond Details";
export const SomethingWentWrong = "Something went wrong";
export const FutureDate = "Future date";
export const SelfEnrollment = "Enable Self Enrollment?";
export const LearnerDocument = "Learner Document";
export const TrainerDocument = "Trainer Document";
export const FileSizeNote =
  "Note: Supported file formats are .pdf .docx .txt .pptx (<25MB)";
export const PendingSkillFileSizeNote =
  "Note: Supported file formats are .pdf .docx .txt (<25MB)";
export const TotalExceedsLimit = "Total upload size exceeds 25MB";
export const FileSizeError = "File size should not exceed 25MB";
export const zipFileSizeNote = "Note: Supported file formats are .zip";
export const PdfFileSizeNote = "Note: Supported file formats are .pdf";
export const QuestionFileSizeNote =
  "Note: Supported file formats are .pdf, .docx, .mp4, .mp3, .png, .jpg, .jpeg";
export const Completed = "Completed";
export const CourseCompleted = "Course completed";
export const RateNow = "Rate now";
export const Internal = "internal";
export const SkillType = "Skill Type";
export const KnowledgeAssessmentType = "Knowledge Assessment Type";
export const Knowledge = "Knowledge";
export const Skill = "Skill";
export const SkillAssessment = "Skill Assessment";
export const PrePostAssessment = "Pre-Post Assessment";
export const NoOfModules = "No of Modules";
export const NoOfAttempt = "No of Attempt";
export const FieldAssessment = "Field Assessment";
export const OJTAssessment = "OJT Assessment";
export const UPLOAD_SCORM_FILE = "Upload Scorm File";
export const PleaseDeleteTheUploadedScorm =
  "Please delete the uploaded SCORM file to add new SCORM file";
export const SkillTypeError = "Select Skill type";
export const ScormToUpload = "Please Add Scorm document to upload";
export const AddDuration = "Please add the duration";
export const AddTrainerDocument = "Please add Trainer document to upload";
export const AddLearnerDocument = "Please add Learner document to upload";
export const AddDocument = "Please Add document to upload";
export const Scorm = "Scorm";
export const InDraft = "In Draft";
export const YetToStart = "Yet to Start";
export const Locked = "Locked";
export const Inactive = "Inactive";
export const DeleteLessonPlan = "Are you sure you want to delete lesson plan?";
export const DeleteUserMessage = "Are you sure you want to delete user?";
export const DeleteUserHeader = "Delete user";
export const DeleteCourse = "Are you sure you want to delete course?";
export const DeleteModuleFromQB = "Are you sure you want to delete Module?";
export const DeleteQuestionFromModule =
  "Are you sure you want to delete Question?";
export const DeleteQB = "Are you sure you want to delete Question Bank?";
export const DeleteAssessment = "Are you sure you want to delete assessment?";
export const AssessmentTypeChange =
  "Are you sure you want to change skill type? (Uploaded files will be overridden)";
export const Confirmation = "Confirmation";
export const ConfirmRoleSwitch = "Confirm Role Switch";
export const SwitchRole = "Switch Role";
export const Confirm = "Confirm";
export const DeleteLessonPlanHeader = "Delete Lesson plan";
export const DeleteModuleHeader = "Delete Module";
export const RenameModuleName = "Rename module name";
export const DeleteQuestionHeader = "Delete Question";
export const PleaseEnterQuestion = "Please enter Question";
export const ErrorInTheFile =
  "There are errors in the file data. Please Correct the data and upload again";
export const DeleteQuestionBankHeader = "Delete Question Bank";
export const DeleteAssessmentHeader = "Delete Assessment";
export const SkillTypeHeader = "Skill Type";
export const DeleteFileHeader = "Delete File";
export const InactiveCourseMessage =
  " Are you sure you want to set the status to inactive?";
export const DeleteMaterialFile = " Are you sure you want to delete the file";
export const SelectQuestionBank = "Please select Question Bank";
export const SelectQuestionBankField = "Select Question Bank";
export const EnterOverallCount = "Please enter Overall Count";
export const EnterAssessmentName = "Please enter Assessment name";
export const SelectAssessmentType = "Please select type";
export const EnterEstimatedTime = "Please enter Assessment duration";
export const SelectPolicy = "Please select policy";
export const SelectAssessment = "Please select assessment";
export const SelectLO = "Please select LO";
export const EnterUsername = "Enter Username";
export const EnterEmail = "Enter Email";
export const EnterEmailError = "Please enter Email";
export const EnterProperEmailError = "Please enter proper Email";
export const EnterPassword = "Enter Password";
export const AdditionalInfoFile = "Additional_Information";
export const CourseStructureFile = "Course_Structure";
export const CompetencyFile = "Competency_Statement";
export const LessonPlanFile = "Lesson_Plan";
export const LOFile = "Learning_Outcome";
export const NoDataText = "No Data Found";
export const NoLODataText = "No LO’s are selected";
export const Weeks = "weeks";
export const AnswerChoice = "Answer choice";
export const Choice = "Choices";
export const AddResponses = "Add Responses";
export const DeleteOption = "Delete";
export const RenameOption = "Rename";
export const AddQuestions = "Add Questions";
export const SingleSelection = "Single Selection";
export const MultiSelection = "Multi Selection";
export const OpenField = "Open Field";
export const ListEmployee = "Employee List";
export const AddExternalEmployee = "Add External Employee";
export const UpdateExternalEmployee = "Update External Employee";
export const EmployeeType = "Employee Type";
export const LMSRole = "LMS Role";
export const ValidTill = "Valid Till";
export const ReasonForAccountCreation = "Reason for Account Creation";
export const ExternalNameError = "Name cannot exceed 100 characters";
export const ExternalReasonError = "Reason cannot exceed 255 characters";
export const EmployeeTypeRequired = "Employee Type is required.";
export const NameRequired = "Name is required.";
export const PhoneNumberLength = "Phone number should be 10 digits.";
export const LMSRoleRequired = "LMS Role is required.";
export const JobRoleRequired = "Job Role is required.";
export const IndustryRequired = "Industry is required.";
export const ReasonRequired = "Reason for account creation is required.";
export const TimeRemaining = "Time Remaining";
export const MCQScore = "MCQ Score";
export const Disable = "Disable";
export const MapRole = "Map Role";
export const AttemptOn = "Attempt on: ";
export const FirstAttemptOn = "First attempt on: ";
export const YourScore = "Your Score";
export const ReattemptDays = "Re-attempt can be taken after ";
export const Role = "Role";
export const SBU = "SBU";
export const Level = "Level";
export const OneTimeTraining = "One Time Training";
export const PleaseEnterPositiveError =
  "Please enter a positive number not exceeding 99";
export const SumOfClassRoomAndPracticalError =
  "The sum of classroom and practical must not exceed 100";
export const SumOfTheoryAndPracticalError =
  "The sum of Theory and practical must not exceed 100";
export const EnterComments = "Please enter comments to send";
export const EnterCorrectPhoneNumber = "Enter proper 10 digit Phone Number";
export const EnterCorrectSecondaryPhoneNumber =
  "Enter proper 10 digit correct Secondary Phone Number";
export const ValidEmailId = "Enter valid Email Id";
export const ValidExtension = "Enter valid Extension";
export const ValidSecondaryEmailId = "Enter valid Secondary Email Id";
export const CurriculumDetails = "Curriculum Details";
export const CurriculumName = "Curriculum Name";
export const Courses = "Courses";
export const MinimumAttendanceRequired =
  "Minimum attendance should not exceed 100";
export const MaximumAttendanceRequired =
  "Maximum attendance should not exceed 500";
export const FrequencyRequired = "Frequency is required";
export const FrequencyGreaterThanZero = "Frequency should be more than 0";
export const Frequency = "Frequency";
export const RecurseEvery = "Recur every";
export const End = "End";
export const SelectFrequency = "Select the Frequency";
export const MaximumOverallQuestions = "The value must be between 1 and 250";
export const MaximumMinutesForAssessment =
  "The value must be between 1 and 500 minutes.";
export const SME = "SME";
export const IsRecurringTraining = "Is this a Recurring Training?";
export const OthersReferenceRequired = "Other References is required";
export const OthersToolsRequired = "Other Tools is required";
export const OthersTrainingAidsRequired = "Other Training Aids is required";
export const OthersPrerequisiteIsRequired = "Other Prerequisite is required";
export const ResourceRequired = "Resource is required";
export const CourseNameRequired = "Course name is required";
export const CourseTypeRequired = "Course type is required";
export const CourseOwnerNeeded = "At least one Course owner must be assigned";
export const ObjectiveRequired = "Objective is required";
export const SuggestedHourRequired = "Suggested Hours is required";
export const SuggestedMinutesRequired = "Suggested Minutes is required";
export const CurriculumRequired = "At least one curriculum must be selected.";
export const AllCurriculumRequired = "All the curriculum must be selected.";
export const FillAllTheFields = "Please Fill all the fields.";
export const ExpertiseLevelRequired =
  "At least one expertise level must be selected.";
export const AllExpertiseLevelRequired =
  "All the expertise level must be selected.";
export const BondRupeesRequired = "Bond Rupees is required.";
export const BondYearsRequired = "Bond Years is required.";
export const FutureDateRequired = "Future Date is required.";
export const SelfEnrollmentIsRequired = "Self Enrollment is required.";
export const Module = "Module";
export const Question = "Question";
export const ClickHereToAddReferenceFile = "Click here to add a reference file";
export const ClickHereToAddFile = "Click here to add a file";
export const MapCourse = "Map Course";
export const RemedialActions = "Remedial Action";
export const UploadFile = "Upload File";
export const UploadedSuccessfully = "Uploaded Successfully";
export const PleaseUploadFile = "Please Upload a File";
export const UseThisTemplate = "Use This Template";
export const Download = "Download";
export const MapCourseError = "Please Map the courses";
export const BulkUploadNote = `
  Note: Please adhere to the following guidelines when uploading file:<br />
  - Supported file type: .csv<br />
  - Maximum file size: 25MB<br />
  - Do not delete or modify the header or field names in the template file.<br />
  - Ensure that all required fields are filled in before uploading.<br />
  - If you want to upload the media URL later for the question, add the key "WILL_BE_UPLOADED".<br />
  - If 'Is this a Self Assessment?' is enabled or toggled green, then do not upload the standalone questions.
`;
export const SwitchRoleInformation = `
  You are about to switch to a different role. Please note:<br />
  <li />All current session unsaved data will be lost.<br />
  <li />After switching, the portal will reflect the selected role's permissions and settings.<br />
  Are you sure you want to proceed?
`;
export const Draft = "draft";
export const Manual = "manual";
export const Auto = "auto";
export const QuestionsTab = "questions";
export const AddModule = "Add Module";
export const ViewAll = "View All";
export const ReferenceImage = "Do you want to add reference image?";
export const DummyQuestion = "Test the module question";
export const QuestionFillData =
  "Please fill in all fields and ensure at least one option is marked as correct.";
export const QuestionFillDataForMultiple =
  "Please fill in all fields and ensure at least two option is marked as correct.";
export const PassingPercentage = "Passing %";
export const Enable = "enable";
export const EnableText = "Enable";
export const ReAttempt = "Re-attempt";
export const Count = "count";
export const CoolingPeriod = "Cooling period";
export const PolicyName = "Policy Name";
export const PolicyNameIsRequired = "Policy Name is required.";
export const MinScore = "Min  Score";
export const MaxScore = "Max Score";
export const AddReAttemptPolicy = "Add Re-Attempt Policy";
export const EnterScoreReAttempt = "Enter Score re-attempt";
export const InDays = "in days";
export const NoOfAssessment = "No Of Assessment";
export const NoOfCourse = "No Of Course";
export const Grade = "Grade";
export const Grades = "Grades";
export const Duration = "Duration";
export const EffectiveFrom = "Effective From";
export const ReAttemptPolicy = "Re-attempt Policy";
export const SelfRatingAssessment = "Self Rating Assessment";
export const DurationType = "Duration type";
export const EffectiveFromDate = "Effective from Date";
export const MapSGA = "Map SGA";
export const AuditLogs = "Audit Logs";
export const Type = "Type";
export const CreatedOn = "Created On ";
export const ModificationRequest = "Modification Request";
export const Time = "Time";
export const TargetLevel = "Target Level";
export const CurrentLevel = "Current Level";
export const WriteHere = "Write here";
export const Days = "days";
export const Hours = "hours";
export const Minutes = "minutes";
export const YouPassed = "Congratulations!! You Passed 👍";
export const PassDescription =
  " This is an independent assessment, which is not related to any curriculum";
export const YouFailed = "Better luck next time, You Failed";
export const RemedialActionHeader = "Remedial Action";
export const RemedialActionDescription =
  "To participate in the test you have to go through following courses";
export const Location = "Location";
export const Nomination = "Nomination";
export const Mail = "Mail";
export const Contact = "Contact";
export const CWSAndCatRecId = "CWS & Cat rec ID";
export const RMName = "RM Name";
export const RMEmail = "RM Email";
export const RMContactNumber = "RM Contact Number";
export const DeskNo = "Desk No";
export const ActivityLogs = "Activity logs";
export const DownloadCertificates = "Download Certificates";
export const CertifiedOn = "Certified On";
export const ExpiredOn = "Expired On";
export const RenewCertification = "Renew certification";
export const ClearAll = "ClearAll";
export const By = "by";
export const ContentTopicNotePlaceholder =
  "What is to be trained, eg. EMCP Controls, MWL, HEX, UPS.";
export const ContentSummaryNotePlaceholder =
  "Summarize what the training is about and the purpose of the training.";
export const WhoIsExpectedInTraining =
  "Who is expected to receive this training?";
export const HowLongShouldTrainingBe = "How long should the training be?";
export const TaskForTrainingProgram =
  "Provide 3-6 tasks or concepts that learners should receive from the Training program.";
export const PleaseFillWeightage = "Please fill out all the weightage";
export const DomainRequired = "Domain is required";
export const CompetencyRequired = "Competency is required";
export const LevelRequired = "Level is required";
export const FacilitatorInformationRequired =
  "Facilitator Information is required";
export const SelectLearningOutcome = "Please select Learning Outcome";
export const AddAnyOfAssessments = "Please add any one of the assessments";
export const MinimumSummativeAssessmentError =
  "Please add one Summative assessment";
export const AddAssessment = "Add Assessment";
export const ModifyAssessment = "Modify Assessment";
export const SubmitContentHeader = "Submit Content";
export const SubmitContentConfirmation =
  "Are you sure you want to submit content to SME/TM approval?";
export const AccomplishmentToolTip =
  "What do the learners need to accomplish after training?";
export const ExpectedTargetAudienceToolTip =
  "How many people are expected to receive this training?";
export const CompletedOn = "Completed On";
export const ScheduledOn = "Scheduled On";
export const PublishedOn = "Scheduled On";
export const CreateSchedule = "Create Schedule";
export const RequestForModification = "Request Modification Access";
export const FY = "FY";
export const EnterTags = "Enter tags here";
export const QuestionsWronglyAnswered = "Questions wrongly answered";
export const LearningAssessment = "Learning Assessment";
export const YourResponsesSubmitted = "Your Responses submitted for review";
export const ExamResponseText =
  " Your responses are submitted for TM Validation. Once the TM updates the score, we will notify you.";
export const TotalDuration = "Total duration";
export const ILT = "ILT";
export const VILT = "VILT";
export const Webinar = "Webinar";
export const WBT = "WBT";
export const OverallStatistics = "Overall statistics";
export const SelfEnrolledStatistics = "Self Enrolled statistics";
export const DownloadMaterialFile = "Download Material File";
export const RecurringTrainingToolTip =
  "Is this a reoccurring training or one time training? (Use beside toggle to switch between reoccurring or not)";
export const Milestone = "Milestone";
export const PleaseAddAssessmentName = "Please add an assessment name.";
export const MaximumCharacters = "Maximum 100 characters.";

export const AddValidNumberInRange10 =
  "Please enter a valid number between 1 and 10.";
export const AddValidNumberInRange250 =
  "Please enter a valid number between 1 and 250.";
export const AddValidNumberInRange99 =
  "Please enter a valid number between 1 and 99.";
export const AddValidNumberInRange100 =
  "Please enter a valid number between 1 and 100.";
export const AddValidNumberInRange30 =
  "Please enter a valid number between 1 and 30.";
export const AddValidNumberInRange500 =
  "Please enter a valid number between 1 and 500.";
export const PleaseSelectQuestionBank = "Please select a question bank.";
export const PleaseSelectPolicy = "Please select a policy.";
export const PleaseSelectSkillType = "Please select a skill type.";
export const IsSpecialCurriculum = "Is Special Curriculum";
export const Special = "Special";
export const CurriculumNameIsRequired = "Curriculum Name is required.";
export const RoleIsRequired = "Role is required.";
export const CurriculumIsRequired = "Curriculum is required.";
export const DomainIsRequired = "Domain is required.";
export const FutureDateIsRequired = "Future date is required.";
export const CourseIsRequired = "Course is required.";
export const GradeIsRequired = "Grade is required.";
export const DurationTypeIsRequired = "Duration type is required.";
export const DurationIsRequired = "Duration is required.";
export const EffectiveFromDateIsRequired = "Effective from Date is required.";
export const ReAttemptPolicyIsRequired = "Re-attempt Policy is required.";
export const AddSecondaryTrainer = "Select Secondary Trainer";
export const DurationCount = "Duration Count";
export const Source = "Source";
export const UploadOrDragFiles = "Click here to upload file";
export const MapQb = "Map QB";
export const MapModule = "Map Module";
export const SelectModule = "Select Module";
export const SelectModules = "Select Modules";
export const PleaseMapModule = "Please select modules";
export const ShowWronglyAnsweredQuestion = "Display Incorrect Answers?";
export const DoYouWantToSetThisAsRecurringAssessment =
  "Set as Recurring Assessment?";
export const TermAndCondition = "Term & Condition";
export const Close = "Close";
export const ConfirmSchedule = "Confirm Schedule";
export const NameValidationMessage = "Please enter the proper name";
export const OverallCountError = "Overall Question Count cant be greater than ";
export const ModuleWeightageError = "Module weightage should be add to 100";
export const AssessmentTypeValidationMessage =
  "Please select an assessment type.";
export const OverallQuestionCountValidationMessage =
  "Overall question count must be a number between 1 and 200.";
export const AlreadyReachedLimitForTheDay = "Already reached limit for the day";
export const EstimatedTimeValidationMessage =
  "Estimated time must be a number between 1 and 1440 minutes.";
export const PolicyIdValidationMessage = "Please select a policy.";
export const FrequencyCountValidationMessage =
  "Frequency count must be a number between 1 and 10.";
export const OccurrenceValidationMessage =
  "Occurrence must be a number between 1 and 10.";
export const TermAndConditionValidationMessage =
  "Terms and conditions cannot be empty.";
export const START_DATE_REQUIRED = "Start date is required.";
export const END_DATE_REQUIRED = "End date is required.";
export const START_DATE_GREATER_ERROR =
  "Start date cannot be more than end date.";
export const PleaseAddSomeUsersToSchedule = "Please add some users to schedule";
export const Editor = "Editor";
export const UserDetails = "User Details";
export const Status = "Status";
export const ClassRoom = "ClassRoom";
export const Score = "Score";
export const AddUsers = "+ Add Users";
export const NotStarted = "Not Started";
export const ReviewPending = "Review Pending";
export const Play = "Play";
export const IsReAttemptPolicy = "Is this a re-attempt policy?";
export const CreateReAttemptPolicyPublishConfirmation =
  "Are you sure you want to publish this Re-attempt Policy?";
export const ModifyReAttemptPolicyPublishConfirmation =
  "Are you sure you want to modify the published Re-attempt Policy?";
export const DeleteReAttemptPolicy =
  "Are you sure you want to delete Re-attempt Policy?";
export const DeleteReAttemptPolicyHeader = "Delete Re-Attempt Policy";
export const VersionControlText = "Version Control";
export const CurriculumScheduleConfirmation =
  "Are you sure you want to schedule this for publishing?";
export const CurriculumFirstTimePublishConfirmation =
  "Are you sure you want to publish this now?";
export const CurriculumPublishConfirmation =
  "This action will impact all users currently assigned to this curriculum. Are you sure you want to proceed with these changes?";
export const CurriculumDraftConfirmation =
  "Are you sure you want to draft this now?";
export const Scheduled = "Scheduled";
export const Archive = "Archive";
export const DraftStatus = "Draft";
export const Note = "Note";
export const NoOfCoursesMappedForSelectedYear =
  "course have been mapped for the selected year";
export const AssignYear = "Assign a Year";
export const SelectYear = "Select Year";
export const Year = "Year";
export const MapAssessment = "Map Assessment";
export const Start = "Start";
export const Modules = "Modules";
export const Resume = "Resume";
export const RequestExtension = "Request Extension";
export const RequestExtensionRequest = "Request Extension Request";
export const NumberOfDays = "No of days";
export const NumberOfDaysRequired = "No of days is required";
export const AddReasonRequired = "Add reason is required";
export const StartNote = "Start button will be enabled on start date";
export const SubmittedOn = "Submitted On";
export const Submitted = "Submitted";
export const FinalScore = "Final Score";
export const Retake = "Retake";
export const EstimatedTimer = "Estimated Timer";
export const TotalQuestion = "Total Question";
export const YourReason = "Your Reason";
export const ReviewerReason = "Reviewer Reason";
export const TMReason = "TM Reason";
export const LearnerReason = "Learner Reason";
export const Competent = "Competent";
export const NotCompetent = "Not Competent";
export const PartialCompetent = "Partial Competent";
export const LearnerResponses = "Learner Responses";
export const RMResponses = "RM Responses";
export const PleaseAnswerAllQuestions =
  "Please answer all the questions before submit";
export const PleaseReviewAll = "Please review all the questions";
export const SelfAssessmentSummary = "Self Assessment Summary";
export const Tags = "Tags";
export const ModuleAddedSuccessfully = "Module added successfully";
export const QuestionBankCreatedSuccessful =
  "Question bank created successfully";
export const QuestionBankUpdatedSuccessful =
  "Question bank updated successfully";
export const ILPYear = "ILP Year";
export const SearchForUsersOrGroups = "Search for Users";
export const Or = "Or";
export const Owner = "Owner";
export const Main = "Main";
export const Assistant = "Assistant";
export const Date = "Date:";
export const VersionText = "Version";
export const Current = "Current";
export const SGASubmitAlert = "Are you sure you want to modify this SGA?";
export const Answered = "Answered";
export const InProgress = "In progress";
export const Previous = "Previous";
export const Participants = "Participants";
export const LiteratureReference = "Literature Reference";
export const AreYouSureDeleteSchedule =
  "Are you sure you want to delete schedule?";
export const DeleteSchedule = "Delete Schedule";
export const AnswerGiven = "Answer Given";
export const Correct = "Correct";
export const Wrong = "Wrong";
export const ComingSoon = "Coming Soon";
export const AddProperData = "Add proper data";
export const SubmitAssessmentAlert = "Are you sure you want to submit?";
export const SubmitAssessmentAlert2 =
  "You have not answered some questions. Do you still want to submit?";
export const PleaseEnterValidEmailAndPassword =
  "Please Enter valid email or password...!";
export const AssigneesUpdated = "Assignees updated";
export const Copied = "Copied!";
export const SGA = "SGA";
export const Total = "Total";
export const Pending = "Pending";
export const Approved = "approved";
export const PendingForReview = "Pending for Review";
export const Rejected = "rejected";
export const RejectedHeader = "Rejected";
export const NotYetStarted = "Not Yet Started";
export const PleaseAddReason = "Please add reason";
export const ConfirmScheduleHeader = "Schedule assessment";
export const ConfirmScheduleBody =
  "Are you sure you want to schedule this assessment?";
export const ExtensionRequestLimit = "Days cannot be more that 30 days";
export const ExtensionsAlreadyRaised = "Extension request already raised.";
export const DownloadCertificate = "Download Certificate";
export const Mins = " Mins";
export const AssessmentTypeRequired = "Assessment Type is required";
export const AssessmentRequired = "Assessment is required";
export const StartDateRequired = "Start Date is required";
export const EndDateRequired = "End Date is required";
export const ApproveSkillFile = "Are you sure you want to approve?";
export const RejectSkillFile = "Are you sure you want to decline?";
export const ApproveFile = "Approve";
export const DeclineFile = "Decline";
export const PleaseSelectCourseType = "Please select Course type";
export const PleaseSelectCourse = "Please select Course";
export const PleaseSelectYear = "Please select year";
export const ModifyYear = "Modify Year";
export const MarkAsCompleted = "Mark as Completed";
export const MarkAsCompletedConfirmation =
  "Are you sure you want to Mark as Completed";
export const ApproveYear = "Approve Year";
export const DeclineYear = "Decline Year";
export const ApproveYearBody = "Are you sure you want to approve request";
export const DeclineYearBody = "Are you sure you want to decline request";
export const CourseMandatoryLevel =
  "Course is mandatory for at least one of the level.";
export const EnteredMoreThanRequested = "You entered more days than requested.";
export const MinScoreLessThanMax = "Min score should be less than Max score";
export const MaxScoreLessThanPassingPercentage =
  "Max score should be less than Passing Percentage";
export const PassingPercentageGreaterThanMaxScore =
  "Passing Percentage should be greater than Max score";
export const Declined = "Declined";
export const MinimumDaysBetweenReAttempts = "Minimum Days between Re-Attempts";
export const NewUsers = "New";
export const NewUsersCount = "New (0)";
export const ExistingUsers = "Existing";
export const ExistingUsersCount = "Existing (0)";
export const CourseIsAlreadyMapped =
  "The course is already mapped to your ILP.";
export const ModuleWeightageErrorMessage = action => {
  return `Module weightage for ${action} cannot be empty`;
};
export const ReAttemptPolicyNote =
  "Re-attempt policy (Common for all) can be modified in master data";
export const Yes = "Yes";
export const No = "No";
export const SelectLo = "Select LO";
export const Min = " min";
export const OnlyOneSummativeAssessment =
  "Only one Summative assessment is allowed for a course";
export const ExamAlreadyInProgress =
  "An assessment is already in progress, so you are unable to start the next one at this time. Please complete the ongoing assessment before attempting another.";
export const EmployeeId = "EMP ID";
export const Replacing = " Replacing";
export const EligibleList = "Eligible list";
export const Mobile = "Mobile";
export const ParticipantCheckList = "Participant Check list";
export const selected = "selected";
export const CorrectAnswer = "Correct Answer";
export const Options = "Options";
export const NA = "NA";
export const Available = "Available";
export const Booked = "Booked";
export const NominatedOn = "Nominated On";
export const NotAvailable = "Not Available";
export const ProjectDeliveryMethods =
  "Project Delivery Methods and Contracts in Construction Management";
export const CourseID = "Course ID";
export const CourseEffectiveness = "Course Effectiveness";
export const InstructorEvaluation = "Instructor Evaluation";
export const CourseContent = "Course Content";
export const YourFeedback = "Your Feedback";
export const Domains = "Domains";
export const Industries = "Industries";
export const ModifiedOn = "Modified On";
export const LO = "LO";
export const Competencies = "Competencies";
export const Proficiency = "Proficiency";
export const CreatedBy = "Created By";
export const CreatedAt = "Created At";
export const TeachingPoints = "Teaching Points";
export const Create = "Create";
export const Edit = "Edit";
export const JobRole = "Job Role";
export const Locations = "Locations";
export const Classrooms = "Classrooms";
export const MapMentorAndMentee = "Map Mentor & Mentee";
export const Replace = "Replace";
export const Mentor = "Mentor";
export const Mentee = "Mentee";
export const MapButton = "Map";
export const Continue = "Continue";
export const Instruction = "Instruction";
export const Topic = "Topic";
export const Descriptions = "Descriptions";
export const SessionDetails = "Session Details";
export const KeyHighlights = "Key Highlights";
export const Session = "Session";
export const SessionFeedback = "Session Feedback";
export const MyFeedback = "My Feedback";
export const SessionLists = "Session List";
export const MenteeDetails = "Mentee Details";
export const Menteess = "Mentee's";
export const GiveFeedback = "Give Feedback";
export const UnLinkMentee = "Un-link Mentee";
export const UnlinkCurriculum = "Un-link Curriculum";
export const UnlinkSGA = "Un-link SGA";
export const UnlinkCurriculumMessage =
  "Are you sure you want to un-link curriculum";
export const UnlinkSGAMessage = "Are you sure you want to un-link SGA";
export const UnLinkMenteeConfirmMessage =
  "Are you sure you want to un-link the mentee?";
export const DurationInMinutes = "Duration (in minutes)";
export const Action = "Action";
export const SessionDate = "Session Date";
export const NextSession = "Next Session";
export const TotalSession = "Total Session";
export const UpcomingSession = "Upcoming Session";
export const Mentees = "Mentees";
export const MappedMentor = "Mapped Mentor";
export const MappedMentee = "Mapped Mentee";
export const KeyChangesAndDevelopmentGoals =
  "Key Changes to the IDP / Other Development Goals";
export const KeyActionsOrSteps = "Key Actions / Steps";
export const KeyThoughtsOrSteps = "Key Thoughts / Steps";
export const KeyChanges = "Key Changes";
export const KeyThoughts = "Key Thoughts";
export const KeyThoughtsEmergingFromTheSession =
  "Key Thoughts Emerging from the Session";
export const WhatsWorkingWell = "What's Working Well";
export const WhatsNotWorkingWell = "What's Not Working Well";
export const ResourcesRequired = "Resources Required";
export const DoYouWantToAddNextSession = "Do you want to add next session?";
export const MenteeValidationMessage = "Please select a mentee";
export const DurationValidationMessage = "Please enter duration";
export const DurationLimitValidationMessage =
  "Duration must be a number between 1 and 1440 minutes";
export const EnterTopicValidationMessage = "Please enter a topic";
export const StartDateValidationMessage = "Please select a start date";
export const KeyThoughtsOrStepsValidationMessage =
  "Please enter key thoughts or steps";
export const KeyThoughtsOrStepsLimitationMessage =
  "Key Thoughts/Steps must be 255 characters or less";
export const WhatsWorkingWellValidationMessage =
  "Please enter what's working well";
export const WhatsWorkingWellLimitationMessage =
  "what's working well must be 255 characters or less";
export const WhatsNotWorkingWellValidationMessage =
  "Please enter what's not working well";
export const WhatsNotWorkingWellLimitationMessage =
  "what's not working well must be 255 characters or less";
export const ResourceRequiredValidationMessage =
  "Please enter resources required";
export const ResourceRequiredLimitationMessage =
  "Resources Required must be 255 characters or less";
export const NextSessionValidationMessage =
  "Please select the next session date";
export const KeyChangesValidationMessage =
  "Please Enter Key Changes to the IDP / Other Development Goals ";
export const KeyActionsValidationMessage = "Please Enter Key Actions / Steps";
export const KeyThoughtsValidationMessage = "Please Enter Key Thoughts / Steps";
export const KeyChangesLimitationMessage =
  " Key Changes must be 255 characters or less";
export const KeyActionsLimitationMessage =
  " Key Actions must be 255 characters or less";
export const KeyThoughtsLimitationMessage =
  " Key Thoughts must be 255 characters or less";
export const YourFileFileSizeNote =
  "Note: Supported file formats are .pdf, .doc, .docx";
export const LoadFactorTitle = "Load Factor";
export const ScheduleList = "Schedule List";
export const LastRefreshedOn = "Last refreshed on";
export const Freeze = "Freeze";
export const ModifyPlan = "Modify Plan";
export const Industry = "Industry";
export const ScheduleNow = "Schedule Now";
export const AssistantTrainer = "Assistant Trainer";
export const BatchMinimumSize = "Batch Minimum Size";
export const BatchMaximumSize = "Batch Maximum Size";
export const NominationBatchMinimumSize = "Nomination Batch Minimum Size";
export const NominationBatchMaximumSize = "Nomination Batch Maximum Size";
export const WaitlistEnable = "Waitlist enable?";
export const RMApproval = "RM approval?";
export const SelfEnrollmentEnable = "Self enrollment?";
export const WaitlistNo = "Waitlist No.";
export const TotalWaitlistCountRequired =
  "Total waitlist count is required and must be greater than 0";
export const ScheduleStartDateRequired = "Start date is required.";
export const ScheduleEndDateRequired = "End date is required.";
export const BatchMinimumSizeRequired = "Batch minimum size is required.";
export const BatchMaximumSizeRequired =
  "Batch maximum size must be greater than batch minimum size.";
export const PrimaryTrainerRequired = "Main trainer is required.";
export const RMApprovalRequired = "RM approval is required.";
export const SelfEnrollmentRequired =
  "Self-enrollment must be enabled or disabled.";
export const InvalidNominationStartDate = "Select proper Nomination start date";
export const InvalidNominationEndDate = "Select proper Nomination end date";
export const LocationRequired = "Location is required.";
export const ClassroomRequired = "Classroom is required.";
export const NominationBatchMinimumSizeRequired =
  "Nomination batch minimum size is required.";
export const NominationBatchMaximumSizeRequired =
  "Nomination batch maximum size must be greater than nomination batch minimum size.";
export const NominationStartDateRequired = "Nomination start date is required.";
export const NominationEndDateRequired = "Nomination end date is required.";
export const ValidTrainerIdsRequired = "Selected trainer IDs must be valid.";
export const OngoingClass = "Ongoing Class";
export const EditSchedule = "Edit Schedule";
export const TotalSeat = "Total Seat";
export const AddTrainer = "+ Add Trainer";
export const Replacement = "Replacement";
export const External = "External";
export const Remove = "Remove";
export const RemoveParticipant = "Remove Participant";
export const ApproveTrainerReason = "Approving Reason";
export const ApproveCourseSchedule =
  "Are you sure you want to approve this schedule";
export const RejectCourseSchedule =
  "Are you sure you want to reject this schedule";
export const RejectTrainerReason = "Decline Reason";
export const ApproveNomineeReason = "Approving Reason";
export const RejectNomineeReason = "Decline Reason";
export const AssignReplacementTrainer = "Assign Replacement Trainer";
export const AddAssistantTrainer = "Add Secondary Trainer";
export const RemoveTrainer = "Remove Trainer";
export const RemoveTrainerConfirmation =
  "Are you sure you want to remove this trainer?";
export const AlternateTrainer = "Alternate Trainer";
export const EmptyCommentsAlert = "Please provide a reason for your action.";
export const EmptyTrainerAlert = "Please select a trainer.";
export const CancelSchedule = "Cancel Schedule";
export const CancelScheduleConfirmation =
  "Are you sure you want to cancel schedule";
export const NotYetAdded = "Not yet added";
export const AddParticipant = "+ Add Participant";
export const FindParticipantBy = "Find Participant By";
export const Proceed = "Proceed";
export const ParticipantListValidationRequest =
  "Participant list validation request";
export const ValidateAndSchedule = "Validate & Schedule";
export const CompleteNomination = "Complete Nomination";
export const MarkAttendanceError = "Please mark attendance";
export const StageAnalysis = "Stage 1 analysis";
export const RatingValues =
  "1=Strongly Disagree, 2=Disagree, 3=Somewhat Disagree, 4=Somewhat Agree, 5=Agree, 6=Strongly Agree";
export const FreezeHeader = year => {
  return `Freeze ${year}`;
};
export const FreezeBody = year => {
  return `Are you sure you want to freeze ${year}`;
};
export const ModifyPlanError = "Please ad all correct values";
export const PlanExceededError = course_name => {
  return `Total planned sessions exceed sessions planned for ${course_name}`;
};
export const TrainerUnavailable = "Selected trainer is not available";
export const ClassroomUnavailable = "Selected Classroom is not available";
export const IsReappear = "Is Re-appear?";
export const IsFirstTimer = "Is First Timer?";
export const UnderNoticePeriod = "Under Notice Period?";
export const IsSelfEnrolled = "Is Self Enrolled?";
export const IsActivelyEnrolledInSGA = "Actively enrolled in SGA?";
export const isPendingForCertification = "Is Pending for certification?";
export const IsMappedToILP = "Is Mapped to ILP?";
export const IsNominated = "Is Nominated?";
export const NominationGap = "Nomination Gap?";
export const IsAttendedEarlier = "Is Attended Earlier?";
export const ConsiderTrainingGap = "Consider Training Gap?";
export const After = "After";
export const Before = "Before";
export const PleaseAddParticipants = "Please Add Participants";
export const RemoveParticipants =
  "Are you sure you want to remove participants";
export const DisableEmployee = "Are you sure you want to disable this employee";
export const DisableEmployeeHeader = "Disable employee";
export const GlobalSetting = "Global Setting";
export const DeSelectAll = "Deselect All";
export const WhatYouWillLearnInThisCourse =
  "What you will learn in this course";
export const PreCourseWorkHeader = "Pre - Course work";
export const Material = "Material";
export const ThisCourseIncludes = "This course includes";
export const Instructor = "Instructor";
export const Room = "Room";
export const NominationPeriod = "Nomination Period";
export const CoursePeriod = "Course Period";
export const PleaseTakePreCourse = "Please take Pre Course";
export const PleaseTakeLast =
  "Please take Post assessment, at the last day of the course.";
export const ReviewHasBeenSubmitted = "A review has already been submitted.";
export const EnableLastDayOfCourse =
  "The course feedback form will be enabled on the last day of the course.";
export const TheoryPercentage = "Theory %";
export const PracticalPercentage = "Practical %";
export const SumMustBe100 =
  "The sum of Theory Percentage and Practical Percentage must be 100.";
export const ThisFieldRequired = "This field is required";
export const ContentType = "Content Type";
export const PageNo = "Page No";
export const Feedback = "Feedback";
export const ModuleFeedbackTitle = "Module Feedback";
export const AreYouSureDelete = "Are you sure you want to delete?";
export const KnowledgeAssessment = "Knowledge Assessment";
export const FailedToDownloadFileFrom = "Failed to download file from";
export const WelcomeToPrerequisite =
  "Welcome to the Prerequisite Assessment for the";
export const DesignedToEvaluateYourFoundational =
  "course. This assessment is designed to evaluate your foundational knowledge and skills in key";
export const EnrollMe = "Enroll Me";
export const MailMe = "Mail Me";
export const MailMeAddToILP = "Mail Me & Add to ILP";
export const isRequired = "is required";
export const PleaseRateYourLevelAgreement =
  "Instructions: Please rate your level of agreement for each of the statements below.";
export const FeedbackNumberList =
  " 1=Strongly Disagree, 2=Disagree, 3=Somewhat Disagree, 4=Somewhat Agree, 5=Agree, 6=Strongly Agree";
export const ModuleImprovementForm = "Module Improvement form";
export const CourseFeedbackForm = "Course Feedback form";
export const ActivityWorkbook = "Activity workbook";
export const PreTest = "Pre Test";
export const PostTest = "Post Test";
export const FormativeAssessment = "Formative Assessment";
export const WaitingTrainerApproval = "Waiting for trainer approval";
export const NominationOngoing = "Nomination ongoing";
export const NominationClosed = "Nomination closed";
export const Rescheduled = "Rescheduled";
export const Cancelled = "Cancelled";
export const TrainerApproved = "Trainer approved";
export const Ongoing = "Ongoing";
export const TotalRequest = "Total Request";
export const Expired = "Expired";
export const ApprovedHeader = "Approved";
export const Employee = "Employee";
export const WaitList = "Wait list";
export const NominationClosesOn = "Nomination Closes on";
export const MoreActions = "More Actions";
export const RejectConfirmation = "Are you sure you want to reject?";
export const ApproveConfirmation = "Are you sure you want to approve?";
export const CancelConfirmation = "Are you sure you want to cancel?";
export const RequestSession = "Request Session";
export const UnderReview = "Under review";
export const SessionQuestionWhatsWorkingWell = "What's Working Well?";
export const SessionQuestionWhatsNotWorkingWell = "What's Not Working Well?";
export const SessionQuestionKeyThoughts =
  "Key Thoughts Emerging from the session";
export const Rating = "Rating";
export const CourseStartedWarning = "Course already started";
export const StartDateError = "Recommended start date is 8 weeks from today";
export const DateError = "Selected date is a holiday";
export const UpcomingSessionErrorToastMessage = "Session is not completed yet";
export const DeleteDomainMessage = "Are you sure you want to delete domain";
export const DeleteDomainHeader = "Delete Domain";
export const DeleteJobRoleMessage = "Are you sure you want to delete job role";
export const DeleteJobRoleHeader = "Delete Job Role";
export const DeleteCompetencyHeader = "Delete Competency";
export const DeleteCompetencyMessage =
  "Are you sure you want to delete competency";
export const DeleteIndustryHeader = "Delete Industry";
export const DeleteIndustryMessage = "Are you sure you want to delete industry";
export const DeleteClassRoomHeader = "Delete class room";
export const DeleteClassRoomMessage =
  "Are you sure you want to delete class room";
export const JobRoles = "Job Role";
export const IsRequired = "is required";
export const LimitValidationMessage = "should not be more than 100 characters";
export const DescriptionLimitationMessage =
  "Description should not be more than 255 characters";
export const PleaseSelect = "Please Select";
export const ErrorWhileDownloading = "Error downloading file:";
export const EditRole = "Edit Role";
export const UnArchiveConfirmMessage =
  "Are you sure you want to unArchive this learning outcome?";
export const UnArchive = "UnArchive";
export const ConfirmUnArchive = "Confirm UnArchive";
export const PleaseEnter = "Please Enter";
export const LoginWith = "Or Login with";
export const CWSId = "CWS & Cat rec ID";
export const TeachingPointsLimitationMessage =
  " TeachingPoints should not be more than 255 characters";
export const DeleteLocationHeader = "Delete Location";
export const DeleteLocationMessage = "Are you sure you want to delete location";
export const Regions = "Regions";
export const LearningActivity = "Learning Activity";
export const TopicRequired = "Topic is required";
export const LearningActivityRequired = "Learning activity is required";
export const DeliveryRequired = "Delivery is required";
export const EstimatedTimeRequired = "Estimated time is required";
export const TopicValidationMessage = "Topic cannot exceed 100 characters";
export const LearningActivityValidation =
  "Learning activity cannot exceed 255 characters";
export const DeliveryValidation = "Delivery cannot exceed 255 characters";
export const CertificationAcquired = "Certification Acquired";
export const TotalCertification = "Total certification";
export const RequestedModification = "Request for modification";
export const ModificationRequestedBy = "Modification Requested by";
export const WebinarLink = "Webinar link";
export const EnterWebinarLink = "Please enter Webinar link";
export const EnterValidWebinarLink = "Please enter valid Webinar link";
export const ModifiedBy = "Modified By";
export const Levels = "Levels";
export const BatchSize = "Batch Size";
export const TrainerName = "Trainer Name";
export const CourseDuration = "Course Duration";
export const SGAAssessmentMappedInfo =
  "Disabling reattempts may impact the associated assessments and the Skill Gap Analysis (SGA).";
export const AssociatedAssessment = "Associated Assessments";
export const AssociatedSGA = "Associated SGA";
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

//  ToolTip Messages
export const QBNameToolTipMessage =
  "Enter the name of the question bank. The title should reflect the content and purpose of the Question bank";
export const QBTagsToolTipMessage =
  "Add suitable tags which will help in grouping and identifying the question bank quickly";
export const QBModuleNameTootTipMessage =
  "Enter the name of the Module. The title should reflect the content of the module";
export const QBEnterQuestionsToolTipMessage =
  "Enter the actual question text here. Be clear and concise to ensure that learners understand what is being asked";
export const QBOtherReferenceTootTipMessage =
  "Provide the list of reference materials to be shown to the learner as part of the remedial plan if they have a limited understanding of the module content";
export const QBMapCourseToolTipMessage =
  "Provide the list of courses to be shown to the learner as part of the remedial plan if they have a limited understanding of the module content";
export const QBAnswerChoiceToolTipMessage =
  "Provide the possible answer choices for the question. Ensure that the options are clear and distinct";
export const QBQuestionReferenceFileToolTipMessage =
  "Upload a file that supports the question (if needed). This can provide better clarification, enhancing the learner's understanding of the question";
export const QBRemedialActionFileToolTipMessage =
  "Upload any document you want to show to the learners under the remedial plan section if they have a limited understanding of the module content";
export const QBQuestionBulkUploadFileToolTipMessage = "";

export const RMRequestTypeToolTipMessage =
  "Select External for course request from customers. Select Internal for course request from within organization";
export const CustomerNameToolTipMessage =
  "Provide the Name of the Customer who has requested a training program";
export const PointOfContactToolTipMessage =
  "Provide the name of person who is handling this customer from Gmmco Side";
export const NatureOfBusinessToolTipMessage =
  "Describe the type or category of business activities that the organization is involved in. This helps in understanding the context and relevance of the request.";
export const FrequencyToggleToolTipMessage =
  "If this is a recurring training, toggle the switch to make it green. If this is a One time training, toggle the switch to make it red";
export const FrequencyTypeToolTipMessage =
  "Select how often this training program needs to be offered in a specific period";
export const TopicToolTipMessage =
  "Provide the topic of the course, e.g., Engine model or system type.";
export const SummaryToolTipMessage =
  "Summarize what the training is about and the purpose of the training.";
export const AccomplishmentToolTipMessage =
  "Provide a brief description on what the learners need to accomplish after this training program.";
export const TargetAudienceToolTipMessage =
  "Enter the intended audience for this program";
export const AudienceCountToolTipMessage =
  "Provide a count of people who will attend this program";
export const SuggestedDurationDaysToolTipMessage =
  "Provide a suggested duration for this program in Days";
export const SuggestedDurationHoursToolTipMessage =
  "Provide a suggested duration for this program in hours";
export const ObjectiveToolTipMessage =
  "Provide 3-6 tasks or concepts that learners should receive from the training program.";
export const PrimaryContactNameToolTipMessage =
  "Provide the name of the person who should be contacted to know more about this training request";
export const PrimaryContactNumberToolTipMessage =
  "Enter a valid phone number for the primary contact. This will be used for communication";
export const PrimaryEmailIdToolTipMessage =
  "Enter the email address of the primary contact. This will be used for official correspondence and notifications";
export const PrimaryExtensionToolTipMessage =
  "Enter the phone extension number for the primary contact. This helps in directly reaching the specific person within the organization";
export const SecondaryContactNameToolTipMessage =
  "Enter the name of an alternate point of contact. This person will be contacted,if the primary contact is unavailable.";
export const SecondaryContactNumberToolTipMessage =
  "Enter a valid phone number for the secondary contact. This will be used for communication if the primary contact is unavailable";
export const SecondaryEmailIdToolTipMessage =
  "Enter the email address of the secondary contact. This will be used for additional communication and notifications if needed.";
export const SecondaryExtensionToolTipMessage =
  "Enter the phone extension number for the secondary contact. This helps in reaching the specific person directly within the organization if the primary contact is unavailable.";
export const NotesToolTipMessage =
  "Add any additional information or comments relevant to the request.";

export const ModifyCourseToolTipMessage =
  "Select the course which needs to be modified to accommodate this training need";
export const CourseNameToolTipMessage = "Provide a Course Name";
export const CourseTypeToolTipMessage = "Select the Type of course";
export const TrainerToolTipMessage =
  "Select the person who will develop this course";
export const SMEToolTipMessage =
  "Choose the Subject Matter Expert (SME) responsible for content review or approval";
export const DescriptionToolTipMessage =
  "Provide a clear and concise description of the main goals of this course";

export const DomainToolTipMessage =
  "Select the domain that this course belongs to. This helps categorize the course for easier discovery by learners and ensures it aligns with their specific learning pathways";
export const CompetencyLevelToolTipMessage =
  "Choose the competency this course intends to cover. Competencies define the skills and knowledge learners will develop through this course";
export const ProficiencyLevelToolTipMessage =
  "Select the proficiency level this course belongs to. This helps to determine the learner's skill level, from pre-foundational to expert, ensuring the course is appropriately aligned within a curriculum or expertise level.";
export const FacilitatorInformationToolTipMessage =
  "Provide the list of conditions the facilitator must ensure during the delivery of the course.";

export const PrerequisiteToolTipMessage =
  "Select the List of courses that must be completed by the learner before enrolling in this course";
export const VersionToolTipMessage =
  "Specify the version of the competency statement";
export const ConditionsToolTipMessage =
  "Outline the condition in which this course should be delivered";
export const MinimumRequirementToolTipMessage =
  "outline the minimum qualifications required to deliver this course";
export const MediaDemonstrationToolTipMessage =
  "List the resources needed to deliver this course";
export const EstimatedDaysToolTipMessage =
  "Provide the estimated days required to deliver this course";
export const ClassRoomToolTipMessage =
  "Enter the ratio of learner to Instructor ratio during classroom session";
export const PracticalToolTipMessage =
  "Enter the ratio of learner to Instructor ratio during practical session";
export const ResourceToolTipMessage =
  "List all the resources needed to deliver this course";
export const ReferenceToolTipMessage =
  "List the reference materials used while preparing this course";
export const ReviewToolTipMessage =
  "List all the things the instructor must review with the learners before the beginning of this course";
export const AssessmentToolTipMessage =
  "Outline the Assessment methodology used to evaluate the learners in this course";

export const LessonPlanTopicToolTipMessage =
  "Provide the name of the topic / module";
export const LearningActivitiesToolTipMessages =
  "Enter the type of activity (i.e. Classroom, Practical, Assessment etc..) along with additional information";
export const DeliveryToolTipMessage =
  "Provide a detailed list of items covered in the module";
export const EstimatedTimeToolTipMessage =
  "Provide a estimated time needed to deliver this item";

export const IntendedAudienceToolTipMessage =
  "Specify the target audience for this course.";
export const MinimumAttendanceToolTipMessage =
  "Specify the minimum number of participants required to run this course. The course will only be delivered if this threshold is met";
export const MaximumAttendanceToolTipMessage =
  "Set the maximum number of participants allowed in this course. This ensures effective training and allows for optimal interaction and engagement";
export const SuggestionToolTipMessage =
  "Provide any recommended reading materials, resources, or literature that learners should review before this course to enhance their understanding and engagement";
export const QualificationToolTipMessage =
  "List any qualifications or certifications that learners will earn upon successfully completing this course. This information helps highlight the value of the course";
export const AssessmentCriteriaToolTipMessage =
  "Define the standards and benchmarks used to evaluate learner performance in this course. This includes the knowledge & skills learners must demonstrate to be deemed competent";
export const TheoryRatioToolTipMessage =
  "Specify the proportion of theoretical content in this course compared to practical applications";
export const PracticalRatioToolTipMessage =
  "Specify the proportion of practical activities in this course compared to theoretical content";
export const PracticalActivityDetailsToolTipMessage =
  "Provide a description of the practical activities included in this course";
export const ToolsAidsToolTipMessage =
  "List any tools, equipment, materials or any other resources required to facilitate learning";
export const RegistrationRequirementToolTipMessage =
  "Outline any specific registration requirements for this course, such as deadlines, forms, or approvals needed before enrolling";

export const LearnerDocumentToolTipMessage =
  "Upload all the materials which learners should access during the course";
export const TrainerDocumentToolTipMessage =
  "Upload all the materials which the instructor should access during the course";
export const UploadScormFileToolTipMessage =
  "Upload a correct SCORM file for this course";

export const AssessmentTypeToolTipMessage =
  "Specify the type of assessment (i.e. Knowledge , Skill)";
export const SkillTypeToolTipMessage =
  "Specify the type of Skill Assessment (i.e. OJT or Field Skill Assessment)";
export const UploadLearnerDocToolTipMessage =
  "Upload all the documents the learner should use during the skill assessment";
export const UploadTrainerDocToolTipMessage =
  "Upload all the documents the Assessor should use during the skill assessment";

export const PrimaryTrainerToolTipMessage =
  "Choose the primary trainer responsible for delivering this course";
export const SecondaryTrainerToolTipMessage =
  "Choose the secondary trainers who could deliver this course";
export const CurriculumToolTipMessage =
  "Choose the curriculum that this course will be linked to";
export const ExpertiseLevelToolTipMessage =
  "Select the appropriate level in the chosen curriculum that this course will be linked to";
export const CourseFeeToolTipMessage =
  "Provide the Total Course fee per person";
export const BondRupeesToolTipMessage = "Specify the bond amount per person";
export const BondYearToolTipMessage = "Specify the bond period";
export const FutureDateToolTipMessage =
  "Select a future date to schedule the publication of this course. The course will be added to the course catalogue and will be visible to everyone on this date";
export const SelfEnrollmentToolTipMessage =
  "Choose whether to allow learners to self-enroll in this course. Enabling self-enrollment gives participants the flexibility to register on their own.";

export const AssessmentNameToolTipMessage =
  "Enter the name of the Assessment. The title should reflect the content and purpose of the Assessment";
export const SelectAssessmentTypeToolTipMessage =
  "Select the type of assessment. Choose Self assessment if you don't want this assessment to be graded and planning to add it under the 'Self Assessment' section of Skill Gap Analysis. Else, select 'Standalone Assessment'.";

export const SelectQuestionBankToolTipMessage =
  "Select the Question bank to be associated with this assessment";
export const MapModuleToolTipMessage =
  "Select the list of modules to be added to the assessment from the question bank. This option will be available only if the type of assessment is selected as 'Standalone Assessment'.";

export const ModuleWeightageToolTipMessage =
  "Specify the weightage percentage of each module in the assessment: The total sum should not exceed 100%.";
export const OverallQuestionCountToolTipMessage =
  "Specify the total number of questions to be asked in the assessment";
export const AssessmentDurationToolTipMessage =
  "Specify the Duration of the Assessment";
export const SelectPolicyToolTipMessage =
  "Select the Suitable re-attempt policy";
export const ShowWronglyAnsweredToolTipMessage =
  "Enable this option to display the list of questions which the learners have answered wrongly to the learners at the end of the assessment";
export const AssessmentTagToolTipMessage =
  "Add suitable tags which will help in grouping and identifying the assessment quickly";
export const TermsAndConditionToolTipMessage =
  "Provide a detailed instructions for the learners to read through before they start the assessment";
export const isRecurringToolTipMessage =
  "Select whether you want to configure this assessment as recurring. This option allows the assessment to be automatically scheduled at defined intervals, ensuring regular evaluation of learner";

export const ILPCurriculumToolTipMessage =
  "Select the curriculum you would like to assign to the learner";
export const ILPMapCourseToolTipMessage =
  "Select the course you would like to assign to the learner ILP";
export const ILPDateToolTipMessage = "Set the target date for completion";
export const ILPMapSGAToolTipMessage =
  "Select the Skill Gap Analysis you would like to assign to the learner";
export const ILPAssessmentTypeToolTipMessage =
  "Select the Type of Assessment you would like to enrol the learner into";
export const ILPAssessmentToolTipMessage =
  "Select the Assessment you would like to enrol the learner into";
export const ILPStartDateToolTipMessage =
  "Set the Start Date for the Assessment";
export const ILPEndDateToolTipMessage = "Set the End Date for the Assessment";

export const ScheduleStartDateToolTipMessage =
  "Set the Start date for the assessment";
export const ScheduleEndDateToolTipMessage =
  "Set the End date for the assessment";
export const ScheduleParticipantToolTipMessage =
  "Select the list of people you would like to enrol into this assessment schedule";
export const ScheduleUploadFileToolTipMessage =
  "Upload the list of people you would like to enrol into this assessment schedule";
export const DeclineModificationToolTipMessage =
  "Provide the reason for declining the modification access request";
export const DeclineExtensionToolTipMessage =
  "Provide the reason for declining the Extension request";
export const AddExtensionRequestToolTipMessage =
  "Provide the reason for requesting the Extension";
export const ExtensionDaysToolTipMessage =
  "Enter the number of days you are requesting for the extension";

export const ReAttemptPolicyNameToolTipMessage =
  "Enter the name of the Reattempt policy. The title should clearly reflect the content and purpose of this policy";
export const PassingPercentageToolTipMessage =
  "Input the minimum percentage score needed for learners to pass this assessment";
export const IsReAttemptPolicyToolTipMessage =
  "Enable this option to allow learners to re-attempt the assessment if they do not achieve the passing score";
export const MinScoreToolTipMessage =
  "Specify the minimum score a learner must achieve to qualify for a reattempt of the assessment. This score determines eligibility for additional attempts";
export const MaxScoreToolTipMessage =
  "Specify the maximum score a learner must achieve to qualify for a reattempt of the assessment. This score determines eligibility for additional attempts";
export const NoOfAttemptsToolTipMessage =
  "Enter the maximum number of times a learner can retake the assessment if they are eligible";
export const MinDaysBetweenReAttemptsToolTipMessage =
  "Specify the minimum number of days that must pass before a learner can attempt the assessment again. This interval allows time for review and preparation between attempts";

export const CurriculumNameToolTipMessage =
  "Enter the name of the Curriculum. The title should reflect the content and purpose of the curriculum";
export const RoleToolTipMessage =
  "Specify the job role associated with this curriculum";
export const CurriculumDomainToolTipMessage =
  "Select the domain that this curriculum belongs to. This helps categorize the curriculum for easier discovery by learners and ensures it aligns with specific job roles";
export const CourseToolTipMessage =
  "Select the courses to be mapped in this level of curriculum";
export const PublishLaterToolTipMessage =
  "Enable this option to schedule this curriculum to become active on a future date";
export const CurriculumFutureDateToolTipMessage =
  "Select a future date to schedule this curriculum to become active. Any changes you have made will be applied to all those enrolled into this curriculum on this date";
export const IsSpecialCurriculumToolTipMessage =
  "Check this box only if you don't want to link this curriculum to any specific Job role";
export const JobRoleToolTipMessage =
  "Specify the job role associated with this curriculum";

export const SelfAssessmentToolTipMessage =
  "Select a self-assessment to be included in this SGA. Self-assessments help learners evaluate their own knowledge and skills. These will be reviewed and rated by the Reporting managers once the learners complete it.";
export const SGACourseToolTipMessage =
  "Select the list of Courses to be added to this Skill Gap Analysis.";
export const GradeToolTipMessage =
  "Choose the grade level applicable to this Skill Gap Analysis.";
export const DurationTypeToolTipMessage =
  "Select the Duration type for this Skill Gap Analysis.";
export const DurationCountToolTipMessage =
  "Enter the duration count for the selected Duration type.";
export const EffectiveFromDateToolTipMessage =
  "Set a date for this Skill Gap Analysis to become active. On this date, any changes made now will become active.";
export const ReAttemptPolicyToolTipMessage =
  "Select an appropriate re-attempt policy for this Skill Gap Analysis.";

export const IndustryToolTipMessage =
  "Select the appropriate industries (CI, RI, EPD, etc.) for which load factor of courses to be displayed.";
export const LoadFactorJobRoleToolTipMessage =
  "Pick the job role(s) to see respective curricula/course load factor.";
export const LoadFactorCurriculumToolTipMessage =
  "Select a curricula(s) to see respective courses' load factor.";
export const MasterDataProficiencyToolTipMessage =
  "Select the proficiency level to narrow down the course list further to display the corresponding load factor.";
export const FinancialYearToolTipMessage =
  "Select the year time frame to see the current or projected course load factor as per the current ILP.";
export const QuarterToolTipMessage =
  "Select the quarter to see the course sessions required, ensuring the number does not exceed the session count.";

export const MasterDataDomainToolTipMessage =
  "Select the domain and competency corresponding to the learning outcome.";
export const CompetencyToolTipMessage =
  "Select the competency corresponding to the learning outcome.";
export const ProficiencyToolTipMessage =
  "Select a proficiency level of the learning outcome.";
export const LearningOutcomeToolTipMessage =
  "State what knowledge/skills students will acquire or perform upon completing the course associated with this learning outcome.";
export const TeachingPointsToolTipMessage =
  "Each knowledge or skill has subsets of knowledge or skills as teaching points. State these teaching points within the learning outcome.";

export const DomainNameToolTipMessage =
  "From a curricula perspective, enter a domain name. For example, Service, Sales, HR, etc.";
export const DomainDescriptionToolTipMessage =
  "Add a short description of the domain for clarity on its applicability. Be as specific as possible.";

export const CompetencyNameToolTipMessage =
  "Mention the competency or competency grouping name at the highest level. For example, Electronics, Hydraulics, Negotiation, Territory Management, etc.";
export const CompetencyDescriptionToolTipMessage =
  "Describe the competency as specifically as possible, including particular knowledge or skill details.";

export const IndustryNameToolTipMessage =
  "Type in the specific industry name. For example, CI, RI, EPD, etc.";
export const IndustryDescriptionToolTipMessage =
  "Provide a concise and specific description of the industry.";

export const MasterDataIntendedAudienceToolTipMessage =
  "Specify a job role as per HR records. Avoid creating duplicates or alliances without HR concurrence.";
export const IntendedAudienceDescriptionToolTipMessage =
  "Provide a detailed description of the job role and related training intentions.";

export const GradeNameToolTipMessage =
  "Do not add grades without proper approval from the department head or HR.";
export const GradeDescriptionToolTipMessage =
  "If adding a grade for operational reasons, include the approving authority and date of approval.";

export const LocationNameToolTipMessage =
  "Enter the location name as in HR records. Double-check before adding a manual entry.";

export const ClassroomNameToolTipMessage =
  "Enter a name for the classroom following the standard (e.g., CRxxx-SAP Branch Code, CR1-TN02).";
export const ClassroomDescriptionToolTipMessage =
  "Describe any specific facilities in the classroom. If none, simply enter 'Regular ILT Class'.";

export const LocationToolTipMessage =
  "Select the location where the course will be conducted from the dropdown.";
export const StartDateToolTipMessage =
  "Select the start date of the course, ensuring the nomination window (minimum 8 weeks from today) is accommodated.";
export const EndDateToolTipMessage =
  "Automatically calculated based on the course duration and start date. Modify if necessary.";
export const ClassroomToolTipMessage =
  "Select the available classroom based on the location and regional calendar. If unavailable, check for location-specific holidays.";
export const MainTrainerToolTipMessage =
  "Select the primary trainer for this class based on availability in LMS.";
export const AssistantTrainerToolTipMessage =
  "Select one or more assistant trainers for this class based on availability.";
export const NominationStartDateToolTipMessage =
  "Select the start date of nomination, usually the date of publishing the schedule. Avoid changes unless necessary.";
export const NominationEndDateToolTipMessage =
  "Select the end date of nomination, providing a 4-week response window and 4 weeks for preparation.";
export const BatchMinSizeToolTipMessage =
  "Class size as prescribed in the course catalog. Avoid modifications unless authorized by the CA trainer or training manager.";
export const BatchMaxSizeToolTipMessage =
  "Class size as prescribed in the course catalog. Avoid modifications unless authorized by the CA trainer or training manager.";
export const NominationBatchMinSizeToolTipMessage =
  "Based on 1.5x the minimum batch size. Modify if needed, but ensure justification for deviations from standard policies.";
export const NominationBatchMaxSizeToolTipMessage =
  "Based on 2x the maximum batch size. Modify if needed, but ensure justification for deviations from standard policies.";
export const WaitlistEnableToolTipMessage =
  "Enable waitlist to permit candidates beyond the intended class size. Follow standard L&D policies.";
export const RmApprovalToolTipMessage =
  "Default setting requires RM approval for training participation. Change only if RM approval is unnecessary.";
export const SchedulingSelfEnrollmentToolTipMessage =
  "If enabled, users can self-enroll in this class with admin approval. If disabled, only admins can enroll students.";
export const WaitlistCountToolTipMessage =
  "Enter the number of participants allowed on the waitlist.";

export const AlternativeTrainerToolTipMessage =
  "Select an alternate trainer to replace the primary trainer if unavailable.";
export const AddCommentReplacementReasonToolTipMessage =
  "Enter the reason for replacing the selected trainer. Be specific.";
export const AddCommentApprovalBehalfRmToolTipMessage =
  "Mention the reason for approving on behalf of the RM (calls, mails, etc.).";
export const AddCommentDeclineBehalfRmToolTipMessage =
  "Mention the reason for declining on behalf of the RM (calls, mails, etc.).";
export const AddSecondaryTrainerToolTipMessage =
  "Select a secondary trainer to replace the primary trainer if unavailable.";
export const FindParticipantByToolTipMessage =
  "Select a participant using an employee ID from the pre-populated LMS list.";
export const SearchParticipantToolTipMessage =
  "Search for a participant using their employee ID from the master list.";
export const AddCommentApproveParticipantToolTipMessage =
  "Mention the reason for approving the participant on behalf of the RM (calls, mails, etc.).";
export const AddCommentDeclineParticipantToolTipMessage =
  "Mention the reason for declining the participant on behalf of the RM (calls, mails, etc.).";
export const AddCommentReplacementParticipantToolTipMessage =
  "Enter the reason for replacing the participant (calls, mails, etc.).";

export const IsReAppearToolTipMessage =
  "Select if you want to see a list of people who have exhausted their quota of re-assessments or don't qualify for re-assessments.";
export const IsFirstTimerToolTipMessage =
  "Select if you want the list of first-timers for this course who haven't enrolled before.";
export const IsSelfEnrolledToolTipMessage =
  "Select if you want the list of people who have self-enrolled in this course and shown interest.";
export const ActivelyEnrolledInSGAToolTipMessage =
  "Do you want to include participants who are already mapped to an active SGA for this course?";
export const IsPendingForCertificationToolTipMessage =
  "Select if you want a list of users for whom this is the only pending course in their certification ladder.";
export const IsMappedToILPToolTipMessage =
  "Select 'yes' to see the list of users who have this course mapped to their current ILP.";
export const IsNominatedToolTipMessage =
  "Select 'yes' to see the list of people who have been nominated for this course in the past and have it pending.";
export const NominatedAfterToolTipMessage =
  "Specify the minimum number of weeks required between the future nominated instance of this course.";
export const NominatedBeforeToolTipMessage =
  "Specify the minimum number of weeks required from the previous nomination instance for this course.";
export const IsAttendedEarlierToolTipMessage =
  "Do you want to include participants who have attended this course already but have yet to pass?";
export const CountPastAttendanceToolTipMessage =
  "Enter the maximum number of past attendance instances acceptable for this course.";
export const ConsiderTrainingGapToolTipMessage =
  "Select 'yes' if you want to enforce a specific gap in months between two nominations for the same course.";
export const CountTrainingGapToolTipMessage =
  "Enter the gap in months required between the last training and the newly scheduled training nomination.";

export const EmployeeTypeToolTipMessage =
  "Select how the user is connected to the organization. Be specific! Options include GMMCO Contract, Third Party Contract, or Customer Employee.";
export const NameToolTipMessage =
  "Ensure to get the correct name with spelling from the user's contact. This field allows all types of characters and cannot be changed once the account is created.";
export const EmailIDToolTipMessage =
  "Enter a valid email ID of the user. This field allows all email domains, and relevant communications will be sent to this ID.";
export const PhoneNumberToolTipMessage =
  "Enter the user's primary contact number. This field only allows up to 10 numeric characters and does not permit spaces, alphabets, or special characters.";
export const LMSRoleToolTipMessage =
  "Assign a role to the user with due diligence. This field supports multiple selections. If in doubt, verify twice!";
export const UserJobRoleToolTipMessage =
  "Select the correct job role pertaining to the user. This field allows only numeric values and does not accept negative values.";
export const ValidTillToolTipMessage =
  "Select the date until which the user would require portal access. On this date, access will be automatically revoked.";

//SideBar

export const SideBarTitleDashboard = "Dashboard";
export const SideBarTitleUserManagement = "User Management";
export const SideBarTitleCourseManagement = "Course Management";
export const SideBarTitleCourseRequest = "Course Request";
export const SideBarTitleCourseList = "Course List";
export const SideBarTitleAssessment = "Assessment";
export const SideBarTitleAssessmentCatalog = "Assessment Catalog";
export const SideBarTitleScheduledAssessments = "Scheduled Assessments";
export const SideBarTitleMyAssessment = "My Assessment";
export const SideBarTitleMasterData = "Master data";
export const SideBarTitleQuestionBank = "Question Bank";
export const SideBarTitlePolicy = "Policy";
export const SideBarTitleCurriculum = "Curriculum";
export const SideBarTitleSGA = "SGA";
export const SideBarTitleMentoring = "Mentoring";
export const SideBarTitleManageMentoring = "Manage Mentoring";
export const SideBarTitleIAmAMentor = "I'm a Mentor";
export const SideBarTitleIAmAMentee = "I'm a Mentee";
export const SideBarTitleGeneral = "General";
export const SideBarTitleLoadFactor = "Load Factor";
export const SideBarTitleScheduledCourse = "Scheduled Course";
export const SideBarTitleGlobalSetting = "Global Setting";
export const SideBarTitleNominationRequest = "Nomination Request";
export const SideBarTitleCourseCatalog = "Course Catalog";
export const SideBarTitleMyDevelopmentPlan = "My Development Plan";
export const SideBarTitleMyLearningPlan = "My Learning Plan";
export const SideBarTitleScheduledCourses = "Scheduled Courses";
export const SideBarTitleDomain = "Domain";
export const SideBarTitleCompetency = "Competency";
export const SideBarTitleLearningOutcome = "Learning Outcome";
export const SideBarTitleIndustry = "Industry";
export const SideBarTitleJobRole = "Job Role";
export const SideBarTitleClassRoom = "Classroom";
export const SideBarTitleLocation = "Location";
export const SideBarTitleRegion = "Region";
export const Outcome = "Outcome";
export const FunctionPoints = "Function";
export const WorkOrderNumber = "Work Order Number ";
export const MachineSerialNumber = "Machine Serial Number";

export const WorkOrderNumberRequired = "Work Order Number is required.";
export const MachineSerialNumberRequired = "Machine Serial Number is required.";
export const WorkOrderNumberAlphanumeric =
  "Work order number must be alphanumeric.";
export const LocationAlphanumeric = "Location must be alphanumeric.";
export const MachineSerialNumberAlphanumeric =
  "Machine serial number must be alphanumeric.";

//Empty Screen Messages
export const ClassroomEmptyDescription =
  "No Classrooms were added. Please add classroom across all locations.";
export const IndustryEmptyDescription =
  "No Industries were added. Please add industry.";
export const NominationListEmptyDescription =
  "No nomination created for this course.";
export const NomineeListEmptyDescription =
  "No Nominee added for this schedule.";
export const CompetencyEmptyDescription =
  "No Competency were added. Please add competency across all domains. ";
export const DomainEmptyDescription =
  "No Domain were added. Please add domain across all domains. ";
export const JobRoleEmptyDescription =
  "No JobRole were added. Please add JobRole. ";
export const ManageMentoringEmptyDescription =
  "No Mentor is Mapped with Mentee, Please Map the Mentor and Mentee";
export const PolicyUnMappedEmptyDescription =
  "No policy were added. Please add policy. ";
export const PolicyMappedEmptyDescription =
  "No policy were Mapped. Please add policy. ";
export const launchWebinar = "Launch Webinar";
export const LocationEmptyDescription =
  "No Locations were added. Please add location across all regions. ";
export const RegionEmptyDescription =
  "No Regions were added. Please add regions. ";
export const InvalidNominationStartDateWithEndDate =
  "Selected Nomination start date is greater than Nomination end date";
