import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import Btn from "../../../CommonElements/Button";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import MUIIcons from "../../../CommonElements/MUIIcon/MUIIcons";
import SessionList from "../../../container/SessionList";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getAllMentorSessionListUrl } from "../../../ReduxToolkit/Reducers/LearnerSlice";
import { getUserDetailsList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { Add } from "../../../utils/Constant";
import { MentorTabs } from "../../../utils/helper/helper";

import MentorMenteeList from "./MentorMenteeList";

const MentorPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const { getAllMentorSessionList } = useAppSelector(state => state.learner);
  const { userDetailsList } = useAppSelector(state => state.user);

  const [activeTab, setActiveTab] = useState<string>(statusParam || "mentee");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (activeTab === "sessions")
      dispatch(getAllMentorSessionListUrl({ mentorDomainId: null }));
  }, [dispatch, activeTab]);

  useEffect(() => {
    const menteeIds =
      getAllMentorSessionList?.session_list?.map(item => item.mentee_id) || [];
    if (menteeIds.length > 0) {
      dispatch(getUserDetailsList({ id: menteeIds }));
    }
  }, [dispatch, getAllMentorSessionList]);

  useEffect(() => {
    const mappedData =
      getAllMentorSessionList?.session_list?.map(session => {
        const menteeDetails = userDetailsList?.users?.find(
          user => user.id === session.mentee_id
        );

        return {
          MentorMenteeId: session.mentor_mentee_id,
          sessionId: session.session_id,
          topic: session.topic,
          duration: session.duration,
          sessionDate: session.start_date,
          status: session.status,
          name: menteeDetails?.user_name || "-",
          empId: menteeDetails?.employee_code || "-",
          mobile: menteeDetails?.phone || "-",
        };
      }) || [];

    setFilteredData(mappedData);
  }, [getAllMentorSessionList, userDetailsList]);

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  const handleSearch = (searchTerm: string) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase().trim();

    const filtered =
      lowercasedSearchTerm === ""
        ? getAllMentorSessionList?.session_list?.map(session => {
            const menteeDetails = userDetailsList?.users?.find(
              user => user.id === session.mentee_id
            );

            return {
              topic: session.topic,
              duration: session.duration,
              sessionDate: session.start_date,
              status: session.status,
              name: menteeDetails?.user_name || "-",
              empId: menteeDetails?.employee_code || "-",
              mobile: menteeDetails?.phone || "-",
            };
          })
        : filteredData?.filter(session => {
            return (
              session.name?.toLowerCase().includes(lowercasedSearchTerm) ||
              session.topic?.toLowerCase().includes(lowercasedSearchTerm) ||
              session.empId?.toLowerCase().includes(lowercasedSearchTerm) ||
              session.mobile?.toLowerCase().includes(lowercasedSearchTerm)
            );
          });

    setFilteredData(filtered);
  };

  const onSessionRowClick = row => {
    if (row.status === "completed") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/session-details`, {
        state: { sessionId: row.sessionId, isUpdate: false },
      });
    } else if (row.status === "upcoming") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/i-am-mentor/add-session`, {
        state: {
          sessionId: row.sessionId,
          isUpdate: true,
          sessionDate: row.sessionDate,
          mentorMenteeId: row.MentorMenteeId,
        },
      });
    }
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={MentorTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            {activeTab === "sessions" && (
              <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
                <Link to={"/mentoring/i-am-mentor/session-instruction"}>
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                  >
                    {Add}
                  </Btn>
                </Link>
              </div>
            )}
          </div>
        </CardBody>
      </Card>

      {activeTab === "mentee" && <MentorMenteeList />}
      {activeTab === "sessions" && (
        <SessionList
          data={filteredData}
          handleSearch={handleSearch}
          onRowClick={onSessionRowClick}
          showMentee
        />
      )}
    </div>
  );
};

export default MentorPage;
