import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import ScheduleListContainer from "../../../container/ScheduleListContainer";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getScheduleCourse } from "../../../ReduxToolkit/Reducers/CourseSlice";
import { getClassRoomList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  ClassRoom,
  EndDate,
  Locations,
  Select,
  StartDate,
  Status,
} from "../../../utils/Constant";
import {
  courseScheduleStatusDropdown,
  scheduledTabs,
} from "../../../utils/helper/helper";

import "./style.scss";

const LearnerScheduleList = () => {
  const [activeTab, setActiveTab] = useState("upcoming");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [locationFilterValues, setLocationFilterValues] = useState([]);
  const [classroomFilterValues, setClassroomFilterValues] = useState([]);
  const navigate = useNavigate();

  const { scheduleCourse } = useAppSelector(state => state.course);
  const { locationList, classRoomList } = useAppSelector(state => state.master);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (locationList?.location?.length > 0) {
      const location = locationList.location.map(item => ({
        name: item?.id,
        label: item?.name,
        value: item?.name,
      }));
      setLocationFilterValues(location);
    }
  }, [locationList]);

  useEffect(() => {
    if (classRoomList?.class_room?.length > 0) {
      const classRooms = classRoomList.class_room.map(item => ({
        name: item?.id,
        label: item?.name,
        value: item?.name,
      }));
      setClassroomFilterValues(classRooms);
    }
  }, [classRoomList]);

  useEffect(() => {
    if (selectedFilterValues["location"]?.length > 0)
      dispatch(
        getClassRoomList({
          id: selectedFilterValues["location"],
        })
      );
  }, [selectedFilterValues["location"]]);

  useEffect(() => {
    setSearchResults(scheduleCourse?.scheduled_courses);
  }, [scheduleCourse]);

  useEffect(() => {
    getScheduleList(activeTab);
  }, [activeTab]);

  const getScheduleList = (tabStatus, filterData = null) => {
    dispatch(
      getScheduleCourse({
        status: tabStatus,
        course_id: null,
        filterData: filterData,
      })
    );
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
  };

  const handleSearch = (query: string) => {
    const filteredCourse = scheduleCourse?.scheduled_courses?.filter(course =>
      course.course_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(
      !query ? scheduleCourse?.scheduled_courses : filteredCourse
    );
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    getScheduleList(activeTab, values);
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    getScheduleList(activeTab);
  };

  const handleClick = row => {
    return navigate(
      `/schedule-list/course-details?course_id=${row.course_id}&schedule_id=${row.course_schedule_id}`,
      {
        state: {
          ...row,
        },
      }
    );
  };

  return (
    <div className="page-body page-body-margin schedule-list">
      <Card className="p-1">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={scheduledTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: StartDate,
                      key: "start_date",
                      tooltipText: StartDate,
                      isDate: true,
                      isMultiSelect: false,
                    },
                    {
                      label: EndDate,
                      key: "end_date",
                      tooltipText: EndDate,
                      isDate: true,
                      isMultiSelect: false,
                    },
                    {
                      label: Locations,
                      key: "location",
                      tooltipText: `${Select} ${Locations}`,
                      options: locationFilterValues,
                      isMultiSelect: true,
                    },
                    {
                      label: ClassRoom,
                      key: "classroom",
                      tooltipText: `${Select} ${ClassRoom}`,
                      options: classroomFilterValues,
                      isMultiSelect: true,
                    },
                    {
                      label: Status,
                      key: "status",
                      tooltipText: `${Select} ${Status}`,
                      options: courseScheduleStatusDropdown,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <ScheduleListContainer data={searchResults} onRowClick={handleClick} />
      </Card>
    </div>
  );
};

export default LearnerScheduleList;
