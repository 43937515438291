import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  PUBLISHED,
  TAG_ASSESSMENT,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import AssessmentCard from "../../../container/GenericCard";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getAssessmentCatlogList,
  setAssessmentCatlogPayloadToInitials,
} from "../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getTagsList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../Service";
import {
  AddNew,
  AssessmentType,
  CreatedOn,
  Draft,
  Mins,
  ModificationRequest,
  Module,
  NoDataText,
  Question,
  Select,
  Tags,
  Time,
  Type,
} from "../../../utils/Constant";
import {
  assessmentCatlogTabs,
  assessmentTypesNames,
  assessmentTypes,
  formatCustomDate,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import useIsMobile from "../../../utils/helper/responsive";
import "./style.scss";

const AssessmentCatlogList = () => {
  const dispatch = useAppDispatch();
  const { assessmentCatlogList } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [activeTab, setActiveTab] = useState<string>(Draft);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { usersList } = useAppSelector(state => state.user);
  const { tagList } = useAppSelector(state => state.master);
  const [userMap, setUserMap] = useState(null);
  const isMobile = useIsMobile();
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [tagsListData, setTagsListData] = useState([]);
  const [tagsListDataIdsMap, setTagsListDataIdsMap] = useState({});
  const assessmentOptions = assessmentTypes.map(item => ({
    ...item,
    name: item.value,
  }));
  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
  };

  const handleSearch = (query: string) => {
    const filteredAssessment = assessmentCatlogList?.assessments?.filter(
      assessment => assessment.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredAssessment || []);
    setNoDataFound(!filteredAssessment || filteredAssessment?.length === 0);
  };

  useEffect(() => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: selectedFilterValues["assessment_type"],
      tag: selectedFilterValues["tags"],
    };
    fetchAssessmentCatlog(params);
  }, [activeTab]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getTagsList({ tagType: TAG_ASSESSMENT }));
  }, [dispatch]);

  useEffect(() => {
    const tags = [];
    const tagsMap = {};
    if (tagList) {
      tagList?.tags?.forEach((item, ind) => {
        tagsMap[ind] = item?.ids;
        tags.push({
          name: ind,
          value: item?.tag_name,
          label: item?.tag_name,
        });
      });
    }
    setTagsListData(tags);
    setTagsListDataIdsMap(tagsMap);
  }, [tagList]);

  const fetchAssessmentCatlog = async params => {
    const updatedParams = {
      ...params,
      status: activeTab,
    };
    dispatch(getAssessmentCatlogList(updatedParams)).then((response: any) => {
      if (response?.payload?.assessments?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  const onHeaderDropdownClick = e => {
    e.preventDefault();
    toggleModal();
  };

  const renderAssessmentCatlogCard = (assessment: AssessmentCatlog, index) => {
    return (
      <Col key={index} sm={12} lg={4}>
        <Link
          to={
            assessment?.status === PUBLISHED
              ? `/assessments/assessment-details?assessmentId=${assessment?.id}`
              : "/assessments/add-assessment"
          }
          onClick={() => {
            dispatch(setAssessmentCatlogPayloadToInitials());
          }}
          state={{ assessmentId: assessment?.id }}
        >
          <AssessmentCard
            id={index}
            header={assessment?.name}
            footerBadge={
              assessment?.status === PUBLISHED &&
              assessment?.requests?.modification > 0 &&
              ModificationRequest
            }
            userCardWithoutDetail={{
              imagePath: dynamicImage("dashboard-2/user/2.png"),
              name:
                userMap &&
                assessment?.created_by &&
                userMap[assessment?.created_by]?.user_name,
            }}
            footerDate={CreatedOn + formatCustomDate(assessment?.created_at)}
            onHeaderDropdownClick={onHeaderDropdownClick}
            isHideMoreOption
            moduleDetails={[
              {
                label: Type,
                value:
                  assessmentTypesNames[assessment?.assessment_type?.toString()],
                key: `${assessment.id}_${Type}`,
              },
              {
                label: Question,
                value: assessment?.overall_question_count?.toString(),
                key: `${assessment.id}_${Question}`,
              },
              {
                label: Module,
                value: assessment?.module_count?.toString(),
                key: `${assessment.id}_${Module}`,
              },
              {
                label: Time,
                value: assessment?.estimated_time
                  ? `${assessment?.estimated_time?.toString() + Mins}`
                  : "-",
                key: `${assessment.id}_${Time}`,
              },
            ]}
          />
        </Link>
      </Col>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const tagIds = [];
    if (values["tags"]) {
      values["tags"]?.forEach(item => {
        if (tagsListDataIdsMap[item]) tagIds.push(...tagsListDataIdsMap[item]);
      });
    }
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: values["assessment_type"],
      tag: tagIds?.toString() || null,
    };
    fetchAssessmentCatlog(params);
  };

  const handleClear = () => {
    const params: { assessment_type: string[]; tag: string } = {
      assessment_type: [],
      tag: "",
    };
    fetchAssessmentCatlog(params);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const renderContent = () => {
    if (noDataFound) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    } else if (searchResults?.length > 0) {
      return searchResults?.map((curriculum, index) =>
        renderAssessmentCatlogCard(curriculum, index)
      );
    } else {
      return assessmentCatlogList?.assessments?.map((assessment, index) =>
        renderAssessmentCatlogCard(assessment, index)
      );
    }
  };

  return (
    <div className="page-body page-body-margin assessment-catlog-list catlog-assessments">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={assessmentCatlogTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>

            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-1">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Tags,
                      key: "tags",
                      tooltipText: `${Select} ${Tags}`,
                      options: tagsListData,
                      isMultiSelect: true,
                    },
                    {
                      label: AssessmentType,
                      key: "assessment_type",
                      tooltipText: `${Select} ${AssessmentType}`,
                      options: assessmentOptions,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              {hasPermissionToComponent("CREATE_ASSESSMENT_CATLOG") && (
                <Link
                  to={"/assessments/add-assessment"}
                  onClick={() =>
                    dispatch(setAssessmentCatlogPayloadToInitials())
                  }
                  state={{ assessmentId: null }}
                >
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderContent()}</Row>
    </div>
  );
};

export default AssessmentCatlogList;
