import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { ALL_USERS_STATUS, courseScheduleStatus } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  Available,
  CourseName,
  CoursePeriod,
  Instructor,
  Location,
  NominationPeriod,
  Room,
  Status,
} from "../../utils/Constant";
import {
  formatCustomDate,
  courseScheduleStatusMap,
} from "../../utils/helper/helper";

interface ScheduleListContainerProps {
  data: CourseSchedule[];
  onRowClick?: (row: CourseSchedule) => void;
  filterColumns?: any;
}

const ScheduleListContainer: React.FC<ScheduleListContainerProps> = ({
  data,
  onRowClick,
  filterColumns,
}) => {
  const [userNameMap, setUserNameMap] = useState<{
    [key: string]: { user_name: string };
  }>({});
  const [classRoomNameMap, setClassRoomNameMap] = useState({});

  const dispatch = useAppDispatch();
  const { usersList } = useAppSelector(state => state.user);
  const { classRoomList } = useAppSelector(state => state.master);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    const classRoomMapObj: { [key: string]: { name: string } } = {};
    classRoomList?.class_room?.forEach(location => {
      classRoomMapObj[location.id] = location;
    });
    setClassRoomNameMap(classRoomMapObj);
  }, [classRoomList]);

  useEffect(() => {
    const userMapObj: { [key: string]: { user_name: string } } = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  const columns = [
    {
      name: CourseName,
      selector: (row: CourseSchedule) => row.course_name,
      minWidth: "19rem",
      sortable: true,
    },
    {
      name: Instructor,
      selector: (row: CourseSchedule) =>
        userNameMap[row.primary_trainer_id]?.user_name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Location,
      selector: (row: CourseSchedule) => row?.location_name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Room,
      selector: (row: CourseSchedule) =>
        classRoomNameMap[row.classroom_id]?.name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: NominationPeriod,
      minWidth: "15rem",
      sortable: true,
      cell: row => (
        <div>
          {row.status === courseScheduleStatus.ongoing ||
          row.status === courseScheduleStatus.nomination_closed
            ? formatCustomDate(row?.nomination_end_date)
            : `${formatCustomDate(row?.nomination_start_date)} - ${formatCustomDate(row?.nomination_end_date)}`}
        </div>
      ),
    },
    {
      name: Available,
      selector: (row: CourseSchedule) => row.available_count || 0,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: CoursePeriod,
      selector: (row: CourseSchedule) =>
        `${formatCustomDate(row?.course_start_date)} - ${formatCustomDate(row?.course_end_date)}`,
      minWidth: "15rem",
      sortable: true,
    },
    {
      name: Status,
      minWidth: "16rem",
      sortable: true,
      cell: (row: CourseSchedule) =>
        onRowClick ? (
          <button
            onClick={() => onRowClick(row)}
            className={`border-0 bg-transparent schedule-list__${row.status}`}
          >
            {courseScheduleStatusMap[row.status]}
          </button>
        ) : (
          <span className={`schedule-list__${row.status}`}>
            {courseScheduleStatusMap[row.status]}
          </span>
        ),
    },
  ];

  const filteredColumns = columns?.filter(
    column => !filterColumns?.includes(column.name)
  );

  return (
    <DataTable
      onRowClicked={onRowClick}
      columns={filteredColumns}
      data={data}
      pagination
      fixedHeader
      striped
    />
  );
};

ScheduleListContainer.propTypes = {
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  filterColumns: PropTypes.any,
};

export default ScheduleListContainer;
