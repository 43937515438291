import sjcl from "sjcl";

const ENCRYPTION_KEY = process.env.REACT_APP_SECRET_KEY;

// Function to encrypt data
const encrypt = (data: any): string => {
  const serializedData = JSON.stringify(data);
  return sjcl.encrypt(ENCRYPTION_KEY, serializedData);
};

// Function to decrypt data
const decrypt = (encryptedData: string): any => {
  try {
    const decryptedData = sjcl.decrypt(ENCRYPTION_KEY, encryptedData);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

// Save data to local storage with encryption
export const saveToLocalStorage = (key: string, value: any) => {
  const encryptedValue = encrypt(value);
  localStorage.setItem(key, encryptedValue);
};

// Get data from local storage with decryption
export const getFromLocalStorage = (key: string): any => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue) {
    return decrypt(encryptedValue);
  }
  return null;
};

// Remove a specific item from local storage
export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

// Clear all local storage
export const removeAllLocalStorage = () => {
  localStorage.clear();
};

// Constants for storage keys
export const LOGGED_IN_USER = "LOGGED_IN_USER";
export const SAVE_LOCATION_STATE = "LOCATION_STATE";
export const LEARNER_COMPETENCY_ASSESSMENT = "LEARNER_COMPETENCY_ASSESSMENT";
export const OPENED_SCORM_DETAILS = "OPENED_SCORM_DETAILS";
export const COURSE_ASSESSMENT = "COURSE_ASSESSMENT";
