import { FixedSizeList as List } from "react-window";
import "./style.scss";

const SearchDropDownPaginator = ({
  options,
  children,
  maxHeight,
  getValue,
  itemSize = 35,
}: any) => {
  const [value] = getValue();

  const flatOptions = options?.flatMap((option: any) =>
    "options" in option ? option?.options : option
  );

  const initialOffset =
    flatOptions?.findIndex((option: any) => option?.value === value?.value) *
    itemSize;

  return (
    <List
      height={maxHeight}
      itemCount={children?.length}
      itemSize={itemSize + 5}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div className="dropdown-item" style={style}>
          {children[index]}
        </div>
      )}
    </List>
  );
};

export default SearchDropDownPaginator;
