import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ALL_USERS_STATUS } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import FeedbackAnalysis from "../../../../container/FeedbackAnalysisCard";
import NoData from "../../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getCourseModuleFeedabck } from "../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { Feedback, NoDataText } from "../../../../utils/Constant";

const ModuleFeedback = ({
  scheduledId,
  courseName,
  startDate,
  endDate,
  locationName,
  classRoomName,
  instructorId,
  nominationStartDate,
  nominationEndDate,
  status,
}) => {
  const dispatch = useAppDispatch();
  const { usersList } = useAppSelector(state => state.user);
  const [statusData, setStatusData] = useState([]);
  const [userMap, setUserMap] = useState({});

  const { questionsList } = useAppSelector(state => state.feedback);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    getFeedbackList();
  }, []);

  const getFeedbackList = () => {
    dispatch(
      getCourseModuleFeedabck({
        type: "module",
        courseScheduleId: scheduledId,
      })
    );
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const statusItems = questionsList?.module_feedback?.map((feedback: any) => {
      if (userMap) {
        const participant = userMap[feedback.participant_id];
        return {
          id: participant?.id,
          name: participant?.user_name,
          empId: participant?.employee_code,
          mobile: participant?.phone,
          participant_id: feedback.participant_id?.toString(),
          feedback: [
            {
              label: Feedback,
              value: feedback.module_count,
            },
          ],
        };
      }
    });

    setStatusData(statusItems);
  }, [questionsList, userMap]);

  return (
    <div className="mt-3">
      <div className="mt-2 p-1">
        {statusData.map(item => {
          return (
            <div key={item.participant_id}>
              <Link
                to={"/schedule-module-feedbacklist"}
                state={{
                  course_name: courseName,
                  location_name: locationName,
                  classroom_name: classRoomName,
                  tranierName: userMap?.[instructorId]?.user_name,
                  course_start_date: startDate,
                  course_end_date: endDate,
                  course_schedule_id: scheduledId,
                  nominationStartDate,
                  nominationEndDate,
                  status,
                  participant_id: item.participant_id,
                }}
                className="mt-2"
              >
                <FeedbackAnalysis
                  userDetails={item}
                  statusItems={item.feedback}
                />
              </Link>
              <Divider />
            </div>
          );
        })}
        {questionsList?.module_feedback?.length === 0 && (
          <div className="p-5">
            <NoData
              showCard={false}
              showIcon={false}
              svg="empty-folder-icon"
              title={NoDataText}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ModuleFeedback.propTypes = {
  scheduledId: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  classRoomName: PropTypes.string.isRequired,
  instructorId: PropTypes.string.isRequired,
  nominationStartDate: PropTypes.string,
  nominationEndDate: PropTypes.string,
  status: PropTypes.string,
};

export default ModuleFeedback;
