import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";

import { EXTERNAL_LEARNER, INTERNAL_LEARNER } from "../../../Api/constants";
import SimpleAccordion from "../../../CommonElements/Accordion";
import Btn from "../../../CommonElements/Button";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MapSgaModal from "../../../CommonElements/MapSgaModal";
import CommonModal from "../../../CommonElements/Modal";
import MUIIcons from "../../../CommonElements/MUIIcon/MUIIcons";
import SearchBar from "../../../CommonElements/SearchBar";
import GenericCard from "../../../container/GenericCard";
import NoData from "../../../container/NoData";
import SgaCard from "../../../container/SgaCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { setLearnerAssessmentDetailsToInitial } from "../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { createSGAExtensionRequest } from "../../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  getListOfSga,
  getMappedAssessment,
  getMappedSgaList,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { unMapSGA } from "../../../ReduxToolkit/Reducers/SgaSlice";
import {
  Add,
  AddExtensionRequestToolTipMessage,
  AddReason,
  AddReasonRequired,
  CompletedOn,
  Confirm,
  CoolingPeriod,
  Days,
  End,
  Enter,
  ExtensionDaysToolTipMessage,
  FinalScore,
  Mins,
  Modules,
  NoDataText,
  Note,
  NumberOfDays,
  NumberOfDaysRequired,
  Questions,
  RequestExtension,
  Resume,
  Retake,
  Start,
  StartNote,
  SubmittedOn,
  Time,
  UnlinkSGA,
  UnlinkSGAMessage,
} from "../../../utils/Constant";
import {
  assessmentStatus,
  assessmentTabStatus,
  assessmentTypesMap,
  calculateDayDifference,
  examStatus,
  formatCustomDate,
  formatDate,
  handleKeyDownOnNumericWithoutDecimal,
  scrollToTop,
  showToast,
} from "../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

import "./style.scss";

const ILPSga = ({ userId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { mappedSga, listOfSga, mappedAssessment } = useAppSelector(
    state => state.learningPlan
  );
  const { roleList, proficiencyList } = useAppSelector(state => state.master);

  const [isMapSgaModalOpen, setIsMapSgaModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [filteredSga, setFilteredSga] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [days, setDays] = useState("");
  const [reason, setReason] = useState("");
  const [userSgaId, setUserSgaId] = useState(null);

  useEffect(() => {
    scrollToTop();
    if (isMapSgaModalOpen) {
      dispatch(getListOfSga());
    }
  }, [isMapSgaModalOpen]);

  useEffect(() => {
    if (mappedSga) {
      setSearchResults(mappedSga?.sga || []);
    }
  }, [mappedSga]);

  useEffect(() => {
    if (userSgaId) {
      dispatch(
        getMappedAssessment({
          userId: null,
          user_sga_id: userSgaId,
        })
      );
    }
  }, [userSgaId]);

  useEffect(() => {
    (async () => {
      getSGAData();
    })();
  }, [dispatch, userId]);

  const getSGAData = async () => {
    await dispatch(getRolesList({}));
    await dispatch(getProficiencyList());
    await dispatch(getMappedSgaList({ type: "sga", userId }));
  };

  useEffect(() => {
    if (!Array.isArray(listOfSga)) return;
    const filteredArray = listOfSga.reduce((acc, item) => {
      const roleName = getRoleNameById(item.role_id);

      item?.levels?.forEach(level => {
        const levelNames = item.levels.map(level =>
          getLevelNameById(level.level_id)
        );
        acc.push({
          id: level.id,
          roleName,
          levelNames,
        });
      });

      return acc;
    }, []);

    setFilteredSga(filteredArray);
  }, [listOfSga, mappedSga, proficiencyList, roleList]);

  const getRoleNameById = (id: number) => {
    const role = roleList?.job_role?.find(role => role.id === id);
    return role ? role.name : "";
  };

  const getLevelNameById = (id: number) => {
    const level = proficiencyList?.proficiency?.find(level => level.id === id);
    return level ? level.level_name : "";
  };

  const calculateCompletionPercentage = (
    totalAssessments: number,
    passedAssessments: number
  ): number => {
    if (totalAssessments <= 0) {
      return 0;
    }
    const percentage = (passedAssessments / totalAssessments) * 100;
    return Math.ceil(percentage);
  };

  const mapSgaModalToggle = isMapped => {
    setIsMapSgaModalOpen(!isMapSgaModalOpen);
    if (isMapped === true) {
      dispatch(getMappedSgaList({ type: "sga", userId }));
    }
  };

  const handleSearch = (text: string) => {
    const filteredSga =
      mappedSga?.sga?.filter(sga =>
        getRoleNameById(sga?.role_id).toLowerCase().includes(text.toLowerCase())
      ) || [];
    setSearchResults(filteredSga);
  };

  const sgaOptions = filteredSga
    ?.map(item =>
      item?.levelNames?.map(levelName => ({
        label: `${item.roleName} - ${levelName}`,
        id: item.id,
      }))
    )
    .flat();

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedName("");
      setDays("");
      setReason("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const onUnlinkClick = id => {
    setSelectedId(id);
    setSelectedName("unlink");
    toggleModal();
  };

  const onExtensionClick = id => {
    setSelectedId(id);
    setSelectedName("extension");
    toggleModal();
  };

  const handleUnlinkConfirm = async () => {
    if (selectedName === "unlink") {
      const payload = {
        type: "sga",
        user_sga_id: Number(selectedId) || null,
      };
      await dispatch(unMapSGA({ payload: payload }));
    }
    if (selectedName === "extension") {
      if (!days) {
        showToast(NumberOfDaysRequired, "error");
        return;
      }

      if (!reason) {
        showToast(AddReasonRequired, "error");
        return;
      }

      await dispatch(
        createSGAExtensionRequest({
          extenstionRequest: {
            user_sga_id: selectedId,
            type: "sga",
            no_of_days_extend: parseInt(days, 10),
            reason: reason,
          },
        })
      );
    }

    await dispatch(getMappedSgaList({ type: "sga", userId }));
    toggleModal();
  };

  const getButtonTitle = status => {
    if (status === assessmentStatus.inProgress) {
      return Resume;
    } else if (status === assessmentStatus.yetToStart) {
      return Start;
    } else if (status === assessmentStatus.retake) {
      return Retake;
    }
  };

  const handleExam = assessment => {
    if (assessment?.assessment_type === assessmentTypesMap.competency) {
      dispatch(setLearnerAssessmentDetailsToInitial());
      return navigate("/self-assessment", {
        state: {
          participantId: assessment?.participant_id,
        },
      });
    }
    if (assessment?.status === assessmentStatus.yetToStart) {
      //Came via Start
      return navigate("/assessment-instruction", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment?.name,
          termsAndCondition: assessment?.term_and_condition,
        },
      });
    } else {
      //Came via Resume
      return navigate("/learning-assessment", {
        state: {
          participantId: assessment?.participant_id,
          name: assessment.name,
        },
      });
    }
  };

  const isUpcomingExam = dateString => {
    const inputDate = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return inputDate > today;
  };

  const getButtonType = assessment => {
    const { start_date, status, cooling_period_date } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);
    const isCoolingPeriod = coolingPeriod > 0;

    if (
      status === examStatus.under_review ||
      status === examStatus.failed ||
      status === examStatus.passed ||
      userId
    ) {
      return null;
    }

    if (isUpcomingExam(start_date)) {
      return {
        title: Start,
        onClick: () => {
          return;
        },
        disabled: true,
      };
    }

    if (status === assessmentStatus.yetToStart) {
      return {
        title: getButtonTitle(status),
        disabled: isCoolingPeriod,
        onClick: isCoolingPeriod
          ? () => {
              return;
            }
          : () => handleExam(assessment),
      };
    }

    return null;
  };

  const handleClick = item => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);

    if ([EXTERNAL_LEARNER, INTERNAL_LEARNER].includes(loginData.currentRole)) {
      return navigate("/assessment-list/assessment-details", {
        state: {
          assessment: item,
          activeTab: getTabStatus(item),
          hideAction: userId,
        },
      });
    }

    return navigate(
      `/assessments/assessment-details?assessmentId=${item.assessment_id}`
    );
  };

  const getTabStatus = assessment => {
    const { start_date, status } = assessment;
    if (
      status === examStatus.failed ||
      status === examStatus.passed ||
      userId
    ) {
      return assessmentTabStatus.past;
    }
    if (isUpcomingExam(start_date)) {
      return assessmentTabStatus.upcoming;
    }
  };

  const renderAssessmentCard = (assessment, index) => {
    const {
      participant_id,
      name,
      start_date,
      end_date,
      estimated_time,
      cooling_period_date,
      module_count,
      overall_question_count,
      end_time,
      score,
      status,
      assessment_type,
    } = assessment;
    const coolingPeriod = calculateDayDifference(cooling_period_date);

    const footerDate = (() => {
      if (status === examStatus.passed || status === examStatus.failed) {
        return `${CompletedOn} ${formatDate(end_time)}`;
      } else if (status === examStatus.under_review) {
        return `${SubmittedOn} ${formatDate(end_time)}`;
      }
      return null;
    })();

    return (
      <Col key={participant_id + index} sm={12} lg={4}>
        <button
          className="border-0 bg-transparent pointer p-0 w-100 text-start"
          onClick={() => handleClick(assessment)}
        >
          <GenericCard
            id={participant_id}
            header={name}
            moduleDetails={[
              {
                label: Start,
                value: start_date ? formatCustomDate(start_date) : "-",
                key: `${participant_id}_${Start}`,
              },
              {
                label: End,
                value: end_date ? formatCustomDate(end_date) : "-",
                key: `${participant_id}_${End}`,
              },
              {
                label: Time,
                value: estimated_time ? `${estimated_time} ${Mins}` : "-",
                key: `${participant_id}_${Time}`,
              },
              {
                label: Questions,
                value: overall_question_count ?? "-",
                key: `${participant_id}_${Questions}`,
              },
              {
                label: Modules,
                value: module_count ?? "-",
                key: `${participant_id}_${Modules}`,
              },
            ]}
            isHideMoreOption={true}
            footerBadgeRight={
              coolingPeriod &&
              (status !== examStatus.passed || status !== examStatus.failed)
                ? `${CoolingPeriod} ${coolingPeriod} ${Days}`
                : ""
            }
            footerBadge={
              assessment_type !== assessmentTypesMap.competency &&
              (status === examStatus.passed || status === examStatus.failed) &&
              `${FinalScore} ${score}%`
            }
            footerBadgeStatus={
              (status === examStatus.passed || status === examStatus.failed) &&
              status === examStatus.passed
                ? "draft"
                : "rejected"
            }
            btnType={getButtonType(assessment)}
            altText={isUpcomingExam(start_date) && `${Note}: ${StartNote}`}
            footerDate={footerDate}
          />
        </button>
        {/* </Link> */}
      </Col>
    );
  };

  const renderSgaCard = () => {
    const accordionData = searchResults?.map((item, index) => ({
      id: item.user_sga_id,
      accordionItemClass: "item-class",
      accordionHeaderClass: "header-class",
      spanClass: "learning-sga__header",
      accordionHeading: (
        <div>
          <SgaCard
            id={item?.user_sga_id}
            showUnlink={item?.status === "in_progress"}
            showExtension={
              item?.status === "in_progress" &&
              hasPermissionToComponent("RAISE_SGA_EXTENSION")
            }
            extensionRequested={item?.extension_request?.status === "pending"}
            reason={item?.extension_request?.reason}
            days={item?.extension_request?.no_of_days_extended}
            onExtensionClick={onExtensionClick}
            key={item.sga_id + index}
            status={item?.status}
            extension_id={item?.extension_request?.id}
            role={getRoleNameById(item.role_id)}
            expertiseLevel={getLevelNameById(item.level_id)}
            percentageCompleted={calculateCompletionPercentage(
              item.total_assessment,
              item.passed_assessment
            )}
            getSGAData={getSGAData}
            onUnlinkClick={onUnlinkClick}
          />
        </div>
      ),
      icon: true,
      bodyText: (
        <Row>
          {mappedAssessment?.data?.assessments?.map((item, index) =>
            renderAssessmentCard(item, index)
          )}
        </Row>
      ),
    }));

    return accordionData;
  };

  let sizeTitle = "";
  if (selectedName === "unlink") {
    sizeTitle = UnlinkSGA;
  } else if (selectedName === "extension") {
    sizeTitle = RequestExtension;
  }

  const renderModalBody = type => {
    return (
      <>
        {type === "extension" && (
          <Form className="g-3 custom-input" noValidate>
            <Row className="d-flex align-items-end">
              <Col sm="12" lg="12">
                <FormGroup>
                  <LabelTooltip
                    label={NumberOfDays}
                    tooltipText={ExtensionDaysToolTipMessage}
                    important
                  />
                  <Input
                    onChange={e => {
                      setDays(e.target.value);
                    }}
                    value={days}
                    name="noOfDays"
                    type="number"
                    inputMode="numeric"
                    onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                    placeholder={`${Enter} ${NumberOfDays}`}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" lg="12">
                <FormGroup>
                  <LabelTooltip
                    label={AddReason}
                    tooltipText={AddExtensionRequestToolTipMessage}
                    important
                  />
                  <Input
                    onChange={e => {
                      setReason(e.target.value);
                    }}
                    value={reason}
                    name="addReason"
                    type="textarea"
                    placeholder={`${Enter} ${AddReason}`}
                    maxLength={256}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )}
        {type === "unlink" && UnlinkSGAMessage}
      </>
    );
  };

  const onAccordionClick = id => {
    setUserSgaId(id);
  };

  return (
    <div className="learning-sga">
      <CommonModal
        sizeTitle={sizeTitle}
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleUnlinkConfirm}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedName)}
      </CommonModal>
      <Card className="mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col
              xs="12"
              lg="12"
              className="d-flex flex-column flex-lg-row justify-content-end"
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                {userId &&
                  hasPermissionToComponent("USER_MANAGEMENT.MAP_SGA") && (
                    <>
                      <Btn
                        icon={
                          <MUIIcons
                            size={16}
                            iconName="AddCircleOutlineOutlined"
                          />
                        }
                        color="primary"
                        onClick={mapSgaModalToggle}
                      >
                        {Add}
                      </Btn>
                      <MapSgaModal
                        sga={sgaOptions}
                        isOpen={isMapSgaModalOpen}
                        toggle={mapSgaModalToggle}
                        userId={userId}
                      />
                    </>
                  )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {searchResults.length > 0 && (
        <SimpleAccordion
          accordionList={renderSgaCard()}
          onAccordionClick={onAccordionClick}
        />
      )}

      {searchResults.length === 0 && (
        <NoData svg="empty-folder-icon" title={NoDataText} showCard={false} />
      )}
    </div>
  );
};

ILPSga.propTypes = {
  userId: PropTypes.number,
};
export default ILPSga;
