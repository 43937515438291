import { useEffect, useState, useCallback } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { STATUS_200, ALL_USERS_STATUS } from "../../../Api/constants";
import { UpsertDomainPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createDomain,
  deleteDomain,
  getManageMasterDomainList,
  getManageMasterDomainListById,
  setDomainListByIdToInitialValue,
  updateDomain,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddNew,
  Competency,
  DeleteDomainHeader,
  DeleteDomainMessage,
  Domain,
  Domains,
  Enter,
  Confirm,
  NoDataText,
  NotYetAdded,
  DomainEmptyDescription,
  DomainNameToolTipMessage,
  DomainDescriptionToolTipMessage,
} from "../../../utils/Constant";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";

const DomainPage = () => {
  const dispatch = useAppDispatch();
  const { domainList, domainListById, loading } = useAppSelector(
    state => state.manageMasterData
  );
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const { usersList } = useAppSelector(state => state.user);
  const [modalOpen, setModalOpen] = useState(false);
  const [accordionData, setAccordionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [domainToDelete, setDomainToDelete] = useState(null);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [userMap, setUserMap] = useState(null);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, []);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(getManageMasterDomainList());
  }, [dispatch]);

  useEffect(() => {
    if (domainList?.domain?.length > 0) {
      setAccordionData(
        domainList?.domain?.map(domain => ({
          id: domain.id.toString(),
          name: domain.domain_name,
          description: domain.description,
          createdOn: domain.created_at,
          modifiedOn: domain.updated_at,
          modifiedBy: userMap ? userMap[domain.updated_by]?.user_name : "-",
          createdBy: userMap ? userMap[domain.created_by]?.user_name : "-",
          depedentBadges: [
            { id: 1, badge: `${Competency}: ${domain.competency_count}` },
          ],
        }))
      );
    }
  }, [domainList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = accordionData.filter(domain =>
        domain.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(accordionData);
    }
  }, [searchQuery, accordionData]);

  useEffect(() => {
    if (domainListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: domainListById.domain_name,
        description: domainListById.description,
      }));
    }
  }, [domainListById]);

  const handleSave = useCallback(
    async (title: string, description: string) => {
      const payload: UpsertDomainPayload = { name: title, description };

      let res = null;
      if (domainListById) {
        payload.id = domainListById.id;
        res = await dispatch(
          updateDomain({ updateDomainPayload: payload })
        ).then(val => {
          return val;
        });
      } else {
        res = await dispatch(
          createDomain({ createDomainPayload: payload })
        ).then(val => {
          return val;
        });
      }
      if (res?.payload?.status_code === STATUS_200) {
        toggleModal();
      }
      await dispatch(getManageMasterDomainList());
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    const selectedId = parseInt(id);
    dispatch(getManageMasterDomainListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setDomainToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (domainToDelete) {
      dispatch(deleteDomain({ id: domainToDelete })).then(() => {
        dispatch(getManageMasterDomainList());
        toggleDeleteConfirmModal();
      });
    }
  };

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (accordionData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={DomainEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
        />
      );
    }

    if (filteredData?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }

    return filteredData?.map(item => (
      <MasterDataAccordion
        key={item.id}
        accordionItems={item}
        onEditClick={() => handleEdit(item.id)}
        onDeleteClick={() => handleDelete(item.id)}
        loginData={loginData}
      />
    ));
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteDomainHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteDomainMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Domains}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {accordionData?.length > 0 && (
                <Btn
                  icon={
                    <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                  }
                  color="primary"
                  onClick={() => {
                    dispatch(setDomainListByIdToInitialValue());
                    setModalFormValues(null);
                    toggleModal();
                  }}
                >
                  {AddNew}
                </Btn>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {renderContent()}

      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Domain}
        placeholderText={`${Enter} ${Domain}`}
        toolTipText={DomainNameToolTipMessage}
        toolTipDescriptionText={DomainDescriptionToolTipMessage}
        initialValues={modalFormValues}
      />
    </div>
  );
};

export default DomainPage;
