import PropTypes from "prop-types";

import { Badges, H1, MUIIcons, P } from "../../AbstractElements";
import { DownloadCertificates, ScheduledOn } from "../../utils/Constant";
import "./style.scss";
import { formatCustomDate } from "../../utils/helper/helper";

const ProjectDescription = ({
  title,
  courseValues,
  description,
  scheduledOn,
  isDownloadCertificate,
}) => {
  return (
    <div className="course-description">
      <div className="d-flex gap-1 justify-content-between course-description__container pb-3">
        <div>
          <H1 className="course-description__title">{title}</H1>
        </div>

        <div className="d-flex align-items-center gap-1">
          {courseValues.map(item => (
            <>
              <div key={item.id} className="course-description__course-item">
                <P className="m-0 course-description__course-value">
                  {item.label}: {item.value}
                </P>
              </div>
              <span className="course-description__devider" />
            </>
          ))}
          {isDownloadCertificate?.name && (
            <Badges
              onClick={isDownloadCertificate.handleDownload}
              className="d-flex align-items-center pointer"
              status={"published"}
            >
              {DownloadCertificates}
              <MUIIcons
                className="pointer"
                size={24}
                iconName="FileDownloadOutlined"
              />
            </Badges>
          )}
        </div>
      </div>
      <P className="course-description__content mt-2">{description}</P>
      {scheduledOn && (
        <div className="course-description__date-wrapper d-flex gap-2">
          <MUIIcons
            className="course-description__calender"
            size={18}
            iconName="CalendarTodayOutlined"
          />
          <P className="course-description__schedule-on m-0">
            {ScheduledOn} {formatCustomDate(scheduledOn)}
          </P>
        </div>
      )}
    </div>
  );
};

ProjectDescription.propTypes = {
  title: PropTypes.string.isRequired,
  courseValues: PropTypes.array,
  description: PropTypes.string,
  scheduledOn: PropTypes.string,
  isDownloadCertificate: PropTypes.any,
};

export default ProjectDescription;
