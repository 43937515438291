import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Input, Row } from "reactstrap";

import { Btn, MUIIcons, P } from "../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  courseTypeMap,
  EXTERNAL_LEARNER,
  EXTERNAL_TRAINER,
  PUBLISHED,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MapCourseModal from "../../../CommonElements/MapCourseModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import LearningPlanCourseCard, {
  LearningPlanCardData,
} from "../../../container/LearningPlanCourseCard";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getUserSpecificCourseList,
  updateCourseStatus,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  approveMappedYear,
  getILPCourse,
  mapYearAPI,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Add,
  Approve,
  ApproveYearBody,
  CourseType,
  Decline,
  DeclineYearBody,
  Enter,
  ILT,
  MarkAsCompleted,
  MarkAsCompletedConfirmation,
  ModifyYear,
  NoDataText,
  PleaseAddReason,
  PleaseSelectYear,
  Reason,
  Select,
  Submit,
  VILT,
  WBT,
  Webinar,
  Year,
  YouDoNotHaveAccess,
} from "../../../utils/Constant";
import {
  courseFilterDropdownList,
  generateFiscalYearSeries,
  showToast,
} from "../../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  getFromLocalStorage,
} from "../../../utils/helper/localStorageutils";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

const AssignedILP = ({ userId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ilpCourseList, loading } = useAppSelector(
    state => state.learningPlan
  );

  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<LearningPlanCardData[]>(
    []
  );
  const [fyYear, setFyYear] = useState(new Date().getFullYear().toString());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseFilterData, setCourseFilterData] = useState({});
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const fiscalYears = generateFiscalYearSeries(0, 10);
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  const toggleActionModal = () => {
    if (isActionModalOpen) {
      setSelectedCourseId("");
      setSelectedOption("");
      setSelectedYear("");
      setReason("");
    }
    setIsActionModalOpen(!isActionModalOpen);
  };

  useEffect(() => {
    fetchPublishedCourses();
  }, [dispatch]);

  const fetchPublishedCourses = () => {
    const courseData = {};
    dispatch(
      getUserSpecificCourseList({
        status: [PUBLISHED],
        course_type: ALL_COURSE_FILTER_STATUS,
        user_id: userId,
      })
    ).then(response => {
      response?.payload?.course_list?.forEach(item => {
        if (!courseData[item?.course_type]) {
          courseData[item?.course_type] = [];
        }
        courseData[item?.course_type]?.push(item);
      });
    });
    setCourseFilterData(courseData);
  };

  useEffect(() => {
    fetchCourses();
  }, [dispatch, fyYear, selectedItems]);

  const fetchCourses = async () => {
    const courseType =
      selectedItems.length > 0 ? selectedItems : ALL_COURSE_FILTER_STATUS;
    await dispatch(
      getILPCourse({
        courseType: courseType,
        selfEnrollment: false,
        fyYear: fyYear,
        userId: userId,
      })
    );
  };

  useEffect(() => {
    const getCourseStatus = course => {
      if (course.status === "requested") {
        if (hasPermissionToComponent("USER_MANAGEMENT.APPROVE_MODIFIED_YEAR")) {
          return "waiting_rm";
        } else if (
          hasPermissionToComponent("USER_MANAGEMENT.MODIFY_YEAR_TO_COURSE")
        ) {
          return "requested_rm";
        }
      }
      if (course?.course_type === "wbt" && course?.status === "mapped") {
        return "yts";
      }

      return course.status;
    };

    const mappedCourseData = ilpCourseList?.mapped_courses?.map(course => {
      const domainNames =
        ilpCourseList?.domain[course?.course_id]?.map(
          domainItem => domainItem.name
        ) || [];

      return {
        id: course.id,
        courseId: course.course_id.toString(),
        courseName: course.course_name,
        domainName: domainNames,
        status: getCourseStatus(course),
        badgeTextColor: true,
        scheduledOn: course.created_at,
        completedOn: null,
        assignedBy: null,
        courseType: courseTypeMap[course.course_type],
      };
    });

    setSearchResults(mappedCourseData);
  }, [ilpCourseList]);

  const handleSearch = (text: string) => {
    const filteredCourses = searchResults.filter(course =>
      course.courseName.toLowerCase().includes(text.toLowerCase())
    );
    setSearchResults(filteredCourses);
  };

  const handleDone = (values: { [key: string]: string[] }) => {
    if (values["course_type"]) {
      const newSelectedItems = values["course_type"];
      setSelectedItems(newSelectedItems);
      fetchCourses();
    }
  };

  const handleClear = () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
    fetchCourses();
  };

  const handleYearChange = (year: string) => {
    setFyYear(year);
  };

  const onDropdownClick = (id: string, name: string) => {
    setSelectedCourseId(id);
    setSelectedOption(name);
    toggleActionModal();
  };

  const handleClick = item => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);

    if ([EXTERNAL_LEARNER, EXTERNAL_TRAINER].includes(loginData.currentRole)) {
      return showToast(YouDoNotHaveAccess, "error");
    }

    return navigate(
      `/course-catalog/course-details?course_id=${item.courseId}`
    );
  };

  const renderCourses = () => {
    if (loading) {
      return null;
    }

    if (
      ilpCourseList?.mapped_courses?.length === 0 ||
      searchResults?.length === 0
    ) {
      return (
        <NoData svg="empty-folder-icon" title={NoDataText} showCard={false} />
      );
    }

    return (
      <LearningPlanCourseCard
        data={searchResults}
        onDropdownClick={onDropdownClick}
        showDropDown={true}
        handleClick={handleClick}
      />
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePrimaryClick = async () => {
    if (selectedOption === ModifyYear) {
      if (!validateModifyYear()) return;

      const extractedYear = parseInt(selectedYear.split(" ")[1].split("-")[0]);
      const startYear =
        extractedYear < 50 ? 2000 + extractedYear : 1900 + extractedYear;
      const payload = {
        type: "modify",
        id: selectedCourseId,
        year: startYear,
        reason: reason,
        user_id: userId,
      };
      await dispatch(mapYearAPI({ content: payload }));
    }

    if (selectedOption === Approve) {
      await dispatch(
        approveMappedYear({
          content: { type: "approved", id: selectedCourseId },
        })
      );
    }

    if (selectedOption === Decline) {
      await dispatch(
        approveMappedYear({
          content: { type: "rejected", id: selectedCourseId },
        })
      );
    }

    if (selectedOption === MarkAsCompleted) {
      const payload = {
        course_id: parseInt(selectedCourseId),
        user_id: userId,
        status: "completed",
      };
      await dispatch(
        updateCourseStatus({
          courseStatus: payload,
        })
      );
    }

    await fetchCourses();
    toggleActionModal();
  };

  const validateModifyYear = () => {
    if (selectedYear === "") {
      showToast(PleaseSelectYear, "error");
      return false;
    }
    if (reason === "") {
      showToast(PleaseAddReason, "error");
      return false;
    }
    return true;
  };

  const convertFytoYear = fyString => {
    const match = fyString.match(/\d{2}/);
    if (match) {
      return `${match[0]}`;
    }
    return null;
  };

  const renderModalBody = () => {
    if (selectedOption === ModifyYear) {
      return (
        <div className="mb-3">
          <LabelTooltip label={Year} tooltipText={Year} important />
          <Input
            type="select"
            value={selectedYear}
            onChange={e => setSelectedYear(e.target.value)}
          >
            <option value="" disabled>
              {Select} {Year}
            </option>
            {fiscalYears
              .filter(year => {
                const converted = convertFytoYear(year);
                return converted !== (parseInt(fyYear) % 100)?.toString();
              })
              .map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </Input>

          <div className="mt-3">
            <LabelTooltip label={Reason} tooltipText={Reason} important />
            <Input
              onChange={e => {
                setReason(e.target.value);
              }}
              value={reason}
              name="reason"
              type="textarea"
              placeholder={`${Enter} ${Reason}`}
              maxLength={255}
            />
          </div>
        </div>
      );
    }

    if (selectedOption === Approve) {
      return <P>{ApproveYearBody}</P>;
    }

    if (selectedOption === Decline) {
      return <P>{DeclineYearBody}</P>;
    }

    if (selectedOption === MarkAsCompleted) {
      return <P>{MarkAsCompletedConfirmation}</P>;
    }
  };

  return (
    <>
      <CommonModal
        sizeTitle={selectedOption}
        modalBodyClassName=""
        isOpen={isActionModalOpen}
        toggle={toggleActionModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryClick}
        primaryBtnText={Submit}
      >
        {renderModalBody()}
      </CommonModal>
      <MapCourseModal
        userId={userId}
        data={courseFilterData}
        isOpen={isModalOpen}
        toggle={toggleModal}
        fetchCourses={fetchCourses}
      />
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="7" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {ILT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.ilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {VILT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.vilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Webinar} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.webinar}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {WBT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.wbt}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end gap-3 align-items-center"
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: CourseType,
                        key: "course_type",
                        tooltipText: `${Select} ${CourseType}`,
                        options: courseFilterDropdownList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
                <YearNavigator
                  pastYears={20}
                  futureYears={20}
                  onYearChange={handleYearChange}
                />
              </div>
              <div>
                {userId &&
                  hasPermissionToComponent("USER_MANAGEMENT.ASSIGN_ILP") && (
                    <Btn
                      icon={
                        <MUIIcons
                          size={16}
                          iconName="AddCircleOutlineOutlined"
                        />
                      }
                      color="primary"
                      onClick={toggleModal}
                    >
                      {Add}
                    </Btn>
                  )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {renderCourses()}
    </>
  );
};

AssignedILP.propTypes = {
  userId: PropTypes.number,
};
export default AssignedILP;
