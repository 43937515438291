import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_SESSION_FOR_MENTEE,
  GET,
  GET_ALL_MENTEE_SESSION_LIST,
  GET_ALL_MENTEE_SESSION_LIST_URL,
  GET_ALL_MENTEES_UNDER_MENTOR,
  GET_ALL_MENTEES_UNDER_MENTOR_URL,
  GET_ALL_MENTOR_SESSION_LIST,
  GET_ALL_MENTOR_SESSION_LIST_URL,
  GET_ALL_MENTOR_UNDER_MENTEE_LIST,
  GET_ALL_MENTOR_UNDER_MENTEE_LIST_URL,
  GET_LIST_OF_COMPLETE_SESSION_DETAIL,
  GET_MAPPED_MENTOR_LIST,
  GET_MAPPED_MENTOR_LIST_URL,
  GET_MENTOR_SESSION_LIST,
  GET_MENTOR_SESSION_LIST_URL,
  GET_SCORM_DATA,
  GET_SCORM_DATA_API,
  POST,
  POST_MAP_MENTOR_AND_MENTEE,
  POST_MAP_MENTOR_AND_MENTEE_URL,
  PUT,
  PUT_FEEDBACK_FROM_MENTEE,
  PUT_FEEDBACK_FROM_MENTEE_URL,
  PUT_REPLACE_MENTOR,
  PUT_REPLACE_MENTOR_URL,
  PUT_UNLINK_MENTEE_FROM_MENTOR,
  PUT_UNLINK_MENTEE_FROM_MENTOR_URL,
  SYNC_SCORM_DATA,
  SYNC_SCORM_DATA_API,
  UPDATE_SESSION_FOR_MENTEE,
  UPSERT_SESSION_FOR_MENTEE_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  GetAllMenteeSessionListResponse,
  GetAllMenteesUnderMentorResponse,
  GetAllMentorSessionListResponse,
  GetAllMentorUnderMenteeListResponse,
  GetFeedbackFromMenteePayload,
  GetListOfCompleteSessionDetailResponse,
  GetMentorSessionListResponse,
  MapMentorAndMenteePayload,
  MappedMentorListResponse,
  ReplaceMentorPayload,
  UnlinkMenteeFromMentorPayload,
  UpsertSessionForMenteePayload,
} from "../../Api/entities/MentoringCoachingEntity";
import { ScormData, ScormUpsertObject } from "../../Api/entities/ScormEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface PolicyInterface {
  scormData: ScormData;
  loading: boolean;
  error: string | null;
  scormResponse: ScormUpsertObject;
  syncResposne: any;
  mappedMentorList: MappedMentorListResponse;
  mapMentorAndMentee: MapMentorAndMenteePayload;
  replaceMentor: ReplaceMentorPayload;
  getAllMenteesUnderMentor: GetAllMenteesUnderMentorResponse;
  getAllMentorSessionList: GetAllMentorSessionListResponse;
  createSession: UpsertSessionForMenteePayload;
  updateSession: UpsertSessionForMenteePayload;
  getCompletedSessionDetails: GetListOfCompleteSessionDetailResponse;
  getAllMenteeSessionLists: GetAllMenteeSessionListResponse;
  mentorUnderMenteeList: GetAllMentorUnderMenteeListResponse;
  getMentorSessionLists: GetMentorSessionListResponse;
  getFeedbackFromMentee: GetFeedbackFromMenteePayload;
  unlinkMentee: UnlinkMenteeFromMentorPayload;
}

const initialState: PolicyInterface = {
  loading: false,
  error: null,
  scormData: {
    "cmi.core._children": "",
    "cmi.core.student_id": "",
    "cmi.core.student_name": "",
    "cmi.core.lesson_location": "",
    "cmi.core.credit": "",
    "cmi.core.lesson_status": "",
    "cmi.core.entry": "",
    "cmi.core.score_children": "",
    "cmi.core.score.raw": "",
    "cmi.core.score.max": "",
    "cmi.core.score.min": "",
    "cmi.core.total_time": "",
    "cmi.core.lesson_mode": "",
    "cmi.core.exit": "",
    "cmi.core.session_time": "",
    "cmi.suspend_data": "",
    "cmi.launch_data": "",
    "cmi.comments": "",
    "cmi.comments_from_lms": "",
    "cmi.objectives._children": "",
    "cmi.objectives._count": "",
    "cmi.objectives.n.id": "",
    "cmi.objectives.n.score._children": "",
    "cmi.objectives.n.score.raw": "",
    "cmi.objectives.n.score.max": "",
    "cmi.objectives.n.score.min": "",
    "cmi.objectives.n.status": "",
    "cmi.student_data._children": "",
    "cmi.student_data.mastery_score": "",
    "cmi.student_data.max_time_allowed": "",
    "cmi.student_data.time_limit_action": "",
    "cmi.student_preference._children": "",
    "cmi.student_preference.audio": "",
    "cmi.student_preference.language": "",
    "cmi.student_preference.speed": "",
    "cmi.student_preference.text": "",
    "cmi.interactions._children": "",
    "cmi.interactions._count": "",
    "cmi.interactions.n.id": "",
    "cmi.interactions.n.objectives._count": "",
    "cmi.interactions.n.objectives.n.id": "",
    "cmi.interactions.n.time": "",
    "cmi.interactions.n.type": "",
    "cmi.interactions.n.correct_responses._count": "",
    "cmi.interactions.n.correct_responses.n.pattern": "",
    "cmi.interactions.n.weighting": "",
    "cmi.interactions.n.student_response": "",
    "cmi.interactions.n.result": "",
    "cmi.interactions.n.latency : ": "",
  },
  scormResponse: {
    course_id: "",
    scorm_data: null,
  },
  syncResposne: null,
  mappedMentorList: null,
  mapMentorAndMentee: null,
  replaceMentor: null,
  getAllMenteesUnderMentor: null,
  getAllMentorSessionList: null,
  createSession: null,
  updateSession: null,
  getCompletedSessionDetails: null,
  getAllMenteeSessionLists: null,
  mentorUnderMenteeList: null,
  getMentorSessionLists: null,
  getFeedbackFromMentee: null,
  unlinkMentee: null,
};

export const getScormData = createAsyncThunk(
  GET_SCORM_DATA,
  async ({ courseId }: { courseId: string }) => {
    return await apiRequest(GET, GET_SCORM_DATA_API(courseId));
  }
);

export const syncScormData = createAsyncThunk(
  SYNC_SCORM_DATA,
  async ({ scormContent }: { scormContent: ScormData }) => {
    const response = await apiRequest(
      POST,
      SYNC_SCORM_DATA_API(),
      scormContent
    );
    return response.data;
  }
);

export const getMappedMentorList = createAsyncThunk(
  GET_MAPPED_MENTOR_LIST,
  async () => {
    const reponse = await apiRequest(GET, GET_MAPPED_MENTOR_LIST_URL());
    return reponse.data;
  }
);

export const createMapping = createAsyncThunk(
  POST_MAP_MENTOR_AND_MENTEE,
  async ({ mapPayload }: { mapPayload: MapMentorAndMenteePayload }) => {
    return await apiRequest(POST, POST_MAP_MENTOR_AND_MENTEE_URL(), mapPayload);
  }
);

export const replaceMentor = createAsyncThunk(
  PUT_REPLACE_MENTOR,
  async ({
    replaceMentorPayload,
  }: {
    replaceMentorPayload: ReplaceMentorPayload;
  }) => {
    return await apiRequest(
      PUT,
      PUT_REPLACE_MENTOR_URL(),
      replaceMentorPayload
    );
  }
);

export const getAllMenteesUnderMentorList = createAsyncThunk(
  GET_ALL_MENTEES_UNDER_MENTOR,
  async ({
    mentorDomainId,
    status,
    domainId,
  }: {
    mentorDomainId?: number;
    status?: string;
    domainId?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_ALL_MENTEES_UNDER_MENTOR_URL(mentorDomainId, status, domainId)
    );
    return response.data;
  }
);

export const getAllMentorSessionListUrl = createAsyncThunk(
  GET_ALL_MENTOR_SESSION_LIST,
  async ({
    mentorDomainId,
    mentorMenteeId,
  }: {
    mentorDomainId?: number;
    mentorMenteeId?: number;
  }) => {
    const reponse = await apiRequest(
      GET,
      GET_ALL_MENTOR_SESSION_LIST_URL(mentorDomainId, mentorMenteeId)
    );
    return reponse.data;
  }
);

export const createSession = createAsyncThunk(
  CREATE_SESSION_FOR_MENTEE,
  async ({
    upsertSessionPayload,
  }: {
    upsertSessionPayload: UpsertSessionForMenteePayload;
  }) => {
    return await apiRequest(
      POST,
      UPSERT_SESSION_FOR_MENTEE_URL(),
      upsertSessionPayload
    );
  }
);

export const updateSession = createAsyncThunk(
  UPDATE_SESSION_FOR_MENTEE,
  async ({
    upsertSessionPayload,
  }: {
    upsertSessionPayload: UpsertSessionForMenteePayload;
  }) => {
    return await apiRequest(
      PUT,
      UPSERT_SESSION_FOR_MENTEE_URL(),
      upsertSessionPayload
    );
  }
);

export const getCompletedSessionDetail = createAsyncThunk(
  GET_LIST_OF_COMPLETE_SESSION_DETAIL,
  async ({ sessionId }: { sessionId: number }) => {
    const response = await apiRequest(
      GET,
      UPSERT_SESSION_FOR_MENTEE_URL(null, sessionId)
    );
    return response.data;
  }
);

export const getAllMenteeSessionList = createAsyncThunk(
  GET_ALL_MENTEE_SESSION_LIST,
  async () => {
    const response = await apiRequest(GET, GET_ALL_MENTEE_SESSION_LIST_URL());
    return response.data;
  }
);

export const getAllMentorUnderMenteeList = createAsyncThunk(
  GET_ALL_MENTOR_UNDER_MENTEE_LIST,
  async ({ status }: { status: string }) => {
    const response = await apiRequest(
      GET,
      GET_ALL_MENTOR_UNDER_MENTEE_LIST_URL(status)
    );
    return response.data;
  }
);

export const getMentorSessionList = createAsyncThunk(
  GET_MENTOR_SESSION_LIST,
  async ({ mentorMenteeId }: { mentorMenteeId: number }) => {
    const response = await apiRequest(
      GET,
      GET_MENTOR_SESSION_LIST_URL(mentorMenteeId)
    );
    return response.data;
  }
);

export const getMenteeFeedback = createAsyncThunk(
  PUT_FEEDBACK_FROM_MENTEE,
  async ({
    mentorFeedbackPayload,
    sessionId,
  }: {
    mentorFeedbackPayload: GetFeedbackFromMenteePayload;
    sessionId: number;
  }) => {
    return await apiRequest(
      PUT,
      PUT_FEEDBACK_FROM_MENTEE_URL(sessionId),
      mentorFeedbackPayload
    );
  }
);

export const unlinkMenteeFromMentor = createAsyncThunk(
  PUT_UNLINK_MENTEE_FROM_MENTOR,
  async ({
    unlinkMenteePayload,
  }: {
    unlinkMenteePayload: UnlinkMenteeFromMentorPayload;
  }) => {
    return await apiRequest(
      PUT,
      PUT_UNLINK_MENTEE_FROM_MENTOR_URL(),
      unlinkMenteePayload
    );
  }
);

const LearnerSlice = createSlice({
  name: "scormData",
  reducers: {
    setScormToInitialValues: state => {
      state.scormData = initialState.scormData;
      state.scormResponse = initialState.scormResponse;
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getScormData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScormData.fulfilled, (state, action) => {
        state.loading = false;
        state.scormResponse = action.payload;
      })
      .addCase(getScormData.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(syncScormData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(syncScormData.fulfilled, (state, action) => {
        state.loading = false;
        state.syncResposne = action.payload;
      })
      .addCase(syncScormData.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getMappedMentorList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMappedMentorList.fulfilled, (state, action) => {
        state.loading = false;
        state.mappedMentorList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getMappedMentorList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createMapping.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createMapping.fulfilled, (state, action) => {
        state.loading = false;
        state.mapMentorAndMentee = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createMapping.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(replaceMentor.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(replaceMentor.fulfilled, (state, action) => {
        state.loading = false;
        state.replaceMentor = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(replaceMentor.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAllMenteesUnderMentorList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllMenteesUnderMentorList.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllMenteesUnderMentor = action.payload;
      })
      .addCase(getAllMenteesUnderMentorList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAllMentorSessionListUrl.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllMentorSessionListUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllMentorSessionList = action.payload;
      })
      .addCase(getAllMentorSessionListUrl.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createSession.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.loading = false;
        state.createSession = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createSession.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateSession.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSession.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSession = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateSession.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCompletedSessionDetail.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompletedSessionDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getCompletedSessionDetails = action.payload;
      })
      .addCase(getCompletedSessionDetail.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAllMentorUnderMenteeList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllMentorUnderMenteeList.fulfilled, (state, action) => {
        state.loading = false;
        state.mentorUnderMenteeList = action.payload;
      })
      .addCase(getAllMentorUnderMenteeList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getMentorSessionList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMentorSessionList.fulfilled, (state, action) => {
        state.loading = false;
        state.getMentorSessionLists = action.payload;
      })
      .addCase(getMentorSessionList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(unlinkMenteeFromMentor.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unlinkMenteeFromMentor.fulfilled, (state, action) => {
        state.loading = false;
        state.unlinkMentee = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(unlinkMenteeFromMentor.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAllMenteeSessionList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllMenteeSessionList.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllMenteeSessionLists = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getAllMenteeSessionList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getMenteeFeedback.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMenteeFeedback.fulfilled, (state, action) => {
        state.loading = false;
        state.getFeedbackFromMentee = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getMenteeFeedback.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setScormToInitialValues } = LearnerSlice.actions;

export default LearnerSlice.reducer;
