import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Input, Row } from "reactstrap";

import { DefaultButton, H3 } from "../../../AbstractElements";
import Divider from "../../../CommonElements/Divider";
import FixedFooter from "../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../CommonElements/MultiSelectDropdown";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getRolesList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  createExternalUser,
  getLMSRoles,
  getUserDetailsById,
  mapRolesToUser,
  unMapRolesToUser,
  updateExternalUser,
} from "../../../ReduxToolkit/Reducers/UserManagement";
import {
  AddExternalEmployee,
  Create,
  EmailId,
  EmailIDToolTipMessage,
  EmployeeType,
  EmployeeTypeRequired,
  EmployeeTypeToolTipMessage,
  Enter,
  ExternalNameError,
  ExternalReasonError,
  JobRole,
  JobRoleRequired,
  LMSRole,
  LMSRoleRequired,
  LMSRoleToolTipMessage,
  Name,
  NameRequired,
  NameToolTipMessage,
  PhoneNumber,
  PhoneNumberLength,
  PhoneNumberToolTipMessage,
  PleaseProvideRequiredInformation,
  ReasonForAccountCreation,
  ReasonRequired,
  Select,
  Update,
  UpdateExternalEmployee,
  UserJobRoleToolTipMessage,
  ValidEmailId,
  ValidTill,
  ValidTillToolTipMessage,
} from "../../../utils/Constant";
import {
  EMAIL_REGEX,
  employeeTypeOptions,
  getTomorrowDate,
  handleKeyDownOnNumericWithoutDecimal,
  roleMapping,
  scrollToTop,
  showToast,
} from "../../../utils/helper/helper";

interface FormValues {
  employee_type: string;
  name: string;
  email_id: string;
  phone_number: string;
  lms_role: any[];
  job_role: string;
  valid_till: Date | null;
  reason: string;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const AddExternalUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || "";
  const [formValues, setFormValues] = useState<FormValues>({
    employee_type: "",
    name: "",
    email_id: "",
    phone_number: "",
    lms_role: [],
    job_role: "",
    valid_till: getTomorrowDate(),
    reason: "",
  });
  const { roleList } = useAppSelector(state => state.master);
  const { lmsRole, userDetails } = useAppSelector(
    state => state.userManagement
  );
  const [errors, setErrors] = useState<Errors>({});
  const [preSelectedLMSRoles, setPreSelectedLMSRoles] = useState([]);
  const [dbSelectedLMSRoles, setDBSelectedLMSRoles] = useState([]);
  const [lmsRolesOptions, setLMSRolesOptions] = useState([]);

  useEffect(() => {
    dispatch(getLMSRoles());
    dispatch(getRolesList({}));
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetailsById({ id: userId }));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (userDetails?.users?.[0]) {
      const userVal = userDetails?.users[0];
      const ids = [];
      const idMap = [];
      userVal?.mapped_lms_roles?.forEach(item => {
        const roleDetails = roleMapping?.[item?.role_name];
        if (roleDetails) {
          ids.push(item?.role_id?.toString());
          idMap.push({
            name: item?.role_id?.toString(),
            value: roleMapping?.[item?.role_name]?.value,
            label: roleMapping?.[item?.role_name]?.value,
            unique_id: item?.id,
          });
        }
      });
      setDBSelectedLMSRoles(idMap);
      setPreSelectedLMSRoles(idMap);
      setFormValues(prevValues => ({
        ...prevValues,
        employee_type: userVal?.employee_type,
        name: userVal?.user_name,
        email_id: userVal?.email,
        phone_number: userVal?.primary_mobile_number,
        lms_role: ids || [],
        job_role: userVal?.job_role_id,
        valid_till: userVal?.valid_till
          ? new Date(userVal?.valid_till)
          : getTomorrowDate(),
        reason: userVal?.reason_for_account_creation,
      }));
    } else {
      const roleNames = [];
      const idMap = [];
      lmsRole?.lms_role?.forEach(item => {
        const roleDetails = roleMapping?.[item?.role_name];
        if (roleDetails) {
          if (item?.role_name === "el") {
            idMap.push(item?.id?.toString());
            roleNames.push({
              name: item?.id?.toString(),
              value: roleMapping?.[item?.role_name]?.value,
              label: roleMapping?.[item?.role_name]?.value,
              key: item?.role_name,
            });
          }
        }
      });

      setFormValues(prevValues => ({
        ...prevValues,
        lms_role: idMap,
      }));
      setPreSelectedLMSRoles(roleNames);
    }
  }, [userDetails, lmsRole]);

  useEffect(() => {
    const roles = [];
    lmsRole?.lms_role?.forEach(item => {
      const roleDetails = roleMapping?.[item?.role_name];
      if (roleDetails) {
        roles.push({
          name: item?.id?.toString(),
          value: roleMapping?.[item?.role_name]?.value,
          label: roleMapping?.[item?.role_name]?.value,
          key: item?.role_name,
        });
      }
    });
    setLMSRolesOptions(roles);
  }, [lmsRole]);

  useEffect(() => {
    const perselectedLMSRolesMap =
      formValues.lms_role?.map((role: any) => ({
        name: role,
        value: lmsRolesOptions.find(item => item.name === role)?.label || "",
        label: lmsRolesOptions.find(item => item.name === role)?.label || "",
        key: lmsRolesOptions.find(item => item.name === role)?.key || "",
      })) || [];

    setPreSelectedLMSRoles(perselectedLMSRolesMap);
  }, [formValues.lms_role, lmsRolesOptions]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "name" && value.length > 100) {
      setErrors(prevErrors => ({
        ...prevErrors,
        name: ExternalNameError,
      }));
      return;
    } else if (name === "reason" && value.length > 255) {
      setErrors(prevErrors => ({
        ...prevErrors,
        reason: ExternalReasonError,
      }));
      return;
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
    }

    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "email_id" && !EMAIL_REGEX.test(value)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        email_id: ValidEmailId,
      }));
    }
  };

  const handleDateChange = (date: Date | null) => {
    setFormValues(prevValues => ({
      ...prevValues,
      valid_till: date,
    }));
    setErrors(prevErrors => ({ ...prevErrors, valid_till: "" }));
  };

  const handleSubmit = async () => {
    const newErrors: Errors = {};

    if (!formValues.employee_type) {
      newErrors.employee_type = EmployeeTypeRequired;
    }
    if (!formValues.name) {
      newErrors.name = NameRequired;
    }
    if (!formValues.email_id) {
      newErrors.email_id = ValidEmailId;
    }
    if (formValues.phone_number.length !== 10) {
      newErrors.phone_number = PhoneNumberLength;
    }
    if (!preSelectedLMSRoles || preSelectedLMSRoles?.length === 0) {
      newErrors.lms_role = LMSRoleRequired;
    }
    if (!formValues.job_role) {
      newErrors.job_role = JobRoleRequired;
    }
    if (!formValues.reason) {
      newErrors.reason = ReasonRequired;
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      showToast(PleaseProvideRequiredInformation, "error");
      scrollToTop();

      return;
    }

    const payload: any = {
      employee_type: formValues.employee_type,
      company_email_id: formValues.email_id,
      first_name: formValues.name,
      last_name: "",
      valid_till: formValues.valid_till?.toISOString().split("T")[0] || "",
      reason_for_account_creation: formValues.reason,
      primary_mobile_number: formValues.phone_number,
      office_mobile_number: "",
    };
    let res: any = {};
    if (userId) {
      payload.id = userId;
      payload.is_suspended = 0;
      payload.is_update = true;
      res = await dispatch(updateExternalUser({ payload: payload }));
    } else {
      res = await dispatch(createExternalUser({ payload: payload }));
    }
    if (res?.payload?.id) {
      const lms_role_id = preSelectedLMSRoles?.map(item =>
        parseInt(item?.name)
      );

      const difference = dbSelectedLMSRoles
        ?.filter(role => !lms_role_id?.includes(role?.name))
        .map(role => role?.unique_id);

      if (difference && difference.length > 0) {
        const unMap = {
          user_id: res?.payload?.id,
          lms_role_id: difference,
        };

        await dispatch(unMapRolesToUser({ payload: unMap }));
      }

      const payload = {
        user_id: res?.payload?.id,
        job_role_id: parseInt(formValues?.job_role),
        lms_role_id: lms_role_id,
      };
      await dispatch(mapRolesToUser({ payload: payload }));

      return navigate("/user-management");
    }
  };

  return (
    <div className="page-body pb-5">
      <Card className="p-2">
        <CardBody>
          <H3 className="mt-3">
            {userId ? UpdateExternalEmployee : AddExternalEmployee}
          </H3>
          <Divider />
          <div className="mt-3">
            <Row>
              <Col>
                <LabelTooltip
                  label={EmployeeType}
                  tooltipText={EmployeeTypeToolTipMessage}
                  important={true}
                />
                <Input
                  type="select"
                  value={formValues.employee_type}
                  onChange={handleChange}
                  name="employee_type"
                  invalid={!!errors.employee_type}
                >
                  <option value="" disabled>
                    {Select} {EmployeeType}
                  </option>
                  {employeeTypeOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors.employee_type && (
                  <div className="invalid-feedback">{errors.employee_type}</div>
                )}
              </Col>
              <Col>
                <LabelTooltip
                  label={Name}
                  tooltipText={NameToolTipMessage}
                  important
                />
                <Input
                  type="text"
                  name="name"
                  placeholder={`${Enter} ${Name}`}
                  value={formValues.name}
                  onChange={handleChange}
                  invalid={!!errors.name}
                />
                {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <LabelTooltip
                  label={EmailId}
                  tooltipText={EmailIDToolTipMessage}
                  important
                />
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formValues.email_id}
                  name="email_id"
                  type="email"
                  placeholder={`${Enter} ${EmailId}`}
                  invalid={!!errors.email_id}
                  disabled={userDetails?.users?.[0]?.is_email_verified || false}
                />
                <div className="invalid-feedback">{errors.email_id}</div>
              </Col>
              <Col>
                <LabelTooltip
                  label={PhoneNumber}
                  tooltipText={PhoneNumberToolTipMessage}
                  important
                />
                <Input
                  name="phone_number"
                  type="text"
                  value={formValues.phone_number}
                  onChange={handleChange}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  maxLength={10}
                  pattern="\d*"
                  inputMode="numeric"
                  placeholder={`${Enter} ${PhoneNumber}`}
                  invalid={!!errors.phone_number}
                />
                <div className="invalid-feedback">{errors.phone_number}</div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <LabelTooltip
                  label={LMSRole}
                  tooltipText={LMSRoleToolTipMessage}
                  important
                />
                <MultiSelectDropdown
                  onChange={values => {
                    setFormValues(prevValues => ({
                      ...prevValues,
                      lms_role: values,
                    }));
                  }}
                  options={lmsRolesOptions}
                  placeholder={`${Select} ${LMSRole}`}
                  defaultSelected={preSelectedLMSRoles}
                />
                <div className="mt-1 text-sm text-danger">
                  {errors.lms_role}
                </div>
              </Col>
              <Col>
                <LabelTooltip
                  label={JobRole}
                  tooltipText={UserJobRoleToolTipMessage}
                  important
                />
                <Input
                  type="select"
                  value={formValues.job_role}
                  onChange={handleChange}
                  name="job_role"
                  invalid={!!errors.job_role}
                >
                  <option value="" disabled>
                    {Select} {JobRole}
                  </option>
                  {roleList?.job_role?.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Input>
                {errors.job_role && (
                  <div className="invalid-feedback">{errors.job_role}</div>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <LabelTooltip
                  label={ValidTill}
                  tooltipText={ValidTillToolTipMessage}
                  important
                />
                <div className="w-100">
                  <DatePicker
                    selected={formValues.valid_till}
                    onChange={handleDateChange}
                    minDate={getTomorrowDate()}
                    placeholderText={`${Select} ${ValidTill}`}
                    className={`form-control ${errors.valid_till ? "is-invalid" : ""}`}
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation
                    onKeyDown={e => e.preventDefault()}
                  />
                  {errors.valid_till && (
                    <div className="invalid-feedback">{errors.valid_till}</div>
                  )}
                </div>
              </Col>
              <Col />
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <LabelTooltip
                  label={`${Enter} ${ReasonForAccountCreation}`}
                  tooltipText={`${Enter} ${ReasonForAccountCreation}`}
                  important
                />
                <Input
                  type="textarea"
                  name="reason"
                  placeholder={`${Enter} ${ReasonForAccountCreation}`}
                  value={formValues.reason}
                  onChange={handleChange}
                  invalid={!!errors.reason}
                />
                {errors.reason && (
                  <div className="text-danger">{errors.reason}</div>
                )}
              </Col>
              <Col></Col>
            </Row>
          </div>
          <FixedFooter>
            <DefaultButton color="primary" onClick={handleSubmit}>
              {userId ? Update : Create}
            </DefaultButton>
          </FixedFooter>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddExternalUser;
