import JSZip from "jszip";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { Btn, H2, MUIIcons, P } from "../../../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  CONTENT_APPROVED_SME,
  CONTENT_SUBMITTED,
  WBT,
  PUBLISHED,
} from "../../../../../Api/constants";
import Divider from "../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import SliderModal from "../../../../../CommonElements/SliderModal";
import Comments from "../../../../../container/Comments";
import MaterialDetails from "../../../../../container/MaterialDetails";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  getCourseContent,
  reviewCourse,
  submitCourseDetailsByTrainer,
  updateCommentStatus,
} from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getScormData,
  setScormToInitialValues,
} from "../../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddComment,
  Approve,
  Back,
  Comment,
  ConfirmPublish,
  Enter,
  EnterComments,
  MaterialsHeader,
  PleaseAddComment,
  PublishConfirmation,
  PublishLater,
  PublishNow,
  Reject,
  RejectReason,
  RejectStatus,
  Scorm,
  Submit,
} from "../../../../../utils/Constant";
import {
  formatDate,
  getCurrentDate,
  getTomorrowDate,
  showToast,
  sortCommentsByDate,
} from "../../../../../utils/helper/helper";
import {
  saveToLocalStorage,
  OPENED_SCORM_DETAILS,
} from "../../../../../utils/helper/localStorageutils";
import { hasPermissionToComponent } from "../../../../../utils/helper/permission";

const CourseMaterials: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { courseContent } = useAppSelector(state => state.course);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedModal, setClickedModal] = useState("");
  const [sliderModal, setSliderModal] = useState(false);
  const location = useLocation();
  const [comments, setComments] = useState(null);
  const [iconComments, setIconComments] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [commentList, setCommentList] = useState([]);
  const [isComentRead, setIsComentRead] = useState(null);
  const { courseId } = location.state || "";
  const [duration, setDuration] = useState("");
  const [userMap, setUserMap] = useState(null);
  const [materialData, setMaterialData] = useState([
    { documents: [], greeting: "Learner documents", paths: [] },
    { documents: [], greeting: "Trainer Documents", paths: [] },
  ]);
  const [isPublishConfirmModalOpen, setIsPublishConfirmModalOpen] =
    useState(false);
  const [isPublishLater, setIsPublishLater] = useState(false);
  const [futureDate, setFutureDate] = useState(getTomorrowDate());
  const [editingData, setEditingData] = useState(false);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    const contents = [
      { documents: [], greeting: "Learner documents", paths: [] },
      { documents: [], greeting: "Trainer Documents", paths: [] },
    ];
    let comment = [];
    setEditingData(false);
    if (
      courseContent?.trainer_bond_detail?.published_at &&
      courseContent?.trainer_bond_detail?.published_at !== "None"
    ) {
      setEditingData(true);
      setFutureDate(
        courseContent?.trainer_bond_detail?.published_at &&
          courseContent?.trainer_bond_detail?.published_at !== "None"
          ? new Date(courseContent?.trainer_bond_detail?.published_at)
          : null
      );
    }
    setIsPublishLater(
      courseContent?.trainer_bond_detail?.published_at !== "" &&
        courseContent?.trainer_bond_detail?.published_at !== "None"
        ? true
        : false
    );
    if (courseContent?.comment?.course_material) {
      comment = sortCommentsByDate(courseContent.comment.course_material);
    }
    if (courseContent) {
      courseContent?.course_materials?.forEach(element => {
        if (element?.file_category === "learner_document") {
          contents[0].documents.push(element.file_name);
          contents[0].paths.push(element.file_url);
        } else {
          contents[1].documents.push(element.file_name);
          contents[1].paths.push(element.file_url);
        }
      });

      setDuration(courseContent?.duration?.toString() || "");
      if (courseContent?.comment?.comment_status) {
        try {
          const infoStage = courseContent?.comment?.comment_status?.find(
            (stage: { stages: string }) => stage.stages === "course_material"
          );
          setIsComentRead(infoStage ? !infoStage.is_read : false);
        } catch (error) {
          setIsComentRead(false);
        }
      }
    }

    if (courseContent?.course_type === WBT) {
      const scromFile: any = contents[0];
      scromFile.greeting = Scorm;
      setMaterialData([scromFile]);
    } else {
      setMaterialData(contents);
    }
    setCommentList(comment);
  }, [courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "course_material",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getModalHeader = () => {
    if (clickedModal === RejectStatus) {
      return RejectReason;
    }
  };

  const togglePublishConfirmModal = () => {
    setIsPublishConfirmModalOpen(!isPublishConfirmModalOpen);
  };

  const getModalBody = () => {
    if (clickedModal === RejectStatus) {
      return (
        <FormGroup>
          <LabelTooltip
            label={AddComment}
            tooltipText={`${Enter} ${Comment}`}
            important
          />
          <Input
            onChange={e => {
              if (
                (e.target.value && e.target.value?.length <= 255) ||
                e.target.value === ""
              )
                setComments(e.target.value);
            }}
            rows={4}
            name="objective"
            type="textarea"
            placeholder={`${Enter} ${Comment}`}
          />
        </FormGroup>
      );
    }
  };

  const handleApproveReview = async (publish = false) => {
    const reasonObj = {
      course_id: courseId,
      status: "approved",
      reason: "",
      is_review: true,
      course_content: "",
    };
    const publishData = {
      course_id: courseId,
      status: "",
      reason: "",
      is_review: false,
      course_content: {
        is_self_enrollment: false,
        bond_amount: 0,
        bond_year: 0,
        course_publish_date: futureDate
          ? formatDate(futureDate)
          : getCurrentDate(),
        curriculum: [],
        course_fee: 0,
        course_status: CONTENT_APPROVED_SME,
        is_publish_now: !isPublishLater,
      },
    };
    const wrapperObj = {
      courseContent: publish ? publishData : reasonObj,
    };
    await dispatch(reviewCourse(wrapperObj));

    if (
      courseContent?.course_type === WBT &&
      hasPermissionToComponent("PUBLISH_CONTENT")
    ) {
      const combinedArray = [
        ...(courseContent?.competency_statement?.tool_aid?.tool || []),
        ...(courseContent?.competency_statement?.tool_aid?.training_aid || []),
      ];

      const content = {
        ...courseContent,
        status: PUBLISHED,
        min_attendance: courseContent.additional_information.min_attendance,
        max_attendance: courseContent.additional_information.max_attendance,
        suggested_reading:
          courseContent.additional_information.suggested_reading,
        qualification_certification:
          courseContent.additional_information.qualification_certification,
        assessment_criteria:
          courseContent.additional_information.assessment_criteria,
        theory_time_ratio:
          courseContent.additional_information.theory_time_ratio || 0,
        practical_time_ratio:
          courseContent.additional_information.practical_time_ratio || 0,
        pre_course_work:
          courseContent?.additional_information?.pre_course_work || [],
        other_pre_course_works:
          courseContent?.additional_information?.other_pre_course_works || "",
        tooling_aids_required:
          courseContent.additional_information.tooling_aids_required,
        date:
          !courseContent.competency_statement.date ||
          courseContent.competency_statement.date === "" ||
          courseContent.competency_statement.date === "None"
            ? getCurrentDate()
            : courseContent.competency_statement.date,
        prerequisite: courseContent.competency_statement.prerequisite,
        version: courseContent.competency_statement.version,
        conditions: courseContent.competency_statement.conditions,
        min_requirements: courseContent.competency_statement.min_requirements,
        media_demonstration:
          courseContent.competency_statement.media_demonstration,
        estimated_time: courseContent.competency_statement.estimated_time,
        classroom_lab: courseContent.competency_statement.classroom_lab,
        resources: combinedArray || [],
        webinar_link: courseContent?.competency_statement?.webinar_link,
        reference: courseContent.competency_statement.reference,
        resources_activity:
          courseContent.competency_statement.resources_activity,
        review: courseContent.competency_statement.review,
        assessment: courseContent.competency_statement.assessment,
        intended_audience:
          courseContent.additional_information.intended_audience,
        other_intended_audiences:
          courseContent?.additional_information?.other_intended_audiences,
        class_room_ratio: courseContent.competency_statement.class_room_ratio,
        other_references: courseContent?.competency_statement?.other_references,
        other_tools: courseContent?.competency_statement?.other_tools,
        other_training_aids:
          courseContent?.competency_statement?.other_training_aids,
        other_prerequisites:
          courseContent?.competency_statement?.other_prerequisites,
        practical_class_ratio:
          courseContent.competency_statement.practical_class_ratio,
        practical_activity_details:
          courseContent?.additional_information?.practical_activity_details,
        registration_requirement:
          courseContent?.additional_information?.registration_requirement,
      };
      await dispatch(submitCourseDetailsByTrainer({ courseContent: content }));
    }
    await dispatch(getCourseContent({ id: courseId }));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  const handleRejectReview = async () => {
    if (comments === "" || comments === null) {
      showToast(PleaseAddComment, "error");
      return;
    }
    const reasonObj = {
      course_id: courseId,
      status: "rejected",
      reason: comments,
      is_review: true,
      course_content: "",
    };
    const wrapperObj = {
      courseContent: reasonObj,
    };
    await dispatch(reviewCourse(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "course_material",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const onPlayFile = async (fileType: number, fileIndex: number) => {
    try {
      const scormResponse = await dispatch(
        getScormData({ courseId: courseContent?.id.toString() })
      );
      const fileData = [...materialData];
      saveToLocalStorage(OPENED_SCORM_DETAILS, {});
      const scormPackagePath = fileData[fileType].paths[fileIndex];
      if (scormPackagePath.includes(".zip")) {
        const response = await fetch(scormPackagePath);
        const blob = await response.blob();
        const zip = await JSZip.loadAsync(blob);
        const files = [];
        zip.forEach((relativePath, file) => {
          files.push({ path: relativePath, file });
        });

        const indexFile = files.find(
          f =>
            f.path.endsWith("index.html") || f.path.endsWith("launchpage.html")
        );
        if (indexFile) {
          const content = await indexFile.file.async("string");
          const blob = new Blob([content], { type: "text/html" });
          const url = URL.createObjectURL(blob);
          const width = 1400;
          const height = 700;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;
          if (scormResponse?.payload?.data?.scorm_data) {
            saveToLocalStorage(
              OPENED_SCORM_DETAILS,
              scormResponse.payload.data
            );
          } else {
            saveToLocalStorage(OPENED_SCORM_DETAILS, {
              course_id: courseContent?.id.toString(),
              scorm_data: {},
            });
          }
          window.open(
            url,
            "_blank",
            `width=${width},height=${height},top=${top},left=${left}`
          );
        }
      }
    } catch (e) {
      dispatch(setScormToInitialValues());
      saveToLocalStorage(OPENED_SCORM_DETAILS, {});
    }
  };
  return (
    <div className="page-body pb-5">
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isPublishConfirmModalOpen}
        toggle={togglePublishConfirmModal}
        sizeTitle={ConfirmPublish}
        showFooter={true}
        primaryBtnText={Submit}
        onPrimaryBtnClick={() => {
          togglePublishConfirmModal();
          handleApproveReview(true);
        }}
      >
        <P>{PublishConfirmation}</P>
      </CommonModal>
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}
        sizeTitle={getModalHeader()}
        showFooter={clickedModal === RejectStatus}
        primaryBtnText={Submit}
        onPrimaryBtnClick={handleRejectReview}
      >
        {getModalBody()}
      </CommonModal>
      <Card className="p-2">
        <CardBody>
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="d-flex gap-4">
              <div className="comment-icon-container">
                <MUIIcons
                  size={24}
                  onClick={sliderToggle}
                  className="primary-icon-color mt-2 pointer"
                  iconName="ChatBubbleOutlineOutlined"
                />
                {isComentRead != null && isComentRead && (
                  <span className="red-dot"></span>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="mt-4 pb-5">
        <MaterialDetails
          title={MaterialsHeader}
          data={materialData}
          duration={duration}
          showPlay={courseContent?.course_type === WBT}
          onPlayFile={onPlayFile}
          courseContent={courseContent}
          isPublishLater={isPublishLater}
          setIsPublishLater={setIsPublishLater}
          futureDate={futureDate}
          setFutureDate={setFutureDate}
          editingData={editingData}
        />
      </div>

      <FixedFooter>
        <Row>
          {hasPermissionToComponent("APPROVE_STAGES") &&
            (courseContent?.status === CONTENT_SUBMITTED ||
              (hasPermissionToComponent("PUBLISH_CONTENT") &&
                courseContent?.status === CONTENT_APPROVED_SME)) && (
              <Col xs="auto">
                <Btn
                  onClick={() => {
                    setClickedModal(RejectStatus);
                    toggleModal();
                  }}
                  outline
                  className="alert-light-primary"
                  color="primary"
                >
                  {Reject}
                </Btn>
              </Col>
            )}

          {courseContent?.course_type !== WBT && (
            <Col xs="auto">
              <Link
                to="/course-management/course-details/assessment"
                state={{ courseId: courseId }}
              >
                <Btn outline className="alert-light-primary" color="primary">
                  {Back}
                </Btn>
              </Link>
            </Col>
          )}
          {hasPermissionToComponent("WBT_PUBLISH") &&
            (courseContent?.status === CONTENT_SUBMITTED ||
              (hasPermissionToComponent("PUBLISH_CONTENT") &&
                courseContent?.status === CONTENT_APPROVED_SME)) &&
            courseContent?.course_type === WBT && (
              <Col xs="auto">
                <Btn onClick={togglePublishConfirmModal} color="primary">
                  {isPublishLater ? PublishLater : PublishNow}
                </Btn>
              </Col>
            )}

          {hasPermissionToComponent("APPROVE_STAGES") &&
            !hasPermissionToComponent("WBT_PUBLISH") &&
            courseContent?.status === CONTENT_SUBMITTED && (
              <Col xs="auto">
                <Btn onClick={() => handleApproveReview()} color="primary">
                  {Approve}
                </Btn>
              </Col>
            )}
          {hasPermissionToComponent("APPROVE_STAGES") &&
            courseContent?.course_type !== WBT &&
            hasPermissionToComponent("WBT_PUBLISH") &&
            (courseContent?.status === CONTENT_SUBMITTED ||
              courseContent?.status === CONTENT_APPROVED_SME) && (
              <Col xs="auto">
                <Btn onClick={() => handleApproveReview()} color="primary">
                  {Approve}
                </Btn>
              </Col>
            )}
        </Row>
      </FixedFooter>
    </div>
  );
};

export default CourseMaterials;
