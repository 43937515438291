import React from "react";
import { Col, Row } from "reactstrap";

import StatusDisplay from "../../CommonElements/StatusDisplay";
import UserDetailsCard from "../UserDetailsCard";

interface FeedbackAnalysisProps {
  userDetails: {
    name: string;
    empId: string;
    id: number;
    mobile: string;
  };
  statusItems: {
    label: string;
    value: string;
  }[];
}

const FeedbackAnalysis: React.FC<FeedbackAnalysisProps> = ({
  userDetails,
  statusItems,
}) => {
  return (
    <div className="pointer">
      <Row>
        <Col lg="4">
          <UserDetailsCard userDetails={userDetails} />
        </Col>
        {statusItems?.map((item, index) => (
          <Col key={index} className="mt-3">
            <StatusDisplay label={item.label} number={item.value} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FeedbackAnalysis;
