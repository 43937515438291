import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SessionList from "../../../container/SessionList";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getAllMenteeSessionList } from "../../../ReduxToolkit/Reducers/LearnerSlice";
import { getUserDetailsList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { UpcomingSessionErrorToastMessage } from "../../../utils/Constant";
import { MenteeTabs, showToast } from "../../../utils/helper/helper";

import MentorsList from "./MentorsList";

const MenteePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");

  const { getAllMenteeSessionLists } = useAppSelector(state => state.learner);
  const { userDetailsList } = useAppSelector(state => state.user);

  const [activeTab, setActiveTab] = useState<string>(statusParam || "sessions");
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    dispatch(getAllMenteeSessionList());
  }, [dispatch]);

  useEffect(() => {
    const menteeIds =
      getAllMenteeSessionLists?.session_list?.map(item => item.mentor_id) || [];
    if (menteeIds.length > 0) {
      dispatch(getUserDetailsList({ id: menteeIds }));
    }
  }, [dispatch, getAllMenteeSessionLists]);

  useEffect(() => {
    if (getAllMenteeSessionLists?.session_list && userDetailsList?.users) {
      const mappedData = getAllMenteeSessionLists.session_list.map(session => {
        const mentorDetails = userDetailsList.users.find(
          user => user.id === session.mentor_id
        );

        return {
          mentorId: session.mentor_id,
          sessionId: session.session_id,
          topic: session.topic,
          duration: String(session.duration),
          sessionDate: session.start_date,
          status: session.status,
          name: mentorDetails?.user_name || "-",
          empId: mentorDetails?.employee_code || "-",
          mobile: mentorDetails?.phone || "-",
        };
      });

      setFilteredData(mappedData);
    }
  }, [getAllMenteeSessionLists, userDetailsList]);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  const onRowClick = (row: any) => {
    if (row.status === "completed") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/session-details`, {
        state: { sessionId: row.sessionId },
      });
    } else if (row.status === "upcoming") {
      showToast(UpcomingSessionErrorToastMessage, "error");
    }
  };

  const handleSearch = (searchTerm: string) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = filteredData.filter(session => {
      return (
        session.name?.toLowerCase().includes(lowercasedSearchTerm) ||
        session.empId?.toLowerCase().includes(lowercasedSearchTerm) ||
        session.mobile?.toLowerCase().includes(lowercasedSearchTerm) ||
        session.topic?.toLowerCase().includes(lowercasedSearchTerm)
      );
    });
    setFilteredData(filtered);
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody>
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <div className="d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={MenteeTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
          </div>
        </CardBody>
      </Card>

      {activeTab === "sessions" && (
        <SessionList
          handleSearch={handleSearch}
          data={filteredData}
          onRowClick={onRowClick}
          showMentor
          showActions
        />
      )}
      {activeTab === "mentors" && <MentorsList />}
    </div>
  );
};

export default MenteePage;
