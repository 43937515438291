import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { H5, MUIIcons } from "../../AbstractElements";

interface Description {
  label: string;
  value: string | React.ReactNode;
  columnSize?: number;
}

interface Action {
  iconName: "DeleteOutlineOutlined" | "EditOutlined";
  onClick: () => void;
}

interface FlatContainerProps {
  id: number;
  title: string | React.ReactNode;
  description: Description[];
  actions: Action[];
}

const FlatContainer: React.FC<FlatContainerProps> = ({
  title,
  description,
  actions,
}) => {
  return (
    <Row className="d-flex align-items-center">
      <Col className="d-flex justify-content-start">
        <H5 className="fw-bold">{title}</H5>
      </Col>
      {description?.map((desc, index) => (
        <Col lg={desc.columnSize} key={desc.label + index}>
          <div>
            {desc.label && <strong>{desc.label}</strong>}
            {desc.value}
          </div>
        </Col>
      ))}
      {actions.length > 0 && (
        <Col lg="1" className="d-flex justify-content-end gap-3">
          {actions?.map(action => (
            <MUIIcons
              key={action.iconName}
              className="primary-icon-color pointer"
              iconName={action.iconName}
              size={20}
              onClick={action.onClick}
            />
          ))}
        </Col>
      )}
    </Row>
  );
};

FlatContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.array,
  actions: PropTypes.array,
};

export default FlatContainer;
