import { ApexOptions } from "apexcharts";
import { toast, TypeOptions } from "react-toastify";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Tooltip,
} from "reactstrap";

import { MUIIcons, ToolTip } from "../../AbstractElements";
import { courseScheduleStatus } from "../../Api/constants";
import {
  Course,
  FY,
  LiteratureReference,
  MultiSelection,
  OpenField,
  Other,
  SingleSelection,
  Competent,
  NotCompetent,
  PartialCompetent,
  Approve,
  Decline,
  WaitingTrainerApproval,
  NominationClosed,
  Rescheduled,
  Ongoing,
  TrainerApproved,
  Cancelled,
  KeyChangesAndDevelopmentGoals,
  KeyActionsOrSteps,
  ResourcesRequired,
  SessionQuestionWhatsWorkingWell,
  SessionQuestionWhatsNotWorkingWell,
  SessionQuestionKeyThoughts,
  InviteSentOn,
  IsReAppearToolTipMessage,
  IsSelfEnrolledToolTipMessage,
  ActivelyEnrolledInSGAToolTipMessage,
  IsPendingForCertificationToolTipMessage,
  IsMappedToILPToolTipMessage,
  IsNominatedToolTipMessage,
  NominatedBeforeToolTipMessage,
  NominatedAfterToolTipMessage,
  CountPastAttendanceToolTipMessage,
  ConsiderTrainingGapToolTipMessage,
  IsFirstTimerToolTipMessage,
  CountTrainingGapToolTipMessage,
  IsAttendedEarlierToolTipMessage,
  monthNames,
  Completed,
} from "../Constant";

import { currentUserId, hasPermissionToComponent } from "./permission";

export const MAX_RESPONSE_COUNT = 6;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{1,16}$/;

export const customTableStyles = {
  rows: {
    style: {
      cursor: "pointer",
    },
  },
};

export const isValidLink = string => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export const isWithin56Days = (date: Date | string): boolean => {
  const targetDate = new Date(date);
  const today = new Date();

  today.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  const daysDifference = Math.floor(
    (targetDate.getTime() - today.getTime()) / (24 * 60 * 60 * 1000)
  );

  return daysDifference < 56;
};

export const isWeekday = (date: Date) => {
  const day = date.getDay();
  return day !== 0;
};

export const parseDate = (dateStr: string): Date => {
  const [year, month, day] = dateStr.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const getEightWeeksFromToday = () => {
  const today = new Date();
  const eightWeeksFromToday = new Date(today.setDate(today.getDate() + 56));
  return eightWeeksFromToday;
};

export const getFourWeeksFromDate = date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 28);
};

export const showToast = (message: string, type: TypeOptions) => {
  try {
    toast.dismiss();
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "info":
      case "default":
      default:
        toast.info(message);
        break;
    }
  } catch (error) {
    console.error(error);
  }
};

export const scheduledDate = dateString => {
  if (!dateString) {
    return "-";
  }
  const date = new Date(dateString);

  if (date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = monthNames[date.getMonth()];
    const year = String(date.getFullYear()).slice(-2);

    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
};

export const getTomorrowDate = () => {
  const requiredDate = new Date();
  requiredDate.setDate(requiredDate.getDate() + 1);
  return requiredDate;
};

export const getNextDay = (date: Date) => {
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
};

export function getRandomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== "string" || str.length === 0) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const handleAllFilesDownload = async (urls: string[]) => {
  for (const url of urls) {
    if (url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement("a");
        const objectURL = URL.createObjectURL(blob);
        link.href = objectURL;
        link.setAttribute("download", url.split("/").pop() || "file");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectURL);
      } catch (error) {
        console.error(`Failed to download file from ${url}: `, error);
      }
    }
  }
};

export const richTextSupportedItems = [
  "undo",
  "redo",
  "paragraph",
  "italic",
  "bold",
  "blockQuote",
  "bulletedList",
  "numberedList",
];

export const validateValue = (
  name: string,
  value: any,
  allowSpecialChars?: boolean,
  isNumeric?: boolean,
  minValue?: number,
  maxValue?: number
) => {
  if (value === undefined || value === null || value === "") {
    return {
      isValid: false,
      errorMessage: `${name} cannot be empty`,
    };
  }

  if (isNumeric) {
    if (isNaN(Number(value))) {
      return { isValid: false, errorMessage: `${name} must be a number` };
    }

    const numValue = Number(value);
    if (minValue !== null && numValue < minValue) {
      return {
        isValid: false,
        errorMessage: `${name} must be greater than or equal to ${minValue}`,
      };
    }

    if (maxValue !== null && numValue > maxValue) {
      return {
        isValid: false,
        errorMessage: `${name} must be less than or equal to ${maxValue}`,
      };
    }
  }

  if (!allowSpecialChars && /[^a-zA-Z0-9]/.test(value)) {
    return {
      isValid: false,
      errorMessage: `${name} contains invalid characters`,
    };
  }

  return { isValid: true, errorMessage: null };
};

export const facilitatorDefaultData =
  "<ul><li>Without causing damage to components, equipment, and no injury to personnel</li><li>Using appropriate tooling, techniques, and materials</li><li>According to industry/enterprise guidelines, procedure, and policies</li><li>Using and interpreting correct information from the GMMCO specifications</li></ul>";

export const resourceAndReferenceDefaultData =
  "<p>Evidence of achieving this module learning outcomes at a knowledge level, is attained by a summative online assessment. The summative online assessment is open book and a minimum standard of 80% is required to deem the participant as competent.</p><p>Attainment of the participant performance at a skill level (hands-on) is achieved using assessment checklist provided in the Skill Competency Guide. This assessment is conducted by the reporting manager (or the person designated by the reporting manager) by direct observation of tasks and questioning on underpinning knowledge.</p>";

export const reviewDefaultData = "Verify the completion of,";

export const formatCustomDate = (dateString: string): string | undefined => {
  if (!dateString || dateString === "") {
    return;
  }
  const date = new Date(dateString);

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  function getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
};

export const formatJSDate = date => {
  if (date) {
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }
  return "";
};

export const getCurrentDate = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  const currentDate = `${year}-${month}-${day}`;

  return currentDate;
};

export const sortCommentsByDate = (comments: CommentItem[]): CommentItem[] => {
  const commentsCopy = [...comments];
  return commentsCopy.sort(
    (firstComment, secondComment) =>
      new Date(secondComment.created_at).getTime() -
      new Date(firstComment.created_at).getTime()
  );
};

export const formatDate = (selectedDate: string | Date): string => {
  if (selectedDate instanceof Date) {
    const localDate =
      selectedDate.getFullYear() +
      "-" +
      String(selectedDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(selectedDate.getDate()).padStart(2, "0");
    return localDate;
  } else {
    if (selectedDate === "") {
      return;
    }
    const date = new Date(selectedDate);
    if (isNaN(date.getTime())) {
      return;
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
};

export const formatDateTime = (dateString: string): string | undefined => {
  const getOrdinalSuffix = (day: number): string => {
    if (day % 10 === 1 && day !== 11) return "st";
    if (day % 10 === 2 && day !== 12) return "nd";
    if (day % 10 === 3 && day !== 13) return "rd";
    return "th";
  };

  const formatDate = (dateString: string): string | undefined => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return undefined;
    }
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
  };

  const formattedDate = formatDate(dateString);
  if (!formattedDate) {
    return undefined;
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return undefined;
  }

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "AM" : "PM";
  hours = hours % 12 || 12;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
  return `${formattedDate} ${formattedTime}`;
};

export const rmTabs = [
  { id: "new_request", label: "New" },
  { id: "draft", label: "Draft" },
  { id: "reject", label: "Rejected" },
];

export const tmRequestTabs = [
  { id: "new_request", label: "New" },
  { id: "reject", label: "Rejected" },
];

export const courseListTabs = [
  { id: "new_request", label: "New" },
  { id: "draft", label: "Draft" },
  { id: "submitted", label: "Submitted" },
  { id: "approved", label: "Approved" },
  { id: "published", label: "Published" },
  { id: "rejected", label: "Rejected" },
];

export const courseDetailsTabs = [
  { id: "details", label: "Details" },
  { id: "schedules", label: "Schedules" },
];

export const scheduleStatusDropValues = [
  {
    name: "waiting_for_trainer_approval",
    label: "Awaiting Trainer Approval",
    value: "Awaiting Trainer Approval",
  },
  {
    name: "nomination_open",
    label: "Nomination Open",
    value: "Nomination Open",
  },
  {
    name: "nomination_closed",
    label: "Nomination Closed",
    value: "Nomination Closed",
  },
  {
    name: "rescheduled",
    label: "Rescheduled",
    value: "Rescheduled",
  },
  {
    name: "cancelled",
    label: "Trainer Declined",
    value: "Trainer Declined",
  },
  {
    name: "tm_cancelled",
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    name: "trainer_approved",
    label: "Trainer Approved",
    value: "Trainer Approved",
  },
  {
    name: "ongoing",
    label: "Ongoing",
    value: "Ongoing",
  },

  {
    name: "completed",
    label: "Completed",
    value: "Completed",
  },
];

export const courseScheduleStatusMap = {
  nomination_pending: "Nomination Open",
  nomination_ongoing: "Nomination Ongoing",
  rescheduled: "Rescheduled",
  cancelled: "Trainer Declined",
  tm_cancelled: "Cancelled",
  ongoing: "Ongoing",
  upcoming: "Upcoming",
  trainer_approved: "Trainer Approved",
  awaiting_trainer_confirmation: "Awaiting for Trainer confirmation",
  nomination_open: "Nomination Open",
  nomination_closed: "Nomination Closed",
  completed: "Completed",
  awaiting_trainer_approval: "Awaiting Trainer Approval",
  waiting_for_trainer_approval: "Awaiting Trainer Approval",
  course_completed: "Course Completed",
};

export const editCourseScheduleStatus = [
  "completed",
  "course_completed",
  "tm_cancelled",
];

export const rateLearnerStatus = ["ongoing", "completed", "course_completed"];

export const eligibleListStatus = {
  no_of_times_attended: "No. of times attempted",
  status_of_pre_course_work: "Pre course work status",
  last_attempt_course: "Last attempted course name",
  last_attempt_date: " Last attempted course date",
  score_on_last_attempt: "Last attempted assessment score",
  pending_courses: "Pending courses for certification",
  upcoming_course: "Next upcoming course name",
  upcoming_start_date: "Next upcoming course date",
  type: "is First Timer?",
};

export const globalSettingsStatusMap = {
  reappear: "Is Re-appear?",
  self: "Is Self enrolled?",
  not_active_in_sga_enrollment: "Actively enrolled in SGA?",
  pending_for_certification: "Is Pending for certification?",
  mapped_to_ipl: "Is Mapped to ILP?",
  nomination_gap: "Is Nominated?",
  nomination_gap_before_weeks: "Nomination Before (in weeks)",
  nomination_gap_after_weeks: "Nomination After (in weeks)",
  exclude_people_on_count: "Is Attended Earlier?",
  training_gap: "Consider Training Gap?",
  first_timer: "Is FirstTimer?",
  under_notice_period: "Under Notice Period?",
  nomination_gap_count: "Count (times)",
  exclude_people_on_count_count: "Count (times)",
  training_gap_count: "Gap between past training (In months)",
};

export const globalSettingsTootlTipStatusMap = {
  reappear: IsReAppearToolTipMessage,
  self: IsSelfEnrolledToolTipMessage,
  not_active_in_sga_enrollment: ActivelyEnrolledInSGAToolTipMessage,
  pending_for_certification: IsPendingForCertificationToolTipMessage,
  mapped_to_ipl: IsMappedToILPToolTipMessage,
  nomination_gap: IsNominatedToolTipMessage,
  nomination_gap_before_weeks: NominatedBeforeToolTipMessage,
  nomination_gap_after_weeks: NominatedAfterToolTipMessage,
  exclude_people_on_count: IsAttendedEarlierToolTipMessage,
  training_gap: ConsiderTrainingGapToolTipMessage,
  first_timer: IsFirstTimerToolTipMessage,
  under_notice_period: "Under Notice Period?",
  nomination_gap_count: "",
  exclude_people_on_count_count: CountPastAttendanceToolTipMessage,
  training_gap_count: CountTrainingGapToolTipMessage,
};

export const participantValidationStatusMap = {
  rm_eligible_users_percentage: "Max nomination under a RM",
  sbu_eligible_users_percentage: "Max nomination under a SBU",
  region_eligible_users_percentage: "Max nomination under a Region",
  location_eligible_users_percentage: "Max nomination under a Location",
  nomination_gap: "Nomination Gap",
  nomination_gap_before_weeks: "Nomination Before (in weeks)",
  nomination_gap_after_weeks: "Nomination After (in weeks)",
  before_weeks: "Nomination Before (in weeks)",
  after_weeks: "Nomination After (in weeks)",
  training_gap: "Training Gap",
};

export const participantSunMenuStatusMap = {
  nomination_gap_before_weeks: "Nomination Before (in weeks)",
  nomination_gap_after_weeks: "Nomination After (in weeks)",
  sbu_eligible_users_percentage_: "SBU (in %)",
  region_eligible_users_percentage_: "Region (in %)",
  location_eligible_users_percentage_: "Location (in %)",
  training_gap_: "Within (In Days)",
  rm_eligible_users_percentage_: "RM (in %)",
};

export const preCourseWorkStatus = {
  incomplete: "Incomplete",
  complete: "Complete",
  completed: "Complete",
};

export const editScheduleStatus = [
  "nomination_ongoing",
  "awaiting_trainer_approval",
  "nomination_open",
  "upcoming",
  "awaiting_trainer_confirmation",
  "nomination_closed",
  "waiting_for_trainer_approval",
];

export const policyListTabs = [
  { id: "unmapped", label: "Unmapped" },
  { id: "mapped", label: "Mapped" },
];

export const curriculumTabs = [
  { id: "draft", label: "Draft" },
  { id: "published", label: "Published" },
];

export const questionBankTabs = [
  { id: "draft", label: "Draft" },
  { id: "published", label: "Published" },
];

export const assessmentCatlogTabs = [
  { id: "draft", label: "Draft" },
  { id: "published", label: "Published" },
];

export const assessmentCatlogDetailsTabs = [
  { id: "details", label: "Details" },
  { id: "schedule_overview", label: "Schedule Overview" },
  { id: "modification_request", label: "Modification Request" },
];

export const sgaAssessmentCatlogDetailsTabs = [
  { id: "details", label: "Details" },
  { id: "modification_request", label: "Modification Request" },
];

export const scheduledDetailsTabs = [
  { tabId: "assigned", title: "Assigned" },
  { tabId: "pending_review", title: "Pending Review" },
  { tabId: "extension_request", title: "Extension Request" },
];

export const scheduledCourseDetailsTabs = [
  { tabId: "trainer", title: "Trainer" },
  { tabId: "attendees", title: "Attendees" },
  { tabId: "nomination", title: "Nomination" },
  { tabId: "attendance", title: "Attendance" },
  { tabId: "courseFeedback", title: "Course Feedback" },
  { tabId: "moduleFeedback", title: "Module Feedback" },
  { tabId: "rating", title: "Rating" },
  { tabId: "replacement", title: "Replacement" },
];

export const eligibleListTabs = [
  { tabId: "add_users", title: "Add Users" },
  { tabId: "import_users", title: "Import Users" },
];

export const scheduledCompetencyTabs = [
  { tabId: "assigned", title: "Assigned" },
  { tabId: "pending_review", title: "Pending Review" },
];

export const addQuestionBankTabs = [
  { id: "manual", label: "Manual Entry" },
  { id: "auto", label: "Upload Question Bank" },
];

export const addModulesTabs = [
  { title: "Questions", icon: "", tabId: "questions" },
  { title: "Remedial Action", icon: "", tabId: "remidial_actions" },
];

export const ilpLearnerTabs = [
  { id: "overview", label: "Overview" },
  { id: "curriculum", label: "Curriculum" },
  { id: "assigned_ilp", label: "Assigned ILP" },
  { id: "self_enrollment", label: "Self enrollment" },
  { id: "certification", label: "Certification" },
  { id: "unmapped", label: "Unmapped" },
  { id: "sga", label: "SGA" },
  { id: "pending_skill_update", label: "Pending Skill Update" },
  { id: "assessment", label: "Assessment" },
  { id: "training_history", label: "Training History" },
];

export const participantFormTabs = [
  { title: "Select Users", icon: "", tabId: "select_users" },
  { title: "Manage Enrollments", icon: "", tabId: "manage_enrollments" },
];

export const participantTypesTab = [
  { label: "New", href: "", tabId: "enrolling_users" },
  { label: "Existing", href: "", tabId: "enrolled_users" },
];

export const globalSettingsTab = [
  { label: "Eligible Participant List", href: "", tabId: "eligible_list" },
  {
    label: "Participant Validation",
    href: "",
    tabId: "participant_validation",
  },
];

export const courseTypeOptions = [
  { value: "", label: "Select" },
  { value: "ilt", label: "ILT" },
  { value: "vilt", label: "VILT" },
  { value: "webinar", label: "Webinar" },
  { value: "wbt", label: "WBT" },
];

export const frequencyRangeOptions = [
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
  { value: "yearly", label: "Years" },
];

export const suggestedDaysOptions = [
  { value: "daily", label: "Days" },
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
  { value: "yearly", label: "Years" },
];

export const frequencyType = {
  daily: "Days",
  weekly: "Weeks",
  monthly: "Months",
  yearly: "Years",
};

export const courseListOptions = [
  { value: "", label: "Select" },
  { value: "course_1", label: "Course 1" },
  { value: "course_2", label: "Course 2" },
];

export const trainerOptions = [
  { value: "trainer1", name: "trainer1", label: "Alice Johnson" },
  { value: "trainer2", name: "trainer2", label: "David Miller" },
  { value: "trainer3", name: "trainer3", label: "Sophie Wilson" },
  { value: "trainer4", name: "trainer4", label: "Michael Brown" },
];

export const smeOptions = [
  { value: "", label: "Select" },
  { value: "sme1", label: "Sophie Wilson" },
  { value: "sme2", label: "Michael Brown" },
];

export const skillOptions = [
  { value: "field", label: "Field" },
  { value: "ojt", label: "OJT" },
];

export const knowledgeOptions = [
  { value: "", label: "Select" },
  { value: "summative", label: "Summative" },
  { value: "formative", label: "Formative" },
];

export const assessmentTypesOptions = [
  { value: "knowledge", label: "Knowledge" },
  { value: "skill", label: "Skill" },
];

export const selefAssesmentOptions = [
  { value: "", label: "Select" },
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const frequencyOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const proficiencyLevelKeyValue = [
  { id: 1, value: "pre_foundational", label: "Pre-Foundational" },
  { id: 2, value: "foundational", label: "Foundational" },
  { id: 3, value: "advanced", label: "Advanced" },
  { id: 4, value: "expert", label: "Expert" },
];

export const requestTypeOptions = [
  { value: "internal", label: "Internal" },
  { value: "external", label: "External" },
];

export const courseStepperData = [
  "Basic info",
  "Content details",
  "Key contact",
  "Others",
];
export const competencyStepperData = [
  "Basic info",
  "Recommended delivery",
  "Review & Assessment",
];

export const scheduleAssessmentStepperData = ["Set Dates", "Add Users"];

export const skillDocumentMap = {
  "Field Leaner Doc": "learner_document",
  "Field Trainer Doc": "trainer_document",
  "OJT Trainer Doc": "trainer_document",
  "OJT Learner Doc": "learner_document",
};

export const roleMapping = {
  rm: {
    key: "reporting_manager",
    value: "Reporting Manager",
  },

  tm: {
    key: "training_manager",
    value: "Training Manager",
  },

  ta: {
    key: "training_admin",
    value: "Training Admin",
  },

  hra: {
    key: "hr_admin",
    value: "HR Admin",
  },

  sme: {
    key: "sme",
    value: "SME",
  },

  it: {
    key: "internal_trainer",
    value: "Internal Trainer",
  },

  et: {
    key: "external_trainer",
    value: "External Trainer",
  },

  il: {
    key: "internal_learner",
    value: "Internal Learner",
  },

  el: {
    key: "external_learner",
    value: "External Learner",
  },
};

export function getEllipsedFileName(fileName: string, maxLength = 15): string {
  if (fileName) {
    if (fileName?.length <= maxLength) {
      return fileName;
    }
    const extensionMatch = fileName.match(/\.[^.]+$/);
    const fileExtension = extensionMatch ? extensionMatch[0] : "";
    const baseName = fileExtension
      ? fileName.substring(0, fileName.lastIndexOf(fileExtension))
      : fileName;
    const allowedBaseNameLength = maxLength - fileExtension.length - 3;
    const ellipsedBaseName =
      baseName.substring(0, allowedBaseNameLength) + "...";
    return ellipsedBaseName + fileExtension;
  }
}

export const getQuestionsType = (callback, index) => {
  return [
    {
      item: OpenField,
      onClick: () => callback(questionTypeMap[OpenField], index),
    },
    {
      item: SingleSelection,
      onClick: () => callback(questionTypeMap[SingleSelection], index),
    },
    {
      item: MultiSelection,
      onClick: () => callback(questionTypeMap[MultiSelection], index),
    },
  ];
};

export const competencyAnswersMap = {
  competent: Competent,
  not_competent: NotCompetent,
  partial_competent: PartialCompetent,
};

export const questionTypeMap = {
  [SingleSelection]: "single_select",
  [MultiSelection]: "multiple_choice",
  [OpenField]: "open_text",
};

export const selectionTypeMap = {
  single_select: SingleSelection,
  multiple_choice: MultiSelection,
  open_text: OpenField,
};

export function sanitizeString(input: string): string {
  return input?.replace(/[^a-zA-Z0-9]+/g, "_")?.replace(/^_+|_+$/g, "");
}

export const PROFICIENCY_LEVEL = {
  PRE_FOUNDATIONAL: "pre_foundational",
  FOUNDATIONAL: "foundational",
  ADVANCED: "advanced",
  EXPERT: "expert",
};

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused && "red",
    boxShadow: state.isFocused && "0 0 0.2rem red",
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9,
    "&:hover": {
      color: "0.2rem solid red",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused && "#E44141",
    color: state.isFocused && "white",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "#E44141",
    },
  }),
};

export const mapListToOptions = (
  list: any[],
  labelKey: string,
  valueKey: string
): { value: string; label: string }[] => {
  const options = [{ id: valueKey, value: "", label: "Select" }];
  if (list?.length > 0) {
    list.forEach(element => {
      const elementObj = {
        id: element[valueKey].toString(),
        value: element[valueKey].toString(),
        label: element[labelKey],
      };
      options.push(elementObj);
    });
  }
  return options;
};

export const sgaDurationOptions = [
  { value: "", label: "Select" },
  { value: "daily", label: "Days" },
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
];

export const employeeTypeOptions = [
  { value: "gmmco_contract", label: "GMMCO contract" },
  { value: "third_party_contract", label: "Third Party Contract" },
  { value: "customer_employee", label: "Customer employee" },
];

export const eligibleListEmployeeTypeOptions = [
  { name: "internal", label: "Internal", value: "Internal" },
  { name: "gmmco_contract", label: "GMMCO contract", value: "GMMCO contract" },
  {
    name: "third_party_contract",
    label: "Third Party Contract",
    value: "Third Party Contract",
  },
  {
    name: "customer_employee",
    label: "Customer employee",
    value: "Customer employee",
  },
];

export const externalEmployeeStatus = [
  "gmmco_contract",
  "third_party_contract",
  "customer_employee",
];

export const assessmentTypes = [
  { value: "standalone", label: "Standalone assessment" },
  { value: "competency", label: "Self Assessment" },
  { value: "course", label: "Course Assessment" },
];

export const assessmentCreationData = ["standalone", "course"];
export const standaloneAssessmentCreationData = ["standalone"];

export const frequencyTypes = [
  { value: "weekly", label: "Weeks" },
  { value: "monthly", label: "Months" },
  { value: "yearly", label: "Years" },
];

export const frequencyMapping = {
  daily: "Days",
  weekly: "Weeks",
  monthly: "Months",
  yearly: "Years",
};

export const courseFilterDropdownList = [
  { name: "ilt", value: "ILT", label: "ILT" },
  { name: "vilt", value: "VILT", label: "VILT" },
  { name: "webinar", value: "Webinar", label: "Webinar" },
  { name: "wbt", value: "WBT", label: "WBT" },
];

export const iltViltFilterDropdownList = [
  { name: "ilt", value: "ILT", label: "ILT" },
  { name: "vilt", value: "VILT", label: "VILT" },
];

export const courseRequestFilterDropdownList = [
  { name: "internal", value: "Internal", label: "Internal" },
  { name: "external", value: "External", label: "External" },
];

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const courseScheduleStatusDropdown = [
  {
    value: courseScheduleStatus.waiting_for_trainer_approval,
    name: courseScheduleStatus.waiting_for_trainer_approval,
    label: WaitingTrainerApproval,
  },

  {
    name: courseScheduleStatus.nomination_closed,
    value: courseScheduleStatus.nomination_closed,
    label: NominationClosed,
  },
  {
    value: courseScheduleStatus.rescheduled,
    name: courseScheduleStatus.rescheduled,
    label: Rescheduled,
  },

  {
    value: courseScheduleStatus.cancelled,
    name: courseScheduleStatus.cancelled,
    label: Cancelled,
  },

  {
    value: courseScheduleStatus.trainer_approved,
    name: courseScheduleStatus.trainer_approved,
    label: TrainerApproved,
  },

  {
    value: courseScheduleStatus.completed,
    name: courseScheduleStatus.course_completed,
    label: Completed,
  },

  {
    value: courseScheduleStatus.ongoing,
    name: courseScheduleStatus.ongoing,
    label: Ongoing,
  },
];

export const knowledgeHeader = [
  "Assessment Name",
  "Type",
  "Question Bank",
  "Questions",
  "Modules",
  "Assessment Duration (In minutes)",
  "Actions",
];
export const skillHeader = [
  "LO",
  "Type",
  "Learner Docs",
  "Trainer Docs",
  "Actions",
];

export const LoHeader = ["Outcome", "Function", "Action"];

export const generateFiscalYearSeries = (
  yearsBack: number,
  yearsForward: number
): string[] => {
  const currentYear = new Date().getFullYear();
  const fiscalYears: string[] = [];

  const formatYear = (year: number) => year.toString().padStart(2, "0");

  for (let i = -yearsBack; i <= yearsForward; i++) {
    const startYear = currentYear + i;
    const endYear = startYear + 1;
    fiscalYears.push(
      `${FY} ${formatYear(startYear % 100)}-${formatYear(endYear % 100)}`
    );
  }

  return fiscalYears;
};

export const generateLoadFactorFiscalYears = (
  yearsBack: number,
  yearsForward: number
): string[] => {
  const currentYear = new Date().getFullYear();
  const fiscalYears: string[] = [];

  for (let i = -yearsBack; i <= yearsForward; i++) {
    const startYear = currentYear + i;
    const endYear = startYear + 1;
    fiscalYears.push(`${startYear}-${endYear}`);
  }

  return fiscalYears;
};

export const skillAssessmentTabs = [
  { tabId: "material", title: "Material" },
  { tabId: "your_file", title: "Your file" },
];

export const getCurrentFiscalYear = (pastYears, futureYear) => {
  const currentYear = new Date().getFullYear();
  const fiscalYearSeries = generateFiscalYearSeries(pastYears, futureYear);
  const currentFiscalYear = `${FY} ${(currentYear % 100)
    .toString()
    .padStart(
      2,
      "0"
    )}-${((currentYear + 1) % 100).toString().padStart(2, "0")}`;

  const currentFiscalYearIndex = fiscalYearSeries.indexOf(currentFiscalYear);
  return currentFiscalYearIndex;
};

export const calculateTimeDifferenceInSeconds = (
  startDate: string,
  totalMinutes: number
): number => {
  const examStartDate = new Date(startDate);
  const examEndDate = new Date(
    examStartDate.getTime() + totalMinutes * 60 * 1000
  );
  const currentTime = new Date();
  const timeDifferenceInSeconds =
    (examEndDate.getTime() - currentTime.getTime()) / 1000;
  const time = Math.max(Math.round(timeDifferenceInSeconds), 0);
  return Number.isNaN(time) ? 0 : time;
};

export const questionTypes = {
  multiple_choice: "multiple_choice",
  single_select: "single_select",
  open_text: "open_text",
};

export const remedialActionTabs = [
  { title: "Feedback", tabId: "feedback" },
  { title: "Instructor Rating", tabId: "instructor_rating" },
];

export const examRemedialActionTabs = [
  { title: Course, icon: "", tabId: "1" },
  { title: LiteratureReference, icon: "", tabId: "2" },
  { title: Other, icon: "", tabId: "3" },
];

export const participantFeedbackTabs = [
  { title: Course, tabId: "1" },
  { title: LiteratureReference, tabId: "2" },
  { title: Other, tabId: "3" },
];

export const userManagementListTabs = [
  { title: "Internal", icon: "", tabId: "internal" },
  { title: "External", icon: "", tabId: "external" },
  { title: "Suspended", icon: "", tabId: "suspended" },
  { title: "Unmapped Role", icon: "", tabId: "unmapped_role" },
];

export const viewPeopleTabs = [
  { title: "Online Reattempt", icon: "", tabId: "online_reattempt" },
  { title: "Reappear", icon: "", tabId: "reappear" },
  { title: "1st Timers", icon: "", tabId: "first_timers" },
  {
    title: "Last Course to Certificate",
    icon: "",
    tabId: "last_course_to_certificate",
  },
  { title: "Overdue", icon: "", tabId: "overdue" },
];

export const downloadCsv = (name, res) => {
  const blob = new Blob([res], { type: "text/plain" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
};

export const getProficiencyListData = (
  callback,
  proficiency,
  proficiencyLevel
) => {
  const accordionItems = proficiency.map(level => {
    const item = proficiencyLevelKeyValue.find(
      entry => entry.label === level.level_name
    );
    const totalDuration = proficiencyLevel[item.value]?.course?.reduce(
      (total, course) => total + (course.duration ?? 0),
      0
    );
    return {
      id: level.id.toString(),
      key: level.id.toString(),
      accordionItemClass: "item-class",
      accordionHeaderClass: "header-class",
      courseTime: "",
      spanClass: "span-class",
      accordionHeading: level.level_name,
      icon: true,
      bodyText: callback(item.value, level.id),
      courseCount: true,
      totalDuration: `${convertMinutesToHours(totalDuration)} h`,
    };
  });

  return accordionItems;
};

export const PendingSkillTableHeader = [
  "LO",
  "Type",
  "Material File",
  "Your File",
  "Status",
  "Actions",
];

export const truncateText = (title: string, maxLength: number) => {
  if (title?.length <= maxLength) {
    return title;
  }
  return title?.toString()?.slice(0, maxLength) + "...";
};
export const handleKeyDownOnNumericWithoutDecimal = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Delete"];
  if (
    !allowedKeys.includes(e.key) &&
    (isNaN(Number(e.key)) ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "e" ||
      e.key === "E")
  ) {
    e.preventDefault();
  }
};

export const handleKeyDownOnNumericWithDecimal = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  const allowedKeys = [
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
    "Delete",
    ".",
  ];
  if (
    !allowedKeys.includes(e.key) &&
    (isNaN(Number(e.key)) ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "e" ||
      e.key === "E")
  ) {
    e.preventDefault();
  }
};

export const AssessmentOptions = [
  { value: "", label: "Select" },
  { value: "self", label: "Self" },
  { value: "standalone", label: "Standalone" },
];

export const eligibleListSearchBy = [
  { value: "employee_id", label: "Employee Id" },
  { value: "name", label: "Name" },
  { value: "email", label: "Email" },
];

export const AssessmentTabs = [
  { id: "current", label: "Active" },
  { id: "upcoming", label: "Upcoming" },
  { id: "past", label: "Past" },
  { id: "under_review", label: "Under review" },
];

export const calculateDayDifference = givenDateString => {
  if (!givenDateString) {
    return;
  }
  const givenDate = new Date(givenDateString);
  const today = new Date();
  givenDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  const timeDifference = givenDate.getTime() - today.getTime();
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return dayDifference > 0 ? dayDifference : 0;
};

export const getOrdinalWord = number => {
  const ordinalWords = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];

  return ordinalWords[number] || `${number}th`;
};

export const assessmentStatus = {
  inProgress: "in_progress",
  yetToStart: "yts",
  retake: "retake",
  underReview: "under_review",
  completed: "completed",
  passed: "passed",
  failed: "failed",
  mapped: "mapped",
};

export const assessmentTypesMap = {
  competency: "competency",
  standalone: "standalone",
  sga: "sga",
  course: "course",
};

export const assessmentTypesNames = {
  competency: "Self",
  standalone: "Standalone",
  sga: "Course",
  course: "Course",
};

export const examStatus = {
  passed: "passed",
  failed: "failed",
  under_review: "under_review",
};

export const assessmentTabStatus = {
  current: "current",
  underReview: "under_review",
  past: "past",
  upcoming: "upcoming",
};

export const initialChartData = {
  options: {
    chart: {
      id: "annotation-chart",
    },
    xaxis: {
      categories: [],
    },
  },
  series: [
    {
      name: "Series 1",
      data: [],
    },
  ],
};

export const convertMinutesToHours = (minutes: number): string => {
  const hours = (minutes / 60).toFixed(2);

  if (hours === "0.00" || isNaN(Number(hours))) {
    return "0";
  }

  return hours;
};

export const convertHoursToDaysAndMinutes = inputHours => {
  const days = Math.floor(inputHours / 24);
  const hours = Math.floor(inputHours % 24);
  const minutes = Math.round((inputHours % 1) * 60);
  return { days, hours, minutes };
};

export const assessmentParticipantStatus = {
  yts: "Yet to start",
  in_progress: "In progress",
  failed: "Failed",
  passed: "Passed",
  under_review: "Under review",
  certification: "Certification",
  retake: "Retake",
  draft: "Draft",
  completed: "Completed",
  mapped: "Not Scheduled",
};

export const scheduledTrainersStatus = {
  approved: "Approved",
  rejected: "Rejected",
  pending: "Pending",
  replaced: "Replaced",
  pending_approval: "Pending for approval",
};

export const assessmentTypeOptionsMap = {
  ojt: "OJT Assessment",
  field: "Field Assessment",
};

export const nomineeStatusMap = {
  pending: "Pending",
  rm_rejected: "RM Rejected",
  tm_rejected: "TM Rejected",
  tm_replaced: "TM Replaced",
  rm_replaced: "RM Replaced",
  removed: "Removed",
  expired: "Expired",
  approved: "Approved",
  completed: "Completed",
  cancelled: "Trainer Declined",
  tm_cancelled: "Cancelled",
  pre_test_completed: "Pre-Test Completed",
  pre_test_under_review: "Pre-Test Under Review",
  post_test_completed: "Post-Test Completed",
  post_test_under_review: "Post-Test Under Review",
};

export const examTakenStatus = [
  "pre_test_completed",
  "pre_test_under_review",
  "post_test_completed",
  "post_test_under_review",
];

export const pendingStatus = ["Under review", "Draft"];

export const UserListTabs = [
  { id: "active", label: "Active" },
  { id: "suspended", label: "Suspended" },
];

export const userManagementTabs = [
  { id: "overview", label: "Overview" },
  { id: "curriculum", label: "Curriculum" },
  { id: "assigned_ilp", label: "Assigned ILP" },
  { id: "self_enrollment", label: "Self enrollment" },
  { id: "unmapped", label: "Unmapped" },
  { id: "sga", label: "SGA" },
  { id: "pending_skill_update", label: "Pending Skill Update" },
  { id: "assessment", label: "Assessment" },
  { id: "training_history", label: "Training History" },
];
export const displayStatus = ["under_review", "draft"];

const countOccurrences = (responses: string[]) => {
  const counts = {
    competent: 0,
    not_competent: 0,
    partial_competent: 0,
  };
  responses.forEach(response => {
    if (counts[response] !== undefined) {
      counts[response]++;
    }
  });
  return counts;
};

const processGraphData = (
  trainerResponses: string[],
  learnerResponses: string[]
) => {
  const trainerCounts = countOccurrences(trainerResponses);
  const learnerCounts = countOccurrences(learnerResponses);

  const totalTrainerResponses =
    trainerCounts.competent +
    trainerCounts.not_competent +
    trainerCounts.partial_competent;

  const totalLearnerResponses =
    learnerCounts.competent +
    learnerCounts.not_competent +
    learnerCounts.partial_competent;

  const calculatePercentage = (count: number, total: number) => {
    return total > 0 ? Math.round((count / total) * 100) : 0;
  };

  return {
    categories: ["Competent", "Not Competent", "Partial Competent"],
    series: [
      {
        name: "Trainer Response",
        data: [
          calculatePercentage(trainerCounts.competent, totalTrainerResponses),
          calculatePercentage(
            trainerCounts.not_competent,
            totalTrainerResponses
          ),
          calculatePercentage(
            trainerCounts.partial_competent,
            totalTrainerResponses
          ),
        ],
      },
      {
        name: "Learner Response",
        data: [
          calculatePercentage(learnerCounts.competent, totalLearnerResponses),
          calculatePercentage(
            learnerCounts.not_competent,
            totalLearnerResponses
          ),
          calculatePercentage(
            learnerCounts.partial_competent,
            totalLearnerResponses
          ),
        ],
      },
    ],
  };
};

export const getChartData = (
  trainerResponses: string[],
  learnerResponses: string[]
) => {
  const { categories, series } = processGraphData(
    trainerResponses,
    learnerResponses
  );

  const options = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      max: 100,
      title: {
        text: "Percentage (%)",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return { options, series };
};

export const isDateLessThanOrEqualToToday = dateToCopare => {
  const expiresAtDate = new Date(dateToCopare + "T00:00:00Z");
  const today = new Date();
  const startOfTodayUTC = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
  );
  return expiresAtDate <= startOfTodayUTC;
};

export const ilpCourseSummaryData = data => {
  return [
    {
      id: 1,
      icon: "graduation-cap",
      count: data.total_courses,
      label: "Total Course",
      infoData: [
        { courseName: "WBT", totalCount: data.course_types.total_courses.wbt },
        {
          courseName: "VILT",
          totalCount: data.course_types.total_courses.vilt,
        },
        { courseName: "ILT", totalCount: data.course_types.total_courses.ilt },
        {
          courseName: "Webinar",
          totalCount: data.course_types.total_courses.webinar,
        },
      ],
    },
    {
      id: 2,
      icon: "completed-tick",
      count: data.completed_courses,
      label: "Completed",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.completed_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.completed_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.completed_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.completed_courses.webinar,
        },
      ],
    },
    {
      id: 3,
      icon: "upcoming-calendar",
      count: data.upcoming_courses,
      label: "Upcoming",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.upcoming_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.upcoming_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.upcoming_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.upcoming_courses.webinar,
        },
      ],
    },
    {
      id: 4,
      icon: "scheduled-calendar",
      count: data.scheduled_courses,
      label: "Scheduled",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.scheduled_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.scheduled_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.scheduled_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.scheduled_courses.webinar,
        },
      ],
    },
    {
      id: 5,
      icon: "overdue-calendar",
      count: data.overdue_courses,
      label: "Overdue",
      infoData: [
        {
          courseName: "WBT",
          totalCount: data.course_types.overdue_courses.wbt,
        },
        {
          courseName: "VILT",
          totalCount: data.course_types.overdue_courses.vilt,
        },
        {
          courseName: "ILT",
          totalCount: data.course_types.overdue_courses.ilt,
        },
        {
          courseName: "Webinar",
          totalCount: data.course_types.overdue_courses.webinar,
        },
      ],
    },
  ];
};

export const calculateValuePercentage = (value: number, totalCourses) => {
  if (!totalCourses || totalCourses === 0) return 0;
  return Number(((value / totalCourses) * 100).toFixed(1));
};

export const multiProgressData = courseData => {
  const totalCourses = courseData.total_courses;
  const percentageData = [
    {
      id: courseData.id,
      title: "Completed",
      percentage: calculateValuePercentage(
        courseData.completed_courses,
        totalCourses
      ),
      className: "success-color",
    },
    {
      title: "Upcoming",
      percentage: calculateValuePercentage(
        courseData.upcoming_courses,
        totalCourses
      ),
      className: "warning-color",
    },
    {
      title: "Scheduled",
      percentage: calculateValuePercentage(
        courseData.scheduled_courses,
        totalCourses
      ),
      className: "assigned-color",
    },
    {
      title: "Overdue",
      percentage: calculateValuePercentage(
        courseData.overdue_courses,
        totalCourses
      ),
      className: "error-color",
    },
  ];

  return percentageData;
};

export const ratingDropdown = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },

  {
    label: "6",
    value: 6,
  },
];

export const scheduledTabs = [
  { id: "upcoming", label: "Upcoming" },
  { id: "my-session", label: "My Session" },
];

export const contentType = [
  { value: "", label: "Select" },
  {
    label: "Slide",
    value: "slide",
  },
  {
    label: "PPT",
    value: "ppt",
  },
];

export const loadFactorColumns = (dropdownOpen, toggleDropdown, navigate) => [
  {
    name: "Course Name",
    selector: row => row.course_name,
    minWidth: "16rem",
    sortable: true,
  },
  {
    name: "Total (not yet completed)",
    selector: row => row.total_not_completed,
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Online Reattempt",
    selector: row => row.online_reattempt,
    minWidth: "10rem",
    sortable: true,
  },
  {
    name: "Reappear",
    selector: row => row.reappear,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "1st Timers",
    selector: row => row.first_timers,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Last course to certificate",
    selector: row => row.last_course_to_certificate,
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Overdue",
    selector: row => row.overdue,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Total ILT Participant",
    selector: row => row.total_ilt_participants,
    minWidth: "12rem",
    sortable: true,
  },
  {
    name: "Self Enrolment",
    selector: row => row.self_enrolled,
    minWidth: "12rem",
    sortable: true,
  },
  {
    name: "Sessions Planned",
    selector: row => row.sessions_planned,
    minWidth: "10rem",
    sortable: true,
  },
  {
    name: "Sessions Completed",
    selector: row => row.sessions_completed,
    minWidth: "10rem",
    sortable: true,
  },
  {
    name: "Shortfall",
    selector: row => row.shortfall,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Planned Q1",
    selector: row => row.planned_q1,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Planned Q2",
    selector: row => row.planned_q2,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Planned Q3",
    selector: row => row.planned_q3,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Planned Q4",
    selector: row => row.planned_q4,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Actual Q1",
    selector: row => row.actual_completed_q1,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Actual Q2",
    selector: row => row.actual_completed_q2,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Actual Q3",
    selector: row => row.actual_completed_q3,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Actual Q4",
    selector: row => row.actual_completed_q4,
    minWidth: "8rem",
    sortable: true,
  },

  {
    name: "Action",
    selector: row => row.action,
    minWidth: "6rem",
    sortable: true,
    cell: row =>
      hasPermissionToComponent("LOAD_FACTOR_ACTION") ? (
        <div>
          <Dropdown
            isOpen={dropdownOpen === row.id}
            toggle={() => toggleDropdown(row.id)}
            direction="start"
          >
            <DropdownToggle color="" className="three-dots primary-text-color">
              <MUIIcons
                iconName="MoreVertOutlined"
                className="primary-icon-color"
                size={17}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => {
                  navigate("/schedule-course", {
                    state: {
                      course_id: row.course_id,
                      course_name: row.course_name,
                      load_factor: true,
                    },
                  });
                }}
              >
                Schedule
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  navigate("/people", {
                    state: { id: row.id, name: row.course_name },
                  })
                }
              >
                View People
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      ) : (
        "-"
      ),
  },
];

export const learnerRatingColumns = [
  {
    name: "Question",
    selector: row => row.question_text,
    minWidth: "16rem",
    sortable: true,
  },
  {
    name: "Rating",
    selector: row => row.response,
    minWidth: "16rem",
    sortable: true,
  },
];

export const trainingHistoryColumns = [
  {
    name: "Course Name",
    selector: row => row?.courseName,
    minWidth: "12rem",
    sortable: true,
  },
  {
    name: "Course Type",
    selector: row => row?.courseType,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Status",
    selector: row => assessmentParticipantStatus[row?.status],
    minWidth: "10rem",
    sortable: true,
  },
  {
    name: "Completed On",
    selector: row =>
      row?.completedOn && row?.completedOn !== null
        ? formatCustomDate(row?.completedOn)
        : "-",
    minWidth: "12rem",
    sortable: true,
  },
  {
    name: "Knowledge Score",
    selector: row => row?.knowledgeScore || "-",
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Skill Completion (%)",
    selector: row => row?.skillScore || "-",
    minWidth: "8rem",
    sortable: true,
  },
];

export const scheduledCourseColumns = (
  tooltipOpen,
  toggleTooltip,
  onReview,
  userMap,
  navigate
) => [
  {
    name: "Course Name",
    selector: row => row.course_name,
    minWidth: "16rem",
    sortable: true,
  },
  {
    name: "Instructor",
    selector: row =>
      userMap ? userMap[row?.primary_trainer_id]?.user_name : null,
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Location",
    selector: row => row?.location_name,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Room",
    selector: row => row?.classroom_name,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Nomination Period",
    selector: row =>
      formatCustomDate(row?.nomination_start_date) +
      " - " +
      formatCustomDate(row?.nomination_end_date),
    minWidth: "15rem",
    sortable: true,
  },
  {
    name: "Course Period",
    selector: row =>
      formatCustomDate(row?.course_start_date) +
      " - " +
      formatCustomDate(row?.course_end_date),
    minWidth: "15rem",
    sortable: true,
  },
  {
    name: "Available",
    selector: row => row.available_count,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Booked",
    selector: row => row.booked_count,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Waitlist",
    selector: row => row.waitlist_count,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Status",
    selector: row => courseScheduleStatusMap[row.status],
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Action",
    selector: row => row.action,
    minWidth: "6rem",
    sortable: true,
    cell: row =>
      (row.status === "awaiting_trainer_approval" ||
        row.status === "waiting_for_trainer_approval") &&
      hasPermissionToComponent("APPROVE_SCHEDULE") &&
      (currentUserId() === row.primary_trainer_id ||
        currentUserId() === row.replaced_trainer_id) ? (
        <div className="d-flex gap-3">
          <div>
            <span id={"tooltip-approve"}>
              <MUIIcons
                onClick={() => {
                  onReview(row?.course_schedule_id, "reject");
                }}
                className="primary-icon-color pointer"
                iconName="CancelOutlined"
                size={27}
              />
            </span>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen[101010] || false}
              target={"tooltip-approve"}
              toggle={() => toggleTooltip(101010)}
            >
              {Decline}
            </Tooltip>
          </div>
          <div>
            <span id={"tooltip-reject"}>
              <MUIIcons
                onClick={() => {
                  onReview(row?.course_schedule_id, "approve");
                }}
                className="text-success pointer"
                iconName="CheckCircleOutlined"
                size={27}
              />
            </span>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen[1010101] || false}
              target={"tooltip-reject"}
              toggle={() => toggleTooltip(1010101)}
            >
              {Approve}
            </Tooltip>
          </div>
        </div>
      ) : (
        <div>
          {hasPermissionToComponent("UPSERT_COURSE_SCHEDULE") &&
            !editCourseScheduleStatus.includes(row?.status) && (
              <MUIIcons
                size={24}
                className="primary-icon-color pointer"
                iconName="EditOutlined"
                onClick={() => {
                  navigate("/schedule-course", {
                    state: {
                      schedule_id: row.course_schedule_id,
                      course_id: row.course_id,
                      course_name: row.course_name,
                      ongoing_status: row?.status,
                    },
                  });
                }}
              />
            )}
          {hasPermissionToComponent("UPSERT_COURSE_SCHEDULE") &&
            editCourseScheduleStatus.includes(row?.status) && (
              <MUIIcons
                size={24}
                className="primary-icon-color pointer"
                iconName="VisibilityOutlined"
                onClick={() =>
                  navigate("/schedule-course", {
                    state: {
                      schedule_id: row.course_schedule_id,
                      course_id: row.course_id,
                      course_name: row.course_name,
                      makeNonEditable: true,
                    },
                  })
                }
              />
            )}
        </div>
      ),
  },
];

export const coursePeopleData = {
  online_reattempt: [
    {
      user_id: 15,
    },
    {
      user_id: 20,
    },
  ],
  reappear: [
    {
      user_id: 16,
    },
  ],
  first_timers: [
    {
      user_id: 17,
    },
  ],
  last_course_to_certificate: [
    {
      user_id: 18,
    },
  ],
  overdue: [
    {
      user_id: 19,
    },
  ],
};

interface ModifyPlansColumnsProps {
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    rowId: number,
    field: string
  ) => void;
}

export const modifyPlansColumns = (
  handleInputChange: ModifyPlansColumnsProps["handleInputChange"]
) => [
  {
    name: "Course Name",
    selector: (row: any) => row.course_name,
    minWidth: "16rem",
    sortable: true,
  },
  {
    name: "Total ILT Participant",
    selector: (row: any) => row.total_ilt_participants,
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Completed Schedules",
    selector: (row: any) => row.sessions_completed,
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Q1",
    selector: (row: any) => row.planned_q1,
    minWidth: "10rem",
    sortable: true,
    cell: (row: any) => (
      <Input
        className="table-input"
        type="text"
        value={row.planned_q1}
        onChange={e => handleInputChange(e, row.id, "planned_q1")}
        onKeyDown={handleKeyDownOnNumericWithoutDecimal}
      />
    ),
  },
  {
    name: "Q2",
    selector: (row: any) => row.planned_q2,
    minWidth: "10rem",
    sortable: true,
    cell: (row: any) => (
      <Input
        className="table-input"
        type="text"
        value={row.planned_q2}
        onChange={e => handleInputChange(e, row.id, "planned_q2")}
        onKeyDown={handleKeyDownOnNumericWithoutDecimal}
      />
    ),
  },
  {
    name: "Q3",
    selector: (row: any) => row.planned_q3,
    minWidth: "10rem",
    sortable: true,
    cell: (row: any) => (
      <Input
        className="table-input"
        type="text"
        value={row.planned_q3}
        onChange={e => handleInputChange(e, row.id, "planned_q3")}
        onKeyDown={handleKeyDownOnNumericWithoutDecimal}
      />
    ),
  },
  {
    name: "Q4",
    selector: (row: any) => row.planned_q4,
    minWidth: "10rem",
    sortable: true,
    cell: (row: any) => (
      <Input
        className="table-input"
        type="text"
        value={row.planned_q4}
        onChange={e => handleInputChange(e, row.id, "planned_q4")}
        onKeyDown={handleKeyDownOnNumericWithoutDecimal}
      />
    ),
  },
];

export const MentorDetailsTabs = [
  { id: "mentees", label: "Mentee's" },
  { id: "session", label: "Session" },
];

export const MenteesListTabs = [
  { title: "Current", icon: "", tabId: "1" },
  { title: "Past", icon: "", tabId: "2" },
];

export const InstructionData = {
  sections: [
    {
      id: 1,
      heading: "Heading 1 ( Heading from GMCCO )",
      content: "Content 1  ( Content from GMCCO )",
    },
    {
      id: 2,
      heading: "Heading 2  ( Heading from GMCCO )",
      content: "Content 2  ( Content from GMCCO )",
    },
    {
      id: 3,
      heading: "Heading 3  ( Heading from GMCCO )",

      content: "Content 3  ( Content from GMCCO )",
    },
  ],
};

export const courseScheduledColumns = (
  navigate,
  toggleTooltip,
  tooltipOpen,
  onReview,
  userMap
) => [
  {
    name: "Course Period",
    selector: row =>
      formatCustomDate(row?.course_start_date) +
      " - " +
      formatCustomDate(row?.course_end_date),
    minWidth: "16rem",
    sortable: true,
  },
  {
    name: "Instructor",
    selector: row => (
      <div className="d-flex gap-1 align-items-center">
        {userMap ? userMap[row?.primary_trainer_id]?.user_name : null}
        {(row.status === "awaiting_trainer_approval" ||
          row.status === "waiting_for_trainer_approval") && (
          <>
            <span
              id={`tooltipIcon${row.course_schedule_id}`}
              className="ml-2 pointer"
            >
              <MUIIcons
                className="pointer"
                iconName="InfoOutlined"
                size={18}
                onClick={() =>
                  toggleTooltip(`tooltipIcon${row.course_schedule_id}`)
                }
              />
            </span>
            <ToolTip
              placement="top"
              target={`tooltipIcon${row.course_schedule_id}`}
              isOpen={tooltipOpen === `tooltipIcon${row.course_schedule_id}`}
              toggle={() =>
                toggleTooltip(`tooltipIcon${row.course_schedule_id}`)
              }
            >
              {userMap ? userMap[row?.primary_trainer_id]?.user_name : null}{" "}
              {InviteSentOn} {formatDate(row?.created_at)}
            </ToolTip>
          </>
        )}
      </div>
    ),
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Location",
    selector: row => row?.location_name,
    minWidth: "10rem",
    sortable: true,
  },
  {
    name: "Room",
    selector: row => row?.classroom_name,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Nomination Period",
    selector: row =>
      formatCustomDate(row?.nomination_start_date) +
      " - " +
      formatCustomDate(row?.nomination_end_date),
    minWidth: "15rem",
    sortable: true,
  },
  {
    name: "Available",
    selector: row => row.available_count,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Booked",
    selector: row => row.booked_count,
    minWidth: "8rem",
    sortable: true,
  },
  {
    name: "Waitlist",
    selector: row => row.waitlist_count,
    minWidth: "8rem",
    sortable: true,
  },

  {
    name: "Status",
    selector: row => courseScheduleStatusMap[row.status],
    minWidth: "14rem",
    sortable: true,
  },
  {
    name: "Action",
    selector: row => row.action,
    minWidth: "6rem",
    sortable: true,
    cell: row =>
      hasPermissionToComponent("APPROVE_SCHEDULE") &&
      (currentUserId() === row.primary_trainer_id ||
        currentUserId() === row.replaced_trainer_id) ? (
        (row.status === "awaiting_trainer_approval" ||
          row.status === "waiting_for_trainer_approval") && (
          <div className="d-flex gap-3">
            <div>
              <span id={`tooltip-approve-${row.course_schedule_id}`}>
                <MUIIcons
                  onClick={() => onReview(row?.course_schedule_id, "reject")}
                  className="primary-icon-color pointer"
                  iconName="CancelOutlined"
                  size={27}
                />
              </span>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen === `approve-${row.course_schedule_id}`}
                target={`tooltip-approve-${row.course_schedule_id}`}
                toggle={() =>
                  toggleTooltip(`approve-${row.course_schedule_id}`)
                }
              >
                {Decline}
              </Tooltip>
            </div>
            <div>
              <span id={`tooltip-reject-${row.course_schedule_id}`}>
                <MUIIcons
                  onClick={() => onReview(row?.course_schedule_id, "approve")}
                  className="text-success pointer"
                  iconName="CheckCircleOutlined"
                  size={27}
                />
              </span>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen === `reject-${row.course_schedule_id}`}
                target={`tooltip-reject-${row.course_schedule_id}`}
                toggle={() => toggleTooltip(`reject-${row.course_schedule_id}`)}
              >
                {Approve}
              </Tooltip>
            </div>
          </div>
        )
      ) : (
        <div>
          {hasPermissionToComponent("UPSERT_COURSE_SCHEDULE") &&
            !editCourseScheduleStatus.includes(row?.status) && (
              <MUIIcons
                size={24}
                className="primary-icon-color pointer"
                iconName="EditOutlined"
                onClick={() =>
                  navigate("/schedule-course", {
                    state: {
                      schedule_id: row.course_schedule_id,
                      course_id: row.course_id,
                      course_name: row.course_name,
                      ongoing_status: row?.status,
                    },
                  })
                }
              />
            )}
          {hasPermissionToComponent("UPSERT_COURSE_SCHEDULE") &&
            editCourseScheduleStatus.includes(row?.status) && (
              <MUIIcons
                size={24}
                className="primary-icon-color pointer"
                iconName="VisibilityOutlined"
                onClick={() =>
                  navigate("/schedule-course", {
                    state: {
                      schedule_id: row.course_schedule_id,
                      course_id: row.course_id,
                      course_name: row.course_name,
                      makeNonEditable: true,
                    },
                  })
                }
              />
            )}
        </div>
      ),
  },
];

export const HighlightQuestions = [
  {
    id: 1,
    question: SessionQuestionWhatsWorkingWell,
  },
  {
    id: 2,
    question: SessionQuestionWhatsNotWorkingWell,
  },
  {
    id: 3,
    question: ResourcesRequired,
  },
];

export const FeedbackQuestions = [
  {
    id: 1,
    question: KeyChangesAndDevelopmentGoals,
  },
  {
    id: 2,
    question: KeyActionsOrSteps,
  },
  {
    id: 3,
    question: SessionQuestionKeyThoughts,
  },
];

export const MentorTabs = [
  { id: "mentee", label: "Mentee" },
  { id: "sessions", label: "Sessions" },
];

export const MenteeTabs = [
  { id: "sessions", label: "Sessions" },
  { id: "mentors", label: "Mentors" },
];

export const MentorsListTabs = [
  { title: "Current", icon: "", tabId: "1" },
  { title: "Past", icon: "", tabId: "2" },
];

export const SessionStatusFilterDropdownList = [
  { name: "upcoming", value: "upcoming", label: "Upcoming" },
  { name: "completed", value: "completed", label: "Completed" },
];

export const trainingHistoryFilterList = [
  { name: "mapped", value: "Not Scheduled", label: "Not Scheduled" },
  { name: "upcoming", value: "Upcoming", label: "Upcoming" },
  { name: "requested", value: "Requested", label: "Requested" },
  { name: "in_progress", value: "In Progress", label: "In Progress" },
  { name: "passed", value: "Passed", label: "Passed" },
  { name: "failed", value: "Failed", label: "Failed" },
  { name: "completed", value: "Completed", label: "Completed" },
  { name: "overdue", value: "Overdue", label: "Overdue" },
];

export const series = [
  {
    name: "Rating",
    data: [60, 12, 90],
  },
];

export const options: ApexOptions = {
  chart: {
    type: "bar" as const,
    toolbar: {
      show: false,
    },
    fontFamily: "inherit",
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
      borderRadius: 2,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    show: true,
    borderColor: "#f1f1f1",
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  xaxis: {
    categories: [
      "Course Content",
      "Course Effectiveness",
      "Instructor Evaluation",
    ],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    title: {
      text: "",
    },
    min: 0,
    max: 100,
    tickAmount: 5,
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  colors: ["#000"],
};

export const isDateToday = dateString => {
  const currentDate = new Date().toISOString().slice(0, 10);
  return currentDate === dateString;
};

export const nominationListTabs = [
  { id: "all", label: "All" },
  { id: "pending_nomination", label: "Pending Nomination" },
  { id: "expired", label: "Expired" },
  { id: "replacement", label: "Replacement" },
];

export const isDateGreaterThanTodayDate = date => {
  const currentDate = new Date();
  const givenDate = new Date(date);

  currentDate.setHours(0, 0, 0, 0);
  givenDate.setHours(0, 0, 0, 0);

  return givenDate > currentDate;
};

export const nominationDetailsTabs = [
  { id: "details", label: "Details" },
  { id: "participant", label: "Participant" },
  { id: "upcoming", label: "Upcoming" },
];

export const participantDetailsTabs = [
  { id: "attendees", label: "Attendees" },
  { id: "nomination", label: "Nomination" },
  { id: "replacement", label: "Replacement" },
];

export const learningOutcomeTabs = [
  { id: "published", label: "Published" },
  { id: "archive", label: "Archive" },
];
