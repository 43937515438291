import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { H3 } from "../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../Api/constants";
import BorderTabs from "../../../CommonElements/BorderTabs";
import Divider from "../../../CommonElements/Divider";
import SearchBar from "../../../CommonElements/SearchBar";
import UserProfileCardDetails from "../../../CommonElements/UserProfileCardDetails";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { fetchCoursePeopleData } from "../../../ReduxToolkit/Reducers/LoadFactorSlice";
import {
  getProficiencyList,
  getSbuList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { NoDataText } from "../../../utils/Constant";
import { viewPeopleTabs } from "../../../utils/helper/helper";

const ViewPeople = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { usersList } = useAppSelector(state => state.user);
  const { coursePeopleData } = useAppSelector(state => state.loadFactor);
  const { id, name } = location.state || {};
  const [activeTab, setActiveTab] = useState("online_reattempt");
  const [searchValue, setSearchValue] = useState("");
  const [userNameMap, setUserNameMap] = useState({});
  const { sbuList, proficiencyList } = useAppSelector(state => state.master);
  const [sbuFilterList, setSbuFilterList] = useState({});
  const [levelFilterList, setLevelFilterList] = useState({});

  useEffect(() => {
    dispatch(getSbuList());
    dispatch(getProficiencyList());
  }, [dispatch]);

  useEffect(() => {
    const value = {};
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        value[item?.id] = item;
      });
    }
    setSbuFilterList(value);
  }, [sbuList]);

  useEffect(() => {
    const value = {};
    if (proficiencyList) {
      proficiencyList?.proficiency?.forEach(item => {
        value[item?.id] = item;
      });
    }
    setLevelFilterList(value);
  }, [proficiencyList]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    if (id) dispatch(fetchCoursePeopleData(id));
  }, [dispatch, id]);

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  const handleSearch = (value, users) => {
    if (!value) return users;

    return users.filter(user => {
      const userDetails = userNameMap[user.user_id];

      return (
        userDetails?.user_name?.toLowerCase().includes(value.toLowerCase()) ||
        userDetails?.designation?.toLowerCase().includes(value.toLowerCase()) ||
        userDetails?.mapped_rm[0]?.name
          ?.toLowerCase()
          .includes(value.toLowerCase()) ||
        userDetails?.phone?.toLowerCase().includes(value.toLowerCase()) ||
        userDetails?.email?.toLowerCase().includes(value.toLowerCase()) ||
        userDetails?.employee_code?.toString().includes(value) ||
        userDetails?.sbu?.toString().includes(value)
      );
    });
  };

  const renderUserCards = users => {
    const filteredUsers = handleSearch(searchValue, users);

    if (filteredUsers?.length === 0) {
      return (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      );
    }

    return filteredUsers?.map(user => {
      return (
        <div key={user.user_id} className={"pointer user-list__card"}>
          <UserProfileCardDetails
            userProfileUrl={""}
            name={userNameMap[user.user_id]?.user_name}
            detail={userNameMap[user.user_id]?.designation}
            expertiseLevel={userNameMap[user.user_id]?.level_id}
            location={userNameMap[user.user_id]?.location}
            rmName={userNameMap[user.user_id]?.mapped_rm[0]?.name}
            phoneNumber={userNameMap[user.user_id]?.phone}
            mailId={userNameMap[user.user_id]?.email}
            cwsAndCatRecId={1}
            uniqueKey={userNameMap[user.user_id]?.id}
            empId={userNameMap[user.user_id]?.employee_code}
            sbu={userNameMap[user.user_id]?.sbu}
            sbuFilterList={sbuFilterList}
            levelFilterList={levelFilterList}
          />
        </div>
      );
    });
  };

  const usersForActiveTab = coursePeopleData?.[activeTab] || [];

  return (
    <div className="page-body">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <H3 className="mt-3">{name || ""}</H3>
            <SearchBar onSearch={value => setSearchValue(value)} />
          </div>
          <Divider />
          <div className="mt-2">
            <BorderTabs
              tabs={viewPeopleTabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              showCard={false}
            />
          </div>
          <div className="mt-3">{renderUserCards(usersForActiveTab)} </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ViewPeople;
