import { getCurrentDate } from "../../utils/helper/helper";

export const convertCourseDetailsPayload = (
  basicInputFormValue: any,
  courseContent: any
): CourseDetailsPayload => {
  const combinedArray = [
    ...(courseContent?.competency_statement?.tool_aid?.tool || []),
    ...(courseContent?.competency_statement?.tool_aid?.training_aid || []),
  ];

  return {
    course_name: basicInputFormValue.course_name,
    course_id: courseContent?.course_id || "",
    course_request_id: courseContent?.course_request_id || null,
    course_type: basicInputFormValue.course_type,
    description: courseContent?.description || "",
    status: courseContent?.status || "new",
    duration: courseContent?.duration || 0,
    min_attendance: courseContent?.additional_information?.min_attendance || 0,
    max_attendance: courseContent?.additional_information?.max_attendance || 0,
    suggested_reading:
      courseContent?.additional_information?.suggested_reading || "",
    qualification_certification:
      courseContent?.additional_information?.qualification_certification || "",
    assessment_criteria:
      courseContent?.additional_information?.assessment_criteria || "",
    theory_time_ratio:
      courseContent?.additional_information?.theory_time_ratio || 0,
    practical_time_ratio:
      courseContent?.additional_information?.practical_time_ratio || 0,
    practical_activity_details:
      courseContent?.additional_information?.practical_activity_details || "",
    tooling_aids_required:
      courseContent?.additional_information?.tooling_aids_required || "",
    other_pre_course_works:
      courseContent?.additional_information?.other_pre_course_works || "",
    pre_course_work:
      courseContent?.additional_information?.pre_course_work || [],
    registration_requirement:
      courseContent?.additional_information?.registration_requirement || "",
    objective: basicInputFormValue.objective,
    date:
      (courseContent?.competency_statement?.date &&
      courseContent?.competency_statement?.date !== "None"
        ? courseContent?.competency_statement?.date
        : getCurrentDate()) || getCurrentDate(),
    prerequisite: courseContent?.competency_statement?.prerequisite || [],
    version: courseContent?.competency_statement?.version || "",
    conditions: courseContent?.competency_statement?.conditions || "",
    min_requirements:
      courseContent?.competency_statement?.min_requirements || "",
    media_demonstration:
      courseContent?.competency_statement?.media_demonstration || "",
    estimated_time: courseContent?.competency_statement?.estimated_time || null,
    classroom_lab: courseContent?.competency_statement?.classroom_lab || "",
    resources: combinedArray || [],
    webinar_link: courseContent?.competency_statement?.webinar_link || "",
    reference: courseContent?.competency_statement?.reference || [],
    resources_activity:
      courseContent?.competency_statement?.resources_activity || "",
    review: courseContent?.competency_statement?.review || "",
    assessment: courseContent?.competency_statement?.assessment || "",
    class_room_ratio:
      courseContent?.competency_statement?.class_room_ratio || 0,
    intended_audience:
      courseContent?.additional_information?.intended_audience || [],
    other_intended_audiences:
      courseContent?.additional_information?.other_intended_audiences || "",
    practical_class_ratio:
      courseContent?.competency_statement?.practical_class_ratio || 0,
  };
};
