import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { UpsertRegionPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createRegion,
  deleteRegion,
  getManageMasterRegionList,
  getManageMasterRegionListById,
  setRegionListByIdToInitialValue,
  updateRegion,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  AddNew,
  Enter,
  Regions,
  NoDataText,
  NotYetAdded,
  DeleteLocationHeader,
  Confirm,
  DeleteLocationMessage,
  Region,
  Location,
  RegionEmptyDescription,
} from "../../../utils/Constant";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../utils/helper/localStorageutils";

const RegionPage = () => {
  const dispatch = useAppDispatch();
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [accordionData, setAccordionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [regionToDelete, setRegionToDelete] = useState(null);

  const { regionList, regionListById, loading } = useAppSelector(
    state => state.manageMasterData
  );

  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getManageMasterRegionList());
  }, [dispatch]);

  useEffect(() => {
    if (regionList?.region?.length > 0) {
      setAccordionData(
        regionList?.region?.map(region => ({
          id: region.id.toString(),
          name: region.name,
          description: region.description,
          createdOn: region.created_at,
          modifiedOn: region.updated_at,
          depedentBadges: [
            { id: 1, badge: `${Location}: ${region.location_count}` },
          ],
        }))
      );
    }
  }, [regionList]);

  useEffect(() => {
    if (searchQuery) {
      const filtered = accordionData?.filter(comp =>
        comp.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(accordionData);
    }
  }, [searchQuery, accordionData]);

  useEffect(() => {
    if (regionListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: regionListById.name,
        description: regionListById.description,
      }));
    }
  }, [regionListById]);

  const handleSave = useCallback(
    (title: string, description: string) => {
      const payload: UpsertRegionPayload = { name: title, description };

      if (regionListById) {
        payload.id = regionListById.id;
        dispatch(updateRegion({ updateRegionPayload: payload })).then(() => {
          dispatch(getManageMasterRegionList());
          toggleModal();
        });
      } else {
        dispatch(createRegion({ createRegionPayload: payload })).then(() => {
          dispatch(getManageMasterRegionList());
          toggleModal();
        });
      }
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    const selectedId = parseInt(id);
    dispatch(getManageMasterRegionListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setRegionToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (regionToDelete) {
      dispatch(deleteRegion({ id: regionToDelete })).then(() => {
        dispatch(getManageMasterRegionList());
        toggleDeleteConfirmModal();
      });
    }
  };
  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (accordionData?.length === 0) {
      return (
        <NoData
          svg={"empty-folder-icon"}
          title={NotYetAdded}
          description={RegionEmptyDescription}
          buttonText={AddNew}
          onclick={toggleModal}
        />
      );
    }

    if (filteredData?.length === 0) {
      return <NoData svg={"empty-folder-icon"} title={NoDataText} />;
    }

    return filteredData?.map(item => (
      <MasterDataAccordion
        key={item.id}
        accordionItems={item}
        onEditClick={() => handleEdit(item.id)}
        onDeleteClick={() => handleDelete(item.id)}
        loginData={loginData}
      />
    ));
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteLocationHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteLocationMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Regions}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {accordionData?.length > 0 && (
                <Btn
                  icon={
                    <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                  }
                  color="primary"
                  onClick={() => {
                    dispatch(setRegionListByIdToInitialValue());
                    setModalFormValues(null);
                    toggleModal();
                  }}
                >
                  {AddNew}
                </Btn>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {renderContent()}

      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Region}
        placeholderText={`${Enter} ${Region}`}
        toolTipText={`${Enter} ${Region}`}
        initialValues={modalFormValues}
      />
    </div>
  );
};

export default RegionPage;
