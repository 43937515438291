import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "reactstrap";

import { PUBLISH_STATUS } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getCurriculumList } from "../../ReduxToolkit/Reducers/CurriculumSlice";
import { mapCurriculum } from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Domain,
  Curriculum,
  IsSpecialCurriculum,
  Submit,
  DomainRequired,
  RoleIsRequired,
  CurriculumIsRequired,
  CurriculumDomainToolTipMessage,
  IsSpecialCurriculumToolTipMessage,
  RoleToolTipMessage,
  ILPCurriculumToolTipMessage,
  JobRole,
} from "../../utils/Constant";
import { mapListToOptions } from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";
import CheckboxButton from "../SwitchButton";

interface DropdownOption {
  id: number;
  value: string;
  label: string;
}
interface MapCurriculumModalProps {
  isOpen: boolean;
  toggle: (isMapped?: boolean) => void;
  domains: DropdownOption[];
  roles: DropdownOption[];
  userId?: number;
}

const MapCurriculumModal: React.FC<MapCurriculumModalProps> = ({
  isOpen,
  toggle,
  domains,
  roles,
  userId,
}) => {
  const [selectedDomain, setSelectedDomain] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [curriculumOptions, setCurriculumOptions] = useState([]);
  const [selectedCurriculum, setSelectedCurriculum] = useState<string>("");
  const [isSpecialCurriculum, setIsSpecialCurriculum] = useState(false);
  const [errors, setErrors] = useState({
    selectedDomain: "",
    selectedRole: "",
    selectedCurriculum: "",
  });
  const dispatch = useAppDispatch();
  const { curriculumList } = useAppSelector(state => state.curriculum);
  const { mappedCurriculum } = useAppSelector(state => state.learningPlan);

  useEffect(() => {
    if (selectedDomain && selectedRole) {
      fetchCurriculum();
    }
  }, [selectedDomain, selectedRole, isSpecialCurriculum]);

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDomain(event.target.value);
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value);
  };

  const handleCurriculumChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedCurriculum(event.target.value);
  };

  useEffect(() => {
    const options = mapListToOptions(curriculumList?.curriculum, "name", "id");
    const uniqueOptions = options.filter(
      option =>
        !mappedCurriculum?.curriculum?.some(
          curriculum => curriculum.id.toString() === option.value
        )
    );
    setCurriculumOptions(uniqueOptions);
    setSelectedCurriculum("");
  }, [curriculumList]);

  const fetchCurriculum = () => {
    dispatch(
      getCurriculumList({
        status: PUBLISH_STATUS,
        curriculum_type: {
          domain: [selectedDomain],
          role: [selectedRole],
          specialCurriculum: isSpecialCurriculum,
        },
      })
    );
  };

  const getMapCurriculumModalBody = () => {
    return (
      <Form className="custom-input" noValidate>
        <div className="mb-3">
          <LabelTooltip
            label={Domain}
            tooltipText={CurriculumDomainToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <Input
            type="select"
            value={selectedDomain}
            onChange={handleDomainChange}
            invalid={!!errors?.selectedDomain}
          >
            {domains.map(domain => (
              <option key={domain.id} value={domain.value}>
                {domain.label}
              </option>
            ))}
          </Input>
          {errors?.selectedDomain && (
            <div className="invalid-feedback">{errors?.selectedDomain}</div>
          )}
        </div>

        <div className="mb-3">
          <Row className="align-items-center">
            <Col xs="auto">
              <LabelTooltip
                label={IsSpecialCurriculum}
                tooltipText={IsSpecialCurriculumToolTipMessage}
                important={false}
                placement={"bottom"}
              />
            </Col>
            <Col xs="auto">
              <CheckboxButton
                onChange={e => {
                  setIsSpecialCurriculum(e.target.checked);
                }}
                checked={isSpecialCurriculum}
              />
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <LabelTooltip
            label={JobRole}
            tooltipText={RoleToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <Input
            type="select"
            value={selectedRole}
            onChange={handleRoleChange}
            invalid={!!errors?.selectedRole}
          >
            {roles.map(role => (
              <option key={role.id} value={role.value}>
                {role.label}
              </option>
            ))}
          </Input>
          {errors?.selectedRole && (
            <div className="invalid-feedback">{errors?.selectedRole}</div>
          )}
        </div>

        <div className="mb-3">
          <LabelTooltip
            label={Curriculum}
            tooltipText={ILPCurriculumToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <Input
            type="select"
            value={selectedCurriculum}
            onChange={handleCurriculumChange}
            invalid={!!errors?.selectedCurriculum}
            disabled={!selectedRole || !selectedDomain}
          >
            {curriculumOptions.map(curriculum => (
              <option key={curriculum.id} value={curriculum.value}>
                {curriculum.label}
              </option>
            ))}
          </Input>
          {errors?.selectedCurriculum && (
            <div className="invalid-feedback">{errors?.selectedCurriculum}</div>
          )}
        </div>
      </Form>
    );
  };

  const validateForm = () => {
    const newErrors = {
      selectedDomain: "",
      selectedRole: "",
      selectedCurriculum: "",
    };

    let isError = false;
    if (!selectedDomain) {
      newErrors.selectedDomain = DomainRequired;
      isError = true;
    }

    if (!selectedRole) {
      newErrors.selectedRole = RoleIsRequired;
      isError = true;
    }

    if (!selectedCurriculum) {
      newErrors.selectedCurriculum = CurriculumIsRequired;
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      return;
    }

    dispatch(
      mapCurriculum({
        curriculumContent: {
          type: "curriculum",
          user_id: userId,
          id: Number(selectedCurriculum),
        },
      })
    ).then(res => {
      if (res?.payload) {
        toggle(true);
      }
    });
  };

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={"Map Curriculum"}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getMapCurriculumModalBody()}
    </CommonModal>
  );
};

MapCurriculumModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  domains: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  userId: PropTypes.number,
};

export default MapCurriculumModal;
