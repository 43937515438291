import { createSlice } from "@reduxjs/toolkit";

import { FillAllFields } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

const initialState = {
  numberLevel: 1,
  basicInputFormValue: {
    prerequisite: [],
    create_date: "",
    version_number: "",
    condition: "",
    personnel_requirement: "",
    media_demonstration: "",
    classroom_environment: "",
    estimated_delivery: "",
    class_room_ratio: "0.0",
    practical_class_ratio: "0.0",
    resources: "",
    webinar_link: "",
    reference: "",
    review: "",
    assessment: "",
    tools: "",
    training_aids: "",
    other_references: "",
    other_tools: "",
    other_training_aids: "",
    other_prerequisites: "",
  },
  showFinish: false,
};

const CompetencyWizardSlice = createSlice({
  name: "CompetencyWizardSlice",
  initialState,
  reducers: {
    setCompetencyNumberLevel: (state, action) => {
      state.numberLevel = action.payload;
    },
    setCompetencyBasicInputFormValueToInitials: state => {
      state.basicInputFormValue = initialState.basicInputFormValue;
    },
    setBasicInputFormValue: (state, action) => {
      state.basicInputFormValue = action.payload;
    },
    setShowFinish: (state, action) => {
      state.showFinish = action.payload;
    },
    handleBackButton: state => {
      state.showFinish = false;
      if (state.numberLevel === 2) {
        state.numberLevel = state.numberLevel - 1;
      }
      if (state.numberLevel === 3) {
        state.numberLevel = state.numberLevel - 1;
      }
    },
    handleNextButton: state => {
      if (state.numberLevel === 1) {
        state.numberLevel = 2;
      } else if (state.numberLevel === 2) {
        state.numberLevel = 3;
        state.showFinish = true;
      } else {
        showToast(FillAllFields, "error");
      }
    },
  },
});

export const {
  setCompetencyNumberLevel,
  setCompetencyBasicInputFormValueToInitials,
  setBasicInputFormValue,
  setShowFinish,
  handleBackButton,
  handleNextButton,
} = CompetencyWizardSlice.actions;
export default CompetencyWizardSlice.reducer;
