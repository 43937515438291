import PropTypes from "prop-types";
import { Container, FormGroup, Input } from "reactstrap";

import { Btn } from "../../AbstractElements";
import CommentsCard from "../../CommonElements/CommentsCard";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import {
  AddComment,
  Description,
  Enter,
  NoDataText,
  Send,
} from "../../utils/Constant";
import "./style.scss";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";
import NoData from "../NoData";

const Comments = ({
  send = false,
  comments = [],
  userMap = {},
  onCommentsChange,
  onSendComments,
  text = "",
}) => {
  const isMobileView = useIsMobile();

  return (
    <div className="pt-4 mr-5 comments-wrapper">
      <div className="comments-section">
        {comments?.length > 0 ? (
          comments?.map(item => (
            <div key={item.id}>
              <CommentsCard userMap={userMap} comment={item} />
            </div>
          ))
        ) : (
          <NoData
            svg={"empty-folder-icon"}
            title={NoDataText}
            showCard={false}
          />
        )}
      </div>
      {hasPermissionToComponent("ADD_COMMENT") && send && (
        <Container className={"mt-3 pr-5 comment-input-container"}>
          <FormGroup
            className={`${!isMobileView ? "comment-section-margin" : ""}`}
          >
            <LabelTooltip
              label={AddComment}
              tooltipText={`${Enter} ${Description}`}
              important
            />
            <Input
              onChange={onCommentsChange}
              name="comments"
              type="textarea"
              rows={3}
              value={text}
              placeholder={`${Enter} ${Description}`}
            />
          </FormGroup>
          <div
            className={`pb-3 d-flex justify-content-between ${!isMobileView ? "comment-section-margin" : ""}`}
          >
            <div />
            <Btn onClick={onSendComments} color="primary">
              {Send}
            </Btn>
          </div>
        </Container>
      )}
    </div>
  );
};

Comments.propTypes = {
  send: PropTypes.bool,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    })
  ),
  userMap: PropTypes.object,
  onCommentsChange: PropTypes.func,
  onSendComments: PropTypes.func,
  text: PropTypes.string,
};

export default Comments;
