import JSZip from "jszip";
import PropTypes from "prop-types";
import { Button, Card, CardBody } from "reactstrap";

import { useAppDispatch } from "../../../src/ReduxToolkit/Hooks";
import { MUIIcons, P } from "../../AbstractElements";
import { updateCourseStatus } from "../../ReduxToolkit/Reducers/CourseSlice";
import {
  getScormData,
  setScormToInitialValues,
} from "../../ReduxToolkit/Reducers/LearnerSlice";
import { Play } from "../../utils/Constant";
import {
  saveToLocalStorage,
  OPENED_SCORM_DETAILS,
} from "../../utils/helper/localStorageutils";
import { currentUserId } from "../../utils/helper/permission";
const PlayCourseMaterial = ({ material, courseId }) => {
  const dispatch = useAppDispatch();
  const onPlayFile = async (scormPackagePath: string) => {
    try {
      const scormResponse = await dispatch(
        getScormData({ courseId: courseId.toString() })
      );
      saveToLocalStorage(OPENED_SCORM_DETAILS, {});
      if (scormPackagePath.includes(".zip")) {
        const response = await fetch(scormPackagePath);
        const blob = await response.blob();
        const zip = await JSZip.loadAsync(blob);
        const files = [];
        zip.forEach((relativePath, file) => {
          files.push({ path: relativePath, file });
        });
        const indexFile = files.find(
          f =>
            f.path.endsWith("index.html") || f.path.endsWith("launchpage.html")
        );
        if (indexFile) {
          const content = await indexFile.file.async("string");
          const blob = new Blob([content], { type: "text/html" });
          const url = URL.createObjectURL(blob);
          const width = 1400;
          const height = 700;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;
          if (scormResponse?.payload?.data?.scorm_data) {
            saveToLocalStorage(
              OPENED_SCORM_DETAILS,
              scormResponse.payload.data
            );
          } else {
            saveToLocalStorage(OPENED_SCORM_DETAILS, {
              course_id: courseId.toString(),
              scorm_data: {},
            });
          }
          window.open(
            url,
            "_blank",
            `width=${width},height=${height},top=${top},left=${left}`
          );
        }
      }
      const payload = {
        course_id: courseId.toString(),
        user_id: currentUserId(),
        status: "in_progress",
      };
      await dispatch(
        updateCourseStatus({
          courseStatus: payload,
        })
      );
    } catch (e) {
      dispatch(setScormToInitialValues());
      saveToLocalStorage(OPENED_SCORM_DETAILS, {});
    }
  };

  return (
    <Card key={material.file_name}>
      <CardBody>
        <P>{material.file_name}</P>
        <Button
          onClick={() => onPlayFile(material.file_url)}
          color="primary"
          className="d-flex align-items-center gap-1"
        >
          <MUIIcons size={24} iconName="PlayCircleFilledWhiteOutlined" />
          {Play}
        </Button>
      </CardBody>
    </Card>
  );
};

PlayCourseMaterial.propTypes = {
  material: PropTypes.object,
  courseId: PropTypes.number,
};

export default PlayCourseMaterial;
