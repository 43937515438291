import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H3 } from "../../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../../Api/constants";
import DateRangeDisplay from "../../../../../CommonElements/DateRangeDisplay";
import Divider from "../../../../../CommonElements/Divider";
import StatusDisplay from "../../../../../CommonElements/StatusDisplay";
import FeedbackAnalysis from "../../../../../container/FeedbackAnalysisCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import { getFeedbackByCourseFeedbackId } from "../../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Instructor,
  Location,
  RatingValues,
  Room,
} from "../../../../../utils/Constant";
import { learnerRatingColumns } from "../../../../../utils/helper/helper";

const RatingSummary = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    scheduledId,
    participant_id,
    courseName,
    startDate,
    endDate,
    locationName,
    classRoomName,
    instructorId,
    statusData,
    rm,
  } = location.state || {};
  const { feedbackDetail } = useAppSelector(state => state.feedback);
  const { usersList } = useAppSelector(state => state.user);
  const [userMap, setUserMap] = useState({});

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(
      getFeedbackByCourseFeedbackId({
        course_feedback_id: scheduledId,
        participant_id: participant_id,
      })
    );
  }, [dispatch, scheduledId, participant_id]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const participantId = feedbackDetail?.feedback?.participant_id;
  const participant = userMap[participantId] || {};

  const newStatusData = feedbackDetail?.feedback?.overall_category?.map(
    category => ({
      label: category.category,
      value: `${category.percentage}%`,
    })
  );

  const userDetails = {
    id: participant.id,
    name: participant.user_name,
    empId: participant.employee_code,
    mobile: participant.phone,
    participant_id: feedbackDetail?.feedback?.participant_id?.toString(),
  };

  return (
    <div className="page-body">
      <Card className="p-2">
        <CardBody>
          <H3 className="fw-bold">{courseName}</H3>
          <Row className="mt-3">
            <Col lg="4" className="mt-1">
              <DateRangeDisplay startDate={startDate} endDate={endDate} />
            </Col>
            <Col className="d-flex gap-5 mt-3">
              <StatusDisplay label={Location} number={locationName} />
              <StatusDisplay label={Room} number={classRoomName} />
              <StatusDisplay
                label={Instructor}
                number={userMap[instructorId]?.user_name || ""}
              />
            </Col>
          </Row>
          <Divider />
          <div className="pt-3">
            <FeedbackAnalysis
              userDetails={userDetails}
              statusItems={rm ? newStatusData : statusData}
            />
          </div>
          <Divider />
          <div className="mt-2">{RatingValues}</div>
          <DataTable
            className="nomination-table"
            columns={learnerRatingColumns}
            data={
              feedbackDetail?.feedback?.overall_category?.[0]
                ?.question_responses || []
            }
            pagination
            fixedHeader
            striped
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default RatingSummary;
