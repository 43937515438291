import { useEffect, useState } from "react";

import UserProfileCard from "../../container/UserProfileCard";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getLoggedInUserDetails } from "../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../Service";
import {
  Contact,
  CWSAndCatRecId,
  Grade,
  Location,
  Mail,
  RMContactNumber,
  RMEmail,
  RMName,
  SBU,
} from "../../utils/Constant";
import { currentUserId } from "../../utils/helper/permission";
import { UserProfileInformationDetails } from "../../utils/helper/propTypes";

const ProfileDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const loggedInUserId = currentUserId();
  const [userProfileData, setUserProfileData] = useState(null);

  const { loggedInUserDetailsList } = useAppSelector(state => state.user);

  useEffect(() => {
    dispatch(getLoggedInUserDetails({ id: loggedInUserId }));
  }, []);

  useEffect(() => {
    const UserDetails = loggedInUserDetailsList?.users?.find(
      user => user.id === loggedInUserId
    );
    if (UserDetails) {
      const mapUserToProfileDetails = (
        user: any
      ): UserProfileInformationDetails => ({
        userProfileUrl: dynamicImage("dashboard-4/user.png"),
        name: user.user_name,
        designation: user.designation ? user.designation : "-",
        employee_code: user.employee_code ? user.employee_code : "-",
        userDetails: [
          {
            icon: "BadgeOutlined",
            title: CWSAndCatRecId,
            detail: user?.cws_cat_rec ? user?.cws_cat_rec : "-",
          },
          {
            icon: "SchoolOutlined",
            title: Grade,
            detail: user?.grade ? user?.grade : "-",
          },
          {
            icon: "PinOutlined",
            title: SBU,
            detail: user?.sbu ? user?.sbu : "-",
          },
          {
            icon: "PersonOutline",
            title: RMName,
            detail: user.mapped_rm?.length > 0 ? user.mapped_rm[0].name : "-",
          },
          {
            icon: "PhoneOutlined",
            title: Contact,
            detail: user?.phone ? user?.phone : "-",
          },
          {
            icon: "EmailOutlined",
            title: Mail,
            detail: user.email ? user.email : "-",
          },
          {
            icon: "EmailOutlined",
            title: RMEmail,
            detail:
              user.mapped_rm?.length > 0 && user.mapped_rm[0].email
                ? user.mapped_rm[0].email
                : "-",
          },
          {
            icon: "PhoneOutlined",
            title: RMContactNumber,
            detail: user?.mapped_rm?.[0]?.rm_phone || "-",
          },
          {
            icon: "LocationOnOutlined",
            title: Location,
            detail:
              [user?.office_city || "", user?.office_state || ""]
                .filter(Boolean)
                .join(", ") || "-",
          },
        ],
      });
      setUserProfileData(mapUserToProfileDetails(UserDetails));
    }
  }, [loggedInUserDetailsList]);

  return (
    <div className="page-body page-body-margin">
      <UserProfileCard userData={userProfileData || {}} />
    </div>
  );
};

export default ProfileDetails;
