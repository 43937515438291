import React, { useState } from "react";
import { Accordion } from "reactstrap";

import CommonAccordionItem from "./AccordionItem";

interface AccordionItemProps {
  id: string;
  accordionItemClass?: string;
  accordionHeaderClass?: string;
  courseTime?: string;
  spanClass?: string;
  accordionHeading: React.ReactNode;
  icon?: boolean;
  bodyText: React.ReactNode;
  showCheckbox?: boolean;
  showDropdown?: boolean;
  questions?: number;
}

interface SimpleAccordionProps {
  accordionList: AccordionItemProps[];
  showCheckbox?: boolean;
  showTimer?: boolean;
  onCheckBoxChange?: (id: string) => void;
  onAccordionClick?: (id: string) => void;
  selectedIds?: any[];
  showDropdown?: boolean;
  showNoOfQuestions?: boolean;
  noOfQuestions?: number;
}

const SimpleAccordion: React.FC<SimpleAccordionProps> = ({
  accordionList,
  showCheckbox = false,
  onCheckBoxChange,
  selectedIds,
  showTimer,
  showDropdown,
  showNoOfQuestions,
  onAccordionClick,
}) => {
  const [open, setOpen] = useState<string | undefined>("0");

  const toggle = (id: string) =>
    open === id ? setOpen(undefined) : setOpen(id);

  return (
    <Accordion open={open} toggle={toggle} className="dark-accordion">
      {accordionList?.map(data => (
        <div className="mb-4" key={data.id}>
          <CommonAccordionItem
            {...data}
            onCheckBoxChange={onCheckBoxChange}
            selectedIds={selectedIds}
            showCheckbox={showCheckbox}
            showTimer={showTimer}
            showDropdown={showDropdown}
            noOfQuestions={data?.questions}
            showNoOfQuestions={showNoOfQuestions}
            onAccordionClick={onAccordionClick}
          />
        </div>
      ))}
    </Accordion>
  );
};

export default SimpleAccordion;
