import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  GET,
  GET_ALL_USERS_REQUEST_URL,
  GET_LOGGED_IN_USERS_DETAIL,
  GET_USERS,
  GET_USERS_DETAIL,
  GET_USER_DETAILS_REQUEST_URL,
  POST,
  UPLOAD_FILE,
  UPLOAD_FILE_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface UsersData {
  usersList: UsersResponse;
  userDetailsList: UsersResponse;
  loggedInUserDetailsList: UsersResponse;
  loading: boolean;
  error: string | null;
}

const initialState: UsersData = {
  usersList: { users: [] },
  userDetailsList: { users: [] },
  loggedInUserDetailsList: { users: [] },
  loading: false,
  error: null,
};

export const getUsersList = createAsyncThunk(
  GET_USERS,
  async ({ role }: { role: string[] }) => {
    const response = await apiRequest(
      GET,
      GET_ALL_USERS_REQUEST_URL(role.toString())
    );
    return response.data;
  }
);

export const getLoggedInUserDetails = createAsyncThunk(
  GET_USERS_DETAIL,
  async ({ id }: { id: number[] }) => {
    const response = await apiRequest(GET, GET_USER_DETAILS_REQUEST_URL(id));
    return response.data;
  }
);

export const getUserDetailsList = createAsyncThunk(
  GET_LOGGED_IN_USERS_DETAIL,
  async ({ id }: { id: number[] }) => {
    const response = await apiRequest(GET, GET_USER_DETAILS_REQUEST_URL(id));
    return response.data;
  }
);

export const uploadFileApi = createAsyncThunk(
  UPLOAD_FILE,
  async ({ content }: { content }) => {
    const response = await apiRequest(POST, UPLOAD_FILE_URL(), content);
    return response;
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetailsToInitialState: state => {
      state.userDetailsList = initialState.userDetailsList;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadFileApi.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFileApi.fulfilled, state => {
        state.loading = false;
      })
      .addCase(uploadFileApi.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getUsersList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUsersList.fulfilled,
        (state, action: PayloadAction<UsersResponse>) => {
          state.loading = false;
          state.usersList = action.payload;
        }
      )
      .addCase(getUsersList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getUserDetailsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getUserDetailsList.fulfilled,
        (state, action: PayloadAction<UsersResponse>) => {
          state.loading = false;
          state.userDetailsList = action.payload;
        }
      )
      .addCase(getUserDetailsList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getLoggedInUserDetails.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getLoggedInUserDetails.fulfilled,
        (state, action: PayloadAction<UsersResponse>) => {
          state.loading = false;
          state.loggedInUserDetailsList = action.payload;
        }
      )
      .addCase(getLoggedInUserDetails.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setUserDetailsToInitialState } = UserSlice.actions;
export default UserSlice.reducer;
