import { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { Btn, H3 } from "../../../AbstractElements";
import Divider from "../../../CommonElements/Divider";
import FixedFooter from "../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../CommonElements/MultiSelectDropdown";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCurriculumList } from "../../../ReduxToolkit/Reducers/CurriculumSlice";
import {
  fetchLoadFactorData,
  modifyLoadFactorData,
} from "../../../ReduxToolkit/Reducers/LoadFactorSlice";
import {
  getIndustryJobRolesList,
  getIndustryList,
  getProficiencyList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Curriculum,
  FinancialYearToolTipMessage,
  Industry,
  IndustryToolTipMessage,
  JobRole,
  LoadFactorCurriculumToolTipMessage,
  LoadFactorJobRoleToolTipMessage,
  MasterDataProficiencyToolTipMessage,
  ModifyPlan,
  ModifyPlanError,
  PlanExceededError,
  ProficiencyLevel,
  Save,
  Select,
  Year,
} from "../../../utils/Constant";
import {
  generateLoadFactorFiscalYears,
  modifyPlansColumns,
  showToast,
} from "../../../utils/helper/helper";

const ModifyPlans = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const fiscalYears = useMemo(() => generateLoadFactorFiscalYears(0, 10), []);
  const { year } = location.state || {};
  const [dropVals, setDropVals] = useState({
    industry: [],
    job_role: [],
    curriculum: [],
    proficiency: [],
    year: fiscalYears[year],
  });
  const [tableData, setTableData] = useState([]);
  const { industryList, roleOnFilter, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { curriculumList } = useAppSelector(state => state.curriculum);
  const { loadFactorList } = useAppSelector(state => state.loadFactor);

  useEffect(() => {
    dispatch(getIndustryList());
    dispatch(getProficiencyList());
    fetchLoadFactor();
  }, []);

  useEffect(() => {
    if (dropVals.industry.length > 0) {
      const industryIds = dropVals.industry.map(item => item.name);
      dispatch(getIndustryJobRolesList({ ind_id: industryIds?.toString() }));
    }
  }, [dropVals.industry, dispatch]);

  useEffect(() => {
    if (dropVals.job_role.length > 0) {
      const jobRoleIds = dropVals.job_role.map(item => item.name);
      dispatch(
        getCurriculumList({
          status: ["published"],
          roles: jobRoleIds?.toString(),
        })
      );
    }
  }, [dropVals.job_role, dispatch]);

  useEffect(() => {
    if (loadFactorList?.load_factor) {
      const apiData = loadFactorList.load_factor.map(row => ({
        ...row,
        planned_q1: row.planned_q1,
        planned_q2: row.planned_q2,
        planned_q3: row.planned_q3,
        planned_q4: row.planned_q4,
      }));
      setTableData(apiData);
    }
  }, [loadFactorList]);

  const fetchLoadFactor = async () => {
    const filters: any = {
      industry_id: dropVals.industry.map(item => item.name),
      job_role_id: dropVals.job_role.map(item => item.name),
      curriculum_id: dropVals.curriculum.map(item => item.name),
      proficiency: dropVals.proficiency.map(item => item.name),
      year: fiscalYears[year],
    };

    dispatch(fetchLoadFactorData(filters));
  };

  useEffect(() => {
    fetchLoadFactor();
  }, [dropVals]);

  const dropdownOptions = useMemo(() => {
    const mapOptions = (data, labelKey, valueKey) =>
      data?.map(item => ({
        name: item[valueKey]?.toString(),
        value: item[labelKey],
        label: item[labelKey],
      })) || [];

    return {
      industryOptions: mapOptions(industryList?.industry, "name", "id"),
      jobRoleOptions: mapOptions(roleOnFilter?.job_role, "name", "id"),
      curriculumOptions: mapOptions(curriculumList?.curriculum, "name", "id"),
      proficiencyOptions: mapOptions(
        proficiencyList?.proficiency,
        "level_name",
        "id"
      ),
      fiscalYearOptions: fiscalYears.map((year, index) => {
        return {
          name: index.toString(),
          value: year,
          label: year,
        };
      }),
    };
  }, [
    industryList,
    roleOnFilter,
    curriculumList,
    proficiencyList,
    fiscalYears,
  ]);

  const handleMultiSelectChange = (field, selectedIds) => {
    let updatedValues = [];
    let dataList;
    switch (field) {
      case "industry":
        dataList = industryList;
        break;
      case "job_role":
        dataList = roleOnFilter;
        break;
      case "curriculum":
        dataList = curriculumList;
        break;
      case "proficiency":
        dataList = proficiencyList;
        break;
      case "year":
        dataList = fiscalYears;
        setDropVals(prevState => ({
          ...prevState,
          [field]: selectedIds,
        }));
        return;
      default:
        return;
    }

    updatedValues = selectedIds
      ?.map(id => {
        const foundItem = dataList[field]?.find(
          item => item?.id?.toString() === id
        );
        return foundItem
          ? {
              name: foundItem.id?.toString(),
              value:
                field === "proficiency" ? foundItem.level_name : foundItem.name,
              label:
                field === "proficiency" ? foundItem.level_name : foundItem.name,
            }
          : null;
      })
      .filter(Boolean);

    setDropVals(prevState => ({
      ...prevState,
      [field]: updatedValues,
    }));
  };

  const handleInputChange = (e, rowId, field) => {
    const { value } = e.target;
    let numericValue = parseInt(value, 10) || 0;
    numericValue = Math.min(numericValue, 1000);

    setTableData(prevData =>
      prevData.map(row => {
        if (row.id !== rowId) return row;
        return { ...row, [field]: numericValue };
      })
    );
  };

  const handleSave = async () => {
    let invalidRow = null;
    let exceededRow = null;

    for (const row of tableData) {
      const totalPlanned =
        (parseInt(row.planned_q1) || 0) +
        (parseInt(row.planned_q2) || 0) +
        (parseInt(row.planned_q3) || 0) +
        (parseInt(row.planned_q4) || 0);

      if (
        row.planned_q1 === "" ||
        row.planned_q1 === undefined ||
        row.planned_q2 === "" ||
        row.planned_q2 === undefined ||
        row.planned_q3 === "" ||
        row.planned_q3 === undefined ||
        row.planned_q4 === "" ||
        row.planned_q4 === undefined
      ) {
        invalidRow = row;
        break;
      }

      if (totalPlanned > row.sessions_planned) {
        exceededRow = row;
        break;
      }
    }

    if (invalidRow) {
      showToast(ModifyPlanError, "error");
      return;
    }

    if (exceededRow) {
      showToast(PlanExceededError(exceededRow.course_name), "error");
      return;
    }

    const modifiedData = tableData.map(row => ({
      id: row.id,
      sessions_planned: row.sessions_planned,
      planned_q1: parseInt(row.planned_q1),
      planned_q2: parseInt(row.planned_q2),
      planned_q3: parseInt(row.planned_q3),
      planned_q4: parseInt(row.planned_q4),
    }));

    await dispatch(modifyLoadFactorData(modifiedData));
    await fetchLoadFactor();
    return navigate("/load-factor");
  };

  return (
    <div className="page-body pb-5">
      <Card>
        <CardBody>
          <H3 className="mt-2">{ModifyPlan}</H3>
          <Divider />
          <Row>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Industry}
                  tooltipText={IndustryToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("industry", values)
                  }
                  options={dropdownOptions.industryOptions}
                  placeholder={`${Select} ${Industry}`}
                  defaultSelected={dropVals.industry}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={JobRole}
                  tooltipText={LoadFactorJobRoleToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("job_role", values)
                  }
                  options={dropdownOptions.jobRoleOptions}
                  placeholder={`${Select} ${JobRole}`}
                  defaultSelected={dropVals.job_role}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Curriculum}
                  tooltipText={LoadFactorCurriculumToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("curriculum", values)
                  }
                  options={dropdownOptions.curriculumOptions}
                  placeholder={`${Select} ${Curriculum}`}
                  defaultSelected={dropVals.curriculum}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={ProficiencyLevel}
                  tooltipText={MasterDataProficiencyToolTipMessage}
                  important={false}
                />
                <MultiSelectDropdown
                  onChange={values =>
                    handleMultiSelectChange("proficiency", values)
                  }
                  options={dropdownOptions.proficiencyOptions}
                  placeholder={`${Select} ${ProficiencyLevel}`}
                  defaultSelected={dropVals.proficiency}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Year}
                  tooltipText={FinancialYearToolTipMessage}
                  important={false}
                />
                <Input
                  type="select"
                  value={year}
                  onChange={e => {
                    handleMultiSelectChange("year", e.target.value);
                  }}
                  name="assessment_type"
                  disabled
                >
                  {dropdownOptions?.fiscalYearOptions?.map(option => (
                    <option key={option.name} value={option.name}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <div className="mt-2">
            <DataTable
              columns={modifyPlansColumns(handleInputChange)}
              data={tableData}
              pagination
              fixedHeader
              striped
            />
          </div>
        </CardBody>
      </Card>
      <FixedFooter>
        <div className="wizard-footer d-flex gap-3 justify-content-end">
          <Btn color="primary" onClick={handleSave}>
            {Save}
          </Btn>
        </div>
      </FixedFooter>
    </div>
  );
};

export default ModifyPlans;
