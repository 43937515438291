import React, { useState } from "react";

import { H5, P, ToolTip } from "../../AbstractElements";
import { truncateText } from "../../utils/helper/helper";
import { DetailHeaderProps } from "../../utils/helper/propTypes";

const DetailHeaderContainer: React.FC<DetailHeaderProps> = ({
  assign,
  name,
  isToolTip,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<{
    [key: number]: boolean;
  }>({});

  const handleTooltip = (name: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  return (
    <div className="flex-grow-1">
      <P className="mb-3 text-gray">{assign}</P>
      {isToolTip ? (
        <div className="fw-bold">
          <span id="tooltip-header-name" className="text-truncate pointer">
            <H5 className="fw-bold">{truncateText(name, 10)}</H5>
          </span>
          <ToolTip
            placement="top"
            target="tooltip-header-name"
            isOpen={tooltipOpen[name] || false}
            toggle={() => handleTooltip(name)}
          >
            {name}
          </ToolTip>
        </div>
      ) : (
        <H5 className="fw-bold">{name}</H5>
      )}
    </div>
  );
};

export default DetailHeaderContainer;
