import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Input, Row } from "reactstrap";

import { Btn, H4, SecondaryButton } from "../../../../AbstractElements";
import { HRA, TA, TM } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  createLearningOutcome,
  getLearningOutcomeById,
} from "../../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  getDomainList,
  getProficiencyList,
  getCompetencyList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Add,
  Archive,
  AuditLogs,
  Competency,
  CompetencyToolTipMessage,
  Create,
  Domain,
  Edit,
  Enter,
  LearningOutcomes,
  LearningOutcomeToolTipMessage,
  LimitValidationMessage,
  MasterDataDomainToolTipMessage,
  PleaseEnter,
  PleaseSelect,
  Proficiency,
  ProficiencyToolTipMessage,
  Select,
  TeachingPoints,
  TeachingPointsLimitationMessage,
  TeachingPointsToolTipMessage,
  Update,
} from "../../../../utils/Constant";
import "./style.scss";
import { richTextSupportedItems } from "../../../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../../../utils/helper/localStorageutils";
import AuditLog from "../../../SGA/MapSga/AuditLog";

const LOForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id } = location.state || {};
  const { domainList, competencyList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const loginData = getFromLocalStorage(LOGGED_IN_USER);
  const { learningOutcomeListById } = useAppSelector(
    state => state.manageMasterData
  );

  const [selectedDomain, setSelectedDomain] = useState("");
  const [selectedCompetency, setSelectedCompetency] = useState("");
  const [selectedProficiency, setSelectedProficiency] = useState("");
  const [learningOutcomes, setLearningOutcomes] = useState("");
  const [teachingPoints, setTeachingPoints] = useState("");
  const [showActivityLog, setShowActivityLog] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    domain: "",
    competency: "",
    proficiency: "",
    learningOutcomes: "",
    teachingPoints: "",
  });

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getProficiencyList());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getLearningOutcomeById({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (learningOutcomeListById) {
      const { outcome, domain_id, competency_id, proficiency_id } =
        learningOutcomeListById;
      setTeachingPoints(
        learningOutcomeListById.function ? learningOutcomeListById.function : ""
      );
      setLearningOutcomes(outcome);
      setSelectedDomain(domain_id.toString());
      setSelectedCompetency(competency_id.toString());
      setSelectedProficiency(proficiency_id.toString());
    }
  }, [learningOutcomeListById, domainList]);

  useEffect(() => {
    if (selectedDomain) {
      dispatch(getCompetencyList({ domainId: selectedDomain }));
    }
  }, [selectedDomain]);

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const domainId = event.target.value;
    setSelectedDomain(domainId);
  };

  const handleCompetencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedCompetency(event.target.value);
  };

  const handleProficiencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedProficiency(event.target.value);
  };

  const handleLearningOutcomesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLearningOutcomes(event.target.value);
  };

  const validateForm = () => {
    let formValid = true;
    const newErrors = {
      domain: "",
      competency: "",
      proficiency: "",
      learningOutcomes: "",
      teachingPoints: "",
    };

    if (!selectedDomain) {
      newErrors.domain = `${PleaseSelect}  ${Domain}`;
      formValid = false;
    }
    if (!selectedCompetency) {
      newErrors.competency = `${PleaseSelect} ${Competency}`;
      formValid = false;
    }
    if (!selectedProficiency) {
      newErrors.proficiency = `${PleaseSelect} ${Proficiency}`;
      formValid = false;
    }
    if (!learningOutcomes) {
      newErrors.learningOutcomes = `${PleaseEnter} ${LearningOutcomes}`;
      formValid = false;
    } else if (learningOutcomes.length > 100) {
      newErrors.learningOutcomes = LimitValidationMessage;
    }
    if (!teachingPoints) {
      newErrors.teachingPoints = `${PleaseEnter} ${TeachingPoints}`;
      formValid = false;
    } else if (teachingPoints.length > 255) {
      newErrors.teachingPoints = TeachingPointsLimitationMessage;
    }

    setErrors(newErrors);
    return formValid;
  };

  const handleButtonClick = async status => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      competency_id: parseInt(selectedCompetency),
      proficiency_id: parseInt(selectedProficiency),
      outcome: learningOutcomes,
      function: teachingPoints,
      status: status,
    };

    if (id) {
      payload["id"] = id;
    }

    const response = await dispatch(
      createLearningOutcome({
        createLearningOutcomePayload: payload,
      })
    );
    if (response?.payload) {
      return navigate(
        `${process.env.PUBLIC_URL}/master-data/learning-outcome-list`
      );
    }
  };

  const handleActivityModal = () => {
    setShowActivityLog(prevState => !prevState);
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody>
          <H4 className="mt-3 mb-2">
            {id ? `${Edit} ${LearningOutcomes}` : `${Add} ${LearningOutcomes}`}
          </H4>
          <Divider />
          <Row>
            <Col>
              <LabelTooltip
                label={Domain}
                tooltipText={MasterDataDomainToolTipMessage}
                important
              />
              <Input
                type="select"
                placeholder={`${Select} ${Domain}`}
                value={selectedDomain}
                onChange={handleDomainChange}
              >
                <option value="">
                  {Select} {Domain}
                </option>
                {domainList?.domain?.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.domain_name}
                  </option>
                ))}
              </Input>
              {errors.domain && (
                <div className="text-danger">{errors.domain}</div>
              )}
            </Col>
            <Col>
              <LabelTooltip
                label={Competency}
                tooltipText={CompetencyToolTipMessage}
                important
              />
              <Input
                type="select"
                placeholder={`${Select} ${Competency}`}
                value={selectedCompetency}
                onChange={handleCompetencyChange}
              >
                <option value="">
                  {Select} {Competency}
                </option>
                {competencyList?.competency?.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.competency_name}
                  </option>
                ))}
              </Input>
              {errors.competency && (
                <div className="text-danger">{errors.competency}</div>
              )}
            </Col>
            <Col>
              <LabelTooltip
                label={Proficiency}
                tooltipText={ProficiencyToolTipMessage}
                important
              />
              <Input
                type="select"
                placeholder={`${Select} ${Proficiency}`}
                value={selectedProficiency}
                onChange={handleProficiencyChange}
              >
                <option value="">
                  {Select} {Proficiency}
                </option>
                {proficiencyList?.proficiency?.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.level_name}
                  </option>
                ))}
              </Input>
              {errors.proficiency && (
                <div className="text-danger">{errors.proficiency}</div>
              )}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={12}>
              <LabelTooltip
                label={LearningOutcomes}
                tooltipText={LearningOutcomeToolTipMessage}
                important
              />
              <Input
                placeholder={`${Enter} ${LearningOutcomes}`}
                type="textarea"
                value={learningOutcomes}
                onChange={handleLearningOutcomesChange}
              />
              {errors.learningOutcomes && (
                <div className="text-danger">{errors.learningOutcomes}</div>
              )}
            </Col>
          </Row>

          <Row className="mt-3 mb-3">
            <Col lg={12}>
              <LabelTooltip
                label={TeachingPoints}
                tooltipText={TeachingPointsToolTipMessage}
                important
              />
              <CKEditor
                editor={ClassicEditor}
                data={teachingPoints}
                onChange={(_event, editor) => {
                  const data = editor.getData();
                  setTeachingPoints(data);
                }}
                config={{
                  toolbar: {
                    items: richTextSupportedItems,
                  },
                }}
              />
              {errors.teachingPoints && (
                <div className="text-danger">{errors.teachingPoints}</div>
              )}
            </Col>
          </Row>

          <FixedFooter alignFirstButtonStart={true}>
            {id && (
              <>
                <SecondaryButton onClick={handleActivityModal}>
                  {AuditLogs}
                </SecondaryButton>

                {(loginData?.currentRole === TM ||
                  loginData?.currentRole === TA ||
                  loginData?.currentRole === HRA) && (
                  <Btn
                    className="border-0 bg-transparent pointer"
                    onClick={() => handleButtonClick("archived")}
                  >
                    <H4 className="primary-text-color fw-light">{Archive}</H4>
                  </Btn>
                )}
              </>
            )}
            <Btn onClick={() => handleButtonClick("published")} color="primary">
              {id ? Update : Create}
            </Btn>
          </FixedFooter>
        </CardBody>
      </Card>
      <AuditLog
        auditLogs={learningOutcomeListById?.audit_logs}
        handleActivityModal={handleActivityModal}
        showActivityLog={showActivityLog}
      />
    </div>
  );
};

export default LOForm;
