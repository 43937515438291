import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MUIIcons, LI } from "../../../../AbstractElements";
import { useAppContext } from "../../../../AppContext";
import { useAppDispatch } from "../../../../ReduxToolkit/Hooks";
import { logout } from "../../../../ReduxToolkit/Reducers/AuthenticationSlice";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
  removeAllLocalStorage,
} from "../../../../utils/helper/localStorageutils";

const ProfileBox = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setShowSwitchRoleModal } = useAppContext();
  const [profilesMessage, setProfilesMessage] = useState([]);

  useEffect(() => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    const constructedOptions = [
      {
        id: 1,
        name: "Profile",
        icon: "AccountCircleOutlined",
        link: `${process.env.PUBLIC_URL}/profile`,
      },
    ];

    if (loginData?.mappedRoles?.length > 1) {
      const switchRoleOption = {
        id: 2,
        name: "Switch Role",
        icon: "SyncAltOutlined",
        link: null,
      };
      constructedOptions.push(switchRoleOption);
    }
    constructedOptions.push({
      id: 3,
      name: "Log Out",
      icon: "LogoutOutlined",
      link: `${process.env.PUBLIC_URL}/login`,
    });

    setProfilesMessage(constructedOptions);
  }, []);

  const handleClick = async (name: string, link: string) => {
    if (name === "Log Out") {
      const response = await dispatch(logout());
      if (response?.payload) {
        removeAllLocalStorage();
        navigate(link);
      }
    } else if (name === "Switch Role") {
      setShowSwitchRoleModal(true);
    } else navigate(link);
  };
  return (
    <div>
      {profilesMessage?.map(data => (
        <LI className="p-2" key={data.id}>
          <div
            className="d-flex align-items-center dropdown-item-text"
            onClick={() => handleClick(data.name, data.link)}
          >
            <MUIIcons
              className="dropdown-item-icon"
              size={24}
              iconName={data.icon}
            />
            <span>{data.name}</span>
          </div>
        </LI>
      ))}
    </div>
  );
};

export default ProfileBox;
