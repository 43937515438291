export const saveToSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = (key: string): any => {
  const value = sessionStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

export const removeFromSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const removeAllSessionStorage = () => {
  sessionStorage.clear();
};

export const REDIRECT_URL = "REDIRECT_URL";
