import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";

import { H1, H2 } from "../../../../AbstractElements";
import { WILL_BE_UPLOADED } from "../../../../Api/constants";
import FileHandler from "../../../../CommonElements/FileHandler";

import "./style.scss";

const MultiChoise = ({
  questionId,
  questionNumber,
  title,
  options,
  handleChange,
  isChecked,
  mediaUrl,
}) => {
  return (
    <div className="multi-choise mt-2">
      <H1 className="multi-choise__question">{questionNumber}</H1>
      <H2 className="multi-choise__title">{title}</H2>
      {mediaUrl && mediaUrl !== WILL_BE_UPLOADED && (
        <FileHandler showPreview={true} mediaUrl={mediaUrl} />
      )}
      {options?.map(option => (
        <div key={option.option_id} className="multi-choise__checkbox">
          <Input
            onChange={e => handleChange(e, option.option_id, questionId)}
            name="is_reattempt"
            id={option.option_id}
            type="checkbox"
            checked={isChecked(option.option_id, questionId)}
            className="primary-checkbox"
          />
          <Label
            className="multi-choise__checkbox__label"
            htmlFor={option.option_id}
            check
          >
            {option.option_text}
          </Label>
        </div>
      ))}
    </div>
  );
};

MultiChoise.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  questionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      option_text: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
  mediaUrl: PropTypes.string,
};

export default MultiChoise;
