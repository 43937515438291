import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "reactstrap";

import MUIIcons from "../MUIIcon/MUIIcons";

interface IconListProps {
  items: IconWithTextData[];
}

interface IconWithTextData {
  id: number;
  iconName: any;
  value: string | number;
  columnSize: string;
}

const IconWithValue: React.FC<IconListProps> = ({ items }) => {
  return (
    <Row className="d-flex justify-content-start">
      {items.map(item => (
        <Col
          lg={item.columnSize}
          key={item.id}
          className="d-flex align-items-center justify-content-start mb-2"
        >
          <MUIIcons
            className="text-gray me-2"
            iconName={item.iconName}
            size={20}
          />
          <span className="fw-bold">{item.value || "-"}</span>
        </Col>
      ))}
    </Row>
  );
};

IconWithValue.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      iconName: PropTypes.any.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      columnSize: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default IconWithValue;
