import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_CURRICULUM,
  CREATE_CURRICULUM_LIST,
  GET,
  GET_CURRICULUM_LIST,
  GET_CURRICULUM_LIST_API,
  POST,
  DELETE_CURRICULUM_COURSE,
  DELETE,
  GET_CURRICULUM_BY_ID_API,
  GET_CURRICULUM_BY_ID,
  PUT,
  UNMAP_CURRICULUM,
  UNMAP_CURRICULUM_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  CurriculumListResponse,
  CurriculumDetailsPayload,
  DeleteCoursePayload,
  CurriculumResponse,
} from "../../Api/entities/CurriculumEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface CuriculumInterface {
  curriculumList: CurriculumListResponse;
  loading: boolean;
  error: string | null;
  curriculum: CurriculumResponse;
}

const initialState: CuriculumInterface = {
  curriculumList: { curriculum: [], domain: {}, duration: {} },
  loading: false,
  error: null,
  curriculum: {
    role_id: null,
    level: [],
    id: null,
    name: "",
    is_special_curriculum: null,
    publish_at: "",
    version: [],
    domain: [],
    status: "",
  },
};

export const getCurriculumList = createAsyncThunk(
  GET_CURRICULUM_LIST,
  async ({
    status,
    curriculum_type,
    roles,
  }: {
    status: string[];
    curriculum_type?: object;
    roles?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_CURRICULUM_LIST_API(status.toString(), curriculum_type, roles)
    );
    return response.data;
  }
);

export const createCurriculumContent = createAsyncThunk(
  CREATE_CURRICULUM_LIST,
  async ({
    curriculumContent,
  }: {
    curriculumContent: CurriculumDetailsPayload;
  }) => {
    const response = await apiRequest(
      curriculumContent?.id ? PUT : POST,
      CREATE_CURRICULUM(),
      curriculumContent
    );
    return response;
  }
);

export const deleteCurriculumCourse = createAsyncThunk(
  DELETE_CURRICULUM_COURSE,
  async ({ curriculumContent }: { curriculumContent: DeleteCoursePayload }) => {
    const response = await apiRequest(
      DELETE,
      CREATE_CURRICULUM(),
      curriculumContent
    );
    return response;
  }
);

export const getCurriculumById = createAsyncThunk(
  GET_CURRICULUM_BY_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_CURRICULUM_BY_ID_API(id));
    return response.data;
  }
);

export const unMapCurriculum = createAsyncThunk(
  UNMAP_CURRICULUM,
  async ({ payload }: { payload: any }) => {
    const response = await apiRequest(POST, UNMAP_CURRICULUM_URL(), payload);
    return response;
  }
);

const CurriculumSlice = createSlice({
  name: "curriculum",
  reducers: {},
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getCurriculumList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCurriculumList.fulfilled,
        (state, action: PayloadAction<CurriculumListResponse>) => {
          state.loading = false;
          state.curriculumList = action.payload;
        }
      )
      .addCase(getCurriculumList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createCurriculumContent.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCurriculumContent.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createCurriculumContent.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteCurriculumCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCurriculumCourse.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteCurriculumCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(unMapCurriculum.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unMapCurriculum.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(unMapCurriculum.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCurriculumById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCurriculumById.fulfilled,
        (state, action: PayloadAction<CurriculumResponse>) => {
          state.loading = false;
          state.curriculum = action.payload;
        }
      )
      .addCase(getCurriculumById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export default CurriculumSlice.reducer;
