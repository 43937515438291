import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, H2, MUIIcons } from "../../../AbstractElements";
import {
  courseTypeMap,
  EXTERNAL_LEARNER,
  EXTERNAL_TRAINER,
  WBT,
  YtsStatus,
} from "../../../Api/constants";
import MapCurriculumModal from "../../../CommonElements/MapCurriculumModal";
import CommonModal from "../../../CommonElements/Modal";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { unMapCurriculum } from "../../../ReduxToolkit/Reducers/CurriculumSlice";
import {
  getCourseByUserCurriculumId,
  getMappedCurriculumList,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  getDomainList,
  getProficiencyList,
  getRolesList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Add,
  Confirm,
  Curriculum,
  Domain,
  NoDataText,
  UnlinkCurriculum,
  UnlinkCurriculumMessage,
  YouDoNotHaveAccess,
} from "../../../utils/Constant";
import {
  assessmentStatus,
  mapListToOptions,
  showToast,
  trainingHistoryColumns,
} from "../../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  getFromLocalStorage,
} from "../../../utils/helper/localStorageutils";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import "./style.scss";

const ILPCurriculum = ({ userId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [activeCurriculum, setActiveCurriculum] = useState(null);
  const [isMapCurriculumModalOpen, setIsMapCurriculumModalOpen] =
    useState(false);
  const [domainListData, setDomainListData] = useState([]);
  const [roleListData, setRoleListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const { mappedCurriculum, mappedCurriculumUserCourse } = useAppSelector(
    state => state.learningPlan
  );
  const { domainList, roleList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { loading } = useAppSelector(state => state.learningPlan);

  useEffect(() => {
    dispatch(getMappedCurriculumList({ userId: userId }));
    dispatch(getRolesList({}));
    dispatch(getDomainList());
    dispatch(getProficiencyList());
  }, []);

  useEffect(() => {
    if (activeCurriculum?.userCurriculumId && activeCurriculum?.activeLevel) {
      dispatch(
        getCourseByUserCurriculumId({
          userCurriculumId: activeCurriculum.userCurriculumId,
        })
      );
    }
  }, [activeCurriculum?.userCurriculumId]);

  const getMappedList = () => {
    dispatch(getMappedCurriculumList({ userId: userId }));
  };

  useEffect(() => {
    const options = mapListToOptions(domainList?.domain, "domain_name", "id");
    setDomainListData(options);
  }, [domainList]);

  useEffect(() => {
    if (roleList) {
      const options = mapListToOptions(roleList.job_role, "name", "id");
      setRoleListData(options);
    }
  }, [roleList]);

  useEffect(() => {
    if (mappedCurriculum?.curriculum?.length > 0) {
      const currentCurriculum = mappedCurriculum?.curriculum[0];
      setActiveCurriculum({
        userCurriculumId: currentCurriculum?.user_curriculum_id,
        activeLevel: currentCurriculum?.level_ids?.[0],
        id: currentCurriculum.id,
      });
    }
  }, [mappedCurriculum]);

  const findLevel = id => {
    return proficiencyList?.proficiency?.find(level => level.id === id);
  };

  const mapTrainingHistoryToLearningPlanCardData = () => {
    const mappedCourse =
      mappedCurriculumUserCourse && activeCurriculum?.activeLevel
        ? mappedCurriculumUserCourse[activeCurriculum.activeLevel]
        : [];
    return mappedCourse?.map(course => {
      return {
        id: course?.id,
        courseId: course?.course_id?.toString(),
        status:
          course.status === assessmentStatus.mapped &&
          course?.course_type === WBT
            ? YtsStatus
            : course.status,
        badgeTextColor: true,
        courseName: course.course_name,
        completedOn: course?.completed_on,
        courseType: courseTypeMap[course?.course_type],
        knowledgeScore: course?.knowledge_percentage,
        skillScore: course?.skill_percentage,
      };
    });
  };

  const onRowClick = item => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);

    if ([EXTERNAL_LEARNER, EXTERNAL_TRAINER].includes(loginData.currentRole)) {
      return showToast(YouDoNotHaveAccess, "error");
    }

    return navigate(
      `/course-catalog/course-details?course_id=${item.courseId}`
    );
  };

  const renderCourses = () => {
    if (loading) {
      return null;
    }

    return (
      <DataTable
        columns={[
          ...trainingHistoryColumns,
          {
            name: Domain,
            selector: () =>
              mappedCurriculum?.domain[activeCurriculum?.id]
                ?.map(item => item.name)
                ?.join(", ") || "-",
            minWidth: "8rem",
            sortable: true,
          },
        ]}
        data={mapTrainingHistoryToLearningPlanCardData()}
        pagination
        fixedHeader
        striped
        onRowClicked={onRowClick}
      />
    );
  };

  const handleLevelClick = (userCurriculumId, activeLevel, id) => {
    setActiveCurriculum({
      userCurriculumId,
      activeLevel,
      id,
    });
  };

  const mapCurriculumModalToggle = isMapped => {
    setIsMapCurriculumModalOpen(!isMapCurriculumModalOpen);
    if (isMapped === true) {
      getMappedList();
    }
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleUnlinkConfirm = async () => {
    const payload = {
      type: "curriculum",
      user_curriculum_id: Number(selectedId) || null,
    };
    await dispatch(unMapCurriculum({ payload: payload }));
    getMappedList();
    toggleModal();
  };

  const onUnlinkClick = id => {
    setSelectedId(id);
    toggleModal();
  };

  return (
    <div className="ilp-curriculum">
      <CommonModal
        sizeTitle={UnlinkCurriculum}
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleUnlinkConfirm}
        primaryBtnText={Confirm}
      >
        {UnlinkCurriculumMessage}
      </CommonModal>

      <Card className="p-2 mb-3">
        <CardBody className="custom-overview-curriculum">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <H2 className="text-black">{Curriculum}</H2>
            {userId &&
              hasPermissionToComponent("USER_MANAGEMENT.ADD_CURRICULUM") && (
                <>
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                    onClick={mapCurriculumModalToggle}
                  >
                    {Add}
                  </Btn>
                  {isMapCurriculumModalOpen && (
                    <MapCurriculumModal
                      isOpen={isMapCurriculumModalOpen}
                      toggle={mapCurriculumModalToggle}
                      domains={domainListData}
                      roles={roleListData}
                      userId={userId}
                    />
                  )}
                </>
              )}
          </div>
        </CardBody>
      </Card>

      {mappedCurriculum?.curriculum?.length > 0 && (
        <Row>
          <Col xl="3" className="box-col-6">
            <div className="md-sidebar">
              <Card>
                <CardBody>
                  {mappedCurriculum?.curriculum?.length > 0 &&
                    mappedCurriculum.curriculum.map(item => (
                      <div className="ilp-curriculum__list" key={item.id}>
                        <h5 className="primary-text-color py-2 mb-2 d-flex align-items-center gap-1">
                          {item.name}

                          {hasPermissionToComponent("UNMAP_CURRICULUM") && (
                            <button
                              className="border-0 bg-transparent pointer"
                              onClick={() =>
                                onUnlinkClick(item.user_curriculum_id)
                              }
                            >
                              <MUIIcons
                                className="pointer primary-icon-color"
                                iconName="LinkOffOutlined"
                                size={20}
                              />
                            </button>
                          )}
                        </h5>
                        <ul>
                          {item.level_ids.map(data => (
                            <li key={data}>
                              <button
                                className={`w-100 border-0 bg-white pointer p-2 text-start ${
                                  data === activeCurriculum?.activeLevel &&
                                  activeCurriculum.userCurriculumId ===
                                    item.user_curriculum_id
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleLevelClick(
                                    item.user_curriculum_id,
                                    data,
                                    item.id
                                  )
                                }
                              >
                                <span className="title">
                                  {findLevel(data)?.level_name}
                                </span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col xl="9">{renderCourses()}</Col>
        </Row>
      )}

      {mappedCurriculum?.curriculum?.length === 0 && !loading && (
        <NoData svg={"empty-folder-icon"} title={NoDataText} showCard={false} />
      )}
    </div>
  );
};

ILPCurriculum.propTypes = {
  userId: PropTypes.number,
};

export default ILPCurriculum;
