import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { ALL_USERS_STATUS } from "../../../../Api/constants";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import LOAccordion from "../../../../CommonElements/LOAccordion";
import MultiSelectDropdown from "../../../../CommonElements/MultiSelectDropdown";
import NoData from "../../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllLearningOutcomeList } from "../../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  getCompetencyList,
  getDomainList,
  getProficiencyList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Competency,
  CompetencyToolTipMessage,
  Domain,
  MasterDataDomainToolTipMessage,
  NoDataText,
  Proficiency,
  ProficiencyToolTipMessage,
  Select,
} from "../../../../utils/Constant";

const LOPublished = ({ searchQuery }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { domainList, competencyList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { usersList } = useAppSelector(state => state.user);
  const { learningOutcomeList } = useAppSelector(
    state => state.manageMasterData
  );

  const [domainListData, setDomainListData] = useState([]);
  const [competencyListData, setCompetencyListData] = useState([]);
  const [proficiencyListData, setProficiencyListData] = useState([]);
  const [dropdownValues, setDropdownValues] = useState({
    domain: "",
    competency: "",
    proficiency: "",
  });
  const [selectedDomainIds, setSelectedDomainIds] = useState([]);
  const [selectedCompetencyIds, setSelectedCompetencyIds] = useState([]);
  const [selectedLevelIds, setSelectedLevelIds] = useState([]);
  const [accordionData, setAccordionData] = useState([]);
  const [userMap, setUserMap] = useState(null);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, []);

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getProficiencyList());
  }, [dispatch]);

  function getUniqueArrayFromString(inputString) {
    const arrayFromString = inputString.split(",");

    const uniqueArray = [...new Set(arrayFromString)];

    return uniqueArray;
  }

  useEffect(() => {
    if (
      dropdownValues.domain.length > 0 &&
      dropdownValues.competency.length > 0 &&
      dropdownValues.proficiency.length > 0
    ) {
      dispatch(
        getAllLearningOutcomeList({
          status: "published",
          domainIds: dropdownValues.domain,
          competencyIds: dropdownValues.competency,
          proficiencyIds: dropdownValues.proficiency,
        })
      );
    } else {
      setAccordionData([]);
    }
  }, [dropdownValues]);

  useEffect(() => {
    const domains = [];
    const domainNames = [];
    const domainArray = getUniqueArrayFromString(dropdownValues?.domain);
    domainList?.domain?.forEach(element => {
      if (domainArray.includes(element.id.toString())) {
        domainNames.push({
          name: element.id.toString(),
          value: element.domain_name,
          label: element.domain_name,
        });
      }
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setSelectedDomainIds(domainNames);
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    const competencies = [];
    const competencyNames = [];
    const competencyArray = getUniqueArrayFromString(
      dropdownValues?.competency
    );
    competencyList?.competency?.forEach(element => {
      if (competencyArray.includes(element.id.toString())) {
        competencyNames.push({
          name: element.id.toString(),
          label: element.competency_name,
          value: element.competency_name,
        });
      }
      competencies.push({
        name: element.id.toString(),
        label: element.competency_name,
        value: element.competency_name,
      });
    });
    setSelectedCompetencyIds(competencyNames);
    setCompetencyListData(competencies);
  }, [competencyList]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const proficiencies = [];
    const levelNames = [];
    const levelArray = getUniqueArrayFromString(dropdownValues?.proficiency);
    proficiencyList?.proficiency?.forEach(element => {
      if (levelArray.includes(element.id.toString())) {
        levelNames.push({
          name: element.id.toString(),
          label: element.level_name,
          value: element.level_name,
        });
      }
      proficiencies.push({
        name: element.id.toString(),
        label: element.level_name,
        value: element.level_name,
      });
    });
    setSelectedLevelIds(levelNames);
    setProficiencyListData(proficiencies);
  }, [proficiencyList]);

  const getMultiDropdownDataData = (name: string, value: any[]) => {
    if (name === "domain") {
      const domainMap = [];
      const domainValues = [];
      domainListData.forEach((element: any) => {
        if (value.includes(element.name)) {
          domainValues.push(element.name?.toString());
          domainMap.push({
            name: element.name?.toString(),
            value: element.label,
            label: element.label,
          });
        }
      });
      setDropdownValues({
        ...dropdownValues,
        domain: domainValues?.toString(),
      });
      setSelectedDomainIds(domainMap);
    }
    if (name === "competency") {
      const competencyMap = [];
      const competencyValues = [];
      competencyListData.forEach((element: any) => {
        if (value.includes(element.name)) {
          competencyValues.push(element.name?.toString());
          competencyMap.push({
            name: element.name?.toString(),
            value: element.label,
            label: element.label,
          });
        }
      });
      setDropdownValues({
        ...dropdownValues,
        competency: competencyValues?.toString(),
      });
      setSelectedCompetencyIds(competencyMap);
    }

    if (name === "level") {
      const levelMap = [];
      const levelValues = [];
      proficiencyListData.forEach((element: any) => {
        if (value.includes(element.name)) {
          levelValues.push(element.name?.toString());
          levelMap.push({
            name: element.name?.toString(),
            value: element.label,
            label: element.label,
          });
        }
      });
      setDropdownValues({
        ...dropdownValues,
        proficiency: levelValues?.toString(),
      });
      setSelectedLevelIds(levelMap);
    }
  };

  useEffect(() => {
    const filteredPublishedLearningOutcomes =
      learningOutcomeList?.learning_outcomes.filter(outcome =>
        outcome.outcome.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const formattedAccordionData = filteredPublishedLearningOutcomes?.map(
      outcome => ({
        id: outcome.id.toString(),
        title: outcome.outcome,
        description: outcome.function,
        depedentBadges: [
          {
            id: 1,
            badge: getItemNameById(
              domainList.domain,
              outcome.domain_id,
              "domain_name"
            ),
          },
          {
            id: 2,
            badge: getItemNameById(
              competencyList.competency,
              outcome.competency_id,
              "competency_name"
            ),
          },
          {
            id: 3,
            badge: getItemNameById(
              proficiencyList.proficiency,
              outcome.proficiency_id,
              "level_name"
            ),
          },
        ],
        createdOn: outcome.created_at,
        modifiedOn: outcome.updated_at,
        modifiedBy: userMap ? userMap[outcome.updated_by]?.user_name : "-",
        createdBy: userMap ? userMap[outcome.created_by]?.user_name : "-",
      })
    );

    if (
      dropdownValues.domain.length === 0 &&
      dropdownValues.competency.length === 0 &&
      dropdownValues.proficiency.length === 0
    ) {
      setAccordionData([]);
    } else {
      setAccordionData(formattedAccordionData);
    }
  }, [learningOutcomeList, userMap, searchQuery]);

  const getItemNameById = (list: any[], id: number, key: string) => {
    const item = list.find(item => item.id === id);
    return item ? item[key] : "";
  };

  const handleEditClick = (
    id: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    navigate(
      `${process.env.PUBLIC_URL}/master-data/learning-outcome-list/edit-learning-outcome`,
      {
        state: { id },
      }
    );
  };

  const handleDomainChange = values => {
    setCompetencyListData([]);
    const domainArray = getUniqueArrayFromString(values?.toString());
    if (domainArray?.length > 0 && domainArray[0] != "")
      dispatch(getCompetencyList({ domainId: domainArray?.toString() }));
    getMultiDropdownDataData("domain", values);
  };

  return (
    <div>
      <Card>
        <CardBody className="mb-3">
          <Row>
            <Col>
              <LabelTooltip
                label={Domain}
                tooltipText={MasterDataDomainToolTipMessage}
                important
              />
              <MultiSelectDropdown
                onChange={handleDomainChange}
                options={domainListData}
                placeholder={`${Select} ${Domain}`}
                defaultSelected={selectedDomainIds}
              />
            </Col>
            <Col>
              <LabelTooltip
                label={Competency}
                tooltipText={CompetencyToolTipMessage}
                important
              />
              <MultiSelectDropdown
                onChange={values =>
                  getMultiDropdownDataData("competency", values)
                }
                options={competencyListData}
                placeholder={`${Select} ${Competency}`}
                defaultSelected={selectedCompetencyIds}
              />
            </Col>
            <Col>
              <LabelTooltip
                label={Proficiency}
                tooltipText={ProficiencyToolTipMessage}
                important
              />
              <MultiSelectDropdown
                onChange={values => getMultiDropdownDataData("level", values)}
                options={proficiencyListData}
                placeholder={`${Select} ${Proficiency}`}
                defaultSelected={selectedLevelIds}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      {accordionData?.length > 0 ? (
        accordionData.map(item => (
          <LOAccordion
            key={item.id}
            accordionItems={item}
            onEditClick={handleEditClick}
          />
        ))
      ) : (
        <NoData svg={"empty-folder-icon"} title={NoDataText} />
      )}
    </div>
  );
};

LOPublished.propTypes = {
  searchQuery: PropTypes.string,
};

export default LOPublished;
