import PropTypes from "prop-types";
import { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import { H4, P, Badges, Progressbar } from "../../AbstractElements";
import "./style.scss";
import { courseTypeMap } from "../../Api/constants";
import BorderTabs from "../../CommonElements/BorderTabs";
import { RemedialActionHeader } from "../../utils/Constant";
import { examRemedialActionTabs } from "../../utils/helper/helper";
import MaterialDetails from "../MaterialDetails";

const RemedialAction = ({ remedialActions }) => {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  const calculateCourseCounts = () => {
    let completedCount = 0;
    let totalCount = 0;

    remedialActions?.corseData?.forEach(module => {
      module?.courses?.forEach(course => {
        totalCount++;
        if (course.status === "completed" || course.status === "passed") {
          completedCount++;
        }
      });
    });
    const percentageCompleted =
      totalCount > 0 ? (completedCount / totalCount) * 100 : 0;

    return { completedCount, totalCount, percentageCompleted };
  };

  const counts = calculateCourseCounts();

  return (
    <div>
      <Card className="remedial-action mt-2">
        <CardHeader className="d-flex align-items-center gap-2">
          <H4>{RemedialActionHeader}</H4>
          {counts.totalCount > 0 && (
            <>
              <Progressbar
                className="w-25"
                color="dark"
                value={counts.percentageCompleted}
              />
              {`${counts.completedCount}/${counts.totalCount}`}
            </>
          )}
        </CardHeader>
        <CardBody className="mt-2">
          <BorderTabs
            tabs={examRemedialActionTabs}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />

          {remedialActions?.corseData?.map(action => (
            <>
              {activeTab === "1" && action.courses && (
                <>
                  <H4 className="mb-2">{action.module_name}</H4>
                  <Row>
                    {action.courses?.map(item => (
                      <Col md="4" xs="12" key={item.id}>
                        <Card>
                          <CardBody>
                            <div>
                              <Badges>{courseTypeMap[item.course_type]}</Badges>
                            </div>
                            <P>{item.course_name}</P>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </>
          ))}
          {activeTab === "2" && remedialActions?.literature && (
            <div className="mt-4">
              <MaterialDetails title={""} data={remedialActions?.literature} />
            </div>
          )}

          {activeTab === "3" && (
            <>
              {remedialActions?.remedialNote?.map(remedial => (
                <>
                  {remedial.remedialNote && (
                    <>
                      <H4 className="mb-2">{remedial.module_name}</H4>
                      <Card>
                        <CardBody>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: remedial.remedialNote,
                            }}
                          />
                        </CardBody>
                      </Card>
                    </>
                  )}
                </>
              ))}
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

RemedialAction.propTypes = {
  remedialActions: PropTypes.array.isRequired,
};

export default RemedialAction;
