import { subDays } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";

import { DefaultButton, H3 } from "../../../AbstractElements";
import { STATUS_200, TRAINER_STATUS } from "../../../Api/constants";
import Divider from "../../../CommonElements/Divider";
import FixedFooter from "../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../CommonElements/MultiSelectDropdown";
import TrainerDropdown from "../../../container/TrainerDropdown";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getCourseContent,
  getTrainersAvailability,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getScheduledCourseById,
  resetScheduleDataState,
  scheduleCourse,
  updateScheduledCourse,
} from "../../../ReduxToolkit/Reducers/LoadFactorSlice";
import {
  fetchCalendar,
  getClassRoomList,
  getLocationList,
  setClassRoomToInitial,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  AssistantTrainer,
  AssistantTrainerToolTipMessage,
  BatchMaximumSize,
  BatchMaximumSizeRequired,
  BatchMaxSizeToolTipMessage,
  BatchMinimumSize,
  BatchMinimumSizeRequired,
  BatchMinSizeToolTipMessage,
  ClassRoom,
  ClassroomRequired,
  ClassroomToolTipMessage,
  ClassroomUnavailable,
  DateError,
  EndDate,
  EndDateToolTipMessage,
  Enter,
  InvalidNominationEndDate,
  InvalidNominationStartDate,
  InvalidNominationStartDateWithEndDate,
  Location,
  LocationRequired,
  LocationToolTipMessage,
  MainTrainer,
  MainTrainerToolTipMessage,
  NominationBatchMaximumSize,
  NominationBatchMaximumSizeRequired,
  NominationBatchMaxSizeToolTipMessage,
  NominationBatchMinimumSize,
  NominationBatchMinimumSizeRequired,
  NominationBatchMinSizeToolTipMessage,
  NominationEndDate,
  NominationEndDateToolTipMessage,
  NominationStartDate,
  NominationStartDateToolTipMessage,
  PrimaryTrainerRequired,
  Publish,
  RMApproval,
  Select,
  SelfEnrollmentEnable,
  StartDate,
  StartDateError,
  StartDateToolTipMessage,
  TotalWaitlistCountRequired,
  TrainerUnavailable,
  WaitlistCountToolTipMessage,
  WaitlistEnable,
  WaitlistNo,
} from "../../../utils/Constant";
import {
  editCourseScheduleStatus,
  getEightWeeksFromToday,
  getNextDay,
  handleKeyDownOnNumericWithoutDecimal,
  isWeekday,
  isWithin56Days,
  showToast,
} from "../../../utils/helper/helper";

const defaultFormValues = {
  course_id: "",
  location_id: "",
  start_date: getEightWeeksFromToday(),
  end_date: null,
  classroom_id: [],
  batch_minimum_size: 0,
  batch_maximum_size: 0,
  nomination_start_date: null,
  nomination_end_date: null,
  nomination_batch_minimum_size: 0,
  nomination_batch_maximum_size: 0,
  waitlist_enable: false,
  rm_approval: false,
  self_enrollment: false,
  total_waitlist_count: 0,
  primary_trainer_id: [],
  assistant_trainer_ids: [],
  status: "",
};

const defaultErrors = {
  location_id: "",
  start_date: "",
  end_date: "",
  classroom_id: "",
  batch_minimum_size: "",
  batch_maximum_size: "",
  nomination_start_date: "",
  nomination_end_date: "",
  nomination_batch_minimum_size: "",
  nomination_batch_maximum_size: "",
  total_waitlist_count: "",
  assistant_trainer_ids: "",
  primary_trainer_id: "",
};

const ONGOING_STATUS = "ongoing";

const ScheduleCourse = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    schedule_id,
    course_id,
    load_factor,
    makeNonEditable,
    ongoing_status,
  } = location.state || {};
  const { locationList, classRoomList, calendarData } = useAppSelector(
    state => state.master
  );
  const [selectedTrainers, setSelectedTrainers] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState([]);

  const { courseContent } = useAppSelector(state => state.course);
  const { scheduleCourseById } = useAppSelector(state => state.loadFactor);
  const [lessonPlan, setLessonPlan] = useState(0);
  const [addedTrainers, setAddedTrainers] = useState({
    primary: [],
    secondary: [],
    others: [],
  });
  const [classroomOptions, setClassroomOptions] = useState([]);

  const [trainersList, setTrainersList] = useState([]);
  const [trainerListIds, setTrainerListIds] = useState([]);
  const [userMap, setUserMap] = useState({});
  const { usersList } = useAppSelector(state => state.user);
  const { trainerAvailability } = useAppSelector(state => state.course);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [isEditFlow, setIsEditFlow] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [otherTrainerChecked, setOtherTrainerChecked] = useState<string[]>([]);

  const isOnGoing = () => {
    return ongoing_status === ONGOING_STATUS;
  };

  useEffect(() => {
    setIsEditFlow(false);
    dispatch(setClassRoomToInitial());
    setFormValues(defaultFormValues);
    setErrors(defaultErrors);
    dispatch(getLocationList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetScheduleDataState());
    if (schedule_id) {
      dispatch(getScheduledCourseById(schedule_id));
    }
  }, [dispatch, schedule_id]);

  useEffect(() => {
    if (scheduleCourseById && schedule_id && Object.keys(userMap)?.length > 0) {
      const assistants = [];
      if (scheduleCourseById?.assistant_trainer_ids) {
        scheduleCourseById?.assistant_trainer_ids?.forEach(item => {
          if (item && userMap[item]?.user_name)
            assistants.push({
              value: userMap[item]?.user_name,
              label: userMap[item]?.user_name,
              name: item,
            });
        });
      }

      const assignedTrainer = {
        id: scheduleCourseById?.primary_trainer_id,
        name: userMap[scheduleCourseById?.primary_trainer_id]?.user_name,
        is_available: true,
        always_available: true,
      };

      const assignedClassroom = {
        id: scheduleCourseById?.classroom_id,
        name: scheduleCourseById?.classroom_name,
        is_available: true,
      };

      const resObj = {
        course_id: scheduleCourseById?.course_id,
        status: scheduleCourseById?.status,
        location_id: scheduleCourseById?.location_id,
        start_date: scheduleCourseById?.start_date
          ? new Date(scheduleCourseById?.start_date)
          : new Date(),
        end_date: scheduleCourseById?.end_date
          ? new Date(scheduleCourseById?.end_date)
          : new Date(),
        classroom_id: [assignedClassroom],
        batch_minimum_size: scheduleCourseById?.batch_minimum_size,
        batch_maximum_size: scheduleCourseById?.batch_maximum_size,
        nomination_start_date: scheduleCourseById?.nomination_start_date
          ? new Date(scheduleCourseById?.nomination_start_date)
          : new Date(),
        nomination_end_date: scheduleCourseById?.nomination_end_date
          ? new Date(scheduleCourseById?.nomination_end_date)
          : new Date(),
        nomination_batch_minimum_size:
          scheduleCourseById?.nomination_batch_minimum_size,
        nomination_batch_maximum_size:
          scheduleCourseById?.nomination_batch_maximum_size,
        waitlist_enable: scheduleCourseById?.waitlist_enable,
        rm_approval: scheduleCourseById?.rm_approval,
        self_enrollment: scheduleCourseById?.self_enrollment,
        total_waitlist_count: scheduleCourseById?.total_waitlist_count || 0,
        primary_trainer_id: [assignedTrainer],
        assistant_trainer_ids: assistants || [],
      };
      setIsEditFlow(true);
      setSelectedClassroom([assignedClassroom]);
      setSelectedTrainers([assignedTrainer]);

      if (scheduleCourseById?.location_id) {
        const formattedStartDate = moment(
          scheduleCourseById?.start_date
        ).format("YYYY-MM-DD");
        const formattedEndDate = moment(scheduleCourseById?.end_date).format(
          "YYYY-MM-DD"
        );

        dispatch(
          getClassRoomList({
            id: scheduleCourseById?.location_id,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            schedule_id: schedule_id || null,
          })
        );
      }
      setFormValues(resObj);
    }
  }, [dispatch, scheduleCourseById, schedule_id, userMap]);

  useEffect(() => {
    dispatch(getUsersList({ role: TRAINER_STATUS }));
    if (course_id) {
      dispatch(
        getCourseContent({
          id: course_id,
          course_version_id: load_factor ? course_id : null,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (trainerAvailability && trainerAvailability?.trainers?.length > 0) {
      setAddedTrainers(prevAddedTrainers => {
        const updatedAddedTrainers = { ...prevAddedTrainers };

        trainerAvailability.trainers.forEach(({ trainer_id, is_available }) => {
          Object.keys(updatedAddedTrainers).forEach(category => {
            updatedAddedTrainers[category] = updatedAddedTrainers[category].map(
              item => {
                if (item.id === trainer_id) {
                  return { ...item, available: is_available };
                }
                return item;
              }
            );
          });
        });

        return updatedAddedTrainers;
      });
    }
  }, [trainerAvailability]);

  useEffect(() => {
    if (formValues?.location_id) {
      const todaysDate = moment().format("YYYY-MM-DD");

      dispatch(
        fetchCalendar({
          date: todaysDate,
          location_id: formValues?.location_id,
        })
      );
    }
  }, [dispatch, formValues?.location_id]);

  useEffect(() => {
    if (
      formValues?.start_date &&
      formValues?.end_date &&
      trainerListIds?.length > 0
    ) {
      if (!isEditFlow) setSelectedTrainers([]);
      setOtherTrainerChecked([]);
      const formattedStartDate = moment(formValues.start_date).format(
        "YYYY-MM-DD"
      );
      const formattedEndDate = moment(formValues.end_date).format("YYYY-MM-DD");
      const trainerMap = {
        trainer_id: trainerListIds,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
      dispatch(
        getTrainersAvailability({
          trainerMap: trainerMap,
          schedule_id: schedule_id || null,
        })
      );
    }
  }, [dispatch, formValues?.start_date, formValues?.end_date, trainerListIds]);

  useEffect(() => {
    const userMapObj = {};
    const trainers = [];

    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
      trainers.push({
        label: user?.user_name,
        name: user?.id,
        value: user?.user_name,
      });
    });

    setTrainersList(trainers);
  }, [usersList, schedule_id]);

  const knowTrainerAvailability = id => {
    if (formValues?.start_date && formValues?.end_date) {
      const formattedStartDate = moment(formValues.start_date).format(
        "YYYY-MM-DD"
      );
      const formattedEndDate = moment(formValues.end_date).format("YYYY-MM-DD");
      const trainerMap = {
        trainer_id: id,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
      dispatch(
        getTrainersAvailability({
          trainerMap: trainerMap,
          schedule_id: schedule_id || null,
        })
      );
    }
  };

  useEffect(() => {
    const userMapObj = {};
    const trainers = [];
    const assignedTrainersList = {
      primary: [],
      secondary: [],
      others: [],
    };

    const assignedTrainersListIds = [];
    const checkTrainers = [];

    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
      trainers.push({
        label: user?.user_name,
        name: user?.id,
        value: user?.user_name,
      });
    });

    courseContent?.course_assignees?.forEach((item: any) => {
      if (item?.assignment_type === "instructor") {
        assignedTrainersListIds.push(item?.user_id);
        if (item?.user_priority === "primary") {
          checkTrainers.push(item?.user_id);
          assignedTrainersList["primary"].push({
            id: item?.user_id,
            name: userMapObj[item?.user_id]?.user_name,
            available:
              trainerAvailability?.trainers?.find(
                (trainer: any) => item?.user_id === trainer?.id
              )?.is_available || false,
          });
        } else {
          checkTrainers.push(item?.user_id);
          assignedTrainersList["secondary"].push({
            id: item?.user_id,
            name: userMapObj[item?.user_id]?.user_name,
            available:
              trainerAvailability?.trainers?.find(
                (trainer: any) => item?.user_id === trainer?.id
              )?.is_available || false,
          });
        }
      }
    });

    usersList?.users?.forEach(user => {
      if (!assignedTrainersListIds.includes(user?.id)) {
        assignedTrainersList["others"].push({
          id: user?.id,
          name: user?.user_name,
          available: false,
        });
      }
    });

    setUserMap(userMapObj);
    setTrainerListIds(assignedTrainersListIds);
    setTrainersList(trainers);
    setAddedTrainers(assignedTrainersList);

    if (checkTrainers?.length > 0) knowTrainerAvailability(checkTrainers);
  }, [usersList, courseContent?.course_assignees]);

  useEffect(() => {
    const assignedClassroom = [];
    classRoomList?.class_room?.forEach((item: any) => {
      assignedClassroom.push({
        id: item?.id,
        name: item?.name,
        available: item?.is_available,
      });
    });
    setClassroomOptions(assignedClassroom);
  }, [classRoomList]);

  useEffect(() => {
    const formattedStartDate = moment(formValues?.start_date).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(formValues?.end_date).format("YYYY-MM-DD");

    if (formValues?.location_id && formattedStartDate && formattedEndDate)
      dispatch(
        getClassRoomList({
          id: formValues?.location_id,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          schedule_id: schedule_id || null,
        })
      );
  }, [
    dispatch,
    formValues?.start_date,
    formValues?.end_date,
    formValues?.location_id,
    schedule_id,
  ]);

  useEffect(() => {
    if (courseContent?.competency_statement?.estimated_time) {
      setLessonPlan(
        Math.ceil(
          parseFloat(courseContent?.competency_statement?.estimated_time)
        )
      );
    }

    const minAttendance = courseContent?.additional_information?.min_attendance;
    const maxAttendance = courseContent?.additional_information?.max_attendance;

    if (minAttendance) {
      setFormValues(prevValues => {
        const newMinSize = minAttendance;
        return {
          ...prevValues,
          batch_minimum_size: newMinSize,
          nomination_batch_minimum_size: Math.round(newMinSize * 1.5),
        };
      });
    }

    if (maxAttendance) {
      setFormValues(prevValues => {
        const newMaxSize = maxAttendance;
        return {
          ...prevValues,
          batch_maximum_size: newMaxSize,
          nomination_batch_maximum_size: newMaxSize * 2,
        };
      });
    }
  }, [courseContent]);

  useEffect(() => {
    if (formValues.start_date && lessonPlan > 0 && !isEditFlow) {
      const currentDate = new Date(formValues.start_date);

      let daysAdded = 0;

      while (daysAdded < lessonPlan) {
        daysAdded++;

        currentDate.setDate(currentDate.getDate() + 1);
      }

      currentDate.setDate(currentDate.getDate() - 1);

      setFormValues(prevFormValues => ({
        ...prevFormValues,
        end_date: currentDate,
      }));
    }
  }, [formValues.start_date, lessonPlan, calendarData]);

  const getNextValidDate = initialDate => {
    const nextDate = new Date(initialDate);
    return nextDate;
  };

  useEffect(() => {
    if (formValues.start_date && !schedule_id) {
      let nominationStartDate = new Date(formValues.start_date);
      let nominationEndDate = new Date(formValues.start_date);

      const potentialStartDate = new Date(nominationStartDate);
      potentialStartDate.setDate(potentialStartDate.getDate() - 56);

      const potentialEndDate = new Date(nominationEndDate);
      potentialEndDate.setDate(potentialEndDate.getDate() - 28);

      const normalizeDate = date => {
        const normalized = new Date(date);
        normalized.setHours(0, 0, 0, 0);
        return normalized;
      };

      const today = normalizeDate(new Date());

      if (normalizeDate(potentialStartDate) >= today) {
        nominationStartDate.setDate(nominationStartDate.getDate() - 56);
      } else {
        nominationStartDate = today;
      }

      if (normalizeDate(potentialEndDate) >= today) {
        nominationEndDate.setDate(nominationEndDate.getDate() - 28);
      } else {
        nominationEndDate = today;
      }

      nominationStartDate = getNextValidDate(nominationStartDate);
      nominationEndDate = getNextValidDate(nominationEndDate);

      setFormValues(prevFormValues => ({
        ...prevFormValues,
        nomination_start_date: nominationStartDate,
        nomination_end_date: nominationEndDate,
      }));
    }
  }, [formValues.start_date, calendarData]);

  useEffect(() => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      nomination_batch_minimum_size: Math.round(
        formValues?.batch_minimum_size * 1.5
      ),
      nomination_batch_maximum_size: formValues?.batch_maximum_size * 2,
    }));
  }, [formValues?.batch_minimum_size, formValues?.batch_maximum_size]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNumericChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = Math.min(100, parseInt(value, 10) || 0);

    setFormValues(prevValues => ({
      ...prevValues,
      [name]: numericValue,
    }));
  };

  const getValidDate = (date: Date): Date => {
    const validDate = new Date(date);

    return validDate;
  };

  const isDateValid = (date, excludeDates, filterDate) => {
    return (
      !excludeDates.some(
        excludedDate =>
          excludedDate.getDate() === date.getDate() &&
          excludedDate.getMonth() === date.getMonth() &&
          excludedDate.getFullYear() === date.getFullYear()
      ) && filterDate(date)
    );
  };

  const showWarning = date => {
    const currentDate = new Date(date);
    const excludeDates = calendarData?.calendar?.map(
      (dateStr: any) => new Date(dateStr.date)
    );
    const isValid = isDateValid(currentDate, excludeDates, isWeekday);
    if (!isValid) {
      showToast(DateError, "warning");
    }
  };

  const onDateChange = (fieldName, date) => {
    if (date) {
      const validStartDate = getValidDate(new Date(date));

      if (fieldName === "start_date") {
        setIsEditFlow(false);
        if (isWithin56Days(date)) {
          showToast(StartDateError, "warning");
        }

        const nominationStartDate = new Date(validStartDate);
        const potentialStartDate = new Date(nominationStartDate);
        potentialStartDate.setDate(potentialStartDate.getDate() - 56);

        const nominationEndDate = new Date(validStartDate);
        const potentialEndDate = new Date(nominationEndDate);
        potentialEndDate.setDate(potentialEndDate.getDate() - 28);

        const normalizeDate = date => {
          const normalized = new Date(date);
          normalized.setHours(0, 0, 0, 0);
          return normalized;
        };

        const today = normalizeDate(new Date());

        if (normalizeDate(potentialStartDate) >= today) {
          nominationStartDate.setDate(nominationStartDate.getDate() - 56);
        } else {
          nominationStartDate.setFullYear(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          );
        }

        if (normalizeDate(potentialEndDate) >= today) {
          nominationEndDate.setDate(nominationEndDate.getDate() - 28);
        } else {
          nominationEndDate.setFullYear(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          );
        }

        const validNominationStartDate = getValidDate(nominationStartDate);
        const validNominationEndDate = getValidDate(nominationEndDate);

        setFormValues(prevFormValues => ({
          ...prevFormValues,
          [fieldName]: validStartDate,
          nomination_start_date: validNominationStartDate,
          nomination_end_date: validNominationEndDate,
        }));
      } else {
        setFormValues(prevFormValues => ({
          ...prevFormValues,
          [fieldName]: validStartDate,
        }));
      }
      showWarning(date);
    }
  };

  const validateForm = () => {
    const errors: any = {};

    if (!formValues.location_id || formValues.location_id === "") {
      errors.location_id = LocationRequired;
    }
    if (!formValues.classroom_id || formValues.classroom_id?.length === 0) {
      errors.classroom_id = ClassroomRequired;
    }
    if (
      !formValues.primary_trainer_id ||
      formValues.primary_trainer_id.length === 0
    ) {
      errors.primary_trainer_id = PrimaryTrainerRequired;
    }
    if (formValues.batch_minimum_size <= 0) {
      errors.batch_minimum_size = BatchMinimumSizeRequired;
    }
    if (formValues.batch_maximum_size <= formValues.batch_minimum_size) {
      errors.batch_maximum_size = BatchMaximumSizeRequired;
    }
    if (formValues.nomination_batch_minimum_size <= 0) {
      errors.nomination_batch_minimum_size = NominationBatchMinimumSizeRequired;
    }
    if (
      formValues.nomination_batch_maximum_size <=
      formValues.nomination_batch_minimum_size
    ) {
      errors.nomination_batch_maximum_size = NominationBatchMaximumSizeRequired;
    }
    if (formValues.waitlist_enable && formValues.total_waitlist_count <= 0) {
      errors.total_waitlist_count = TotalWaitlistCountRequired;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const nominationStartDate = formValues?.nomination_start_date
      ? new Date(formValues.nomination_start_date)
      : null;
    const nominationEndDate = formValues?.nomination_end_date
      ? new Date(formValues.nomination_end_date)
      : null;

    if (nominationStartDate) {
      nominationStartDate.setHours(0, 0, 0, 0);
      if (nominationStartDate < today) {
        errors.nomination_start_date = InvalidNominationStartDate;
      }
    }

    if (nominationEndDate) {
      nominationEndDate.setHours(0, 0, 0, 0);
      if (nominationEndDate < today) {
        errors.nomination_end_date = InvalidNominationEndDate;
      }
    }

    if (
      formValues.nomination_start_date &&
      formValues.nomination_end_date &&
      formValues.nomination_start_date > formValues.nomination_end_date
    ) {
      errors.nomination_start_date = InvalidNominationStartDateWithEndDate;
    }

    setErrors(errors);

    if (errors && Object.keys(errors)?.length > 0) {
      return false;
    }
    return true;
  };

  const onPublish = async () => {
    if (!validateForm()) {
      return;
    }

    const payload = { ...formValues };
    payload.primary_trainer_id =
      payload.primary_trainer_id?.[0]?.id || payload.primary_trainer_id || null;

    payload.classroom_id =
      payload.classroom_id?.[0]?.id || payload.classroom_id || null;

    const submissionPayload: any = {
      ...payload,
      start_date: moment(payload.start_date).format("YYYY-MM-DD"),
      course_version_id: load_factor ? course_id : null,
      end_date: moment(payload.end_date).format("YYYY-MM-DD"),
      nomination_start_date: moment(payload.nomination_start_date).format(
        "YYYY-MM-DD"
      ),
      nomination_end_date: moment(payload.nomination_end_date).format(
        "YYYY-MM-DD"
      ),
      assistant_trainer_ids:
        payload.assistant_trainer_ids?.map((item: any) => item?.name) || [],
      course_id: load_factor
        ? null
        : scheduleCourseById?.course_id || course_id,
      status: schedule_id
        ? scheduleCourseById?.status
        : "waiting_for_trainer_approval",
    };

    let response = null;
    if (schedule_id) {
      submissionPayload.id = schedule_id;
      response = await dispatch(updateScheduledCourse(submissionPayload));
    } else {
      response = await dispatch(scheduleCourse(submissionPayload));
    }

    if (response?.payload?.status_code === STATUS_200) {
      if (load_factor) return navigate("/load-factor");
      else
        return navigate(
          `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${scheduleCourseById?.course_id || course_id}`,
          {
            state: {
              courseId: scheduleCourseById?.course_id || course_id,
              scheduled: true,
            },
          }
        );
    }
  };

  return (
    <div className="page-body pb-5">
      <Card>
        <CardBody>
          <H3 className="mt-3">{courseContent?.course_name}</H3>
          <Divider />
          <div className="mt-1">
            <Row>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={Location}
                    tooltipText={LocationToolTipMessage}
                    important
                  />
                  <Input
                    name="location_id"
                    type="select"
                    value={formValues?.location_id}
                    onChange={e => {
                      handleChange(e);
                      onDateChange("classroom_id", "");
                      dispatch(setClassRoomToInitial());
                      setSelectedTrainers([]);
                      setSelectedClassroom([]);
                      setOtherTrainerChecked([]);
                      setFormValues(prevFormValues => ({
                        ...prevFormValues,
                        primary_trainer_id: [],
                        assistant_trainer_ids: [],
                      }));
                      const formattedStartDate = moment(
                        formValues?.start_date
                      ).format("YYYY-MM-DD");
                      const formattedEndDate = moment(
                        formValues?.end_date
                      ).format("YYYY-MM-DD");

                      dispatch(
                        getClassRoomList({
                          id: e.target.value?.toString(),
                          start_date: formattedStartDate,
                          end_date: formattedEndDate,
                          schedule_id: schedule_id || null,
                        })
                      );
                    }}
                    disabled={makeNonEditable || isOnGoing()}
                    invalid={!!errors.location_id}
                  >
                    <option value="" disabled>
                      {Select} {Location}
                    </option>
                    {locationList?.location?.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Input>
                  <div className="invalid-feedback">{errors.location_id}</div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={StartDate}
                    tooltipText={StartDateToolTipMessage}
                    important
                  />
                  <div className={"w-100"}>
                    <DatePicker
                      selected={
                        formValues?.start_date instanceof Date
                          ? formValues?.start_date
                          : new Date(formValues?.start_date)
                      }
                      onChange={(date: Date | null) =>
                        onDateChange("start_date", date)
                      }
                      placeholderText={`${Enter} ${StartDate}`}
                      className={`w-100 form-control ${errors?.start_date ? "is-invalid" : ""}`}
                      dateFormat="dd-MM-yyyy"
                      minDate={getNextDay(new Date())}
                      disabledKeyboardNavigation={true}
                      disabled={makeNonEditable || isOnGoing()}
                      onKeyDown={e => e.preventDefault()}
                      highlightDates={[
                        ...Array.from({ length: 52 }, (_, i) => {
                          const date = new Date();
                          date.setDate(date.getDate() - date.getDay() + i * 7);
                          return date;
                        }),
                        ...(Array.isArray(calendarData?.calendar)
                          ? calendarData.calendar.map(
                              (dateStr: any) => new Date(dateStr?.date)
                            )
                          : []),
                      ]}
                    />
                    {errors?.start_date && (
                      <div className="invalid-date-picker-feedback">
                        {errors?.start_date}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={EndDate}
                    tooltipText={EndDateToolTipMessage}
                    important
                  />
                  <div className={"w-100"}>
                    <DatePicker
                      selected={
                        formValues?.end_date instanceof Date
                          ? formValues?.end_date
                          : new Date(formValues?.end_date)
                      }
                      onChange={(date: Date | null) =>
                        onDateChange("end_date", date)
                      }
                      placeholderText={`${Enter} ${EndDate}`}
                      className={`w-100 form-control ${errors?.end_date ? "is-invalid" : ""}`}
                      dateFormat="dd-MM-yyyy"
                      disabled={makeNonEditable || isOnGoing()}
                      minDate={formValues?.start_date || getNextDay(new Date())}
                      disabledKeyboardNavigation={true}
                      onKeyDown={e => e.preventDefault()}
                      highlightDates={[
                        ...Array.from({ length: 52 }, (_, i) => {
                          const date = new Date();
                          date.setDate(date.getDate() - date.getDay() + i * 7);
                          return date;
                        }),
                        ...(Array.isArray(calendarData?.calendar)
                          ? calendarData.calendar.map(
                              (dateStr: any) => new Date(dateStr?.date)
                            )
                          : []),
                      ]}
                    />
                    {errors?.end_date && (
                      <div className="invalid-date-picker-feedback">
                        {errors?.end_date}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={ClassRoom}
                    tooltipText={ClassroomToolTipMessage}
                    important
                  />
                  <TrainerDropdown
                    isTrainer={false}
                    primaryTrainers={[]}
                    secondaryTrainers={[]}
                    otherTrainers={classroomOptions}
                    disabled={makeNonEditable}
                    isMultiSelect={false}
                    onBlur={(id: any, _type) => {
                      if (
                        id?.length > 0 &&
                        (id?.[0]?.available === false ||
                          id?.[0]?.is_available === false)
                      ) {
                        showToast(ClassroomUnavailable, "warning");
                      }
                      setFormValues(prevValues => ({
                        ...prevValues,
                        classroom_id: id,
                      }));
                    }}
                    selectedTrainers={selectedClassroom}
                    setSelectedTrainers={setSelectedClassroom}
                  />
                  <div className="invalid-feedback">{errors.classroom_id}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <LabelTooltip
                  label={MainTrainer}
                  tooltipText={MainTrainerToolTipMessage}
                  important
                />
                <TrainerDropdown
                  primaryTrainers={addedTrainers?.primary}
                  secondaryTrainers={addedTrainers?.secondary}
                  otherTrainers={addedTrainers?.others}
                  disabled={makeNonEditable || isOnGoing()}
                  isMultiSelect={false}
                  onBlur={(id, type) => {
                    let isPrimary = false;
                    let isSecondary = false;
                    if (type === "primary") {
                      isPrimary = true;
                    } else {
                      trainerAvailability?.trainers?.forEach(item => {
                        if (
                          item?.trainer_id === id[0]?.id &&
                          item?.is_available
                        ) {
                          isSecondary = true;
                        }
                      });
                    }
                    if (
                      (isPrimary && id[0]?.available) ||
                      id[0]?.always_available ||
                      isSecondary
                    ) {
                      setFormValues(prevValues => ({
                        ...prevValues,
                        primary_trainer_id: id,
                      }));
                      const assistantTrainerNames =
                        formValues?.assistant_trainer_ids?.map(
                          trainer => trainer.name
                        ) || [];

                      if (assistantTrainerNames?.includes(id[0]?.id)) {
                        setFormValues(prevValues => ({
                          ...prevValues,
                          primary_trainer_id: id,
                          assistant_trainer_ids: [],
                        }));
                      }
                    } else if (id[0]) {
                      let isAvailable = false;
                      trainerAvailability?.trainers?.forEach(item => {
                        if (
                          item?.trainer_id === id[0]?.id &&
                          item?.is_available
                        ) {
                          isAvailable = true;
                        }
                      });
                      if (!isAvailable) {
                        showToast(TrainerUnavailable, "warning");
                      }
                      setFormValues(prevValues => ({
                        ...prevValues,
                        primary_trainer_id: id,
                      }));
                      const assistantTrainerNames =
                        formValues?.assistant_trainer_ids?.map(
                          trainer => trainer.name
                        ) || [];

                      if (assistantTrainerNames?.includes(id[0]?.id)) {
                        setFormValues(prevValues => ({
                          ...prevValues,
                          primary_trainer_id: id,
                          assistant_trainer_ids: [],
                        }));
                      }
                    }
                  }}
                  selectedTrainers={selectedTrainers}
                  setSelectedTrainers={setSelectedTrainers}
                  knowTrainerAvailability={knowTrainerAvailability}
                  otherTrainerChecked={otherTrainerChecked}
                  setOtherTrainerChecked={setOtherTrainerChecked}
                />
                {errors.primary_trainer_id && (
                  <div className="mt-1 text-danger">
                    {errors.primary_trainer_id}
                  </div>
                )}
              </Col>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={AssistantTrainer}
                    tooltipText={AssistantTrainerToolTipMessage}
                    important={false}
                  />
                  <MultiSelectDropdown
                    disabled={makeNonEditable || isOnGoing()}
                    onChange={values => {
                      const selected = [];
                      trainersList?.forEach(item => {
                        if (values.includes(item?.name)) {
                          selected.push(item);
                        }
                      });

                      setFormValues(prevValues => ({
                        ...prevValues,
                        assistant_trainer_ids: selected,
                      }));
                    }}
                    options={trainersList
                      ?.filter(item => {
                        if (formValues?.primary_trainer_id?.length > 0)
                          return (
                            formValues?.primary_trainer_id[0]?.id !== item?.name
                          );
                        else if (selectedTrainers?.length > 0) {
                          return selectedTrainers[0]?.id !== item?.name;
                        } else return item;
                      })
                      .map(item => ({
                        name: item?.name,
                        label: item.label,
                        value: item.value,
                      }))}
                    placeholder={AssistantTrainer}
                    defaultSelected={formValues?.assistant_trainer_ids}
                  />

                  {errors.assistant_trainer_ids && (
                    <div className="mt-1 text-danger">
                      {errors.assistant_trainer_ids}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={NominationStartDate}
                    tooltipText={NominationStartDateToolTipMessage}
                    important
                  />
                  <div className={"w-100"}>
                    <DatePicker
                      selected={
                        formValues?.nomination_start_date instanceof Date
                          ? formValues?.nomination_start_date
                          : new Date(formValues?.nomination_start_date)
                      }
                      onChange={(date: Date | null) =>
                        onDateChange("nomination_start_date", date)
                      }
                      placeholderText={`${Enter} ${NominationStartDate}`}
                      className={`w-100 form-control ${errors?.nomination_start_date ? "is-invalid" : ""}`}
                      dateFormat="dd-MM-yyyy"
                      disabled={makeNonEditable || isOnGoing()}
                      minDate={new Date()}
                      maxDate={
                        formValues?.start_date
                          ? subDays(new Date(formValues.start_date), 1)
                          : new Date()
                      }
                      disabledKeyboardNavigation={true}
                      onKeyDown={e => e.preventDefault()}
                      highlightDates={[
                        ...Array.from({ length: 52 }, (_, i) => {
                          const date = new Date();
                          date.setDate(date.getDate() - date.getDay() + i * 7);
                          return date;
                        }),
                      ]}
                    />
                    {errors?.nomination_start_date && (
                      <div className="invalid-date-picker-feedback">
                        {errors?.nomination_start_date}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <LabelTooltip
                    label={NominationEndDate}
                    tooltipText={NominationEndDateToolTipMessage}
                    important
                  />
                  <div className={"w-100"}>
                    <DatePicker
                      selected={
                        formValues?.nomination_end_date instanceof Date
                          ? formValues?.nomination_end_date
                          : new Date(formValues?.nomination_end_date)
                      }
                      onChange={(date: Date | null) =>
                        onDateChange("nomination_end_date", date)
                      }
                      placeholderText={`${Enter} ${NominationEndDate}`}
                      className={`w-100 form-control ${errors?.nomination_end_date ? "is-invalid" : ""}`}
                      dateFormat="dd-MM-yyyy"
                      disabled={makeNonEditable || isOnGoing()}
                      disabledKeyboardNavigation={true}
                      minDate={formValues?.nomination_start_date || new Date()}
                      maxDate={
                        formValues?.start_date
                          ? subDays(new Date(formValues.start_date), 1)
                          : new Date()
                      }
                      onKeyDown={e => e.preventDefault()}
                      highlightDates={[
                        ...Array.from({ length: 52 }, (_, i) => {
                          const date = new Date();
                          date.setDate(date.getDate() - date.getDay() + i * 7);
                          return date;
                        }),
                      ]}
                    />
                    {errors?.nomination_end_date && (
                      <div className="invalid-date-picker-feedback">
                        {errors?.nomination_end_date}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <LabelTooltip
                  important
                  label={BatchMinimumSize}
                  tooltipText={BatchMinSizeToolTipMessage}
                />
                <Input
                  type="number"
                  name="batch_minimum_size"
                  inputMode="numeric"
                  value={formValues?.batch_minimum_size}
                  onChange={handleNumericChange}
                  disabled={makeNonEditable}
                  placeholder={`${Enter} ${BatchMinimumSize}`}
                  invalid={!!errors.batch_minimum_size}
                  min={1}
                  max={100}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                />
                {errors.batch_minimum_size && (
                  <div className="invalid-feedback">
                    {errors.batch_minimum_size}
                  </div>
                )}
              </Col>
              <Col>
                <LabelTooltip
                  important
                  label={BatchMaximumSize}
                  tooltipText={BatchMaxSizeToolTipMessage}
                />
                <Input
                  type="number"
                  name="batch_maximum_size"
                  inputMode="numeric"
                  value={formValues?.batch_maximum_size}
                  disabled={makeNonEditable}
                  onChange={handleNumericChange}
                  placeholder={`${Enter} ${BatchMaximumSize}`}
                  invalid={!!errors.batch_maximum_size}
                  min={1}
                  max={100}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                />
                {errors.batch_maximum_size && (
                  <div className="invalid-feedback">
                    {errors.batch_maximum_size}
                  </div>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <LabelTooltip
                  important
                  label={NominationBatchMinimumSize}
                  tooltipText={NominationBatchMinSizeToolTipMessage}
                />
                <Input
                  type="number"
                  name="nomination_batch_minimum_size"
                  inputMode="numeric"
                  value={formValues?.nomination_batch_minimum_size}
                  onChange={handleNumericChange}
                  disabled={makeNonEditable}
                  placeholder={`${Enter} ${NominationBatchMinimumSize}`}
                  invalid={!!errors.nomination_batch_minimum_size}
                  min={1}
                  max={100}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                />
                {errors.nomination_batch_minimum_size && (
                  <div className="invalid-feedback">
                    {errors.nomination_batch_minimum_size}
                  </div>
                )}
              </Col>
              <Col>
                <LabelTooltip
                  important
                  label={NominationBatchMaximumSize}
                  tooltipText={NominationBatchMaxSizeToolTipMessage}
                />
                <Input
                  type="number"
                  name="nomination_batch_maximum_size"
                  inputMode="numeric"
                  value={formValues?.nomination_batch_maximum_size}
                  onChange={handleNumericChange}
                  placeholder={`${Enter} ${NominationBatchMaximumSize}`}
                  invalid={!!errors.nomination_batch_maximum_size}
                  min={1}
                  disabled={makeNonEditable}
                  max={100}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                />
                {errors.nomination_batch_maximum_size && (
                  <div className="invalid-feedback">
                    {errors.nomination_batch_maximum_size}
                  </div>
                )}
              </Col>
            </Row>
            <div className="d-flex gap-3 mt-3">
              <div>
                <div className="form-check text-dark">
                  <Input
                    className="primary-checkbox"
                    id="flexCheckWaitlistEnable"
                    type="checkbox"
                    checked={formValues?.waitlist_enable}
                    onChange={() => {
                      setFormValues(prevValues => ({
                        ...prevValues,
                        waitlist_enable: !prevValues.waitlist_enable,
                        total_waitlist_count: 0,
                      }));
                    }}
                    disabled={makeNonEditable || isOnGoing()}
                  />
                  <Label
                    className="text-dark"
                    htmlFor="flexCheckWaitlistEnable"
                    check
                  >
                    {WaitlistEnable}
                  </Label>
                </div>
              </div>
              <div>
                <div className="form-check text-dark">
                  <Input
                    className="primary-checkbox"
                    id="flexCheckRMApproval"
                    type="checkbox"
                    disabled={makeNonEditable || isOnGoing()}
                    checked={formValues?.rm_approval}
                    onChange={() =>
                      setFormValues(prevValues => ({
                        ...prevValues,
                        rm_approval: !prevValues.rm_approval,
                      }))
                    }
                  />
                  <Label
                    className="text-dark"
                    htmlFor="flexCheckRMApproval"
                    check
                  >
                    {RMApproval}
                  </Label>
                </div>
              </div>
              <div>
                <div className="form-check text-dark">
                  <Input
                    className="primary-checkbox"
                    id="flexCheckSelfEnrollment"
                    type="checkbox"
                    checked={formValues?.self_enrollment}
                    disabled={makeNonEditable || isOnGoing()}
                    onChange={() =>
                      setFormValues(prevValues => ({
                        ...prevValues,
                        self_enrollment: !prevValues.self_enrollment,
                      }))
                    }
                  />
                  <Label
                    className="text-dark"
                    htmlFor="flexCheckSelfEnrollment"
                    check
                  >
                    {SelfEnrollmentEnable}
                  </Label>
                </div>
              </div>
            </div>
          </div>
          {formValues?.waitlist_enable && (
            <Row>
              <Col lg={6}>
                <LabelTooltip
                  important
                  label={WaitlistNo}
                  tooltipText={WaitlistCountToolTipMessage}
                />
                <Input
                  type="number"
                  name="total_waitlist_count"
                  inputMode="numeric"
                  value={formValues?.total_waitlist_count}
                  onChange={handleNumericChange}
                  placeholder={`${Enter} ${WaitlistNo}`}
                  invalid={!!errors.total_waitlist_count}
                  min={1}
                  max={100}
                  disabled={makeNonEditable || isOnGoing()}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                />
                {errors.total_waitlist_count && (
                  <div className="invalid-feedback">
                    {errors.total_waitlist_count}
                  </div>
                )}
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      {(!schedule_id ||
        (schedule_id &&
          !editCourseScheduleStatus.includes(scheduleCourseById?.status))) && (
        <FixedFooter>
          <DefaultButton color="primary" onClick={onPublish}>
            {Publish}
          </DefaultButton>
        </FixedFooter>
      )}
    </div>
  );
};

export default ScheduleCourse;
