import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";

import { H3, ToolTip } from "../../../../../AbstractElements";
import DateRangeDisplay from "../../../../../CommonElements/DateRangeDisplay";
import StatusDisplay from "../../../../../CommonElements/StatusDisplay";
import FlatContainer from "../../../../../container/FlatContainer";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import { getCourseModuleFeedabck } from "../../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import {
  ContentType,
  Location,
  ModuleFeedbackTitle,
  Nomination,
  PageNo,
  Room,
  Status,
} from "../../../../../utils/Constant";
import { truncateText } from "../../../../../utils/helper/helper";

const ModuleFeedbackList = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { questionsList } = useAppSelector(state => state.feedback);
  const [tooltipModuleNameOpen, setTooltipModuleNameOpen] = useState<{
    [key: number]: boolean;
  }>({});
  const [tooltipDescriptionOpen, setTooltipDescriptionOpen] = useState<{
    [key: number]: boolean;
  }>({});

  useEffect(() => {
    getFeedbackList();
  }, []);

  const getFeedbackList = () => {
    dispatch(
      getCourseModuleFeedabck({
        type: "module",
        courseScheduleId: location.state?.course_schedule_id,
        participant_id: location.state?.participant_id,
      })
    );
  };

  const handleModuleNameTooltip = (id: number) => {
    setTooltipModuleNameOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDescriptionTooltip = (id: number) => {
    setTooltipDescriptionOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="page-body pb-5">
      <Card>
        <CardBody className="m-2">
          <H3 className="fw-bold">{location.state?.course_name}</H3>
          <Row className="mt-3">
            <Col lg="4" className="mt-1">
              <DateRangeDisplay
                startDate={location.state?.course_start_date}
                endDate={location.state?.course_end_date}
              />
            </Col>
            <Col className="d-flex justify-content-between mt-3"></Col>
          </Row>
          <div className="d-flex justify-content-between mt-4">
            <StatusDisplay
              label={Location}
              number={location.state?.location_name}
            />
            <StatusDisplay
              label={Room}
              number={location.state?.classroom_name}
            />
            <StatusDisplay
              label={Nomination}
              number={
                location.state?.nominationStartDate +
                " - " +
                location.state?.nominationEndDate
              }
            />
            <StatusDisplay label={Status} number={location.state?.status} />
          </div>
          <div className="mb-2 mt-3">
            <Divider />
          </div>

          <div className="module-feedback__wrapper my-3">
            <H3 className="fw-bold">{ModuleFeedbackTitle}</H3>
          </div>
          {questionsList?.module_feedback?.map(item => (
            <div key={item.id} className="py-2">
              <FlatContainer
                id={item.id}
                description={[
                  {
                    label: `${ContentType}:`,
                    value: ` ${item.content_type}`,
                    columnSize: 3,
                  },
                  {
                    label: `${PageNo}:`,
                    value: ` ${item?.page_no?.toString()}`,
                    columnSize: 2,
                  },
                  {
                    label: "",
                    value: (
                      <div>
                        <span
                          id={`tooltip-industry-description-${item.id}`}
                          className="text-truncate"
                        >
                          {item.feedback && truncateText(item.feedback, 50)}
                        </span>
                        <ToolTip
                          placement="top"
                          target={`tooltip-industry-description-${item.id}`}
                          isOpen={tooltipDescriptionOpen[item.id] || false}
                          toggle={() => handleDescriptionTooltip(item.id)}
                        >
                          {item.feedback}
                        </ToolTip>
                      </div>
                    ),
                    columnSize: 4,
                  },
                ]}
                title={
                  <div>
                    <span
                      id={`tooltip-industry-name-${item.id}`}
                      className="text-truncate"
                    >
                      {item.module_name && truncateText(item.module_name, 20)}
                    </span>
                    <ToolTip
                      placement="top"
                      target={`tooltip-industry-name-${item.id}`}
                      isOpen={tooltipModuleNameOpen[item.id] || false}
                      toggle={() => handleModuleNameTooltip(item.id)}
                    >
                      {item.module_name}
                    </ToolTip>
                  </div>
                }
                actions={[]}
              />
              <Divider className="my-2" />
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default ModuleFeedbackList;
